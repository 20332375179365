import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import Checkbox from '../common/checkbox';
import MenuV2 from '../common/menu/menu-v2';
import CustomTooltip from '../common/tooltip-new';

const JobTypeListItem = ({
  jobType,
  onDeleteJobType = () => {},
  menuPortal,
  checked,
  setChecked,
  isFromAddJobType = false,
}) => {
  const { t } = useTranslation();

  const { user } = useSelector(state => state.user);
  const { organizations } = user || {};
  const { id: organisation_id } = organizations?.[0] || {};

  const { owner, id: job_type_id, name, description } = jobType || {};
  const { id: owner_id } = owner || {};

  const is_job_type_owner = owner_id === organisation_id;

  const handleJobTypeItemClick = () => {
    if (isFromAddJobType) {
      if (checked.includes(jobType)) {
        setChecked(checked.filter(p => p !== jobType));
      } else {
        setChecked([...checked, jobType]);
      }
    }
  };

  return (
    <JobTypeListItemWrapper
      className="data-container items-center justify-center w-full py-0 cursor sidebar-close-ignore"
      onClick={handleJobTypeItemClick}>
      {isFromAddJobType && (
        <Checkbox onChange={handleJobTypeItemClick} checked={checked.includes(jobType)} is_checked_done={true} />
      )}
      <div className="brd-right flex items-center w-full">
        <CustomTooltip
          wrapperClassName="w-full one-line"
          id={`job-type-name-tooltip-${job_type_id}`}
          content={<label className="inter-500-text white-space-break-spaces">{name}</label>}>
          <label className="inter-500-text color-neutral-900 font-14 one-line line-height-20">{name || '-'}</label>
        </CustomTooltip>
      </div>
      <div className="brd-right flex items-center w-full overflow-hidden">
        <CustomTooltip
          wrapperClassName="w-full one-line"
          hidden={!description}
          id={`job-type-description-tooltip-${job_type_id}`}
          content={<label className="inter-500-text white-space-break-spaces">{description}</label>}>
          <label
            className={classNames(
              'inter-400-text font-14 one-line line-height-20',
              description && 'color-neutral-900',
              !description && 'natural-400-text',
            )}>
            {description || 'No description'}
          </label>
        </CustomTooltip>
      </div>
      {!isFromAddJobType && (
        <div>
          <MenuV2
            menuList={useFilteredMenuList({
              menuList: [
                { name: t('EDIT'), onClick: () => {}, permission: '' },
                ...(is_job_type_owner
                  ? []
                  : [
                      {
                        name: t('REMOVE'),
                        onClick: () => onDeleteJobType(jobType, is_job_type_owner),
                        permission: '',
                      },
                    ]),
              ],
            })}
            iconClassname="rotate-90 dots-icon"
            portal={menuPortal}
          />
        </div>
      )}
    </JobTypeListItemWrapper>
  );
};

const JobTypeListItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};

  &:hover {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .custom-tooltip {
    padding: 10px 16px;
    max-width: 320px;
  }
`;

export default JobTypeListItem;
