import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SkeletonTransition from '../../components/common/skeleton-transition';
import { getRoofDetailsFromManualOrImported } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import {
  getPropertyDetails,
  getPropertyImages,
  getRoofInformation,
  getRoofPanelInformation,
  setRoofPanelInformation,
  updateRoofPanels,
  updateRoofSegment,
} from '../../store/features/propertySlice';
import MapLeafLet from './Leaflet';

const RoofDetailsV1 = ({ fromProperty, property_id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showErrorToast } = useError();
  const { property_id: property_id_param } = useParams();

  const { propertyDetails, roofInformation, roofPanelInformation } = useSelector(state => state.property);
  const { details } = propertyDetails || {};

  const segments = useMemo(() => {
    return (
      (roofInformation &&
        roofInformation?.map(({ segments, whole_roof }) => segments.map(s => ({ ...s, whole_roof }))).flat()) ||
      []
    );
  }, [roofInformation]);

  const [propertyDetailsLoading, setPropertyDetailsLoading] = useState(true);
  const [roofInfoLoading, setRoofInfoLoading] = useState(false);
  const [roofPanels, setRoofPanels] = useState([]);
  const [segmentsState, setSegmentsState] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [segmentsToSave, setSegmentsToSave] = useState([]);
  const [panelsToDelete, setPanelsToDelete] = useState([]);

  const propertyId = fromProperty ? property_id_param : property_id;

  const fetchPropertyDetails = property_id => {
    setPropertyDetailsLoading(true);
    dispatch(getPropertyDetails({ property_id: property_id }))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_DETAILS') }))
      .finally(() => setPropertyDetailsLoading(false));
  };

  const fetchRoofDetailsHandler = property_id => {
    setRoofInfoLoading(true);
    dispatch(getRoofInformation({ id: property_id }))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_ROOF_DETAILS') }))
      .finally(() => setRoofInfoLoading(false));
  };

  const fetchRoofPanels = property_id => {
    const wholeRoofIds = roofInformation?.map(({ whole_roof }) => whole_roof.id);
    Promise.all(wholeRoofIds.map(roof_id => dispatch(getRoofPanelInformation({ id: property_id, roof_id }))))
      .then(data => {
        const updatedRoofPanels = data.flat();
        setRoofPanels(updatedRoofPanels);
        dispatch(setRoofPanelInformation(updatedRoofPanels));
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PANEL_DETAILS') }));
  };

  const fetchPropertyImages = property_id => {
    dispatch(getPropertyImages({ id: property_id }))
      .then(data => {
        setPropertyImages(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_IMAGES') }));
  };

  const updateSegmentsToSave = (segment_id, action = 'add') => {
    const updatedSegmentsToSave = segmentsToSave.filter(id => id !== segment_id);
    if (action === 'add') {
      setSegmentsToSave([...segmentsToSave, segment_id]);
    }
    if (action === 'remove') {
      setSegmentsToSave(updatedSegmentsToSave);
    }
  };

  const onSegmentBoundryUpdate = (segment_id, boundary, area) => {
    const firstBoundary = boundary?.[0];
    const updatedSegments = segmentsState.map(segment => {
      if (segment.id === segment_id) {
        return { ...segment, manual: { ...segment.manual, boundary: [...boundary, firstBoundary], area } };
      }
      return segment;
    });
    setSegmentsState(updatedSegments);
    updateSegmentsToSave(segment_id);
  };

  const onSegmentDetailsUpdate = (segment_id, key, value) => {
    const updatedSegments = segmentsState.map(segment => {
      if (segment.id === segment_id) {
        return { ...segment, manual: { ...segment.manual, [key]: value } };
      }
      return segment;
    });
    setSegmentsState(updatedSegments);
    updateSegmentsToSave(segment_id);
  };

  const onCancelSegment = segment_id => {
    const currentSegment = segments.find(segment => segment.id === segment_id);
    const updatedRoofPanels = roofPanels.map(panel => {
      if (panel.roof?.id === segment_id) {
        const currentPanel = roofPanelInformation.find(({ id }) => id === panel.id);
        return { ...currentPanel };
      }
      return panel;
    });
    const updatedSegments = segmentsState.map(segment => {
      if (segment.id === segment_id) {
        return { ...currentSegment };
      }
      return segment;
    });
    setRoofPanels(updatedRoofPanels);
    setSegmentsState(updatedSegments);
    updateSegmentsToSave(segment_id, 'remove');
  };

  const onSaveSegment = async segment_id => {
    const segment = segmentsState.find(segment => segment.id === segment_id);
    const manualBoundary = getRoofDetailsFromManualOrImported(segment, 'boundary', false, false, false);
    const manualArea = getRoofDetailsFromManualOrImported(segment, 'area', false, false, false);
    const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', false, false, false);
    const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false);
    const whole_roof_id = segment.whole_roof.id;

    const request = {
      boundary: manualBoundary,
      area: manualArea,
      azimuth: azimuth,
      pitch: pitch,
    };

    const panelRequest = roofPanels
      .filter(panel => panel.roof?.id === segment_id)
      .filter(({ id }) => !panelsToDelete.includes(id));

    try {
      const data = await dispatch(updateRoofPanels({ id: propertyId, roof_id: segment_id, request: panelRequest }));
      await dispatch(updateRoofSegment({ id: propertyId, roof_id: segment_id, whole_roof_id, request }));
      const updatedPanelIds = data.map(panel => panel.id);
      const updatedPanels = roofPanels.filter(panel =>
        panel.roof?.id === segment_id ? updatedPanelIds.includes(panel.id) : true,
      );

      setRoofPanels(updatedPanels);
      dispatch(setRoofPanelInformation(updatedPanels));
      updateSegmentsToSave(segment_id, 'remove');
    } catch (error) {
      showErrorToast({ error, default_message: t('ERROR_WHILE_SAVING_SEGMENT_AND_PANELS') });
    }
  };

  const onDeleteSegment = segment_id => {};

  const onUpdatePanelPaths = (panel_id, panelCenter, segment_id) => {
    const updatedPanels = roofPanels.map(panel => {
      if (panel.id === panel_id) {
        return { ...panel, center: panelCenter };
      }
      return panel;
    });
    setRoofPanels(updatedPanels);
    updateSegmentsToSave(segment_id);
  };

  const onUpdatePanelCenter = (panels, segment_ids) => {
    const panelIds = panels.map(panel => panel.id);
    const updatedPanels = roofPanels.map(panel => {
      if (panelIds.includes(panel.id)) {
        const panelData = panels.find(({ id }) => id === panel.id);
        return { ...panelData };
      }
      return panel;
    });
    const updatedSegments = segmentsToSave.filter(segment_id => !segment_ids.includes(segment_id));
    setRoofPanels(updatedPanels);
    setSegmentsToSave([...updatedSegments, ...segment_ids]);
  };

  const onPanelSelectForDelete = panel_id => {
    const isPanelAlreadySelected = panelsToDelete.includes(panel_id);
    if (isPanelAlreadySelected) {
      setPanelsToDelete(panelsToDelete.filter(id => id !== panel_id));
    } else {
      setPanelsToDelete([...panelsToDelete, panel_id]);
    }
  };

  useEffect(() => {
    const propertyId = fromProperty ? property_id_param : property_id;
    if (propertyId) {
      if (fromProperty) {
        fetchPropertyDetails(propertyId);
      } else {
        setPropertyDetailsLoading(false);
      }
      fetchRoofDetailsHandler(propertyId);
      fetchPropertyImages(propertyId);
    }
  }, [property_id_param, fromProperty, property_id]);

  useEffect(() => {
    const propertyId = fromProperty ? property_id_param : property_id;
    if (roofInformation?.length && propertyId) {
      fetchRoofPanels(propertyId);
    }
  }, [roofInformation?.length, property_id_param, fromProperty, property_id]);

  useEffect(() => {
    setSegmentsState(segments);
  }, [segments]);

  return (
    <SkeletonTransition
      loading={roofInfoLoading || propertyDetailsLoading}
      loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
      height={'100%'}
      containerClassName="line-height-1 h-full w-full rounded-3">
      <div className="overflow-scroll edit-roof flex-1">
        <div className={'flex items-center justify-center radius-3 entire-map-wrapper h-full relative'}>
          <MapLeafLet
            coordinates={propertyDetails.location}
            roofInformation={roofInformation}
            isSolar={false}
            isRoofEdit={true}
            additionalOptions={{ draggable: true }}
            property_id={fromProperty ? property_id_param : property_id}
            roofPanels={roofPanels}
            segments={segmentsState}
            onSegmentBoundryUpdate={onSegmentBoundryUpdate}
            onSaveSegment={onSaveSegment}
            onDeleteSegment={onDeleteSegment}
            onUpdatePanelPaths={onUpdatePanelPaths}
            propertyImages={propertyImages}
            segmentsToSave={segmentsToSave}
            onSegmentDetailsUpdate={onSegmentDetailsUpdate}
            onPanelSelectForDelete={onPanelSelectForDelete}
            panelsToDelete={panelsToDelete}
            annual_electric_kwh={details?.annual_electric_kwh}
            onClearPanelSelection={() => setPanelsToDelete([])}
            onUpdatePanelCenter={onUpdatePanelCenter}
            onCancelSegment={onCancelSegment}
          />
        </div>
      </div>
    </SkeletonTransition>
  );
};

export default RoofDetailsV1;
