import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import JobTypeListItem from './job-type-list-items';

const JobTypeList = ({
  debouncedSearch = '',
  fetchMoreData,
  showLoading,
  jobTypes,
  hasMore,
  isFromAddJobType = false,
  setSelectedJobTypes,
  selectedJobTypes = [],
  onDeleteJobType = () => {},
}) => {
  const { t } = useTranslation();

  const jobTypeListTableHeaders = useMemo(() => {
    return [
      ...(isFromAddJobType ? [{ name: '', key: 'ITEM_SELECTOR' }] : []),
      { name: t('NAME'), key: 'NAME' },
      { name: t('DESCRIPTION'), key: 'DESCRIPTION' },
      ...(isFromAddJobType ? [] : [{ name: '', key: 'MENU' }]),
    ];
  }, []);

  return (
    <JobTypeListWrapper
      className={classNames('flex-column flex-1 w-full radius-1 overflow-hidden', isFromAddJobType ? 'pt-6' : 'p-6')}>
      <SkeletonTransition
        loading={showLoading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full add-product-table">
        {jobTypes?.length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader
                headers={jobTypeListTableHeaders}
                is_cBAChecked_done={true}
                allSelected={selectedJobTypes.length === jobTypes.length}
                is_checked_done={true}
                onSelectorClick={() => {
                  if (selectedJobTypes.length === jobTypes.length) {
                    setSelectedJobTypes([]);
                  } else {
                    setSelectedJobTypes(jobTypes.map(jobType => jobType));
                  }
                }}
              />
              <InfiniteScrollV2 hasMore={hasMore} fetchMoreData={fetchMoreData} containerId="">
                {(jobTypes || []).map((jobTypeData, index) => (
                  <JobTypeListItem
                    jobType={jobTypeData}
                    key={jobTypeData?.id}
                    menuPortal={jobTypes?.length < 3}
                    checked={selectedJobTypes}
                    setChecked={setSelectedJobTypes}
                    isFromAddJobType={isFromAddJobType}
                    onDeleteJobType={onDeleteJobType}
                  />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData
            className={classNames('flex-1', isFromAddJobType && 'add-product-table')}
            title={t('NO_RESULTS_FOUND')}
            description={t('NO_RESULTS_DESCRIPTION')}
          />
        ) : (
          <div className="flex-column flex-1 items-center justify-center">
            <NoData
              className={classNames('search-project', isFromAddJobType && 'add-product-table')}
              title={t('NO_JOB_TYPE')}
              description={t(isFromAddJobType ? 'NO_DEFAULT_JOB_TYPE_HERE' : 'NO_JOB_TYPE_HERE')}
              EmptyIcon={NoDocumentIcon}
              iconClassName="relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </JobTypeListWrapper>
  );
};

const JobTypeListWrapper = styled.div`
  border-radius: 6px;

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .search-project {
    max-width: 400px;
  }
`;

export default JobTypeList;
