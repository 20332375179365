import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AccountsIcon } from '../../assets/images/sidebar/accounts.svg';
import { ReactComponent as AppointmentIcon } from '../../assets/images/sidebar/appointments.svg';
import { ReactComponent as AutomationLogsIcon } from '../../assets/images/sidebar/automation-logs.svg';
import { ReactComponent as AutomationsIcon } from '../../assets/images/sidebar/automations.svg';
import { ReactComponent as IntegrationsIcon } from '../../assets/images/sidebar/integrations.svg';
import { ReactComponent as JobsIcon } from '../../assets/images/sidebar/jobs.svg';
import { ReactComponent as PlatformsIcon } from '../../assets/images/sidebar/platforms.svg';
import { ReactComponent as PropertiesIcon } from '../../assets/images/sidebar/properties.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/sidebar/settings.svg';
import { ReactComponent as TasksIcon } from '../../assets/images/sidebar/tasks.svg';
import AblyProviderWrapper from '../../components/ably/ably-provider-wrapper';
import MainLayout from '../../components/main-layout';
import { OrganisationContext } from '../../context/organisationContext';
import { getUsersPermissions, isUserSassOperator } from '../../helpers/utils';
import Modal from '../../popup';
import history from '../../routes/history';
import { ROUTE_PATHS } from '../../routes/route-paths';
import SideModel from '../../sidemodel';
import { getBoardList } from '../../store/features/boardSlice';
import { addToast } from '../../store/features/toastSlice';
import { MainContentWrapper } from '../../styles/pages/main-content.styled';

const Main = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);
  const { user } = useSelector(state => state.user);

  const [modal, setModal] = useState({
    type: null,
    content: {},
  });
  const [insideModal, setInsideModal] = useState({
    type: null,
    content: {},
  });
  const [sideModal, setSideModal] = useState({
    type: null,
    content: {},
  });

  const permissions = useMemo(() => {
    return getUsersPermissions();
  }, [user]);

  const hasPermission = permission => {
    return permissions?.includes(permission);
  };

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      !!(permissionList.length > 0 && permissionList.find(permission => permissions?.includes(permission)))
    );
  };

  const [selectedPath, setSelectedPath] = useState(location.pathname);

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: t('YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'),
          }),
        );
  };

  const items = useMemo(
    () => [
      {
        id: 'PROPERTIES',
        icon: PropertiesIcon,
        text: t('PROPERTIES'),
        redirect: '/properties',
        selected: location.pathname === '/properties',
        subitems: [],
        hasPermission: hasMultiplePermission(['PROPERTIES_VIEW']),
        permissionFunc: () => hasMultiplePermission(['PROPERTIES_VIEW']),
      },
      {
        id: 'ACCOUNTS',
        icon: AccountsIcon,
        text: t('ACCOUNTS'),
        redirect: '/accounts',
        selected: location.pathname === '/accounts',
        subitems: [],
        hasPermission: hasMultiplePermission(['ACCOUNTS_VIEW']) && isUserSassOperator(),
        permissionFunc: () => hasMultiplePermission(['ACCOUNTS_VIEW']) && isUserSassOperator(),
      },
      {
        id: 'PARTNERS',
        icon: AccountsIcon,
        text: t('PERTNERS'),
        redirect: '/partners',
        selected: location.pathname === '/partners',
        subitems: [],
        hasPermission: hasMultiplePermission(['PARTNERS_VIEW']) && !isUserSassOperator(),
        permissionFunc: () => hasMultiplePermission(['PARTNERS_VIEW']) && !isUserSassOperator(),
      },
      {
        id: 'LEAD',
        icon: JobsIcon,
        iconSelected: JobsIcon,
        text: t('LEADS'),
        redirect: '/boards/lead',
        selected: location.pathname === '/boards/lead',
        hasPermission: hasMultiplePermission(['BOARDS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['BOARDS_VIEW']),
        subitems: [],
        isHidden: true,
      },
      {
        id: 'DEAL',
        icon: JobsIcon,
        iconSelected: JobsIcon,
        text: t('DEALS'),
        redirect: '/boards/deal',
        selected: location.pathname === '/boards/deal',
        hasPermission: hasMultiplePermission(['BOARDS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['BOARDS_VIEW']),
        subitems: [],
        isHidden: true,
      },
      {
        id: 'JOB',
        icon: JobsIcon,
        iconSelected: JobsIcon,
        text: t('JOBS'),
        redirect: '/boards/board',
        selected: location.pathname === '/boards/board',
        hasPermission: hasMultiplePermission(['BOARDS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['BOARDS_VIEW']),
        subitems: [],
        isHidden: true,
      },
      {
        id: 'TASKS',
        icon: TasksIcon,
        iconSelected: TasksIcon,
        text: t('TASKS'),
        redirect: '/tasks',
        selected: location.pathname === '/tasks',
        hasPermission: hasMultiplePermission(['TASKS_VIEW', 'TASKS_VIEW_OWN', 'TASKS_MANAGE']),
        permissionFunc: () => hasMultiplePermission(['TASKS_VIEW', 'TASKS_VIEW_OWN', 'TASKS_MANAGE']),
        subitems: [],
      },
      {
        id: 'APPOINTMENTS',
        icon: AppointmentIcon,
        iconSelected: AppointmentIcon,
        text: t('ACTIVITIES'),
        redirect: '/activities',
        selected: location.pathname === '/activities',
        subitems: [],
        hasPermission: hasMultiplePermission(['ACTIVITIES_VIEW']),
        permissionFunc: () => hasMultiplePermission(['ACTIVITIES_VIEW']),
      },
      {
        id: 'SCHEDULES',
        icon: AppointmentIcon,
        iconSelected: AppointmentIcon,
        text: t('SCHEDULING'),
        redirect: '/scheduling',
        selected: location.pathname === '/scheduling',
        subitems: [],
        hasPermission: hasMultiplePermission(['ACTIVITIES_VIEW']),
        permissionFunc: () => hasMultiplePermission(['ACTIVITIES_VIEW']),
      },
      {
        id: 'PLATFORMS',
        icon: PlatformsIcon,
        iconSelected: PlatformsIcon,
        text: t('PLATFORMS'),
        redirect: '/platforms',
        selected: location.pathname === '/platforms',
        hasPermission: hasMultiplePermission(['INTEGRATIONS_VIEW', 'AUTOMATIONS_VIEW', 'AUTOMATION_LOGS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['INTEGRATIONS_VIEW', 'AUTOMATIONS_VIEW', 'AUTOMATION_LOGS_VIEW']),
        subitems: [
          {
            id: 0,
            text: t('INTEGRATIONS'),
            redirect: '/platforms/integrations',
            selected: location.pathname.startsWith('/platforms/integrations'),
            icon: IntegrationsIcon,
            hasPermission: hasMultiplePermission(['INTEGRATIONS_VIEW']),
            permissionFunc: () => hasMultiplePermission(['INTEGRATIONS_VIEW']),
          },
          {
            id: 1,
            text: t('AUTOMATION'),
            redirect: '/platforms/automations',
            selected: location.pathname.startsWith('/platforms/automations'),
            icon: AutomationsIcon,
            hasPermission: hasMultiplePermission(['AUTOMATIONS_VIEW']),
            permissionFunc: () => hasMultiplePermission(['AUTOMATIONS_VIEW']),
          },
          {
            id: 3,
            text: t('AUTOMATION_LOGS'),
            redirect: '/platforms/automation-logs',
            selected: location.pathname.startsWith('/platforms/automation-logs'),
            icon: AutomationLogsIcon,
            hasPermission: hasMultiplePermission(['AUTOMATION_LOGS_VIEW']),
            permissionFunc: () => hasMultiplePermission(['AUTOMATION_LOGS_VIEW']),
          },
        ],
      },
      {
        id: 'SETTINGS',
        icon: SettingsIcon,
        iconSelected: SettingsIcon,
        text: t('SETTINGS'),
        redirect: '/settings',
        selected: location.pathname === '/settings',
        subitems: [],
        hasPermission: hasMultiplePermission(['USERS_VIEW']),
        permissionFunc: () => hasMultiplePermission(['USERS_VIEW']),
      },
    ],
    [location.pathname, permissions],
  );

  const [menuItems, setMenuItems] = useState(items);

  const onItemClick = (itemId, subitemId) => {
    const item = menuItems.find(item => item.id === itemId);
    if (item) {
      if (item.subitems.length > 0) {
        if (subitemId !== 0 && !subitemId)
          setMenuItems(
            menuItems.map(menuItem => (menuItem.id === itemId ? { ...menuItem, open: !menuItem.open } : menuItem)),
          );
        else {
          navigate(item.subitems.find(subitem => subitem.id === subitemId).redirect);
        }
      } else {
        navigate(item.redirect);
      }
    }
  };

  useEffect(() => {
    if (selectedPath) {
      setMenuItems(
        menuItems.map(menuItem =>
          selectedPath.startsWith(menuItem.redirect)
            ? {
                ...menuItem,
                hasPermission: menuItem?.permissionFunc && menuItem?.permissionFunc(),
                open: menuItem.subitems.length > 0,
                selected: true,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  hasPermission: subitem?.permissionFunc && subitem?.permissionFunc(),
                  selected: selectedPath.startsWith(subitem.redirect),
                })),
              }
            : {
                ...menuItem,
                hasPermission: menuItem?.permissionFunc && menuItem?.permissionFunc(),
                selected: false,
                open: false,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  hasPermission: subitem?.permissionFunc ? subitem?.permissionFunc() : true,
                  selected: false,
                })),
              },
        ),
      );
    }
  }, [selectedPath, permissions, user]);

  useEffect(() => {
    if (history.action === 'POP' || history.action === 'REPLACE' || history.action === 'PUSH') {
      setSelectedPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: t('YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION'),
          }),
        );
  };

  const fetchBoardList = async (board_type, board_type_url) => {
    let boardList = [];
    try {
      boardList = await dispatch(getBoardList({ params: { board_type: board_type } }));
    } catch (error) {
      console.error('Error while fetching board list:', error);
      boardList = [];
    }

    const boardListItems = boardList.map(board => ({
      id: board.id,
      imgIcon: board.icon.active,
      text: board.name,
      redirect: `/boards/${board_type_url}/${board.id}`,
      selected: location.pathname?.startsWith(`/boards/${board_type_url}/${board.id}`),
      hasPermission: true,
      permissionFunc: () => true,
    }));
    const isDataEmpty = boardList.length === 0;
    console.log('boardListItems', boardListItems);
    setMenuItems(prev =>
      prev.map(item =>
        item.id === board_type ? { ...item, subitems: [...boardListItems], isHidden: isDataEmpty } : item,
      ),
    );
  };

  useEffect(() => {
    if (hasPermission('BOARDS_VIEW')) {
      fetchBoardList('JOB', 'board');
      fetchBoardList('LEAD', 'lead');
      fetchBoardList('DEAL', 'deal');
    }
  }, [user.isAccessAccount]);

  return (
    <MainContentWrapper>
      <AblyProviderWrapper>
        <OrganisationContext.Provider
          value={{
            modal,
            setModal,
            insideModal,
            setInsideModal,
            checkPermission,
            hasPermission,
            hasMultiplePermission,
            checkMultiplePermission,
            sideModal,
            setSideModal,
          }}>
          <MainLayout menuItems={menuItems} onItemClick={onItemClick} selectedPath={selectedPath} user={user}>
            <div className="main">
              <div className="content">
                <Routes>
                  {ROUTE_PATHS.filter(path => hasMultiplePermission(path.permission)).map(path => (
                    <Route
                      key={path.id}
                      element={<path.Component {...path.params} path={path.path} {...path.actions} />}
                      path={path.path}
                      index
                    />
                  ))}
                  <Route
                    element={
                      <Navigate
                        replace
                        to={
                          ROUTE_PATHS.find(path => hasMultiplePermission(path.permission))
                            ? ROUTE_PATHS.find(path => hasMultiplePermission(path.permission)).path
                            : '/profile'
                        }
                      />
                    }
                    path="*"
                  />
                </Routes>
              </div>
            </div>
            <SideModel />
            <Modal />
          </MainLayout>
        </OrganisationContext.Provider>
      </AblyProviderWrapper>
    </MainContentWrapper>
  );
};

export default Main;
