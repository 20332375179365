import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as RoundExplanation } from '../../assets/icons/round-explanation.svg';
import Button from '../../components/common/button/button';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import IconContainer from '../../components/common/icon-container';
import Switch from '../../components/common/switch';
import TimeSelectorElement from '../../components/common/time-selector/time-selector-element';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { addToast } from '../../store/features/toastSlice';
import { progressWorkitem } from '../../store/features/workitemSlice';

const MoveToStage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { user } = useSelector(state => state.user);
  const { modal, setModal } = useContext(OrganisationContext);
  const { workitem_id, stage, decisionStage, onSuccess, setSkipBlocker } = modal?.content || {};

  const { user: userDetails, organizations } = user || {};
  const userOrganization = organizations?.[0] || {};

  const [loading, setLoading] = useState(false);
  const [release, setRelease] = useState(false);
  const [snooze, setSnooze] = useState(false);
  const [snoozeData, setSnoozeData] = useState({ date: null, time: null });

  const [dataError, setDataError] = useState({ date: false, time: false });

  const onConfirmAction = async () => {
    if (snooze && !snoozeData.date && !snoozeData.time) {
      setDataError({ date: !snoozeData.date, time: !snoozeData.time });
      showErrorToast({ default_message: t('PLEADE_SELECT_SNOOZE_DATE_TIME'), id: nanoid() });
      return;
    } else if (snooze && !snoozeData.time) {
      showErrorToast({ default_message: t('PLEASE_SELECT_SNOOZE_TIME'), id: nanoid() });
      return;
    } else if (snooze && !snoozeData.date) {
      showErrorToast({ default_message: t('PLEASE_SELECT_SNOOZE_DATE'), id: nanoid() });
      return;
    }

    const { date, time } = snoozeData || {};
    const splitedTime = time?.split(':') || [];
    const suspendedDate = snooze
      ? moment.unix(date).set({ hour: splitedTime?.[0], minute: splitedTime?.[1] }).unix()
      : null;

    const request = {
      decision: decisionStage ? { id: decisionStage?.id } : null,
      stage: !decisionStage ? { id: stage?.id } : null,
      visible: snooze ? { suspended_date: suspendedDate } : null,
      owners: release
        ? []
        : [
            {
              user: {
                id: userDetails.id,
              },
              organization: {
                id: userOrganization.id,
              },
            },
          ],
    };

    if (setSkipBlocker) {
      setSkipBlocker(release);
    }
    setLoading(true);
    dispatch(progressWorkitem({ workitem_id: workitem_id, request }))
      .then(() => {
        setModal(initModal);
        dispatch(addToast({ error: false, text: t('SUCCESSFULLY_JOB_MOVED'), id: nanoid() }));
        onSuccess && onSuccess(release);
      })
      .catch(error => {
        if (setSkipBlocker) {
          setSkipBlocker(false);
        }
        showErrorToast({ error, default_message: t('ERROR_WHILE_MOVING_JOB'), id: nanoid() });
      })
      .finally(() => setLoading(false));
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <MoveToStageWrapper className="flex-column items-center pxy-6">
        <IconContainer
          Icon={RoundExplanation}
          iconContainerClassname="pxy-3 radius-6"
          iconHeight={24}
          iconWidth={24}
          iconColor="primary_500"
          backgroundColor="primary_50"
        />
        <div className="flex-column mt-5 text-center">
          <label className="inter-500-text natural-900-text font-18">{t('MOVE_TO', { value: stage?.name })}?</label>
          <label className="inter-400-text natural-500-text">{t('MOVE_THIS_JOB', { value: stage?.name })}</label>
        </div>
        <div className="flex-column mt-8 row-gap-3 w-full">
          <div className="flex items-center justify-between">
            <label className="inter-500-text natural-700-text">{t('RELEASE')}</label>
            <Switch enabled={release} onClick={() => setRelease(!release)} />
          </div>
          <div className="flex items-center justify-between">
            <label className="inter-500-text natural-700-text">{t('SNOOZE')}</label>
            <Switch enabled={snooze} onClick={() => setSnooze(!snooze)} />
          </div>
        </div>
        {snooze && (
          <div className="flex-column w-full mt-8">
            <label className="inter-500-text natural-900-text mb-1">{t('SNOOZE_UNTIL')}</label>
            <div className="date-time-selector">
              <div className="flex w-full">
                <DateSelectorElement
                  selectedDate={snoozeData.date}
                  setSelectedDate={date => setSnoozeData({ ...snoozeData, date: date })}
                  placeholder={t('SELECT_DATE')}
                  error={dataError.date}
                />
              </div>
              <div className="flex w-full">
                <TimeSelectorElement
                  selectedTime={snoozeData.time}
                  setSelectedTime={time => setSnoozeData({ ...snoozeData, time: time })}
                  isRequired={true}
                  error={dataError.time}
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex-column row-gap-3 mt-8 w-full">
          <Button
            className={`primary ${loading && 'disabled'}`}
            label={t('PROGRESS')}
            onClick={onConfirmAction}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`primary-white ${loading && 'disabled'}`}
            label={t('CANCEL')}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </MoveToStageWrapper>
    </CSSTransition>
  );
};

export const MoveToStageWrapper = styled.div`
  width: 384px;

  .date-time-selector {
    display: grid;
    grid-template-columns: 1fr 115px;
    column-gap: 8px;
  }
`;

export default MoveToStage;
