import { TRANSLATIONS_EN_PROPERTY_COSTS } from './property-costs-translation';
import { TRANSLATIONS_EN_PROPERTY_QUOTE } from './property-quote-translation';

export const TRANSLATIONS_EN = {
  EMAIL_ADDRESS: 'Email address',
  password: 'Password',
  PASSWORD: 'password',
  ENTER_PASSWORD: 'Enter your password',
  CONFIRM_PASSWORD: 'Confirm Password',
  LOGIN: 'Login',
  FORGOTTEN_PASSWORD: 'Forgotten password',
  INCORRECT_EMAIL_PASSWORD: 'Incorrect email or password',
  INVALID_EMAIL_FORMAT: 'Please enter a valid email address',
  RECEIVE_CODE: 'Enter your email to receive the code',
  ENTER_EMAIL_ADDRESS: 'Enter your email address',
  WE_SENT_EMAIL: 'We sent you an email',
  SEND_EMAIL: 'Send email',
  SECURITY_REASON_DETAIL_TEXT:
    'For added security, we need to further verify your account. Please, enter security code from email here',
  INCORRECT_CODE_DETAIL_TEXT: 'Incorrect code please check your code is correct and try again',
  VERIFY: 'Verify',
  RESEND_SECURE_CODE: 'Resend secure code',
  RESET_YOUR_PASSWORD: 'Re-set your password',
  NEW_PASSWORD: 'New password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  SET_NEW_PASSWORD: 'Set new password',
  WELCOME_BACK: 'Welcome back!',
  SIGN_IN_NEEDED_TEXT: 'You need to sign in to your account',
  ACCOUNT_DEACTIVATE_DETAIL_TEXT:
    'Your account has been de-activated, please ask the workspace owner to re-activate your account',
  FORGOTTEN_YOUR_PASSWORD: 'Forgotten your password?',
  ENTER_EMAIL_BELOW_DETAIL_TEXT: 'Enter your email below. If it exists we will send you an email with a 6 digit code',
  RESEND_EMAIL: 'Re-send email',
  SUBMIT: 'Submit',
  SELECTED: 'selected',
  SELECT_ALL: 'Select all',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  ADD_NEW: 'Add new',
  ADD_PROPERTY: 'Add Property',
  ADD_TASKS: 'Add Tasks',
  ADD_TASK: 'Add Task',
  ADD_USERS: 'Add Users',
  ADD_USER: 'Add User',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  EDIT: 'Edit',
  DELETE: 'Delete',
  CONFIRM: 'Confirm',
  NUMBER: 'number',
  KEEP: 'Keep',
  ENTER: 'Enter',
  MILESTONE: 'Milestone',
  ERROR: 'Error',
  SUCCESS: 'Success',
  FULL_NAME: 'Full name',
  ENTER_FULL_NAME: 'Enter full name',
  ADD_EMPLOYEE: 'Add Employee',
  EDIT_EMPLOYEE: 'Edit Employee',
  NAME: 'Name',
  EMAIL: 'Email',
  ADDRESS: 'Address',
  SMS: 'SMS',
  AGE: 'Age',
  TYPE: 'Type',
  VIEW: 'View',
  STOREYS: 'Storeys',
  BEDROOMS: 'Bedrooms',
  BATHROOMS: 'Bathrooms',
  RECEPTIONS: 'Receptions',
  EXTENSIONS: 'Extensions',
  CONSERVATORY: 'Conservatory',
  ELECTRIC_BOILER: 'Electric Boiler',
  MAIN_GAS: 'Main gas',
  CONTACT_DETAILS: 'Contact Details',
  EDIT_COMPANY_CONTACT: 'Edit Company Contact',
  COMPANY_NAME: 'Company name',
  SIZE: 'Size',
  ENTER_SIZE: 'Enter a size',
  SQ_MTR: `sq. mtr`,
  MAIN_HEATING_SYSTEM: 'Main heating system',
  SECOND_HEATING_SYSTEM: 'Second heating system',
  TITLE: 'Title',
  FULL_NAME: 'Full Name',
  SEND_SMS: 'Send SMS',
  DESCRIPTION: 'Description',
  NO_DESCRIPTION: 'No Description',
  SETTINGS: 'Settings',
  SECURITY: 'Security',
  CONTINUE: 'Continue',
  LOG_OUT: 'Log Out',
  MY_PROFILE: 'My Profile',
  PROFILE: 'Profile',
  EXIT_MASQUERADE: 'Exit Masquerade',
  CONFIGURE_YOUR_SECURITY: 'Configure your security settings',
  USERS: 'Users',
  MANAGE_CURRENT_AND_INVITE_USERS: 'Manage current and invite new users',
  TEAMS: 'Teams',
  TEAM: 'Team',
  MANAGE_AND_INVITE_NEW_TEAMS: 'Manage current and create new teams',
  ROLES: 'Roles',
  MANAGE_CURRENT_AND_INVITE_NEW_ROLE: 'Manage current and create new roles',
  USER_MANAGEMENT: 'User Management',
  MANAGE_USER_ROLES_AND_TEAMS: 'Manage users, roles and teams',
  INVITE_USER: 'Invite User',
  NO_DATA: 'No data',
  NO_DATA_DESCRIPTION: 'No data found. The automation log is currently empty.',
  NO_AUTOMATION_LOGS_HERE: 'There are currently no automation logs here. Please check back later or add a new one.',
  TYPE_USER_EMAIL: 'Type user’s email',
  TYPE_USER_NAME: 'Full user’s name',
  TYPE_USER_FULL_NAME: 'Enter the user’s full name',
  TYPE_TEAM_FULL_NAME: 'Enter a name for the team',
  TYPE_ROLE_FULL_NAME: 'Enter a name for the role',
  PHONE_OPTIONAL: 'Phone (optional)',
  PHONE_NUMBER: 'Phone number',
  ROLE: 'Role',
  TEAM_NAME: 'Team name',
  ROLE_NAME: 'Role name ',
  USER_ROLE: 'User’s role',
  TYPE_USER_PHONENUMBER: '"Type user’s phone number"',
  ADD_NEW_TEAM_VALIDATION: 'Please enter a name,please enter a description',
  EMPLOYEE: 'Employee',
  EMPLOYEE_NUMBER: 'Employee number',
  TYPE_EMPLOYEE_NUMBER: "Type employee's number",
  YOU_WANT_TO_DELETE_TEAM: 'Are you sure you want to delete this team?',
  YOU_WANT_TO_DELETE_TEAM_MEMBER: 'Are you sure you want to remove  this user from the team?',
  DELETE_ACTION_DESCRIPTION: 'This action cannot be undone',
  RESEND_INVITE_USER: 'Are you sure you want to resend an invite to this user?',
  TEAM_ADDED: 'Successfully team added',
  DESCRIPTION_PLACEHOLDER: 'Enter description here',
  DESCRIPTION_PLACEHOLDER_ROLE: 'Enter a description for the role',
  NO_RESULTS: 'No results',
  NO_AUTOMATIONS: 'No automations',
  NO_RESULTS_FOUND: 'No results found',
  NO_ACTIONS_FOUND: 'No actions found',
  NO_RESULTS_DESCRIPTION:
    "It seems that there are no records that match your current search criteria. Please double-check your search terms or try a different search to find the information you're looking for.",
  ID: 'ID',
  DATE: 'Date',
  EXPIRY: 'Expiry',
  STATUS: 'Status',
  PLATFORMS: 'Platforms',
  AUTOMATIONS: 'Automations',
  AUTOMATION: 'Automation',
  AUTOMATION_LOGS: 'Automation Logs',
  AUTOMATION_LOG: 'Automation Log',
  EVENT: 'Event',
  TEAM_NAME: 'Team Name',
  SERVICE: 'Service',
  SERVICES: 'Services',
  AUTOMATION_DETAILS: 'Automation details',
  OWNER: 'Owner',
  CAMPAIGN: 'Campaign',
  CONFIGURATION: 'Configuration',
  ADD_NEW_ACTION: 'Add new action',
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
  RETRY: 'Retry',
  PROGRESS: 'Progress',
  BACK: 'Back',
  ADD: 'Add',
  UPDATE: 'Update',
  NEXT: 'Next',
  ACTION: 'Action',
  INCORRECT_CODE: 'Incorrect code',
  INCORRECT_PASSWORD: 'Invalid password',
  SOMETHING_WRONG: 'Something Wrong!',
  ACTIVITIES: 'Activities',
  MAP: 'Map',
  SELECT_SERVICE: 'Select service',
  SELECT: 'Select',
  PRIMARY: 'Primary',
  MOBILE: 'Mobile',
  MOBILE_PRIMARY: 'Mobile (Primary)',
  LANDLINE: 'Landline',
  PREFERRED_CALL_TIME: 'Preferred call time',
  EMAIL_PRIMARY: 'Email (Primary)',
  ENTER_EMAIL: 'Enter email',
  SEND_EMAIL_INVITE: 'Send Email Invite',
  REMOVE: 'Remove',
  WHEN: 'When',
  SEARCH: 'Search',
  REASON: 'Reason',
  CHANGE: 'Change',
  CONDITION: 'Condition',
  TRIGGER: 'Trigger',
  ADD_CONDITION: 'Add condition',
  DELETE_TEAM_MEMBER_DESCRIPTION: 'You can add this user again later if needed',
  NO_ROLE: 'no role',
  DESCRIPTION_PLACEHOLDER_TEAM: 'Enter a description for the team',
  ACTIVE: 'Active',
  AVAILBILITY: 'Availability',
  ERROR_UPDATE_STATUS: 'Error while updating status',
  REQUIRED_INTIGRATION: 'Please add all required fields in integration',
  REQUIRED_PARAMETERS_INTIGRATION: 'Please add all required parameters in integration',
  REQUIRED_FIELD: 'Please enter required field',
  CANCLE_AUTOMATION: 'Cancel this automation?',
  CANCLE_AUTOMATION: 'Lorem ipsum dolor sit amet consectetur?',
  SELECT_ROLE: 'Select role',
  RECIPIENT_INFORMATION: 'Recipient information',
  QUOTE_NUMBER: 'Quote number',
  QUOTE_DETAILS: 'Quote Details',
  QUOTE_UPDATE: 'Update Quote details successfully',
  ERROR_QUOTE_UPDATE: 'Error while Update Quote details',
  ERROR_LOCATION_UPDATE: 'Error while Updating Location',
  ERROR_UPDATE_CREATE_ON_DATE:
    'You cannot select a date after the expiry date, please select a date prior to the expiry date and try again',
  ERROR_UPDATE_EXPIRY_ON_DATE:
    'You cannot select a date before the expected created date, please select a date after the created date and try again',
  PRODUCT_UPDATED_SUCCESS: 'Product updated successfully',
  LOCATION_UPDATE: 'Location Updated successfully',
  ERROR_PRODUCT_UPDATE: 'Error while updating product',
  DETAILS: 'Details',
  ROLE_DETAILS: 'Role Details',
  ROLE_TIER: 'Role Tier',
  ROLE_PERMISSIONS: 'Role Permissions',
  SAVE: 'Save',
  FILTER: 'Filter',
  FILTER_BY: 'Filter by',
  SORT: 'Sort',
  SORT_BY: 'Sort by',
  EXAMPLE_COMPANY_NAME: 'e.g. Company name',
  EXAMPLE_COMPANY_LONDON: 'e.g. London',
  EXAMPLE_WALNUT_AVENUE: 'e.g. 4 Walnut Avenue',
  EXAMPLE_NAME: 'e.g. 4 MacDonalds',
  EXAMPLE_APARTMENT: 'e.g. Apartment 17',
  EXAMPLE_ACOMB: 'e.g. Acomb',
  EXAMPLE_RYDE: 'e.g. Ryde',
  EXAMPLE_BN16_78F: 'e.g. BN16 78F',
  EXAMPLE_ISLE_OF_WIGHT: 'e.g. Isle of Wight',
  EXAMPLE_EH_0001: 'e.g. EH-0001',
  EXAMPLE_QUOTE_DESCRIPTION: 'e.g. Quote description goes here',
  EXAMPLE_DATE: 'e.g. 12/01/2023',
  CATEGORY: 'Category',
  CATEGORY_SELECT: 'Select category',
  ACCOUNT_ADDRESS: 'Account Address',
  DIFFERENT_BILLING_ADDRESS: 'Different Billing Address',
  SEARCH_FOR_AN_ADDRESS: 'Start typing to search for an address...',
  SEARCH_FOR_AN_ADDRESS_OR_POSTCODE: 'Search for an address or postcode',
  ADDRESS_LINE_ONE: 'Address Line 1',
  ADDRESS_LINE_TWO: 'Address Line 2',
  ADDRESS_LINE_THREE: 'Address Line 3',
  ENTER_ADDRESS_DETAILS: 'Enter Address Details',
  TOWN_CITY: 'Town/City',
  CITY: 'City',
  STATE: 'State',
  COUNTY: 'County',
  TODAY: 'Today',
  SHOW: 'Show',
  RENAME: 'Rename',
  ENTER_STATE: 'Enter State',
  ENTER_COUNTY: 'Enter county',
  POSTCODE: 'Postcode',
  ZIPCODE: 'Zipcode',
  ENTER_ZIPCODE: 'Enter Zipcode',
  ENTER_POSTCODE: 'Enter Postcode',
  OPTIONAL: '(optional)',
  PLEASE_FILL_THE_REQUIRED_FIELDS: 'Please fill the required fields',
  PLEASE_FILL_ALL_THE_REQUIRED_FIELDS: 'Please fill all required fields',
  PLEASE_SAVE_ALL_EMPLOYEES_FIRST: 'Please save all employees first',
  OWNER_FULL_NAME_ERROR: 'Please enter full name of owner',
  ACCOUNT_ADDED: 'Account added',
  ACCOUNT_ADDED_SUCCESSFULLY: "You've successfully added a new Account.",
  ERROR_ADDING_ACCOUNT: 'Error while adding Account',
  ACTIVITY_REMOVED: 'Activity Removed',
  SUCCESSFULLY_REMOVED_ACTIVITY: `You have successfully removed the activity ‘Activity Name’.`,
  NO_RESOURCES_FOUNT_FOR_APPLIED_FILTER:
    'there are no resources found for the applied filters, please check the filters applied and try again',
  SHOW_AVAILABLE_RESOURCES: 'Select a service from the filter above to show available resources in the calendar.',
  NO_RESOURCES_AVAILABLE: 'No resources available',
  NO_SERVICE_SELECTED: 'No service selected',
  SHOW_AVAILABLE_RESOURCES_IN_CALENDAR: 'Please select a service to show available resources in the calendar.',
  RENAME_ACTIVITY: 'Rename Activity',
  REMOVE_ACTIVITY: 'Remove Activity',
  CONTACT_ADDRESS: 'Contact Address',
  SAME_AS_PROPERTY_ADDRESS: 'Same as property address',
  PROPERTY_ADDRESS: 'Property Address',
  PROPERTY_DETAILS: 'Property Details',
  PROPERTY_TYPE: 'Property type',
  PROPERTY_SUB_TYPE: 'Property sub-type',
  SELECT_FROM_LIST: 'Select from list',
  ORIENTATION: 'Orientation',
  FETCHING_ACTIONS_ERROR: 'Error while fetching actions',
  EDIT_ACTION: 'Edit action',
  ADD_ACTION: 'Add action',
  AUTOMATION_NAME_DESCRIPTION_ERROR: 'Please fill Automation name, description and Owner',
  AUTOMATION_ADDED: 'Automation added',
  SUCCESSFULLY_ADDED_AUTOMATION: `You've successfully added a new automation.`,
  ERROR_ADDED_AUTOMATION: 'Error while adding automation',
  ADD_AUTOMATION: 'Add Automation',
  ENTER_AUTOMATION_NAME: 'Enter a name for the automation',
  ENTER_AUTOMATION_DESCRIPTION: 'Enter a description for the automation',
  SELECT_OWNER: 'Select owner',
  AUTOMATION_TRIGGER_ADDED: 'Automation trigger added',
  SUCCESSFULLY_AUTOMATION_TRIGGER_ADDED: `You've successfully added automation trigger.`,
  ERROR_AUTOMATION_TRIGGER: `Error while adding automation trigger.`,
  ERROR_FETCHING_TRIGGER: 'Error while fetching triggers',
  EDIT_TRIGGER: 'Edit trigger',
  ADD_TRIGGER: 'Add trigger',
  NO_TRIGGERS_FOUND: 'No triggers found',
  AUTOMATION_ACTION_DELETED: 'Automation action deleted',
  ERROR_WHILE_DELETING_AUTOMATION: 'Error while deleting automation action',
  DELETE_THIS_AUTOMATION_ACTION: 'Delete this automation action?',
  ARE_YOU_SURE_TO_REMOVE_THIS_ACTION:
    'Are you sure you want to remove this action? Once removed, this action cannot be recovered.',
  UPDATE_AUTOMATION_TRIGGER: 'Automation trigger updated',
  SUCCESSFULLY_UPDATE_AUTOMATION_TRIGGER: `You've successfully updated automation trigger.`,
  ERROR_WHILE_UPDATING_AUTOMATION_TRIGGER: 'Error while updating automation trigger',
  UPDATE_THIS_AUTOMATION_TRIGGER: 'Update this automations trigger?',
  CONFIGURED_TO_THIS_AUTOMATION: 'This will delete all actions currently configured to this automation.',
  ERROR_FETCHING_CONNECTORS: 'Error while fetching connectors',
  FILL_INTEGRATION_NAME_DESCRIPTION_PROVIDER: 'Please fill integration name, description, providerType and provider',
  INTEGRATION_ADDED_SUCCESSFULLY: `Integration added , You've successfully added a new integration`,
  ADD_INTEGRATION: 'Add integration',
  ENTER_INTEGRATION_NAME: 'Enter integration name',
  PROVIDER_TYPE: 'Provider type',
  PROVIDERS: 'Providers',
  INTEGRATION_DISABLE: `Integration disable , You've successfully disabled integration`,
  INTEGRATION_DELETED: `Integration deleted , You've successfully deleted a integration`,
  DELETING_THIS_INTEGRATION:
    'Deleting this integration will impact the following automations and they will be disabled as a result.',
  TIER: 'Tier',
  TYPE_ROLE_TIER: 'Enter a tier for the role',
  START_DATE_ERROR:
    'You cannot select a date after the deadline date, please select a date prior to the deadline date and try again',
  END_DATE_ERROR:
    'You cannot select a date before the expected completion date, please select a date after the expected completion date and try again',
  SELECT_A_DATA: 'Select a date',
  TENANT: 'Tenant',
  JOB_DETAILS: 'Job Details',
  SELECT_JOB_TYPE: 'Select job type',
  JOB_TYPE: 'Job type',
  ADD_JOB_TYPE: 'Add job type',
  JOB_NAME: 'Job Name',
  SEARCH_TYPING_TO_SEE_SOME_RESULTS: 'Start typing to see some results',
  NOT_UPLOADED: 'Not uploaded',
  ENTER_JOB_NAME: 'Enter a name for the job',
  SELECT_PRIMARY_CONTACT: 'Select a primary contact',
  PRIMARY_CONTACT: 'Primary contact',
  ESTIMATED_JOB_VALUE: 'Estimated job value',
  EXPECTED_COMPLETION: 'Expected Completion',
  DEADLINE: 'Deadline',
  PROJECT: 'Project',
  PROJECTS: 'Projects',
  SELECT_PROJECT: 'Select project',
  WORKFLOW: 'Workflow',
  SELECT_WORKFLOW: 'Select workflow',
  PRIMARY_CONTACT_DETAILS: 'Primary contact details',
  NO_MOBILE: 'No Mobile',
  NO_PHONE: 'No Phone',
  NO_LANDLINE: 'No Landline',
  NO_EMAIL: 'No Email',
  NO_ADDRESS: 'No Address',
  NO_USER: 'No User',
  PLEASE_FILL_THE_ALL_FIELDS: 'Please fill all the fields',
  JOB_CREATE_SUCCESSFULLY: 'Job created successfully',
  ERROR_WHILE_CREATING_JOB: 'Error while creating job',
  PLEASE_ADD_CONTACT_FOR_THIS_QUOTE: 'Please add contact for this quote',
  QUOTE_ADDED: 'Quote added',
  SUCCESSFULLY_QUOTE_ADDED: `You've successfully added a new quote.`,
  ERROR_WHILE_ADDING_QUOTE: 'Error while adding quote',
  ADD_AUOTE: 'Add Quote',
  SELECT_CONTACT: 'Select contact',
  CONTACT: 'Contact',
  EXPIRATION_DATE: 'Expiration date',
  QUOTE_REMOVED: 'Quote removed',
  SUCCESSFULLY_REMOVED_QUOTE: 'You have successfully removed the quote',
  ERROR_REMOVING_QUOTE: 'Error while removing quote',
  PLEADE_SELECT_SNOOZE_DATE_TIME: 'Please select snooze date and time',
  PLEASE_SELECT_SNOOZE_TIME: 'Please select snooze time',
  PLEASE_SELECT_SNOOZE_DATE: 'Please select snooze date',
  SUCCESSFULLY_JOB_MOVED: 'Job moved successfully',
  ERROR_WHILE_MOVING_JOB: 'Error while moving job',
  RELEASE: 'Release',
  SNOOZE: 'Snooze',
  SNOOZE_UNTIL: 'Snooze until',
  SELECT_DATE: 'Select date',
  MOVE_TO: 'Move to {{value}}',
  MOVE_THIS_JOB: 'Are you sure you want to move this job to {{value}}',
  MOBILE: 'Mobile',
  COMPANY: 'Company',
  PERSON: 'Person',
  TEAM_MEMBER_DELETED: 'Team member deleted',
  TEAM_MEMBER_DELETED_SUCCESSFULLY: `Team member {{value}} deleted successfully`,
  ERROR_REMOVING_MAMBER_TEAM: 'Error while removing member from team',
  NOT_PROVIDED: 'Not provided',
  PLEASE_ENTER_ONE_METHOD_CONTACT: 'Please enter at least 1 method of contact',
  SEARCH_FOR_AN_EXISTING_CONTACT: 'Search for an existing contact by name, address or postcode',
  SEARCH_FOR_AN_CONTACT: 'Search contact by name, address or postcode',
  CLICK_TO_ADD_ADDRESS: 'Click to add an address',
  PLEASE_SAVE_EMPLOYEE_DETAILS: 'Please save or cancel your changes to this employee',
  NO_EMPLOYEE_FOR_COMPANY: 'No employees exist for this company',
  PLEASE_ADD_RECIPIENT_QUOTE: 'Please add recipient for this quote',
  SELECT_RECIPENT: 'Select recipient',
  RECIPIENT: 'Recipient',
  PLEASE_ADD_ATLEAST_ONE_CONTACT_OR_COMPANY: 'Please add atleast one contact or company',
  SUCCESSFULLY_PROPERTY_CREATED: 'Property created successfully',
  ERROR_PROPERTY_CREATED: 'Error while creating Property',
  ENTER_NAME_DESCRIPTION_TIER: 'Please enter a name, description and tier',
  ENTER_NAME: 'Please enter a name',
  CURRENT_TERM_NOT_EQUAL_TO_100:
    'The current terms do not equal 100%, please update the terms to equal 100% and try again',
  YOU_CANT_ADD_ROLES_WITH_TIER_ABOVE_YOU: 'You cant add roles with tier above you.',
  SUCCESSFULLY_RILE_ADDED: 'Successfully role added',
  USERS_ADDED: 'Users added',
  ERROR_WHILE_ADDING_USER_TO_TEAM: 'Error while adding users to team',
  ADD_NEW_MEMBERS: 'Add new members',
  SEARCH_USERS: 'Search users',
  TEAM_LEAD: 'Team Lead',
  TEAM_MEMBER: 'Team Member',
  AUTOMATION_DELETED: 'Automation deleted',
  SUCCESSFULLY_DELETED_AUTOMATION: 'You have successfully deleted the automation {{value}}',
  AUTOMATION_DISABLED: 'Automation disabled',
  NOT_ABLE_TO_UNDO_THIS_ACTION: 'You will not be able to un-do this action',
  LOREM_IPSUM: 'Lorem ipsum dolor sit amet consecuter adipscing elit.',
  ENTER_USER_NAME_EMAIL_TEAM_ROLE: 'Please fill user name, email, team and role',
  ENTER_USER_FULL_NAME: 'Please enter user full name',
  ENTER_VALID_EMAIL: 'Please enter valid email',
  ERROR_WHILE_ADDING_USER_TO_TEAM: 'Error while adding user to team',
  SUCCESSFULLY_USER_INVITED: 'Successfully User invited',
  INVITE_NEW_USER: 'Invite new user',
  ENTER_USER_EMAIL: `Enter the user’s email`,
  SELECT_USER_ROLE: `Select the user’s role`,
  SELECT_TEAMS_FOR_THE_USER: 'Select teams for the user',
  SEND_INVITE: 'Send Invite',
  DELETE_FILE: 'Delete File',
  DELETE_THESE: 'Delete these',
  FILES: 'files',
  ARE_YOU_SURE_TO_DELETE_THIS_FILE:
    'Are you sure you want to delete this file? Once deleted, this file cannot be recovered.',
  ARE_YOU_SURE_TO_DELETE_THESE: 'Are you sure you want to delete these',
  ONCE_DELETED: 'Once deleted, this file cannot be recovered.',
  FILE_DELETED: 'File deleted',
  FILES_DELETED: 'Files deleted',
  PARTNER_DELETED: 'Partner deleted',
  SUCCESSFULLY_DELETED_FILE: 'You have successfully deleted the file {{value}}',
  SUCCESSFULLY_DELETED_FILES: 'You have successfully deleted {{value}} files',
  SUCCESSFULLY_DELETED_PARTNER: 'You have successfully deleted {{value}} partner',
  ERROR_WHILE_DELETING_NOTES_DETAILS: 'Error while deleting notes Details',
  ERROR_WHILE_DELETING_PARTNER: 'Error while deleting partner',
  ERROR_WHILE_DELETING: 'Error while deleting {{value}}',
  TEAM_DELETED: 'Team deleted',
  ERROR_WHILE_RE_INVITE_USER: 'Error while re invite user',
  ERROR_WHILE_DELETING_USER: 'Error while deleting user',
  FILL_USER_NAME: `Please fill user's name.`,
  FILL_EMAIL: 'Please fill email',
  FILL_ROLE: 'Please fill role',
  FILL_USER_NAME_EMAIL: `Please fill user's name and email`,
  FILL_USER_NAME_ROLE: `Please fill user's name and role`,
  FILL_EMAIL_ROLE: 'Please fill email and role',
  FILL_USER_NAME_EMAIL_ROLE: 'Please fill user name, email and role',
  PLEASE_ENTER_PHONE_NUMBER: 'Please enter phone number',
  PHONE_NUMBER_IS_NOT_VALID: 'Phone number is not valid',
  PLEASE_ENTER_EMPLOYEE_ID: 'Please enter employee id',
  SUCCESSFULLY_OTP_SEND: 'OTP Send Successfully',
  PASSWORD_MINIMUM_REQUIREMENTS: 'Password does not meet the minimum requirements',
  MANY_TIME_ATTEMPTED: 'You have attempted to many requests, please try again later',
  PASSWORD_DO_NOT_MATCH_REQUIREMENT: 'Passwords do not match requirement',
  PASSWORD_DO_NOT_MATCH: 'Passwords do not match',
  PASSWORD_MUST: 'Password must',
  HAVE: 'Have',
  CHARACTERS: 'characters',
  UPPER_CASE: 'upper case',
  LOWER_CASE: 'lower case',
  SPECIAL_CHARACTER: 'special character',
  SELECT_VERIFICATION_METHOD: 'Select Verification Method',
  RECIEVING_VERIFICATION_CODE: `Choose your preferred method of recieving the 6-digit verification code. you can only recieve the code through the email address or phone number associated with your account.`,
  FORGOT_PASSWORD: 'Forgot password',
  ENTER_EMAIL_ADDRESS_SEND_CODE: `Enter the email address associated with your account and we’ll send you a 6 digit code.`,
  TRY_ANOTHER_METHOD: 'Try another method',
  ENTER_PHONE_NUMBER_ASSOCIATED_YOUR_ACCOUNT:
    'Enter the phone number associated with your account and we have send you a 6 digit code.',
  SENT_YOU_AN_EMAIL: `We’ve sent you an email`,
  SENT_YOU_AN_SMS: `We’ve sent you an SMS`,
  ENTER_THE_SIX_DIGIT: 'Please enter the 6 digit code below.',
  WE_SENT_EMAIL_TO: 'We sent an email to',
  WE_SENT_SMS_TO: 'We sent an SMS to',
  DONT_RECEIVE_CODE: 'Didn’t receive the code?',
  RESEND_EMAIL: 'Resend email',
  RESEND_SMS: 'Resend SMS',
  RESET_PASSWORD: 'Reset your password',
  PASSWORD_MUST_DIFFRENT_FROM_PREVIOUS_PASSWORD:
    'Enter a new password below. Your new password must be different from previous passwords.',
  LOG_IN_TO_YOUR_ACCOUNT: 'Log in to your account',
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION: 'You do not have permission to perform this action',
  PROPERTIES: 'Properties',
  ACCOUNTS: 'Accounts',
  PERTNERS: 'Partners',
  BOARDS: 'Boards',
  TASKS: 'Tasks',
  SCHEDULING: 'Scheduling',
  INTEGRATIONS: 'Integrations',
  JOBS_LIST: 'Jobs list',
  SCHEDULE: 'Schedule',
  ADD_TEAM: 'Add Team',
  ADD_NEW_TEAM: 'Add New Team',
  ADD_ROLE: 'Add Role',
  ERROR_WHILE_FETCHING_USERS: 'Error while fetching Users',
  PAYMENT_TERMS: 'Payment terms',
  SELECT_PAYMENT_TERMS: 'Select payment terms',
  NEW_PAYMENT_TERM: 'New Payment Term',
  PAYMENT_TERM_NAME: 'Payment term name',
  EXTENDED_PAYMENT_TERM: 'e.g. Extended payment terms',
  EG_DEPOSIT: 'e.g. Deposit',
  PAYMENT_TERMS_UPDATE: 'Payment terms updated successfully',
  ERROR_PAYMENT_TERMS_UPDATE: 'Error while updating payment terms',
  ADD_SOLAR_DESIGN_IMAGE: 'Solar design image added successfully',
  ERROR_SOLAR_DESIGN_IMAGE: 'Error while adding solar design image',
  ERROR_PAYMENT_TERMS_ADD: 'Error while adding new payment terms',
  SUCCESS_PAYMENT_TERMS_ADD: 'Payment terms created successfully',
  USER_ACTIVATED: 'User activated',
  ERROR_WHILE_ACTIVATING_USER: 'Error while activating user',
  ACTIVATE_YOUR_ACCOUNT: 'Activate your account',
  DETAILS_TO_ACTIVATE_ZERVIO_ACCOUNT: 'Enter the details below to activate your Zervio account.',
  INVITATION_HAS_EXPIRED: 'Oops! It appears that this invitation has expired.',
  FRESH_INVITATION: 'Please get in touch with your organisation and ask them to send you a fresh invitation.',
  ACCOUNT_ACCESSED: 'Account has been accessed',
  ACCOUNT_CANNOT_ACCESSED: 'Account cannot be accessed',
  ACCESS_ACCOUNT: 'Access account',
  ERROR_WHILE_FETCHING_ACCOUNTS: 'Error while fetching accounts',
  NO_ACCOUNT_THAT_NAME: `There's no account with that name, try again.`,
  NO_ACCOUNTS: 'No accounts',
  NO_ACCOUNTS_HERE: 'There are currently no accounts here.',
  NO_TASK: 'No tasks',
  NO_TASK_HERE: 'There are currently no tasks here.',
  AUTOMATION_UPDATED: 'Automation updated',
  SUCCESSFULLY_AUTOMATION_UPDATED: 'You have successfully updated the automation',
  ERROR_WHILE_AUTOMATION_UPDATING: 'Error while updating the automation',
  ERROR_WHILE_FETCHING_AUTOMATION_LOG: 'Error while fetching automations logs',
  ERROR_WHILE_FETCHING_AUTOMATION: 'Error while fetching automations',
  AUTOMATION_ACTION_ADDED: 'Automation action added successfully',
  ERROR_WHILE_ADDING_ACTION: 'Error while adding action',
  AUTOMATION_ACTION_UPDATE: 'Automation action updated successfully',
  ERROR_WHILE_UPDATING_ACTION: 'Error while updating action',
  ADD_SCHEDULE: 'Add schedule',
  SELECT_INTEGRATION: 'Select Integration',
  NO_INTEGRATIONS_FOUND: 'No integrations found',
  NO_INTEGRATIONS_PARAMS_FOUND: 'No integration params found',
  AUTOMATION_TRIGGER_CRITERIA: 'This is what the automation does when the trigger criteria is met',
  AUTOMATION_TRIGGERED_FOLLOWING_EVENT: 'The automation is triggered when the following event takes place',
  RETRY_THIS_ACTION: 'Retry this action?',
  NO_AUTOMATION_HERE: 'There are currently no automations here. Please check back later or add a new one.',
  ERROR_WHILE_FETCHING_STAGE_WORKITEMS: 'Error while fetching stage workitems',
  FAILED_TO_RETRIVE_STAGES: 'Failed to retrieve valid stages',
  WORKITEM_MOVED_SUCCESSFULLY: 'Workitem moved successfully',
  FAILED_TO_MOVE_WORKITEM: 'Failed to move work item',
  ERROR_WHILE_FETCHING_BOARD_DETAILS: 'Error while fetching board details',
  ERROR_WHILE_FETCHING_BOARD_ACCESS: 'Error while fetching board access',
  DAYS_IN_THIS_STAGE: 'Days in this stage',
  DAYS_IN_THIS_PROCESS: 'Days in this process',
  ERROR_WHILE_DOWNLOADING_FILES: 'Error while downloading files',
  FORMAT_NOT_SUPPORTED_TO_VIEW: 'Format not supported to view',
  THERE_IS_NO_MEDIA_ATTECHED: 'There is no media attached for this file',
  FILES: 'files',
  NO_FILES: 'No files',
  NO_FILES_HERE: 'There are currently no files here.',
  DOWNLOADING: 'Downloading',
  FILES: 'files',
  SELECT_OBJECT: 'Select object',
  ERROR_WHILE_FETCHING_INEGRATIONS: 'Error while fetching integrations',
  Please_ADD_NAME_FOR_THE_FILE: 'Please add a name for the file',
  NO_INTEGRATIONS: 'No integrations',
  NO_INTEGRATIONS_DESCRIPTION:
    'There are currently no integrations here. Please check back at a later or add a new one.',
  INTEGRATION_UPDATED_SUCCESSFULLY: 'Integration updated successfully',
  ERROR_WHILE_UPDATING_INTEGRATION: 'Error while updating integration',
  ERROR_WHILE_UPDATING_DETAILS: 'Error while updating details',
  ERROR_WHILE_FETCHING_NOTES_LIST: 'Error while fetching notes list',
  CANNOT_ADD_EMPTY_NOTE: 'You cannot add an empty note, please add some text and try again',
  ERROR_WHILE_ADDING_NOTES_LIST: 'Error while adding notes list',
  DELETE_NOTE: 'Delete Note',
  ARE_YOU_SURE_TO_DELETE_THIS_NOTE:
    'Are you sure you want to delete this note? Once deleted, this note cannot be recovered.',
  ADD_NOTE: 'Add Note',
  NEW_NOTE: 'New note',
  EDIT_NOTE: 'Edit Note',
  NO_NOTES: 'No notes',
  NO_NOTES_HERE: 'There are currently no notes here.',
  PREFERED_TIME: 'Prefered time',
  EMPLOYEES: 'Employees',
  MAIN_DETAILS: 'Main details',
  NO_HEATING: 'No Heating',
  OTHER_DETAILS: 'Other details',
  TENURE: 'Tenure',
  LOCAL_AUTHORITY: 'Local authority',
  LOCAL_AUTHORITY_CODE: 'Local authority code',
  EPC_DETAILS: 'EPC details',
  EPC_CURRENT: 'EPC current',
  EPC_POTENTIAL: 'EPC potential',
  LODGMENT_DATE: 'Lodgment date',
  ERROR_WHILE_FETCHING_TASKS: 'Error while fetching tasks',
  ERROR_WHILE_FETCHING_ACTIVITIES: 'Error while fetching activities',
  ERROR_WHILE_FETCHING_FILES: 'Error while fetching files',
  ERROR_WHILE_FETCHING_TASK_EVIDENCE: 'Error while fetching task evidence',
  CANCEL_THIS_ACTIVITY: 'Cancel this activity?',
  REASON_OF_YOUR_CANCELLATION:
    'Are you sure you want to cancel this activity? Please add the reason of your cancellation below..',
  RESCHEDULE: 'Reschedule',
  NO_FORMS: 'No forms',
  NO_FORMS_HERE: 'There are currently no forms here.',
  BACK_TO_ACTIVITIES: 'Back to activities',
  UPCOMING: 'Upcoming',
  PREVIOUS: 'Previous',
  ADD_ACTIVITY: 'Add Activity',
  NO_ACTIVITIES: 'No activities',
  NO_ACTIVITIES_HERE: 'There are currently no activities here.',
  PROPERTY: 'Property',
  VIEW_DETAILS: 'View details',
  COPIED: 'Copied',
  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  ERROR_WHILE_UPLOADING_FILE: 'Error while uploading file {{name}}',
  THIS_FILE_TOO_LARGE: 'The file is too large',
  ERROR_WHILE_UPLOADING_FILE_TRY_AGAIN: 'Error while uploading file, Please try again',
  PLEASE_ADD_FILES: 'Please add files',
  PLEASE_WAIT_TO_FINISH_UPLOAD_FILE: 'Please wait to finish upload file and resolve errors',
  EVIDENCE: 'Evidence',
  EVIDENCE_CREATED_SUCCESSFULLY: 'Evidence created successfully',
  ERROR_WHILE_ADDING_EVIDENCES: 'Error while adding evidences',
  UPLOAD_FILES: 'Upload Files',
  DRAG_AND_DEOP_FILES_HERE: 'Drag and drop files here or',
  BROWSE: 'browse',
  MAXIMUM_FILE_SIZE_50MP: 'Maximum file size 50 MB.',
  MAXIMUM_FILE_SIZE_100KB: 'Maximum file size 100 KB.',
  MAX_6_CHAR: 'max 6 characters',
  UPLOAD_IMAGE: 'Upload image',
  ALIGNMENT: 'Alignment',
  SUBTASKS: 'Subtasks',
  DIRECT: 'Direct',
  FILE_UPDATED_SUCCESSFULLY: 'File updated successfully',
  ERROR_WHILE_UPDATING_FILE: 'Error while updating file',
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
  ASSIGN_TO_ME: 'Assign to me',
  DAYS_IN_PROCESS: 'Days in Process',
  DAYS_IN_STAGE: 'Days in Stage',
  ERROR_WHILE_FETCHING_JOBS: 'Error while fetching jobs',
  ASSIGNEE: 'Assignee',
  DUE_DATE: 'Due date',
  PRIORITY: 'Priority',
  VALUE: 'Value',
  CREATED_ON: 'Created on',
  CREATED_BY: 'Created by',
  JOBS: 'Jobs',
  NO_JOBS: 'No Jobs',
  NO_JOB: 'No Job',
  NO_JOBS_HERE: 'There are currently no jobs here.',
  ADD_LOG: 'Add Log',
  NO_LOG: 'No log',
  NO_LOG_HERE: 'There are currently no logs here. Please check back later or add a new log.',
  ERROR_WHILE_FETCHING_QUOTES_LIST: 'Error while fetching Quotes list',
  THERE_ARE_CURRENTLY_NO_TASKS_AVAILABLE: 'There are currently no tasks available',
  ERROR_WHILE_FETCHING_PROPERTY_DETAILS: 'Error while fetching property details',
  ERROR_WHILE_FETCHING_ROOF_DETAILS: 'Error while fetching roof details',
  ERROR_WHILE_FETCHING_PROPERTY_CONTACTS: 'Error while fetching property contacts',
  ERROR_WHILE_FETCHING_PROPERTIES: 'Error while fetching properties',
  ERROR_WHILE_FETCHING_PANEL_DETAILS: 'Error while fetching panel details',
  NO_PROPERTIES: 'No properties',
  NO_TASKS: 'No Tasks',
  NO_PROPERTIES_HERE: 'There are currently no properties here. Please check back at a later time.',
  NO_TASKS_HERE: 'There are currently no tasks here. Please check back later or add a new task.',
  NO_COMPANY: 'No Company',
  NO_PERSON: 'No Person',
  NO_CONTACT: 'No Contact',
  ERROR_WHILE_UPDATING_QUOTE_STATUS: 'Error while updating quote status',
  SAVE_CHANGES: 'Save changes',
  SEND_QUOTE: 'Send Quote',
  QUOTE_CODE_HAS_BEEN_SENT: 'Quote {{code}} has been sent to the recipient',
  QUOTE_VALUE_UPDATED_SUCCESSFULLY: 'Quote {{value}} updated successfully',
  YOU_CANNOT_EDIT_A_QUOTE: 'To edit this quote please select the revise option from the top right',
  ERROR_WHILE_FETCHING_TEAM_LIST: 'Error while fetching team list',
  NO_ROLES: 'No roles',
  NO_RULES_HERE: 'There are currently no roles here. Please check back later or add a new role.',
  ARE_YOU_SURE_WANT_TO_DELETE_THIS_ROLE: 'Are you sure you want to delete this role?',
  NO_SUB_TASKS: 'No Sub Tasks',
  MAKE_TEAM_LEAD: 'Make team lead',
  MAKE_TEAM_MEMBER: 'Make team member',
  REMOVE_FROM_TEAM: 'Remove from team',
  NO_TEAM_MEMBERS: 'No team members',
  NO_TEAM_MEMBERS_HERE: 'There are currently no team member here. Please check back at a later time.',
  ERROR_WHILE_FETCHING_TEAM_MEMBERS: 'Error while fetching team members',
  TEAM_MEMBERS: 'Team Members',
  NO_TEAMS: 'No teams',
  NO_TEAMS_HERE: 'There are currently no teams here. Please check back later or add a new team.',
  NO_USERS: 'No users',
  NO_USERS_HERE: 'There are currently no users here. Please check back later or invite a new user.',
  ARE_YOU_SURE_TO_DELETE_THIS_USER: 'Are you sure you want to delete this user?',
  RESEND_INVITE: 'Resend Invite',
  LOADING_DOCUMENT: 'Loading document...',
  LOADING_PAGE: 'Loading page...',
  PAGE: 'Page',
  ERROR_WHILE_FETCHING_PROPERTY_EPCS: 'Error while fetching property epcs',
  QUOTE: 'Quote',
  ERROR_WHILE_UPDATE_JOB: 'Error while update job',
  CANNOT_MOVE_JOBS: 'You cannot move this job to this stage due to workflow configuration or permissions',
  EXP_COMPLETION: 'Exp completion',
  SELECT_DEADLINE: 'Select Deadline',
  CONTACT_UPDATED_SUCCESSFULLY: 'Contact has been updated successfully',
  ERROR_UPDATING_CONTACT: 'Error while updating Contact',
  MAKE_PRIMARY_CONTACT: 'Make primary contact',
  NO_OWNERS: 'No owners',
  ERROR_WHILE_FETCHING_QUOTE_DETAILS: 'Error while fetching quote details',
  QUOTE_DETAILS_UPDATED_SUCCESSFULLY: 'Quote details updated successfully',
  ERROR_WHILE_UPDATING_QUOTE_DETAILS: 'Error while updating quote details',
  ADD_LINE_ITEM: 'Add Line Item',
  DUE_AFTER: 'Due after',
  ADD_NEW_ITEM: 'Add {{item}}',
  PERCENTAGE: 'Percentage',
  DESCRIPTION: 'Description',
  QTY: 'Qty',
  UNIT_PRICE: 'Unit Price',
  TAX: 'Tax',
  AMOUNT: 'Amount',
  DISCOUNT: 'Discount',
  CANCEL_OR_SAVE_CHANGES_BEFORE_SENDING_QUOTE: 'Please cancel or save changes before sending the quote',
  CHANGES_TO_QUOTE_CANCELLED: 'The changes to the quote {{code}} has been cancelled',
  ADD_NEW_JOB: 'Add New Job',
  ENTER_JOB_DETAILS: 'Enter job details here',
  JOB_DESCRIPTION: 'Job Description',
  JOB_NUMBER: 'Job number',
  PO_NUMBER: 'PO number',
  LEAD_GEN_SOURCE: 'Lead Gen Source',
  LEAD_SOURCE: 'Lead Source',
  LEAD_GENERATOR: 'Lead Generator',
  ORGANISATION: 'Organisation',
  USER: 'User',
  START_AT: 'Start at',
  PLEASE_SAVE_CHANGES_BEFORE_UPDATING_QUOTE_STATUS:
    'Please either cancel or save your changes before sending the quote',
  ADD_TIME_LABOUR: 'Add Time / Labour',
  ADD_MISC: 'Add Miscellaneous',
  CATALOG: 'Catalog',
  BUNDLES: 'Bundles',
  SHOW_MORE: 'Show more',
  SHOW_LESS: 'Show less',
  JOB_OWNERS_UPDATED_SUCCESSFULLY: 'Job owners updated successfully',
  ERROR_WHILE_FETCHING_SOLAR_OPTIONS: 'Error while fetching solar options',
  ERROR_WHILE_UPDATING_JOB_OWNERS: 'Error while updating job owners',
  QUOTES: 'Quotes',
  MUST_SELECT_PRODUCT_WHEN_ADDING_CATALOG:
    "You must select a product when adding a catalog product, if you don't wish to select a product please remove this line item and add a miscellaneous line item instead",
  SEARCH_FOR_AN_EMPLOYEE: 'Search for an employee',
  ADD_SUCCESSFULLY_EMPLOYEE: 'Successfully added employee added to company',
  ERROR_WHILE_ADD_EMPLOYEE: 'Error while adding employee',
  SUCCESSFULLY_DELETED_ROLE: 'You have successfully deleted the role {{value}}',
  ROLE_DELETE: 'Role deleted',
  LOG_ACTIVITY: 'Log activity',
  COMING_SOON: 'coming soon',
  DAILY_CAPACITY: 'Daily Capacity',
  JOINED: 'Joined',
  SEND_QUOTE_CONFIRMATION: 'Are you sure you want to send this quote to the customer?',
  SEND: 'Send',
  DISCONNECTED_SUCCESSFULLY: 'Disconnected successfully',
  ERROR_WHILE_DISCONNECTING: 'Error while disconnecting',
  CONNECTED_SUCCESSFULLY: 'Connected successfully',
  ERROR_WHILE_CONNECTING: 'Error while connecting',
  USER_DETAILS: 'User details',
  ERROR_WHILE_FETCHING_USER_DETAILS: 'Error while fetching user details',
  ERROR_WHILE_FETCHING_SERVICE_LIST: 'Error while fetching service list',
  CONTACT_CREATED_SUCCESSFULLY: 'Contact created successfully',
  ERROR_WHILE_CREATE_CONTACT: 'Error while creating Contact',
  UPDATED_SUCCESSFULLY: 'updated successfully',
  USER_MEMBER: 'User is now a member',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  ERROR_WHILE_DELETING_FILE: 'Error while deleting file',
  ACTIVITY_UPDATED_SUCCESSFULLY: 'Activity updated successfully',
  ACTIVITY_CREATED_SUCCESSFULLY: 'Activities created successfully',
  ACTIVITY_CANCELLED: 'Activity has been cancelled',
  FAILED_ACTIVITY_CANCELLED: 'Failed to cancel activity',
  FAILED_UPDATE_ACTIVITY: 'Failed to update activity',
  SOMETHING_WRONG_WHILE_FETCHING_RESOUTCES: 'Something went wrong while fetching resources',
  PLEASE_SELECT_FIRST_ACTIVITY: 'Please select activity first',
  PLEASE_SELECT_SERVICE: 'Please select service',
  PLEASE_SELECT_FIRST_SERVICE: 'Please select service first',
  PLEASE_SELECT_RECIPIENT: 'Please select recipient',
  FAILED_ADD_TASK: 'Failed to add tasks',
  FAILED_UPDATE_RESOURCES: 'Failed to update resources',
  PLEASE_ADD_RESOURCE: 'Please add at least one resource',
  ERROR_WHILE_UPDATING_ROL_PERMISSION: 'Error while updating the role Permission',
  NO_LEAD_GENERATOR_SELECTED: 'No Lead generator selected',
  NO_PARTNER_SELECTED: 'No partner selected',
  ASSIGN_PARTNER: 'Assign partner',
  JOB_INFO: 'Job Info',
  NOTE_UPDATED_SUUCESSFULLY: 'Note updated successfully',
  NOTE_ADDED_SUUCESSFULLY: 'Note added successfully',
  NOTE_DELETED_SUUCESSFULLY: 'Note deleted successfully',
  PROFILE_INFO: 'Profile info',
  CANT_BE_EDITED: 'Can’t be edited',
  STREET_ADDRESS: 'Street Address',
  APARTMENT_SUITE: 'Apartment, Suite, etc',
  LINE_3: 'Line 3',
  CITY_TOWN: 'City/Town',
  TERMS: 'Terms',
  PRODUCT: 'Product',
  PRODUCTS: 'Products',
  INVENTORY: 'Inventory',
  PRODUCT_CONFIGURATION: 'Product Configuration',
  CONFIGURATION_PRODUCT_SETTING: 'Configure your product settings',
  MANAGE_EXISTING_PROJECTS_INVITE: 'Manage current and create new projects',
  MANAGE_EXISTING_PRODUCTS_INVITE: 'Manage current and create new products',
  MANAGE_EXISTING_INVENTORY_CREATE: 'Manage current and create new inventory',
  MANAGE_EXISTING_JOB_TYPES_CREATE: 'Manage current and create new job types',
  CODE: 'Code',
  PROJECTS: 'Projects',
  NO_PROJECTS: 'No projects',
  NO_PRODUCT: 'No products',
  NEW_PROJECT: 'New Project',
  ADD_PROJECT: 'Add Project',
  PROJECT_NAME: 'Project name',
  ENTER_PROJECT_NAME: 'Enter the project name',
  PROJECT_ADDED_SUCCESSFULLY: 'Project Added Successfully',
  ENTER_PROJECT_DESCRIPTION: 'Enter a description for project',
  ADD_WORKFLOW: 'Add workflow',
  NO_PROJECTS_HERE: 'There are currently no projects here. Please check back later or add a new project.',
  NO_PRODUCTS_HERE: 'There are currently no products here. Please check back later or add a new product.',
  ERROR_WHILE_FETCHING_PROJECT_LIST: 'Error while fetching project list',
  ERROR_WHILE_FETCHING_PRODUCT_LIST: 'Error while fetching product list',
  ERROR_WHILE_FETCHING_JOB_TYPE_LIST: 'Error while fetching job type list',
  ERROR_WHILE_FETCHING_PRODUCT: 'Error while fetching product',
  ERROR_WHILE_FETCHING_TAX_SCHEMES: 'Error while fetching taxation schemes',
  ERROR_WHILE_FETCHING_PRODUCT_MEDIA: 'Error while fetching product media',
  DUPLICATE: 'Duplicate',
  PROJECT_DETAILS: 'Project Details',
  ARE_YOU_SURE_WANT_TO_DELETE_THIS_PROJECT: 'Are you sure you want to delete  this project?',
  ARE_YOU_SURE_WANT_TO_DELETE_THIS_PRODUCT: 'Are you sure you want to delete this product?',
  ARE_YOU_SURE_WANT_TO_DELETE_THIS_JOB_TYPE: 'Are you sure you want to delete this job type?',
  PRODUCT_DELETED_SUCCESSFULLY: 'Product deleted successfully',
  JOB_TYPE_DELETED_SUCCESSFULLY: 'Job type deleted successfully',
  ERROR_WHILE_DELETING_PRODUCT: 'Error while deleting product',
  ERROR_WHILE_DELETING_JOB_TYPE: 'Error while deleting job type',
  ARE_YOU_SURE_WANT_TO_REMOVE_THIS_PRODUCT: 'Are you sure you want to remove this product?',
  ARE_YOU_SURE_WANT_TO_REMOVE_THIS_JOB_TYPE: 'Are you sure you want to remove this job type?',
  PRODUCT_REMOVED_SUCCESSFULLY: 'Product removed successfully',
  JOB_TYPE_REMOVED_SUCCESSFULLY: 'Job type removed successfully',
  ERROR_WHILE_REMOVING_PRODUCT: 'Error while removing product',
  ERROR_WHILE_REMOVING_JOB_TYPE: 'Error while removing job type',
  PROFILE_UPDATE_SUCCESSFULLY: 'Profile Update Successfully',
  ERROR_WHILE_UPDATING_PROFILE_DETAIL: 'Error while updating profile detail',
  SEND_THE_CODE_AGAIN: 'Send the code again',
  SERVICES_UPDATE_SUCCESSFULLY: 'Services Update Successfully',
  ERROR_WHILE_UPDATING_SERVICES: 'Error while updating srvices',
  CODE_SENT_SUCCESSFULLY: 'Code sent successfully',
  USER_VALUE_UPDATED: 'User {{value}} has been updated successfully',
  ATTACHMENTS: 'Attachments',
  UPLOADING: 'Uploading',
  ATTACHED: 'Attached',
  UPLOAD_AT_ANYTIME: 'You can upload files anytime.',
  DELETE_THESE_FILES: 'Delete these files?',
  SERVICE_DAILY_CAPACITY_IS_REQUIRED: 'Service and Daily capacity is required',
  CAPACITY: 'Capacity',
  SELECT_TEAM: 'Select team',
  ERROR_WHILE_FETCHING_INTEGRATION_CONFIG: 'Error while fetching integration config',
  PRODUCT: 'Product',
  INVENTORY: 'Inventory',
  PRODUCT_CONFIGURATION: 'Product Configuration',
  CONFIGURATION_PRODUCT_SETTING: 'Configure your product settings',
  MANAGE_EXISTING_PRODUCT_INVITE: 'Manage current and create new product',
  MANAGE_EXISTING_INVENTORY_CREATE: 'Manage current and create new inventory',
  CODE: 'Code',
  DUPLICATE: 'Duplicate',
  ARE_YOU_SURE_WANT_TO_DELETE_THIS_QUOTE_TEMPLATE: 'Are you sure you want to delete  this quote template?',
  PLATFORM_CONFIGURATION: 'Platform Configuration',
  MANAGE_EXISTING_QUOTE_TEMPLATES_INVITE: 'Manage current and create new quote templates',
  DEFAULT: 'Default',
  TERMS: 'Terms',
  TEMPLATE_NAME: 'Template name',
  TASK_MANAGEMENT: 'Task Management',
  TASK_GROUPS: 'Task Groups',
  MANAGE_EXISTING_TASK_INVITE: 'Manage current and create new task',
  WORKFLOW_MANAGEMENT: 'Workflow Management',
  FORM_MANAGEMENT: 'Form Management',
  FORMS: 'Forms',
  MANAGE_EXISTING_FORM_INVITE: 'Manage current and create new form',
  WORKFLOW: 'Workflows',
  MANAGE_EXISTING_WORKFLOW_INVITE: 'Create and manage your organisations workflows',
  MANAGE_EXISTING_BOARDS_INVITE: 'Create and manage your organizations boards',
  ENTER_NAME_QUOTES_TEMPLATE: 'Enter a name for the quote template',
  ENTER_DESCRIPTION_QUOTES_TEMPLATE: 'Enter a description for the quote template',
  ENTER_TERMS_QUOTES_TEMPLATE: 'Enter quote terms',
  QUOTE_TEMPLATE_CREATE_SUCCESSFULLY: 'Quote template {{quotetemplatename}} has been created successfully',
  QUOTE_TEMPLATE_UPDATED_SUCCESSFULLY: 'Quote template {{quotetemplatename}} has been updated successfully',
  ERROR_CREATING_QUOTE_TEMPLATE: 'There was an error creating this quote template',
  ERROR_UPDATING_QUOTE_TEMPLATE: 'There was an error updating this quote template',
  CREATE_TEMPLATE: 'Create Template',
  YOU_CANNOT_ADD_PRODUCT: 'To add product please select the integration option from the top left',
  DRAFT: 'Draft',
  MARK_AS_DRAFT: 'Mark as draft',
  ONLY_IMAGE_UPLOAD_ALLOW: 'Only image files are allowed.',
  PLEASE_UPLOADFILE_MAX_SIZE: 'Please file upload maximum size 100 KB.',
  LEFT: 'Left',
  RIGHT: 'Right',
  CENTER: 'Center',
  LEFT_ALIGN: 'Left align',
  RIGHT_ALIGN: 'Right align',
  CENTRE_ALIGN: 'Centre align',
  LEFT_ALIGNMENT: 'Left alignment',
  RIGHT_ALIGNMENT: 'Right alignment',
  CENTRE_ALIGNMENT: 'Centre alignment',
  ERROR_WHILE_FETCHING_QUOTE_TEMPLATE_DETAILS: 'Error while fetching quote template details',
  PASSWORD_RESET_SUCCESSFULLY: 'Password reset successfully',
  NO_TEMPLATE: 'No Template',
  TEMPLATE_NAME_GOES_HERE: 'Template name goes here',
  CONTACT_INFORMATION: 'Contact information',
  ERROR_WHILE_FETCHING_PROJECT_DETAILS: 'Error while fetching project details',
  NO_JOB_TYPE_WORKFLOW_SET_THIS_PROJECT: 'There are no job type workflows set on this project.',
  MAX_SIX_CHARACTERS: 'max 6 characters',
  PROJECT_UPDATED_SUCCESSFULLY: ' Project {{value}} updated successfully',
  QUOTE_TEMPLATE_DELETED_SUCCESSFULLY: 'You have successfully deleted the quote template {{value}}',
  ERROR_CREATING_PROJECT: 'There was an error creating this project, please try again later',
  ERROR_FETCHING_PARTNERS_LIST: `Error while fetching Partners list `,
  ERROR_ADDING_PARTNERS_LIST: `Error while adding new Partner`,
  ADDING_PARTNERS_LIST_SUCCESSFULLY: `New Partner added successfully`,
  NO_PARTNERS: 'No partners',
  NO_PARTNERS_HERE: 'There are currently no partners here.',
  ROOF_INFORMATION: 'Roof Information',
  VIEW_ROOF: 'View roof',
  ROOF_DETAILS: 'Roof details',
  SEGMENT: 'Segment',
  ANNUAL_SUNSHINE: 'Annual Sunshine',
  MAX_PANELS: 'Max Panels',
  TOTAL_POWER: 'Total power',
  PITCH: 'Pitch',
  ACTUAL_PANELS: 'Actual Panels',
  AZIMUTH: 'Azimuth',
  IMPORT: 'Import',
  ERROR_WHILE_IMPORTING_ROOF_DETAILS: 'Error while importing roof details',
  SOLD_AS: 'Sold as',
  PREVIEW: 'Preview',
  PROPERTY_NAME: 'Property name',
  ENTER_JOB_NUMBER: 'Enter job number',
  ENTER_PO_NUMBER: 'Enter PO number',
  UNITS: 'Units',
  APPLY_FILTERS: 'Apply Filters',
  APPLY_SORT: 'Apply sort',
  RESET: 'Reset',
  CLEAR_ALL: 'Clear all',
  EXIT_JOB: 'Are you sure you want to exit this job',
  COMPLETE_FOLLOWING: 'Do you need to complete one of the following actions before you go',
  DO_NOTHING_EXIT_JOB: 'Do nothing and exit the job',
  PROGRESS_AND_EXIT: 'Progress and exit',
  UNASSIGN_AND_EXIT: 'Un-assign job and exit',
  NO_PHONE_NUMBER: 'No phone number',
  DELETE_PARTNER: 'Delete Partner',
  ARE_YOU_SURE_TO_DELETE_THIS_PARTNER:
    'Are you sure you want to delete this partner? Once deleted, this partner cannot be recovered.',
  WRITE_SOMTHING_AWSOME: 'Write something awesome...',
  ERROR_WHILE_SAVING_SEGMENT_AND_PANELS: 'Error while saving segment and panels',
  ERROR_WHILE_FETCHING_PROPERTY_IMAGES: 'Error while fetching property images',
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',
  CREATED_DATE: 'Created Date',
  PLEASE_PLACE_PIN_ON_PROPERTY: 'Please place the pin on the property desired',
  ...TRANSLATIONS_EN_PROPERTY_QUOTE,
  ...TRANSLATIONS_EN_PROPERTY_COSTS,
  SALES_PRICE_INCLUSIVE: 'Sales Price (Inclusive)',
  SALES_PRICE: 'Sales Price',
  PRODUCT_MEDIA: 'Product media',
  SKU: 'SKU',
  PRODUCT_TYPE: 'Product type',
  SOLD_AS: 'Sold as',
  SELECT_TAX_SCHEME: 'Select tax scheme',
  BRANDS: 'Brands',
  FAMILIES: 'Families',
  FEATURES: 'Features',
  SPECIFICATIONS: 'Specifications',
  ADD_BRAND: 'Add Brand',
  ADD_FAMILY: 'Add Family',
  ADD_FEATURE: 'Add Feature',
  SELECT_FAMILY: 'Select Family',
  SELECT_BRAND: 'Select Brand',
  NEW_FEATURE: 'New Feature',
  SELECT_FEATURE: 'Select Feature',
  NAME_PLACEHOLDER: 'Enter the name',
  DESCRIPTION_PLACEHOLDER: 'Enter the description about the brand here',
  DESCRIPTION_PLACEHOLDER_BRAND: 'Enter a description for the board',
  NEW_BRAND: 'New Brand',
  NEW_FAMILY: 'New Family',
  TAG: 'Tag',
  TAG_PLACEHOLDER: 'Select tag',
  SORT_DESCRIPTION_GOES_HEAR: 'Short description goes here',
  LONG_DESCRIPTION: 'Long description',
  ICON_TAG: 'Icon tag',
  ICON: 'Icon',
  ADD_COMPONENT_MAP: 'Add Component Map',
  ROOF_SAVED_SUCCESSFULLY: 'Roof saved successfully',
  ERROR_WHILE_SAVING_NEW_ROOF: 'Error while saving new roof',
  OFFLINE_OR_POOR_WIFI:
    'Seems like you are offline or on a poor wifi connection, please connect to a stronger wifi connection and try again',
  ERROR_WHILE_DELETING_ROOF: 'Error while deleting roof',
  ROOF_DELETED_SUUCESSFULLY: 'Roof deleted successfully',
  ROOF_TITLE: 'Delete Roof {{index}}',
  ARE_YOU_SURE_DELETE_ROOF:
    'Are you sure you want to delete this roof? Once deleted, this roof and its segments cannot be recovered.',
  SEGMENT_TITLE: 'Delete Segment {{index}}',
  ARE_YOU_SURE_DELETE_SEGMENT:
    'Are you sure you want to delete this segment? Once deleted, this segment cannot be recovered.',
  ERROR_WHILE_DELETING_SEGMENT: 'Error while deleting segment',
  SEGMENT_DELETED_SUUCESSFULLY: 'Segment deleted successfully',
  ELECTRICITY_CONSUMPTION: 'Electricity consumption',
  ANNUALLY: 'Annually',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly',
  USAGE_INTERVAL: 'Usage interval',
  USAGE_VALUES: 'Usage values (kWh)',
  DAILY_SUPPLY_CHARGE: 'Daily supply charge',
  ELECTRICITY_RATES: 'Electricity rates',
  SMART_EXPORT_GUARANTEE: 'Smart Export Guarantee (SEG)',
  ELECTRICITY_RATES_INFO:
    'The electricity rate in each tariff region is used when calculating the savings from not consuming utility power.',
  DAILY_SUPPLY_CHARGE_INFO: 'The daily charge is inclusive in the customer’s bill.',
  SMART_EXPORT_GUARANTEE_INFO: 'This is used when self consumption is not 100%.',
  DAY: 'Day',
  PER_DAY: 'per day',
  PER_KWH: ' per kWh',
  OFF_PEAK: ' Off-Peak',
  ERROR_WHILE_FETCHING_ELECTRICITY_CONSUMPTION: 'Error while fetching Electricity consumption',
  ERROR_WHILE_ELECTRICITY_CONSUMPTION: 'Error while Electricity consumption',
  ONLY_XLSX_AND_CSV_FILES_ARE_ALLOWED: 'Only XLSX and CSV file format are allowed',
  VALIDATE: 'Validate',
  GENERATE_PROPOSAL: 'Generate proposal',
  VIEW_PDF: 'View PDF',
  SHARE_PROPOSAL: 'Share proposal',
  SEND_ESTIMATE: 'Send Estimate',
  ERROR_WHILE_FETCHING_SOLAR_DESIGNS: 'Error while fetching Solar Designs',
  ERROR_WHILE_FETCHING_SOLAR_HOURLY: 'Error while fetching Solar Hourly ',
  ERROR_WHILE_FETCHING_SYSTEM_DETAILS: 'Error while fetching Solar System Details ',
  ERROR_WHILE_FETCHING_SOLAR_FINANCIAL: 'Error while fetching Solar Financial ',
  UPLOAD_CONSUMPTION_VALUE: 'Please set or upload a consumption value in property details to see the graph data',
  SUMMARY: 'Summary',
  PPA: 'PPA',
  CONSUMPTION: 'Consumption',
  SYSTEM_SIZE_DC: 'System Size (DC)',
  ANNUAL_PRODUCTION_MCS: 'Annual production',
  ANNUAL_EXPORT: 'Annual export',
  ANNUAL_CONSUMPTION: 'Annual consumption',
  SYSTEM_DETAILS: 'System Details',
  PPA_RATE: 'PPA Rate',
  CONTRACT_LENGTH: 'Contract Length',
  INSTALLER_MARGIN: 'Installer margin',
  PLATFORM_MARGIN: 'Platform Margin',
  INSTALLER_COST_PER_KWH: 'Installer cost per kWh',
  PLATEFORM_FEE: 'Platform Fee',
  APR_PERCENTAGE: 'APR%',
  FUNDING_PERIOD: 'Funding Period',
  PAYBACK: 'Payback',
  EDIT_DESIGN: 'Edit Design',
  ACCOUNT_IS_INCATIVE: 'Account is inactive',
  SELECT_PANEL: 'Select Panel',
  SUCCESS_SOLAR_DESIGNS_FETCH: 'Successfully fetching Solar Designs',
  PANELS_SAVED_SUCCESSFULLY: 'Panels saved successfully',
  ERROR_WHILE_SAVING_PANELS: 'Error while saving panels',
  SYSTEM_OVERVIEW: 'System overview',
  ERROR_WHILE_FETCHING_EVENT_LOG: 'Error while fetching Event log ',
  LAST_SENT_TO: 'Last sent to',
  GENERATE_PDF: 'Generate PDF',
  SELECT_EVIDENCE: 'Select evidence',
  ERROR_WHILE_FETCHING_EVIDENCE_CONFIG: 'Error while fetching Evidence Config',
  ERROR_WHILE_CREATING_EVIDENCE_CONFIG: 'Error while creating Evidence Config',
  GENERATEING_PDF_WAIT:
    'We are generating your PDF, this can take up too a minute so please be patient before trying to view',
  NO_PRODUCTS_ADDED: 'No products added',
  FAILED_TO_GENERATE_PDF: 'Failed to generate PDF, please try again or contact support if the issue persists',
  DOWNLOAD_PDF: 'Download PDF',
  SHARE: 'Share',
  LAST_SHARE: 'Last share',
  NEW_SHARE: 'New share',
  SENT_TO: 'Sent to',
  TO: 'To',
  CC: 'CC',
  BCC: 'BCC',
  SENT: 'Sent',
  PROPOSAL: 'Proposal',
  SELECT_PROPOSAL: 'Select proposal',
  EMAIL_PLACEHOLDER: 'e.g. johnsmith@gmail.com',
  SUBJECT_PLACEHOLDER: 'e.g. Your solar proposal',
  MESSAGE_PLACEHOLDER: 'e.g. Dear...',
  SUBJECT: 'Subject',
  MESSAGE: 'Message',
  ERROR_WHILE_SEND_PRORPOSAL: 'Error while Send Proposal',
  SELECT_DESIGN: 'Select Design',
  ENERGY_COSTS_UPDATED: 'Energy costs updated successfully',
  ERROR_WHILE_UPDATING_ENERGY_COSTS: 'Error while updating Energy Costs',
  ENERGY_PATTERN_UPDATED: 'Energy pattern updated successfully',
  ERROR_WHILE_UPDATING_ENERGY_PATTERN: 'Error while updating Energy pattern',
  ERROR_WHILE_FETCHING_DESIGN_IMAGES: 'Error while fetching design images',
  TAKE_PHOTO: 'Take photo',
  RE_TAKE: 'Re-take',
  GO_TO_JOB_DETAILS: 'Go to Job Details',
  AREAL_LANDSCAPE: 'Aerial',
  ADD_ELECTRICITY_RATE: 'Add Elecricity Rate',
  PLEASE_UPLOAD_SOME_FILES: 'Please upload some files',
  TOTAL_REVENUE: 'Total Revenue',
  TOTAL_COST: 'Total Cost',
  YEAR: 'Year',
  COSTS: 'COSTS',
  EPC_RATE: 'EPC Rate',
  FINANCE_APR: 'Finance Apr',
  TOTAL_CAPITAL: 'Total Capital',
  INSTALL_COSTS: 'Install Costs',
  TERM: 'Term',
  OPEX_COST: 'OPEX Costs',
  PAYBACK_PERIOD: 'Payback period',
  ANNUAL: 'Annual',
  INSTALLER_RATE: 'Installer Rate',
  TOTAL: 'Total',
  REVENUE: 'Revenue',
  PLATFORM_COST: 'Platform Costs',
  ENERGY: 'Energy',
  EXPORT: 'Export',
  ANNUAL_GROSS_REVENUE: 'Annual gross revenue',
  ANNUAL_COST: 'Annual cost',
  ANNUAL_NET_REVENUE: 'Annual net revenue',
  DELETE_PPA_CALCULATION: 'Are you sure you want to delete this PPA calculation',
  PPA_DELETED: 'PPA calculation deleted successfully',
  THIS_ACTION_CANNOT_UNDONE: 'This action cannot be un-done',
  ERROR_WHILE_FETCHING_FINANCIAL_DESIGN: 'Error while fetching Financial Design ',
  UPDATING_CONSUMPTION_PLEASE_WAIT:
    'We are updating the electricity consumption values, this can take a while so please bear with us',
  PROPOSAL_SENT_SUCCESSFULLY: 'Proposal sent successfully',
  PDF_GENERATED_SUCCESSFULLY: 'PDF generated successfully',
  UPDATE_PRODUCT_SUCCESSFULLY: 'Product updated successfully',
  TYPING_COMMENT: 'Typing a comment...',
  TASK_DETAILS: 'Task Details',
  COST: 'Cost',
  UNASSIGNED: 'Unassigned',
  TASK_NAME: 'Task Name',
  JOB: 'Job',
  ADD_SUBTASK: 'Add subtask',
  REQUIRES_EVIDENCE: 'Requires evidence',
  USE_TIMELINE: 'Use timeline',
  FILE_NAME_GOES_HERE: 'Files name goes here',
  SELECT_TYPE: 'Select Type',
  ADD_EVIDENCE: 'Add evidence',
  FORM_TYPE: 'Form type',
  SUB_TASK: 'Sub-task',
  TASK_ADDED: 'Task added successfully',
  ERROR_WHILE_ADDING_TASK: 'Error while adding task',
  ERROR_WHILE_FETCHING_SUB_TASKS: 'Error while fetching sub tasks',
  ADD_NEW_TASK: 'Add new task',
  ERROR_WHILE_FETCHING_SUPPLIERS_PRICING: 'Error while fetching suppliers pricing',
  NO_SUPPLIER_DATA: 'No supplier data',
  NO_SUPPLIER_DATA_HERE: 'There are currently no suppliers here. Please check back later.',
  PLEASE_SELECT_JOB_FIRST: ' Please select a job before selecting the assignee ',
  ERROR_WHILE_UPDATING_PRODUCT_PRICE: 'Error while updating product price',
  PRODUCT_PRICE_UPDATED_AND_TAX_SCHEME_SUCCESSFULLY: 'Product price and taxation scheme successfully',
  EDIT_TASK: 'Edit Task',
  DELETE_TASK: 'Delete Task',
  ADD_SOME_TASK_DESCRIPTION: 'Add some description about this task',
  ERROR_WHILE_UPDATING_TASK: 'Error while updating task',
  NO_DESCRIPTION_HERE: 'No description here',
  ERROR_WHILE_FETCHING_TASK_DETAILS: 'Error while fetching task details',
  WORKITEM_NOT_MOVED_IN_SAME_STAGE: 'Workitem cannot be moved in the same stage',
  WORKITEM_NOT_MOVED_CONFIG_PERMISSION_ERROR:
    'You cannot move this job to this stage due to workflow configurations or permissions.',
  MOVE_HERE: 'Move here!',
  YOU_CAN_NOT_MOVE_HERE: 'You can’t move it here',
  PLEASE_SELECT_ORGANISATION_FIRST: 'Please select organisation before selecting the user',
  SELECT_OR_TYPE_ORGANISATION: 'Select or type the organisation name',
  SELECT_OR_TYPE_USER: 'Select or type the user name',
  STAGE_ARRIED_DATE: 'Stage arrived date',
  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest first',
  ADD_NEW_PRODUCT: 'Add New Product',
  ADD_PRODUCT: 'Add Product',
  NEW_PRODUCT: 'New Product',
  ADD_MANUALLY: 'Add manually',
  ADD_NEW_JOB_TYPE: 'Add New Job Type',
  NEW_JOB_TYPE: 'New Job Type',
  ERROR_WHILE_ADDING_PRODUCTS: 'Error while adding products',
  ERROR_WHILE_ADDING_JOB_TYPES: 'Error while adding job types',
  PRODUCTS_ADDED_SUCCESSFULLY: 'Products added successfully',
  JOB_TYPES_ADDED_SUCCESSFULLY: 'Job types added successfully',
  PRODUCT_NAME: 'Product name',
  DESCRIPTION_PLACEHOLDER_PRODUCT: 'Enter the description about the product here',
  ENTER_PRODUCT_NAME: 'Enter the product name',
  PRODUCT_ADDED_SUCCESSFULLY: 'Products added successfully',
  ERROR_WHILE_ADDING_PRODUCT: 'Error while adding product',
  STAGE: 'Stage',
  PLEASE_ALSO_SELECT_TAXATION_SCHEME: 'Please also select taxation scheme',
  HARDWARE_COSTS: 'Hardware Costs',
  SERVICE_COSTS: 'Service Costs',
  EXCLUDED: 'Excluded',
  TOTAL_AMOUNT: 'Total Amount',
  FINANCE_AMOUNT: 'Finance Amount',
  TOTAL_FUNDING_REQUIRED: 'Total funding required',
  INTEREST_PAYMENTS: 'Interest Payments',
  ERROR_WHILE_FETCHING_QUOTE_PRODUCTS: 'Error while fetching quote products',
  PPA_EXCLUDED_UPDATED_SUCCESSFULLY: 'PPA Excluded updated successfully',
  ERROR_WHILE_UPDATING_PPA_EXCLUDED: 'Error while updating PPA Excluded',
  EVIDENCES_UPDATED_SUCCESSFULLY: 'Evidences updated successfully',
  ERROR_WHILE_UPDATING_EVIDENCES: 'Error while updating evidences',
  EVIDENCE_UPDATED_SUCCESSFULLY: 'Evidence updated successfully',
  ERROR_WHILE_UPDATING_EVIDENCE: 'Error while updating evidence',
  ERROR_WHILE_FETCHING_NOTES_COMMENTS: 'Error while fetching comments',
  NO_COMMENTS: 'No Comments',
  NO_COMMENTS_HERE: 'No comments here yet.',
  COMMENT_ADDED_SUCCESSFULLY: 'Comment added successfully',
  ERROR_WHILE_ADDING_COMMENT: 'Error while adding comment',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PHOTO: 'Are you sure you want to delete this photo?',
  THIS_ACTION_CANT_UNDONE: 'This action can’t be undone.',
  PRODUCT_MEDIA_DELETED_SUCCESSFULLY: 'Product media deleted successfully',
  PRODUCT_PRIMARY_MEDIA_UPDATE_SUCCESSFULLY: 'Product primary media updated successfully',
  ERROR_WHILE_UPDATING_PRODUCT_PRIMARY_MEDIA: 'Error while updating product primary media',
  ERROR_WHILE_DELETING_PRODUCT_MEDIA: 'Error while deleting product media',
  ADD_PRODUCT_SPECIFICATION_SUCCESSFULLY: 'Specifications added successfully',
  ERROR_WHILE_ADDING_PRODUCT_SPECIFICATION: 'Error while adding product specifications',
  NO_PRODUCT_OWNER_MESSAGE:
    'You do not have permission to edit this product as you are not part of the owning organization.',
  ADD_SPECIFICATION: 'Add specification',
  SPECIFICATIONS_UPDATED_SUCCESSFULLY: 'Specifications updated successfully',
  SPECIFICATIONS_ADDED_SUCCESSFULLY: 'Specifications added successfully',
  SPECIFICATIONS_DELETED_SUCCESSFULLY: 'Specifications deleted successfully',
  ERROR_WHILE_SPECIFICATIONS_UPDATEING: 'Error while updating specifications',
  ERROR_WHILE_SPECIFICATIONS_ADDING: 'Error while adding specifications',
  ERROR_WHILE_SPECIFICATIONS_DELETING: 'Error while deleting specifications',
  ERROR_WHILE_PROCESSING_SPECIFICATIONS: 'Error while processing specifications',
  ERROR_WHILE_UPDATE_TASK: 'Error while updating task',
  STATUS_UPDATED_SUCCESSFULLY: 'Status updated successfully',
  FILE_ACCESS: 'File access',
  SELECT_WHICH_ORGANISATION_CAN_ACCESS_THIS_FILE: 'Select which organizations can access this file',
  THERE_ARE_NO_PARTNERS_TO_SHARE_THIS_FILE_WITH: 'There are no partners to share this file with',
  ERROR_WHILE_IMPORTING_ENERGY_CONSUMPTION: 'Error while importing energy consumption',
  ERROR_WHILE_FETCHING_REASONS_LIST: 'Error while fetching reasons list',
  ERROR_WHILE_CREATING_SOLAR_FINANCIAL: 'Error while creating solar financial',
  ERROR_WHILE_UPDATING_SOLAR_FINANCIAL: 'Error while updating solar financial',
  ERROR_WHILE_UPDATING_PROJECT: 'Error while updating project',
  BEFORE: 'BEFORE',
  DURING: 'DURING',
  AFTER: 'AFTER',
  NO_ENERGY_PATTERN_REQUIRED: 'No energy pattern required with uploaded consumption file',
  ENERGY_PATTERN: 'Energy Pattern',
  NO_ASSIGNEE: 'No assignee',
  NO_EVIDENCE_AVAILABLE: 'No evidence available',
  SELECT_JOB: 'Select job',
  NO_JOB_TYPE: 'No job type',
  NO_JOB_TYPE_HERE: 'There are currently no job type here. Please check back later or add a new quote template.',
  NO_DEFAULT_JOB_TYPE_HERE: 'There are no more default job types available',
  JOB_TYPE_NAME: 'Job type name',
  DESCRIPTION_PLACEHOLDER_JOB_TYPE: 'Enter the description about the job type here',
  ENTER_JOB_TYPE_NAME: 'Enter the job type name',
  SUPPLIER: 'Supplier',
  DEALS: 'Deals',
  DEAL_CREATE_SUCCESSFULLY: 'Deal created successfully',
  ERROR_WHILE_CREATING_DEAL: 'Error while creating deal',
  ADD_NEW_DEAL: 'Add New Deal',
  DEAL_NAME: 'Deal Name',
  ENTER_DEAL_NAME: 'Enter deal name',
  ARE_YOU_SURE_TO_DELETE_THIS_TASK:
    'Are you sure you want to delete this task and it’s subtasks? Once deleted, it cannot be recovered.',
  DELETE_TASK: 'Delete this task?',
  TASK_DELETED_SUCCESSFULLY: 'Task deleted successfully',
  ERROR_WHILE_DELETING_TASK: 'Error while deleting task',
  ERROR_WHILE_DOWNLOADING_FILE: 'Error while downloading file',
  UPLOADED_FILE_CSV: "Uploaded file must be in 'CSV' format",
  ERROR_WHILE_UPDATING_PROPERTY_NAME: 'Error while updating property name',
  PROPERTY_NAME_UPDATED_SUCCESSFULLY: 'Poperty name updated successfully',
  LEADS: 'Leads',
  NO_SERVICE: 'No service',
};
