import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertInfoIcon } from '../../assets/icons/AlertInfoIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/property/cross_icon.svg';
import { ReactComponent as TransferIcon } from '../../assets/images/transfered.svg';
import Button from '../../components/common/button/button.js';
import IconContainer from '../../components/common/icon-container/index.js';
import { OrganisationContext } from '../../context/organisationContext.js';
import { initModal } from '../../helpers/utils.js';
import { useError } from '../../hooks/useError.js';
import { updateEventType } from '../../store/features/automationsSlice.js';
import { addToast } from '../../store/features/toastSlice.js';

const UpdateTrigger = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { setModal, modal } = useContext(OrganisationContext);
  const { automationDetails, onSuccess, currentTrigger, selectedTrigger } = modal?.content ?? {};

  const [loading, setLoading] = useState(false);

  const onDone = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const request = { event_type: selectedTrigger?.event_type };
    dispatch(updateEventType({ request, automation_id: automationDetails?.id }))
      .then(data => {
        setLoading(false);
        setModal(initModal);
        onSuccess && onSuccess(data);
        dispatch(
          addToast({
            error: false,
            title: t('UPDATE_AUTOMATION_TRIGGER'),
            text: t('SUCCESSFULLY_UPDATE_AUTOMATION_TRIGGER'),
            id: nanoid(),
          }),
        );
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_AUTOMATION_TRIGGER'), id: nanoid() });
        setLoading(false);
      });
  };

  const renderTrigger = useCallback((trigger, triggerType, triggerTypeClass = '') => {
    const { id, icon, name, description } = trigger;
    return (
      <div className={classNames('flex items-center cursor border px-4 py-3 radius-1_5 overflow-hidden')} key={id}>
        <div className="flex pxy-1 border radius-1_5 mr-2 trigger-icon">
          <img src={icon?.active} alt="event-type-icon" height={24} width={24} />
        </div>
        <div className="flex-column flex-1 col-gap-3">
          <label className="inter-500-text natural-700-text one-line">{name}</label>
          <label className="inter-400-text natural-500-text font-12 one-line">{description}</label>
        </div>
        <span className={classNames('inter-600-text letter-spacing-1 font-12 h-full', ...triggerTypeClass)}>
          {triggerType}
        </span>
      </div>
    );
  }, []);

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <UpdateTriggerWrapper className="flex-column pxy-6">
        <div className="flex justify-between">
          <div className="flex-1 flex items-center justify-center">
            <div className="flex">
              <AlertInfoIcon />
            </div>
          </div>
          <CrossIcon width={24} height={24} className="cursor" onClick={() => setModal(initModal)} />
        </div>
        <div className="flex-column gap-2 items-center justify-start w-full mt-5">
          <p className="font-18 text-center inter-500-text natural-900-text">{t('UPDATE_THIS_AUTOMATION_TRIGGER')}</p>
          <span className="inter-400-text font-14 natural-500-text text-center">
            {t('CONFIGURED_TO_THIS_AUTOMATION')}
          </span>
        </div>
        <div className="flex-column row-gap-2 mt-8">
          {renderTrigger(currentTrigger, 'CURRENT', ['natural-500-text'])}
          <IconContainer Icon={TransferIcon} iconColor="natural_500" backgroundColor="transparent" />
          {renderTrigger(selectedTrigger, 'NEW', ['primary-500-text'])}
        </div>
        <div className="flex-column justify-center w-full gap-3 mt-6">
          <Button
            className={classNames('primary', loading && 'disabled')}
            label={t('UPDATE')}
            disabled={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </UpdateTriggerWrapper>
    </CSSTransition>
  );
};

const UpdateTriggerWrapper = styled.div`
  width: 380px;
`;

export default UpdateTrigger;
