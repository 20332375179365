import { ReactComponent as RightIcon } from '@assets/images/arrow-right.svg';
import { ReactComponent as CloseIcon } from '@assets/images/toast-close.svg';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { priorityOptions, statusOptions } from '../../../helpers/optionData';
import useDebounce from '../../../helpers/useDebounceHook';
import { getUsers } from '../../../store/features/userManagementSlice';
import { getEngagementAccess } from '../../../store/features/workitemSlice';
import Button from '../../common/button/button';
import Checkbox from '../../common/checkbox';
import DateSelectorElement from '../../common/date-selector/date-selector-element';
import IconContainer from '../../common/icon-container';
import InputSearch from '../../common/input-search';

const TasksFilter = ({
  setShowSortPopover,
  setBoardsFilters,
  boardsFilters,
  onClearFilters = () => {},
  engagement_id = '',
  isFromTaskList = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchAssignees, setSearchAssignees] = useState('');
  const [openSection, setOpenSection] = useState('');
  const [owners, setOwners] = useState([]);

  const debouncedSearch = useDebounce(searchAssignees, 500);

  const assigneeList = owners || [];

  const [filters, setFilters] = useState({
    status: [],
    priority: [],
    assignee: [],
    due_date: null,
  });

  useEffect(() => {
    if (boardsFilters) {
      setFilters(boardsFilters);
    }
  }, [boardsFilters]);

  function updateFilters(key, value) {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (!newFilters[key]) {
        newFilters[key] = [];
      }

      if (key === 'due_date') {
        newFilters[key] = value;
      } else if (Array.isArray(newFilters[key])) {
        const exists = newFilters[key].some(item => item.id === value.id);
        if (exists) {
          newFilters[key] = newFilters[key].filter(item => item.id !== value.id);
        } else {
          newFilters[key] = [...newFilters[key], value];
        }
      }

      return newFilters;
    });
  }

  const applyFilters = () => {
    setBoardsFilters(filters);
    setShowSortPopover(false);
  };

  const toggleSection = section => {
    setOpenSection(prevSection => (prevSection === section ? null : section));
  };

  const fetchUsersList = (merge = false, debouncedSearch, showLoading = true) => {
    dispatch(
      getUsers({
        merge,
        forFetchOnly: true,
        params: {
          page: 0,
          size: 15,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        const { content } = data || {};
        setOwners(content);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_USERS') });
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (isFromTaskList) {
      fetchUsersList(false, debouncedSearch, true);
    }
  }, [debouncedSearch]);

  const fetchEngagementAccess = searchValue => {
    dispatch(getEngagementAccess({ id: engagement_id, params: { search: searchValue } }))
      .then(data => {
        setOwners(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASK_DETAILS') });
        setOwners([]);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (engagement_id && !isFromTaskList) {
      fetchEngagementAccess(debouncedSearch);
    }
  }, [engagement_id, debouncedSearch]);

  return (
    <SortPopoverWrapper className="popover-content bg-white py-5 radius-2 flex-column">
      <div className="justify-between px-5">
        <label className="inter-500-text font-18 natural-900-text line-height-28">{t('FILTER_BY')}</label>
        <IconContainer
          Icon={CloseIcon}
          iconHeight={16}
          iconWidth={16}
          iconColor="natural_500"
          backgroundColor="transparent"
          iconContainerClassname="cursor"
          onClick={() => setShowSortPopover(false)}
        />
      </div>
      <div className={classNames('my-4')}>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': !openSection !== 'project' })}
            onClick={() => toggleSection('PRIORITY')}>
            <div className="flex items-center gap-1">
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'PRIORITY',
                  'natural-400-text': !openSection !== 'PRIORITY',
                })}>
                {t('PRIORITY')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'PRIORITY' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'PRIORITY',
                'rotate-270': openSection === 'PRIORITY',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'hidden', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'PRIORITY' ? 1 : 0,
              visibility: openSection === 'PRIORITY' ? 'visible' : 'hidden',
              height: openSection === 'PRIORITY' ? 'auto' : 0,
              maxHeight: 250,
              transition: { duration: 0.5 },
              overflow: 'auto',
            }}
            className={classNames('bg-natural-50')}>
            <div className="checkbox-content flex-column gap-3 px-5 py-5">
              {priorityOptions?.map(priority => {
                return (
                  <div className="flex items-center gap-2" key={priority?.id}>
                    <Checkbox
                      is_checked_done={true}
                      checked={filters?.priority?.some(item => item.id === priority.id)}
                      onChange={() => updateFilters('priority', priority)}
                      id={`priority-${priority?.id}`}
                    />
                    <label htmlFor={`priority-${priority?.id}`} className="no-select inter-400-text font-16">
                      {priority?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': !openSection !== 'ASSIGNEE' })}
            onClick={() => toggleSection('ASSIGNEE')}>
            <div className="flex items-center gap-1">
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'ASSIGNEE',
                  'natural-400-text': !openSection !== 'ASSIGNEE',
                })}>
                {t('ASSIGNEE')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'ASSIGNEE' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'ASSIGNEE',
                'rotate-270': openSection === 'ASSIGNEE',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'none', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'ASSIGNEE' ? 1 : 0,
              visibility: openSection === 'ASSIGNEE' ? 'visible' : 'hidden',
              height: openSection === 'ASSIGNEE' ? 'auto' : 0,
              transition: { duration: 0.5 },
              maxHeight: 250,
              overflow: 'auto',
            }}
            className={classNames('bg-natural-50')}>
            <div className="sticky top-0 pb-0 pxy-5 bg-natural-50 z-1">
              <InputSearch placeholder={'Search'} value={searchAssignees} onChange={setSearchAssignees} />
            </div>
            <div className="checkbox-content flex-column gap-3 px-5 pb-5">
              {(assigneeList || [])?.map(assignee => {
                return (
                  <div className="flex items-center gap-2" key={assignee?.id}>
                    <Checkbox
                      is_checked_done={true}
                      checked={filters?.assignee?.some(item => item.id === assignee.id)}
                      onChange={() => updateFilters('assignee', assignee)}
                      id={`assignee-${assignee?.id}`}
                    />
                    <label htmlFor={`assignee-${assignee?.id}`} className="no-select inter-400-text font-16">
                      {assignee?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': !openSection !== 'campaign' })}
            onClick={() => toggleSection('STATUS')}>
            <div className="flex items-center gap-1">
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'STATUS',
                  'natural-400-text': !openSection !== 'STATUS',
                })}>
                {t('STATUS')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'STATUS' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'STATUS',
                'rotate-270': openSection === 'STATUS',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'none', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'STATUS' ? 1 : 0,
              visibility: openSection === 'STATUS' ? 'visible' : 'hidden',
              height: openSection === 'STATUS' ? 'auto' : 0,
              transition: { duration: 0.5 },
              overflow: 'auto',
              maxHeight: 250,
            }}
            className={classNames('bg-natural-50')}>
            <div className="checkbox-content flex-column gap-3 px-5 py-5">
              {statusOptions?.map(status => {
                return (
                  <div className="flex items-center gap-2" key={status?.id}>
                    <Checkbox
                      is_checked_done={true}
                      checked={filters?.status?.some(item => item?.id === status?.id)}
                      onChange={() => updateFilters('status', status)}
                      id={`status-${status?.id}`}
                    />
                    <label htmlFor={`status-${status?.id}`} className="no-select inter-400-text font-16">
                      {status?.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
        <div>
          <div
            className={classNames('justify-between py-2 px-5', { 'border-bottom': openSection !== 'lead_source' })}
            onClick={() => toggleSection('DUE_DATE')}>
            <div className="flex items-center gap-1">
              <label
                className={classNames('inter-500-text font-14', {
                  'natural-700-text': openSection === 'DUE_DATE',
                  'natural-400-text': !openSection !== 'DUE_DATE',
                })}>
                {t('DUE_DATE')}
              </label>
            </div>
            <IconContainer
              Icon={RightIcon}
              iconHeight={16}
              iconWidth={16}
              iconColor={openSection === 'DUE_DATE' ? 'natural_700' : 'natural_400'}
              backgroundColor="transparent"
              iconContainerClassname={classNames('cursor', {
                'rotate-90': !openSection !== 'DUE_DATE',
                'rotate-270': openSection === 'DUE_DATE',
              })}
            />
          </div>
          <motion.div
            initial={{ visibility: 'hidden', opacity: 0, height: 0, padding: 0 }}
            animate={{
              opacity: openSection === 'DUE_DATE' ? 1 : 0,
              visibility: openSection === 'DUE_DATE' ? 'visible' : 'hidden',
              height: openSection === 'DUE_DATE' ? 'auto' : 0,
              transition: { duration: 0.5 },
              maxHeight: 250,
            }}
            className={classNames('bg-natural-50')}>
            <div className="bg-natural-50 flex flex-column gap-2 pxy-5">
              <DateSelectorElement
                selectedDate={filters?.due_date}
                setSelectedDate={value => updateFilters('due_date', value)}
                isCalendarIcon={false}
                format="Do MMM, YYYY"
                placeholder={'Select due date'}
              />
            </div>
          </motion.div>
        </div>
      </div>
      <div className="flex col-gap-10 px-5 justify-center mt-1 ">
        <Button
          className={classNames('primary-grey')}
          label={t('CLEAR_ALL')}
          onClick={() => {
            setShowSortPopover(false);
            onClearFilters();
          }}
          size="average"
          borderRadius="100px"
        />
        <Button
          className={classNames('primary ')}
          label={t('APPLY_FILTERS')}
          onClick={applyFilters}
          size="average"
          borderRadius="100px"
        />
      </div>
    </SortPopoverWrapper>
  );
};

const SortPopoverWrapper = styled.div`
  &.popover-content {
    z-index: 10;
    width: 357px;
    box-shadow: 0px 2px 8px -4px rgba(16, 24, 40, 0.1), 0px 6px 17px -3px rgba(16, 24, 40, 0.1);
  }

  .radio-container {
    margin-bottom: 14px;
  }

  .radio-label {
    margin-left: 5px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }
`;

export default TasksFilter;
