import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import Status from '../../components/common/status';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalizeFirstLetterOfWords, formatText, getFormattedNumber, initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { deleteCosts } from '../../store/features/costsSlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteCosts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { modal, setModal } = useContext(OrganisationContext);
  const { costs, title, descriptions, onSuccess } = modal.content;
  const {
    id,
    name,
    quantity,
    cost_type,
    cost_total_amount,
    sale_total_amount,
    cost_unit_price,
    cost_tax_rate,
    sale_unit_price,
    sale_tax_rate,
    description,
  } = costs;

  const [loading, setLoading] = useState(false);
  const onDone = async () => {
    setLoading(true);

    try {
      await dispatch(deleteCosts({ cost_id: id }));

      dispatch(
        addToast({
          error: false,
          title: t('COSTS_REMOVED'),
          text: `${t('SUCCESSFULLY_REMOVED_COSTS')} ${name}.`,
          id: nanoid(),
        }),
      );

      onSuccess && onSuccess();
      setModal(initModal);
    } catch (error) {
      showErrorToast({ error, default_message: t('ERROR_REMOVING_COSTS'), id: nanoid() });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CostsActionWrapper className="flex-column items-center radius-3 pxy-6">
        <div className="flex-column content-wrapper gap-5 items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text natural-400-text text-center font-14">{descriptions}</p>
          </div>
        </div>
        <div className="border radius-1_5 py-4 px-5 w-full">
          <div className="pb-4">
            <div className="flex items-center justify-between pb-1">
              <h2 className="font-16 inter-600-text natural-900-text my-0">{name}</h2>
              <Status
                withDot={false}
                withDottedBorder
                status={cost_type}
                statusText={capitalizeFirstLetterOfWords(formatText(cost_type))}
              />
            </div>
            <div>
              <p className="inter-400-text natural-500-text font-12">{description}</p>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex-column row-gap-2 w-full">
              <div className="flex items-center col-gap-4">
                <p className="inter-400-text natural-500-text font-12">{t('QUANTITY')}:</p>
                <span className=" inter-400-text natural-900-text font-12">{quantity}</span>
              </div>
              <div className="flex items-center col-gap-4">
                <p className="inter-400-text natural-500-text font-12">{t('COST_NET')}: </p>
                <span className=" inter-400-text natural-900-text font-12">
                  {getFormattedNumber(cost_unit_price || '0')}
                </span>
              </div>
              <div className="flex items-center col-gap-4">
                <p className="inter-400-text natural-500-text font-12">{t('COST_TAX')}: </p>
                <span className=" inter-400-text natural-900-text font-12">
                  {cost_tax_rate ? `${cost_tax_rate}%` : '0'}
                </span>
              </div>
              <div className="flex items-center col-gap-2">
                <p className="inter-400-text natural-500-text font-12">{t('COST_PRICE')}: </p>
                <span className=" inter-400-text natural-900-text font-12">
                  {getFormattedNumber(cost_total_amount || '0')}
                </span>
              </div>
            </div>
            <div className="flex-column row-gap-2 w-full">
              <div className="flex items-center col-gap-4">
                <p className="inter-400-text natural-500-text font-12">{t('SALE_NET')}:</p>
                <span className=" inter-400-text natural-900-text font-12">
                  {getFormattedNumber(sale_unit_price || '0')}
                </span>
              </div>

              <div className="flex items-center col-gap-4">
                <p className="inter-400-text natural-500-text font-12">{t('SALE_TAX')}: </p>
                <span className=" inter-400-text natural-900-text font-12">
                  {sale_tax_rate ? `${sale_tax_rate}%` : '0'}
                </span>
              </div>
              <div className="flex items-center col-gap-2">
                <p className="inter-400-text natural-500-text font-12">{t('SALE_PRICE')}: </p>
                <span className=" inter-400-text natural-900-text font-12">
                  {getFormattedNumber(sale_total_amount || '0')}
                </span>
              </div>
            </div>
            <div className="flex-column row-gap-2"></div>
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative')}
            label={t('DELETE')}
            disabled={loading}
            loading={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </CostsActionWrapper>
    </CSSTransition>
  );
};

export const CostsActionWrapper = styled.div`
  width: 420px;
  gap: 24px;
`;

export default DeleteCosts;
