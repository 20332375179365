import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getErrorDescription } from '../helpers/utils';
import { addToast } from '../store/features/toastSlice';

export const useError = props => {
  const {
    getErrorTextFunc = null,
    toastMeta = {},
    showToast = false,
    error = null,
    default_message = 'Something went wrong!',
  } = props || {};
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getErrorText = ({ error = {}, default_message }) => {
    let errorText = getErrorTextFunc ? getErrorTextFunc(error) : getErrorDescription(error);
    const tooLongErrorText = errorText && errorText.length > 100;
    if (tooLongErrorText) {
      errorText = default_message;
      return errorText;
    }
    return errorText || default_message;
  };

  const showErrorToast = ({ additionalMeta = {}, default_message = '', error = null }) => {
    const { code } = error || {};
    const isNetworkError = code === 'ERR_NETWORK' || code === 'ECONNABORTED';
    if (isNetworkError) {
      dispatch(
        addToast({
          ...toastMeta,
          ...additionalMeta,
          text: t('OFFLINE_OR_POOR_WIFI'),
          error: true,
          id: 'OFFLINE_OR_POOR_WIFI',
        }),
      );
      return;
    }
    const errorText = getErrorText({ error, default_message });
    dispatch(addToast({ ...toastMeta, ...additionalMeta, text: errorText, error: true, id: nanoid() }));
  };

  useEffect(() => {
    if (error && showToast) {
      const errorText = getErrorText({ error, default_message });
      dispatch(addToast({ ...toastMeta, text: errorText, error: true, id: nanoid() }));
    }
  }, [error, showToast]);

  return { error, showErrorToast };
};
