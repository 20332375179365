import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import Button from '../../components/common/button/button.js';
import InputElement from '../../components/common/input/index.js';
import SearchableDropdown from '../../components/common/searchable-dropdown/index.js';
import Dropdown from '../../components/common/select-dropdown/index.js';
import { OrganisationContext } from '../../context/organisationContext.js';
import { costsTypeOptions } from '../../helpers/optionData.js';
import useDebounce from '../../helpers/useDebounceHook.js';
import { getFormattedNumber, initModal } from '../../helpers/utils.js';
import { useError } from '../../hooks/useError.js';
import { createCosts } from '../../store/features/costsSlice.js';
import { getProductList, getQuotesList } from '../../store/features/quotesSlice.js';
import { addToast } from '../../store/features/toastSlice.js';

const CustomOptions = ({ innerProps, data, isSelected, selectProps }) => {
  const { getOptionLabel } = selectProps;
  return (
    <div
      className={classNames(
        'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <span
        className={classNames(
          'flex-1 inter-400-text natural-900-text option-text ml-2',
          isSelected && 'inter-500-text',
        )}>
        {getOptionLabel(data)}
      </span>
    </div>
  );
};

const PriceComponent = ({ label, value }) => {
  const formattedValue = getFormattedNumber(value)?.split('£')[1] || 0;
  return (
    <div className="flex-column w-full">
      <div className="mb-1 one-line">
        <span className="inter-500-text natural-900-text">{label}</span>
      </div>
      <div className="flex items-center col-gap-1 py-3 h-44px">
        <span className="percent-before inter-400-text font-14 natural-900-text">£</span>
        <p className={classNames('inter-400-text font-14 ', value <= 0 ? 'natural-400-text' : 'natural-900-text')}>
          {value === 0 ? value : formattedValue}
        </p>
      </div>
    </div>
  );
};

const AddCosts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { setModal, modal } = useContext(OrganisationContext);
  const { engagement_id, onSuccess, isEdit } = modal?.content || {};

  const [costs, setCosts] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchedQuotes, setSearchedQuotes] = useState('');
  const [searchedInventory, setSearchedInventory] = useState('');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const {
    name,
    description,
    inventory,
    quantity,
    quotes,
    cost_unit_price,
    cost_tax_rate,
    sale_unit_price,
    sale_tax_rate,
    cost_type,
  } = costs;

  const cost_total_amount = cost_unit_price * quantity * (1 + (cost_tax_rate / 100 || 0));
  const sale_total_amount = sale_unit_price * quantity * (1 + (sale_tax_rate / 100 || 0));
  const checkAddCostErrors = () => {
    const { quantity, cost_unit_price, cost_tax_rate, sale_unit_price, sale_tax_rate } = costs;
    if (
      !quantity?.trim() ||
      !cost_unit_price?.trim() ||
      !cost_tax_rate?.trim() ||
      !sale_unit_price?.trim() ||
      !sale_tax_rate?.trim()
    ) {
      setError({
        quantity: !quantity?.trim(),
        cost_unit_price: !cost_unit_price?.trim(),
        cost_tax_rate: !cost_tax_rate?.trim(),
        sale_unit_price: !sale_unit_price?.trim(),
        sale_tax_rate: !sale_tax_rate?.trim(),
      });
      dispatch(
        addToast({
          error: true,
          text: t('ERROR_ENTER_COST_PRICE'),
        }),
      );
      return true;
    } else if (quantity < 0 || cost_unit_price < 0 || cost_tax_rate < 0 || sale_unit_price < 0 || sale_tax_rate < 0) {
      setError({
        quantity: true,
        cost_unit_price: true,
        cost_tax_rate: true,
        sale_unit_price: true,
        sale_tax_rate: true,
      });
      dispatch(
        addToast({
          error: true,
          text: t('ERROR_ADD_PRICE_ABOVE'),
        }),
      );
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (checkAddCostErrors() || loading) {
      return;
    }
    setLoading(true);
    const request = {
      sku: '',
      name: name || '',
      description: description || '',
      quantity: quantity || '',
      cost_unit_price: cost_unit_price || 0,
      cost_tax_rate: cost_tax_rate || 0,
      cost_total_amount: cost_total_amount || 0,
      sale_unit_price: sale_unit_price || 0,
      sale_tax_rate: sale_tax_rate || 0,
      sale_total_amount: sale_total_amount || 0,
      cost_type: cost_type?.value || '',
      quote: quotes?.id
        ? {
            id: quotes?.id,
          }
        : null,
      product: {
        id: inventory?.id || '',
      },
      quote_product: null,
    };

    dispatch(createCosts({ engagement_id: engagement_id, request }))
      .then(data => {
        setLoading(false);
        setModal(initModal);
        onSuccess && onSuccess(data);
        dispatch(
          addToast({
            error: false,
            title: t('COSTS_ADDED'),
            text: `${name} ${t('SUCCESSFULLY_COSTS_ADDED')}`,
            id: nanoid(),
          }),
        );
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_ADDING_COST'), id: nanoid() });
        setLoading(false);
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade " in timeout={300} style={{ height: '794px' }}>
      <AddCostsWrapper className="flex-column pxy-10 overflow-scroll">
        <div className="flex justify-between pb-6 border-bottom">
          <p className="inter-700-text natural-900-text font-28">{!isEdit ? t('ADD_NEW_COSTS') : t('EDIT_COSTS')}</p>
        </div>
        <div className="flex-column row-gap-6 mt-8">
          <div className="w-full flex-column">
            <Dropdown
              className="w-full provider-selector"
              onChange={option => {
                setCosts({ ...costs, cost_type: option, inventory: null, quotes: null });
              }}
              name={t('COST_TYPE')}
              options={costsTypeOptions}
              placeholder={t('SELECT_COST_TYPE')}
              value={costs?.cost_type}
            />
          </div>
          {cost_type?.value === 'QUOTE' && (
            <div className="w-full flex-column">
              <SearchableDropdown
                isClearable
                inputValue={searchedQuotes}
                onInputChange={setSearchedQuotes}
                placeholder={t('SELECT')}
                name={t('QUOTES')}
                value={quotes}
                onChange={option => {
                  setCosts({ ...costs, quotes: option, inventory: null });
                  setError({ ...error, quotes: false });
                }}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getQuotesList,
                  pageable: false,
                  payload: {
                    engagement_id: engagement_id,
                    params: { search: debouncedSearch },
                  },
                }}
                getOptionLabel={option => option.code}
                customComponent={{ Option: CustomOptions }}
                error={error.quotes}
              />
            </div>
          )}
          {cost_type?.value === 'MATERIALS' && (
            <div className="w-full flex-column">
              <SearchableDropdown
                inputValue={searchedInventory}
                onInputChange={setSearchedInventory}
                placeholder={t('SELECT')}
                name={t('INVENTORY')}
                value={inventory}
                onChange={option => {
                  setCosts({ ...costs, inventory: option, quotes: null });
                  setError({ ...error, inventory: false });
                }}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getProductList,
                  pageable: true,
                  params: {
                    include_pricing: true,
                  },
                }}
                getOptionLabel={option => option.name}
                customComponent={{ Option: CustomOptions }}
              />
            </div>
          )}
          <div className="w-full flex-column ">
            <InputElement
              name={t('COST_NAME')}
              sub_name={t('OPTIONAL')}
              onChange={value => {
                setCosts({ ...costs, name: value });
              }}
              defaultValue={name || ''}
              placeholder={t('EXAMPLE_COST_NAME')}
            />
          </div>
          <div className="w-full flex-column">
            <InputElement
              name={t('DESCRIPTION')}
              sub_name={t('OPTIONAL')}
              onChange={value => {
                setCosts({ ...costs, description: value });
              }}
              defaultValue={description || ''}
              placeholder={t('EXAMPLE_COST_DESCRIPTION')}
            />
          </div>
          <div className="w-full flex-column">
            <InputElement
              name={t('QUANTITY')}
              type="number"
              onChange={value => {
                setCosts({ ...costs, quantity: value });
                setError({ ...error, quantity: false });
              }}
              placeholder="0"
              defaultValue={quantity}
              error={error.quantity}
            />
          </div>
          <div className="w-full  col-gap-6 cost-grid-sec">
            <InputElement
              name={t('COST_NET')}
              type="number"
              onChange={value => {
                setCosts({ ...costs, cost_unit_price: value });
                setError({ ...error, cost_unit_price: false });
              }}
              placeholder="0"
              defaultValue={cost_unit_price}
              extra_text_before={'£'}
              error={error.cost_unit_price}
            />
            <InputElement
              name={t('COST_TAX')}
              type="number"
              onChange={value => {
                setCosts({ ...costs, cost_tax_rate: value });
                setError({ ...error, cost_tax_rate: false });
              }}
              placeholder="0"
              defaultValue={cost_tax_rate}
              extra_text_after={'%'}
              error={error.cost_tax_rate}
            />

            <PriceComponent label={t('COST_PRICE')} value={cost_total_amount || 0} />
          </div>
          <div className="w-full  col-gap-6 cost-grid-sec">
            <InputElement
              name={t('SALE_NET')}
              type="number"
              onChange={value => {
                setCosts({ ...costs, sale_unit_price: value });
                setError({ ...error, sale_unit_price: false });
              }}
              placeholder="0"
              defaultValue={sale_unit_price}
              extra_text_before={'£'}
              error={error.sale_unit_price}
            />
            <InputElement
              name={t('SALE_TAX')}
              type="number"
              onChange={value => {
                setCosts({ ...costs, sale_tax_rate: value });
                setError({ ...error, sale_tax_rate: false });
              }}
              placeholder="0"
              defaultValue={sale_tax_rate}
              extra_text_after={'%'}
              error={error.sale_tax_rate}
            />

            <PriceComponent label={t('SALE_PRICE')} value={sale_total_amount || 0} />
          </div>
          <div className="flex col-gap-6 justify-center mt-4 w-full">
            <Button
              className={classNames('primary-white flex-1')}
              label={t('CANCEL')}
              disabled={loading}
              onClick={() => setModal(initModal)}
              size="large"
              borderRadius="100px"
            />
            <Button
              className={classNames('primary flex-1')}
              label={isEdit ? t('SAVE') : t('ADD')}
              disabled={loading}
              onClick={onDone}
              size="large"
              borderRadius="100px"
            />
          </div>
        </div>
      </AddCostsWrapper>
    </CSSTransition>
  );
};

const AddCostsWrapper = styled.div`
  width: 456px;
  .cost-grid-sec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default AddCosts;
