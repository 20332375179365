import { nanoid } from 'nanoid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useError } from '../../../hooks/useError';
import {
  getEngagementCustomFormsData,
  getFormsPanels,
  updateEngagementCustomFormsData,
} from '../../../store/features/jobSlice';
import { addToast } from '../../../store/features/toastSlice';
import FormPanel from './form-panel';

const CustomWidgetForm = ({ engagement_id, form }) => {
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const updatedDataTimeout = React.useRef(null);

  const { id: form_id, form_version } = form || {};
  const { id: version_id } = form_version || {};

  const [formPanels, setFormPanels] = React.useState([]);
  const [formData, setFormData] = React.useState([]);

  const fetchFormPanels = () => {
    if (version_id) {
      dispatch(getFormsPanels({ version_id }))
        .then(data => {
          setFormPanels(data);
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };

  const fetchFormData = () => {
    if (form_id) {
      dispatch(getEngagementCustomFormsData({ engagement_id: engagement_id, custom_form_id: form_id }))
        .then(data => {
          setFormData(data);
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };

  React.useEffect(() => {
    fetchFormPanels();
  }, [version_id]);

  React.useEffect(() => {
    fetchFormData();
  }, [form_id]);

  const onUpdateFormData = data => {
    dispatch(updateEngagementCustomFormsData({ engagement_id, custom_form_id: form_id, request: data }))
      .then(() => {
        dispatch(addToast({ text: 'Form data updated successfully', id: nanoid() }));
      })
      .catch(error => {
        showErrorToast({ error, default_message: 'Failed to update form data' });
      });
  };

  const onChangeFormData = (panel_widget_component, submitted_data, lexicon) => {
    const { id: component_id } = panel_widget_component || {};
    let changedData = [...formData];
    const existingData = changedData.find(({ panel_widget_component }) => panel_widget_component?.id === component_id);
    if (existingData) {
      changedData = changedData.map(data => {
        if (data.panel_widget_component?.id === component_id) {
          return { ...data, submitted_data, lexicon };
        }
        return data;
      });
    } else {
      changedData = [...changedData, { panel_widget_component, submitted_data, lexicon }];
    }

    setFormData(changedData);
    if (updatedDataTimeout.current) {
      clearTimeout(updatedDataTimeout.current);
    }
    updatedDataTimeout.current = setTimeout(() => {
      onUpdateFormData(changedData);
    }, 1000);
  };

  if (formPanels.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-column col-gap-2">
      {formPanels.map((panel, index) => (
        <FormPanel key={panel.id} panel={panel} index={index} onChangeFormData={onChangeFormData} formData={formData} />
      ))}
    </div>
  );
};

export default CustomWidgetForm;
