import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BreadcrumbWrapper from '../../../components/common/breadcrumb-wrapper';
import PageHeader from '../../../components/page-header';
import ProductList from '../../../components/product-list';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import { useError } from '../../../hooks/useError';
import { getProductList } from '../../../store/features/quotesSlice';

const productDrawStatusFilter = [
  { id: 'ALL', value: 'ALL', label: 'All', selected: true },
  { id: 'ACTIVE', value: 'ACTIVE', label: 'Active' },
  { id: 'INACTIVE', value: 'INACTIVE', label: 'Inactive' },
];

const initialState = {
  status: productDrawStatusFilter.at(0),
  soldAs: {
    single: false,
    bundle: false,
  },
};

function Products() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageRef = useRef(0);

  const { showErrorToast } = useError();

  const { t } = useTranslation();
  const { hasPermission, setModal, setSideModal } = useContext(OrganisationContext);

  const [search, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [products, setProducts] = useState({});
  const [productFilters, setProductFilters] = useState(initialState);

  const debouncedSearch = useDebounce(search, 500);

  const { content: productList, last } = products || { content: [], last: true };

  const fetchProductList = (page, debouncedSearch, showLoading = true, merge = false, filters) => {
    const { status, soldAs, family } = filters;
    const filterParams = {
      lifecycle: status.value === 'ALL' ? 'ACTIVE,INACTIVE' : status.value,
      sold_as: [soldAs.single ? 'SINGLE' : '', soldAs.bundle ? 'BUNDLE' : ''].filter(Boolean).join(','),
      family_id: family ? [`${family.id}`] : undefined,
    };

    setShowLoading(showLoading);
    dispatch(
      getProductList({
        params: {
          page: page,
          size: 10,
          search: debouncedSearch,
          include_pricing: true,
          ...filterParams,
        },
      }),
    )
      .then(data => {
        const { content, ...rest } = data;
        if (merge) {
          setProducts(prev => ({ ...rest, content: [...(prev.content || []), ...content] }));
        } else {
          setProducts(data);
        }
        pageRef.current = page;
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PRODUCT_LIST') });
        setProducts({});
      })
      .finally(() => setShowLoading(false));
  };

  const fetchMoreData = () => {
    fetchProductList(pageRef.current + 1, debouncedSearch, false, true, productFilters);
  };

  useEffect(() => {
    fetchProductList(0, debouncedSearch, true, false, productFilters);
  }, [debouncedSearch]);

  const onDeleteSuccess = product => {
    const updatedProductList = productList.filter(item => item.id !== product.id);
    setProducts({ ...products, content: updatedProductList });
  };

  const onDeleteProduct = (product, is_product_owner = false) => {
    const modalTitle = is_product_owner
      ? t('ARE_YOU_SURE_WANT_TO_DELETE_THIS_PRODUCT')
      : t('ARE_YOU_SURE_WANT_TO_REMOVE_THIS_PRODUCT');

    setModal({
      type: 'delete-product',
      content: {
        product: product,
        is_product_owner: is_product_owner,
        top: false,
        onSuccess: product => onDeleteSuccess(product),
        title: modalTitle,
      },
    });
  };

  // const redirectToProductDetails = Products => {
  //   setSideModal({
  //     type: 'edit-Product-details',
  //     content: {
  //       Products: Products,
  //       onDeleteProduct: onDeleteProduct,
  //       onSuccess: () => fetchProductList(pageRef.current + 1, debouncedSearch, false),
  //     },
  //   });
  // };

  const addNewProduct = Products => {
    setModal({
      type: 'add-new-product',
      content: {
        onSuccess: () => fetchProductList(0, debouncedSearch, true, false, productFilters),
      },
    });
  };

  return (
    <ProductWrapper className="flex-column w-full h-full items-start justify-start flex-1">
      <BreadcrumbWrapper
        breadcrumbs={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }) },
          {
            text: t('PLATFORM_CONFIGURATION'),
            onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }),
          },
          { text: t('PRODUCT'), onClick: () => {} },
        ]}
      />
      <PageHeader
        showSearchOnLeft={true}
        showAddNewBtn={hasPermission('PRODUCTS_MANAGE')}
        onAddClick={addNewProduct}
        showSearch
        showFilter={true}
        showSort={true}
        searchText={search}
        addButtonLabel={t('ADD_PRODUCT')}
        onSearchChange={setSearchText}
        productFilters={productFilters}
        setProductFilters={setProductFilters}
        productDrawStatusFilter={productDrawStatusFilter}
        setFamily={family => setProductFilters({ ...productFilters, family })}
        family={productFilters.family}
        onApplyFilters={() => fetchProductList(0, debouncedSearch, true, false, productFilters)}
        onClearFilters={() => {
          setProductFilters(initialState);
          fetchProductList(0, debouncedSearch, true, false, initialState);
        }}
      />
      <ProductList
        debouncedSearch={debouncedSearch}
        onDeleteProduct={onDeleteProduct}
        // redirectToProductDetails={redirectToProductDetails}
        fetchMoreData={fetchMoreData}
        showLoading={showLoading}
        productList={productList || []}
        hasMore={!last}
      />
    </ProductWrapper>
  );
}

const ProductWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: 40px minmax(150px, 2fr) minmax(200px, 3fr) minmax(100px, 1fr) 80px 80px 30px;
    column-gap: 48px;
    padding: 16px 24px;
  }
`;

export default Products;
