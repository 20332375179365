import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useError } from '../../hooks/useError';
import { getAutomationsList, setAutomationsListData } from '../../store/features/automationsSlice';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import AutomationsListItem from './automations-list-item';

const automationTableHeaders = [
  { name: 'AUTOMATION', key: 'AUTOMATION' },
  { name: 'DESCRIPTION', key: 'DESCRIPTION' },
  { name: 'ENABLED', key: 'ENABLED' },
  { name: 'OWNER', key: 'OWNER' },
  { name: '', key: 'MENU' },
];

const AutomationList = ({ debouncedSearch = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(0);

  const { showErrorToast } = useError();

  const { automationsListData } = useSelector(state => state.automations);
  const { last, content: automationList } = automationsListData || {};

  const [loading, setLoading] = useState(false);

  const fetchAutomationsList = (page, debouncedSearch, showLoading = true, merge = false) => {
    setLoading(showLoading);
    dispatch(
      getAutomationsList({
        storeData: true,
        merge,
        params: {
          page: page,
          size: 20,
          search: debouncedSearch,
        },
      }),
    )
      .then(() => {
        pageRef.current = page;
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_AUTOMATION') }))
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchMoreData = () => {
    fetchAutomationsList(pageRef.current + 1, debouncedSearch, false, true);
  };

  useEffect(() => {
    fetchAutomationsList(0, debouncedSearch, true);
    return () => {
      dispatch(setAutomationsListData({}));
    };
  }, [debouncedSearch]);

  return (
    <AutomationListWrapper className="flex-column flex-1  radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={loading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {(automationList || []).length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-x-scroll">
            <div className="flex-column w-fit-content min-w-full h-auto">
              <TableHeader headers={automationTableHeaders} />
              <InfiniteScrollV2 hasMore={!last} fetchMoreData={fetchMoreData}>
                {(automationList || []).map((automation, index) => (
                  <AutomationsListItem
                    isFirst={index === 0}
                    automation={automation}
                    key={automation.id}
                    menuPortal={automationList?.length < 3}
                  />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <NoData className="flex-1" title={t('NO_RESULTS_FOUND')} description={t('NO_RESULTS_DESCRIPTION')} />
        ) : (
          <NoData className="flex-1" title={t('NO_AUTOMATIONS')} description={t('NO_AUTOMATION_HERE')} />
        )}
      </SkeletonTransition>
    </AutomationListWrapper>
  );
};

export const AutomationListWrapper = styled.div`
  border-radius: 6px;

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(200px, 2fr) 80px 100px 40px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 500px;
  }
`;

export default AutomationList;
