import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BreadcrumbWrapper from '../../../components/common/breadcrumb-wrapper';
import JobTypeList from '../../../components/job-type-list';
import PageHeader from '../../../components/page-header';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import { useError } from '../../../hooks/useError';
import { getJobTypeList } from '../../../store/features/jobSlice';

const jobTypeDrawStatusFilter = [
  { id: 'ALL', value: 'ALL', label: 'All', selected: true },
  { id: 'ACTIVE', value: 'ACTIVE', label: 'Active' },
  { id: 'INACTIVE', value: 'INACTIVE', label: 'Inactive' },
];

const initialState = {
  status: jobTypeDrawStatusFilter.at(0),
  soldAs: {
    single: false,
    bundle: false,
  },
};

function JobTypes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageRef = useRef(0);

  const { showErrorToast } = useError();

  const { t } = useTranslation();
  const { hasPermission, setModal, setSideModal } = useContext(OrganisationContext);

  const [search, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [jobTypes, setJobTypes] = useState([]);
  const [jobTypeFilters, setJobTypeFilters] = useState(initialState);
  const debouncedSearch = useDebounce(search, 500);

  const fetchJobTypeList = (page, debouncedSearch, showLoading = true, merge = false) => {
    setShowLoading(showLoading);
    dispatch(
      getJobTypeList({
        params: {
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setJobTypes(data);
        pageRef.current = page;
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_JOB_TYPE_LIST') });
        setJobTypes([]);
      })
      .finally(() => setShowLoading(false));
  };

  const fetchMoreData = () => {
    fetchJobTypeList(pageRef.current + 1, debouncedSearch, false, true);
  };

  useEffect(() => {
    fetchJobTypeList(0, debouncedSearch, true, false);
  }, [debouncedSearch]);

  const onDeleteSuccess = jobType => {
    const updatedJobTypeList = jobTypes.filter(item => item?.id !== jobType?.id);
    setJobTypes(updatedJobTypeList);
  };

  const onDeleteJobType = (jobType, is_job_type_owner = false) => {
    const modalTitle = is_job_type_owner
      ? t('ARE_YOU_SURE_WANT_TO_DELETE_THIS_JOB_TYPE')
      : t('ARE_YOU_SURE_WANT_TO_REMOVE_THIS_JOB_TYPE');

    setModal({
      type: 'delete-job-type',
      content: {
        jobType: jobType,
        is_job_type_owner: is_job_type_owner,
        top: false,
        onSuccess: jobType => onDeleteSuccess(jobType),
        title: modalTitle,
      },
    });
  };

  const addNewJobType = () => {
    setModal({
      type: 'add-new-job-type',
      content: {
        onSuccess: () => fetchJobTypeList(0, debouncedSearch, true, false),
      },
    });
  };

  return (
    <JobTypesWrapper className="flex-column w-full h-full items-start justify-start flex-1">
      <BreadcrumbWrapper
        breadcrumbs={[
          { text: t('SETTINGS'), onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }) },
          {
            text: t('PLATFORM_CONFIGURATION'),
            onClick: () => navigate('/settings', { state: 'PLATFORM_CONFIGURATION' }),
          },
          { text: t('JOB_TYPE'), onClick: () => {} },
        ]}
      />
      <PageHeader
        showSearchOnLeft={true}
        showAddNewBtn={hasPermission('JOB_TYPES_MANAGE')}
        onAddClick={addNewJobType}
        showSearch
        showFilter={true}
        showSort={true}
        searchText={search}
        addButtonLabel={t('ADD_JOB_TYPE')}
        onSearchChange={setSearchText}
        jobTypeFilters={jobTypeFilters}
        setJobTypeFilters={setJobTypeFilters}
        jobTypeDrawStatusFilter={jobTypeDrawStatusFilter}
        setFamily={family => setJobTypeFilters({ ...jobTypeFilters, family })}
        family={jobTypeFilters.family}
        onApplyFilters={() => fetchJobTypeList(0, debouncedSearch, true, false)}
        onClearFilters={() => {
          setJobTypeFilters(initialState);
          fetchJobTypeList(0, debouncedSearch, true, false);
        }}
      />
      <JobTypeList
        debouncedSearch={debouncedSearch}
        onDeleteJobType={onDeleteJobType}
        fetchMoreData={fetchMoreData}
        showLoading={showLoading}
        jobTypes={jobTypes || []}
        hasMore={false}
      />
    </JobTypesWrapper>
  );
}

const JobTypesWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(200px, 3fr) 30px;
    column-gap: 48px;
    padding: 16px 24px;
  }
`;

export default JobTypes;
