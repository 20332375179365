import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ElectricityCordIcon } from '../../assets/icons/electricity-cord.svg';
import { ReactComponent as SizeIcon } from '../../assets/icons/property/size.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as SolarPanelIcon } from '../../assets/icons/solar-panel.svg';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { ReactComponent as BatteryIcon } from '../../assets/images/batteries.svg';
import { getFormattedNumberStyle } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getSolarOerviewData } from '../../store/features/quotesSlice';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';

const DesignDetails = ({ solarDesign, index }) => {
  const navigate = useNavigate();
  const { design_data, id } = solarDesign || {};
  const { panels, battery_capacity, system_size, annual_production } = design_data || {};

  const onView = () => {
    navigate(`roof-details?design_id=${id}`);
  };

  const onSystemOverview = () => {
    navigate(`roof-details/system-overview/${id}`);
  };

  return (
    <div className="flex-column mx-6 pxy-4 border radius-2">
      <label className="inter-600-text natural-900-text flex-1">Design {index + 1}</label>
      <div className="flex flex-1 items-center justify-between col-gap-2">
        <div className="flex items-center col-gap-3">
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={SolarPanelIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text one-line">{panels || 0} panels</label>
          </div>
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={BatteryIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text  one-line">
              {parseFloat(battery_capacity || 0).toFixed(2)} kWh
            </label>
          </div>
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={SizeIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={12}
              iconHeight={12}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text  one-line">
              {parseFloat(system_size || 0).toFixed(2)} kW
            </label>
          </div>
          <div className="flex items-center col-gap-1">
            <IconContainer
              Icon={ElectricityCordIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
              iconColor="natural_400"
            />
            <label className="inter-500-text natural-400-text  one-line">
              {getFormattedNumberStyle(annual_production || 0, 'decimal', 0, 0)} kWh
            </label>
          </div>
        </div>
        <div className="flex items-center col-gap-2">
          <Button
            size="medium"
            width="fit-content"
            className="secondary px-3 specified-width"
            label="View"
            onClick={onView}
          />
          <Button
            size="medium"
            label="System overview"
            width="fit-content"
            onClick={onSystemOverview}
            className="secondary px-3 specified-width"
            afterIcon={<RightArrowIcon width={14} height={14} className="primary-500-text" />}
          />
        </div>
      </div>
    </div>
  );
};

const PropertySystemDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { jobDetails } = useSelector(state => state.property);
  const { job_type, engagement } = jobDetails || {};
  const engagement_id = engagement?.id;
  const jobCategory = job_type?.quote_category;

  const isValidCategory = useMemo(
    () => jobCategory === 'SOLAR' || jobCategory === 'SOLAR_BATTERY' || jobCategory === 'BATTERY',
    [jobCategory],
  );

  const [solarDesigns, setSolarDesigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [designSlideOpen, setDesignSlideOpen] = useState(true);

  const fetchSolarDesignData = () => {
    setLoading(true);
    dispatch(
      getSolarOerviewData({
        engagement_id: engagement_id,
      }),
    )
      .then(data => {
        setSolarDesigns(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_SOLAR_DESIGNS') }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (engagement_id && isValidCategory) {
      fetchSolarDesignData();
    }
  }, [engagement_id, isValidCategory]);

  return isValidCategory ? (
    <Fragment>
      <div className="flex-column border radius-2 mt-4">
        <div
          className="cursor px-6 py-4 flex items-center justify-between"
          onClick={() => setDesignSlideOpen(!designSlideOpen)}>
          <label className="inter-600-text natural-900-text flex-1 font-16">Designs</label>
          <Arrow className={classNames('transition', designSlideOpen ? 'rotate-90' : 'rotate-0')} />
        </div>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: designSlideOpen ? 'auto' : 0, transition: { duration: 0.5 } }}
          className={classNames('overflow-y-hidden flex-column row-gap-3', designSlideOpen && 'mb-5')}>
          {loading ? (
            <Skeleton height={'200px'} containerClassName="w-full px-6" />
          ) : (
            <Fragment>
              {solarDesigns.length > 0 ? (
                <Fragment>
                  {solarDesigns.map((solarDesign, index) => (
                    <DesignDetails key={solarDesign.id} solarDesign={solarDesign} index={index} />
                  ))}
                </Fragment>
              ) : (
                <div
                  style={{ height: '100px' }}
                  className="bg-natural-50 flex items-center justify-center mx-6 py-5 radius-3">
                  <label className="natural-400-text inter-500-text">No design available</label>
                </div>
              )}
            </Fragment>
          )}
        </motion.div>
      </div>
    </Fragment>
  ) : null;
};

export default PropertySystemDetails;
