import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as Chart } from '../../assets/icons/property/chart.svg';
import { ReactComponent as Square } from '../../assets/icons/property/square.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/menu/arrow-right-next.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, capitalizeFirstLetterOfWords, formatText } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getTaskSubTasks, updateTask, updateTaskProgress } from '../../store/features/taskSlice';
import { addToast } from '../../store/features/toastSlice';
import { getEngagementAccess } from '../../store/features/workitemSlice';
import { Avatar } from '../common/avatar-group';
import DateSelectorElement from '../common/date-selector/date-selector-element';
import IconContainer from '../common/icon-container';
import InputSearch from '../common/input-search';
import MenuV2 from '../common/menu/menu-v2';
import Status from '../common/status';
import CustomTooltip from '../common/tooltip-new';

export const statusOptions = [
  { label: 'To do', value: 'TODO' },
  { label: 'In-progress', value: 'STARTED' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Done', value: 'COMPLETE' },
];

const priorityOptions = [
  { label: 'Highest', value: 'VERY_HIGH' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
  { label: 'Lowest', value: 'VERY_LOW' },
];

const TaskListItem = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showErrorToast } = useError();

  const { setSideModal, setModal } = useContext(OrganisationContext);

  const { task, arrows = false, isPropertyTaskList, fetchTasks } = props;
  const {
    id: taskId,
    engagement,
    engagement: { workitems, property },
  } = task || {};

  const [showAccordion, setShowAccordion] = useState(null);
  const [subTasks, setSubTasks] = useState(false);

  const isAccordionOpened = task => {
    return task?.id === showAccordion;
  };

  const handleShowTaskDetails = (data, isSubTask = false, isJobDetailsTask = false, parent_task_id = '') => {
    setSideModal({
      type: 'view-task-details',
      content: {
        taskDetails: data,
        isSubTask: isSubTask,
        isSubTask: isSubTask,
        isJobDetailsTask: isJobDetailsTask,
        parent_task_id: parent_task_id,
        onSuccess: () => {
          fetchTasks && fetchTasks();
          fetchSubTasks();
        },
      },
    });
  };

  const fetchSubTasks = () => {
    dispatch(getTaskSubTasks({ task_id: task?.id }))
      .then(data => {
        setSubTasks(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_SUB_TASKS') });
        setSubTasks([]);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (task?.id && task?.subtask_count > 0) {
      fetchSubTasks();
    }
  }, [task?.id]);

  const getStatusStyles = status => {
    switch (status) {
      case 'COMPLETE':
        return {
          color: theme.success_500,
          background: theme.success_50,
        };
      case 'BLOCKED':
        return {
          color: theme.primary_500,
          background: theme.primary_50,
        };
      case 'STARTED':
        return {
          color: theme.purple_500,
          background: theme.purple_50,
        };
      default:
        return {
          color: theme.natural_700,
          background: theme.natural_150,
        };
    }
  };

  const getStageNameOfJob = () => {
    const workitem = workitems?.[0] || {};
    const workitemLength = workitems?.length || 0;
    const stageName = workitem?.stage?.name || null;
    return stageName ? `${workitem?.stage?.name}${workitemLength > 1 ? ` + ${workitemLength}` : ''}` : '-';
  };

  const onJobClick = () => {
    const { workitems } = engagement || {};
    const firstWorkitem = workitems?.[0] || {};
    if (firstWorkitem) {
      navigate(`/properties/property/${property?.id}/workitems/${firstWorkitem.id}`);
    }
  };

  const JobListItem = ({ tooltip, index }) => {
    const isLastItem = index === workitems?.length - 1;
    const { name: engagement_name } = engagement || {};
    const firstWorkitem = workitems?.[0] || {};
    const { days_in_stage, days_in_process } = firstWorkitem || {};

    return (
      <div
        onClick={e => {
          e.stopPropagation();
          onJobClick();
        }}
        className={classNames(
          'flex-column justify-center gap-1 border-bottom-0 jobs-content-tooltip cursor',
          tooltip ? 'py-2 px-2' : 'py-2 px-2 my-2',
          !isLastItem && 'border-bottom',
        )}>
        <label className="inter-500-text natural-700-text line-height-20 font-14 text-ellipsis">
          {engagement_name}
        </label>
        <div className="flex items-center gap-2 justify-between">
          <div className="flex items-center gap-2">
            <div className="flex gap-1">
              <Chart className="orange-500-text mr-1" />
              <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                {days_in_process || '0'}
              </label>
            </div>
            <div className="flex gap-1">
              <div className="flex items-center">
                <Square className="success-500-text" />
                <label className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
                  {days_in_stage || '0'}
                </label>
              </div>
            </div>
          </div>

          {getStageNameOfJob() && (
            <div className="flex gap-1 cursor text-ellipsis">
              <label className="color-purple bg-pink px-3 py-0_5 radius-6 inter-500-text font-12 line-height-20 text-ellipsis">
                {getStageNameOfJob()}
              </label>
            </div>
          )}
        </div>
      </div>
    );
  };

  const TaskContent = ({ taskData, is_sub_task = false }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [priorityTooltipOpen, setPriorityTooltipOpen] = useState(false);
    const [owners, setOwners] = useState(null);
    const [searchOwners, setSearchOwners] = useState('');
    const [assigneeTooltipOpen, setAssigneeTooltipOpen] = useState(false);
    const [jobTooltipOpened, setJobTooltipOpened] = useState(false);
    const [loadingOwners, setLoadingOwners] = useState(false);
    const {
      progress: { status },
      engagement: { id: engagement_id, workitems },
      due_date,
      priority,
      id: task_id,
      assignee,
      name: task_name,
      subtask_count,
    } = taskData || {};
    const selectedStatus = statusOptions.find(item => item?.value === status);
    const selectedPriority = priorityOptions.find(item => item?.value === priority);

    const StatusLabel = ({ data, isMainContent = false }) => {
      const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

      if (isMainContent) {
        return (
          <label
            style={{
              background,
              color,
              borderRadius: '50px',
              width: 'fit-content',
              height: '24px',
            }}
            onClick={e => {
              e.stopPropagation();
              setTooltipOpen(true);
            }}
            className="select-label flex items-center justify-between font-12 px-3 text-center gap-1">
            <label style={{ color }} className="font-12 inter-500-text fw-500">
              {data?.label}
            </label>
            <IconContainer
              Icon={ArrowDownIcon}
              iconColor={color}
              backgroundColor={background}
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
            />
          </label>
        );
      } else {
        return (
          <label
            style={{
              background,
              color,
              borderRadius: '50px',
              height: '24px',
            }}
            className="flex items-center justify-center font-12 px-3 text-center w-full gap-1 font-12 fw-500">
            {data?.label}
          </label>
        );
      }
    };

    const fetchEngagementAccess = () => {
      setLoadingOwners(true);
      dispatch(getEngagementAccess({ id: engagement_id, params: { search: searchOwners } }))
        .then(data => {
          setOwners(data);
        })
        .catch(error => {
          showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASK_DETAILS') });
          setOwners([]);
        })
        .finally(() => {
          setLoadingOwners(false);
        });
    };

    useEffect(() => {
      if (assigneeTooltipOpen) {
        fetchEngagementAccess();
      }
    }, [assigneeTooltipOpen, searchOwners]);

    const updateTaskData = (key, value) => {
      const newSelectedTask = {
        ...taskData,
        [key]: value,
      };
      onUpdate(key, newSelectedTask);
    };

    const onUpdate = async (label, taskDetails) => {
      const { assignee, due_date, priority, service, evidence, estimated } = taskDetails || {};
      const { id: assignee_id } = assignee || {};
      const { id: service_id } = service || {};

      if (
        assignee_id === taskData?.assignee?.id &&
        due_date === taskData?.due_date &&
        priority === taskData?.priority
      ) {
        return;
      } else {
        try {
          const request = {
            ...taskDetails,
            assignee: assignee_id ? { id: assignee_id } : null,
            due_date: due_date,
            priority: priority,
            service: { id: service_id ? service_id : '' },
            evidence: evidence ? evidence : [],
            estimated: estimated?.duration ? estimated : null,
            ...(is_sub_task && {
              parent: {
                id: taskId,
              },
            }),
          };
          const updateValue = await dispatch(updateTask({ id: taskId, request }));
          const formatted_label = capitalize(formatText(label, ' ')) || t('TASK');
          dispatch(addToast({ error: false, text: `${formatted_label} updated successfully` }));

          if (task) {
            fetchTasks();
          }
          if (is_sub_task) {
            fetchSubTasks();
          }
        } catch (error) {
          dispatch(
            addToast({ error: true, text: error?.response?.data?.error_description || t('ERROR_WHILE_UPDATE_TASK') }),
          );
        }
      }
    };

    const onStatusUpdate = async updatedStatus => {
      if (updatedStatus === status) {
        return;
      } else {
        try {
          const request = {
            progress: {
              start_date: taskData?.progress?.start_date || null,
              completed_date: taskData?.progress?.completed_date || null,
              status: updatedStatus || null,
              assignee: taskData?.assignee?.id ? { id: taskData?.assignee?.id } : null,
              priority: taskData?.priority || null,
              ...(is_sub_task && {
                parent: {
                  id: taskId,
                },
              }),
            },
          };
          const updateValue = await dispatch(updateTaskProgress({ id: task_id, request }));
          dispatch(addToast({ error: false, text: t('STATUS_UPDATED_SUCCESSFULLY') }));

          if (task) {
            fetchTasks();
          }
          if (is_sub_task) {
            fetchSubTasks();
          }
        } catch (error) {
          dispatch(
            addToast({ error: true, text: error?.response?.data?.error_description || t('ERROR_WHILE_UPDATE_TASK') }),
          );
        }
      }
    };

    const dateFormat = data => {
      const currentDate = moment();
      const convertedDate = moment.unix(data);
      const isSameDay = currentDate.isSame(convertedDate, 'day');
      const isPastDate = currentDate.isAfter(convertedDate);
      const [isDateEdit, setIsDateEdit] = useState(false);
      const ref = useOnclickOutside(() => {
        setIsDateEdit(false);
      });
      return (
        <div
          ref={ref}
          className="flex-column justify-center row-gap-2 overflow-hidden w-fit-content"
          onClick={e => {
            e.stopPropagation();
            setIsDateEdit(true);
          }}>
          {!isDateEdit ? (
            <label
              className={classNames(
                'inter-400-text font-14 natural-900-text px-2 py-1 task-items',
                isSameDay && 'color-blue',
                !isSameDay && isPastDate && data && 'color-red',
              )}>
              {due_date ? (!isSameDay ? moment.unix(due_date).format('DD MMM') : 'Today') : '-'}
            </label>
          ) : (
            <DateSelectorElement
              selectedDate={due_date}
              isCalendarIcon={false}
              isOpen={isDateEdit}
              useParentFocus={true}
              setSelectedDate={date => updateTaskData('due_date', date)}
              className="task-date-field"
              onCalendarClose={() => setIsDateEdit(false)}
              wrapperClassName={'task-date-selector'}
              format="DD MMM"
              minStartDate={moment().subtract(1, 'day').startOf('day').unix()}
            />
          )}
        </div>
      );
    };

    const onDelete = () => {
      setModal({
        type: 'delete-task',
        content: {
          task: taskData,
          title: t('DELETE_TASK'),
          descriptions: t('ARE_YOU_SURE_TO_DELETE_THIS_TASK'),
          status: <StatusLabel data={selectedStatus} />,
          priority: (
            <Status
              withDot={false}
              withDottedBorder={false}
              status={priority}
              icon={true}
              statusTextClassName="font-10 line-height-20 inter-600-text"
              statusText={capitalizeFirstLetterOfWords(formatText(selectedPriority?.label))}
            />
          ),
          onSuccess: () => {
            fetchTasks && fetchTasks();
          },
        },
      });
    };

    return (
      <>
        <div className="flex items-center overflow-hidden gap-4 py-3">
          <div className="flex flex-column items-start gap-1 overflow-hidden">
            <label className="inter-500-text font-14 natural-900-text one-line w-full">{task_name}</label>
          </div>
        </div>
        <div className="flex-column justify-center row-gap-2 overflow-hidden py-3">
          <p className="inter-400-text font-14 natural-400-text">{subtask_count}</p>
        </div>
        <div className="flex-column justify-center row-gap-2 overflow-hidden py-3">{dateFormat(due_date || '')}</div>
        <CustomTooltip
          wrapperClassName="status-tooltip"
          tooltipClassname="custom-scrollbar thin-scrollbar"
          id={`status-tooltip-${task_id}`}
          place="bottom"
          clickable
          isOpen={tooltipOpen}
          onBlurTooltip={() => setTooltipOpen(false)}
          hidden={!tooltipOpen}
          noArrow={true}
          content={statusOptions
            ?.filter(status => status?.label !== selectedStatus?.label)
            ?.map((data, index) => (
              <div
                key={data?.label}
                onClick={e => {
                  e.stopPropagation();
                  onStatusUpdate(data?.value);
                  setTooltipOpen(false);
                }}
                className="px-4 py-2 font-14 border-bottom cursor proposal-config">
                <StatusLabel data={data} />
              </div>
            ))}>
          <StatusLabel data={selectedStatus} isMainContent={true} />
        </CustomTooltip>

        <CustomTooltip
          wrapperClassName="status-tooltip"
          tooltipClassname="custom-scrollbar thin-scrollbar"
          id={`priority-tooltip-${task_id}`}
          place="bottom"
          clickable
          isOpen={priorityTooltipOpen}
          onBlurTooltip={() => setPriorityTooltipOpen(false)}
          hidden={!priorityTooltipOpen}
          noArrow={true}
          content={priorityOptions
            ?.filter(priority => priority?.label !== selectedPriority?.label)
            ?.map((data, index) => (
              <div
                key={data?.label}
                onClick={e => {
                  e.stopPropagation();
                  updateTaskData('priority', data?.value);
                  setPriorityTooltipOpen(false);
                }}
                className="px-4 py-2 font-14 border-bottom cursor proposal-config">
                <div className="flex items-center">
                  <Status
                    withDot={false}
                    withDottedBorder={false}
                    status={data?.value}
                    icon={true}
                    statusTextClassName="font-10"
                    statusText={capitalizeFirstLetterOfWords(formatText(data?.label))}
                  />
                </div>
              </div>
            ))}>
          <div
            className="flex items-center"
            onClick={e => {
              e.stopPropagation();
              setPriorityTooltipOpen(true);
            }}>
            <Status
              withDot={false}
              withDottedBorder={false}
              status={priority}
              icon={true}
              statusTextClassName="font-10 line-height-20 inter-600-text"
              statusText={capitalizeFirstLetterOfWords(formatText(selectedPriority?.label))}
            />
          </div>
        </CustomTooltip>

        <CustomTooltip
          wrapperClassName="status-tooltip flex w-fit-content"
          tooltipClassname="custom-scrollbar thin-scrollbar"
          id={`assignee-tooltip-${task_id}`}
          place="bottom"
          clickable
          isOpen={assigneeTooltipOpen}
          onBlurTooltip={() => setAssigneeTooltipOpen(false)}
          hidden={!assigneeTooltipOpen}
          noArrow={true}
          content={
            <div
              className="font-14 border-bottom cursor proposal-config overflow-y-hidden"
              onClick={e => e.stopPropagation()}>
              <div className="pxy-3 border-bottom">
                <InputSearch placeholder={'Search'} value={searchOwners} onChange={setSearchOwners} />
              </div>
              {loadingOwners ? (
                <Skeleton height={'200px'} containerClassName="line-height-1 h-full w-full" />
              ) : owners?.length ? (
                <div className="overflow-auto" style={{ height: 200 }}>
                  {owners?.map((owner, index) => (
                    <div
                      key={owner?.id}
                      className="flex items-center gap-2 border-bottom py-3 px-4"
                      onClick={() => {
                        updateTaskData('assignee', owner);
                        setAssigneeTooltipOpen(false);
                      }}>
                      <div className="flex items-center col-gap-1 one-line">
                        <Avatar
                          backgroundColorIndex={index}
                          avatar={owner.id ? owner : { name: '' }}
                          variant="semi_large"
                          avatarWrapperClassname="assign-user"
                        />
                      </div>
                      <label className="inter-400-text font-14 natural-900-text">{owner?.name}</label>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="pxy-4 text-center inter-400-text font-14 natural-900-text">{t('NO_OWNERS')}</p>
              )}
            </div>
          }>
          <div
            className="flex w-fit-content"
            onClick={e => {
              e.stopPropagation();
              setAssigneeTooltipOpen(true);
            }}>
            <Avatar
              backgroundColor={theme.primary_500}
              avatar={assignee?.id ? assignee : { name: '' }}
              variant="semi_large"
              avatarWrapperClassname="assign-user"
            />
          </div>
        </CustomTooltip>
        {!isPropertyTaskList &&
          (workitems?.length > 1 ? (
            <div className="flex-column row-gap-2 justify-center overflow-hidden py-4">
              <CustomTooltip
                wrapperClassName="job-tooltip"
                tooltipClassname="custom-scrollbar thin-scrollbar"
                id={`${task_id}-workflow`}
                place="bottom"
                clickable
                isOpen={jobTooltipOpened}
                hidden={!jobTooltipOpened}
                onBlurTooltip={() => setJobTooltipOpened(false)}
                noArrow={true}
                content={workitems?.map((data, index) => (
                  <JobListItem key={data?.id} tooltip={true} index={index} />
                ))}>
                <Status
                  onClick={e => {
                    e.stopPropagation();
                    setJobTooltipOpened(!jobTooltipOpened);
                  }}
                  withDot={false}
                  withDottedBorder={false}
                  status={'DRAFT'}
                  statusText={capitalizeFirstLetterOfWords(formatText(`${workitems?.length} ${t('JOBS')}`))}
                />
              </CustomTooltip>
            </div>
          ) : workitems?.length == 1 ? (
            workitems?.map((jobitem, index) => <JobListItem key={jobitem?.id} index={index} />)
          ) : (
            <div className="flex items-center col-gap-1">
              <label className="inter-400-text natural-400-text font-14 line-height-20">{t('NO_JOB')}</label>
            </div>
          ))}

        <MenuV2
          menuList={[{ name: t('DELETE'), onClick: () => onDelete() }]}
          menuClassName="h-fit-content property-menu"
          iconClassname="my-4"
        />
      </>
    );
  };

  return (
    <TaskListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={taskId}
      className="relative w-full">
      {task?.subtask_count && arrows ? (
        isAccordionOpened(task) ? (
          <ArrowDownIcon
            width={20}
            height={20}
            className={classNames('absolute arrow-icon cursor', isPropertyTaskList && 'arrow-icon-small')}
            onClick={e => {
              task?.subtask_count && setShowAccordion('');
              e.stopPropagation();
            }}
          />
        ) : (
          <ArrowLeftIcon
            width={20}
            height={20}
            className={classNames('grey-text-04 absolute arrow-icon cursor', isPropertyTaskList && 'arrow-icon-small')}
            onClick={e => {
              task?.subtask_count && setShowAccordion(task?.id);
              e.stopPropagation();
            }}
          />
        )
      ) : (
        <Fragment />
      )}
      <div
        className={`data-container cursor items-center sidebar-close-ignore ${
          task?.subtask_count !== 0 && isAccordionOpened(task) ? 'border-0' : 'border-bottom'
        }`}
        onClick={() => handleShowTaskDetails(task, false, isPropertyTaskList)}>
        <TaskContent taskData={task} />
      </div>

      {task?.subtask_count !== 0 &&
        isAccordionOpened(task) &&
        subTasks &&
        (subTasks || []).map((taskData, index) => {
          const lastIndex = subTasks?.length - 1;
          return (
            <div
              key={taskData?.id}
              className={classNames('tasks-list flex-column gap-3 ', isAccordionOpened(task) && 'opened-subtask')}>
              <div className="">
                <div
                  className={classNames(
                    'data-container cursor items-center sidebar-close-ignore',
                    lastIndex === index && 'border-bottom',
                  )}
                  onClick={() => handleShowTaskDetails(taskData, true, isPropertyTaskList, taskId)}>
                  <TaskContent taskData={taskData} is_sub_task={true} />
                </div>
              </div>
            </div>
          );
        })}
    </TaskListItemWrapper>
  );
};

const TaskListItemWrapper = styled(motion.div)`
  .arrow-icon {
    left: 6px;
    top: 30px;
  }
  .arrow-icon-small {
    top: 24px;
  }

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
  .status-tooltip {
    position: relative;
    .custom-tooltip {
      position: fixed !important;
      padding: 0;
      height: auto;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      border-radius: 6px;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }

    .proposal-config {
      &:hover {
        background-color: ${({ theme }) => theme.natural_100};
      }
    }
    .assign-user {
      border: none;
      transition: all 0.1s;
      &:hover {
        box-shadow: #d3eafe 0px 1px 4px, #d3eafe 0px 0px 0px 3px;
      }
    }
  }
  .jobs-content-tooltip {
    &:hover {
      background-color: ${({ theme }) => theme.primary_50};
    }
  }
  .job-tooltip {
    position: relative;
    .custom-tooltip {
      position: fixed !important;
      padding: 0;
      width: 276px;
      height: auto;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }
  }
  .task-items {
    border-radius: 5px;
    &:hover {
      background-color: ${({ theme }) => theme.natural_200};
    }
  }
  .task-date-field.date-value {
    border: none;
    min-height: auto;
    border-radius: 0%;
    box-shadow: none;
    padding: 0 8px;
    min-height: 24px;
    border-radius: 6px;
  }
  .task-date-selector {
    position: fixed;
    z-index: 10;
  }
`;

export default TaskListItem;
