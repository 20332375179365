import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { statusOptions } from '../../helpers/optionData';
import useDebounce from '../../helpers/useDebounceHook';
import { useError } from '../../hooks/useError';
import { getPropertyTaskList } from '../../store/features/taskSlice';
import Button from '../common/button/button';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import InputSearch from '../common/input-search';
import NoData from '../common/no-data';
import Popover from '../common/popover';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import TasksFilter from '../task-list/filter';
import AppliedFilters from '../task-list/filter/applied-filters';
import TaskListItem from '../task-list/task-list-item';

const taskTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'SUBTASKS', key: 'SUBTASKS' },
  { name: 'DUE DATE', key: 'DUE_DATE' },
  // { name: 'TYPE', key: 'TYPE' },
  { name: 'STATUS', key: 'STATUS' },
  { name: 'PRIORITY', key: 'PRIORITY' },
  { name: 'ASSIGNEE', key: 'ASSIGNEE' },
  { name: '', key: 'MENU' },
];

const defaultStatuses = ['BLOCKED', 'TODO', 'STARTED'];

const defaultFilters = {
  due_date: null,
  priority: null,
  status: statusOptions.filter(item => defaultStatuses.includes(item.id)),
};

const BoardFilterWrapper = ({
  children,
  taskFilters,
  setTaskFilters,
  showFilterPopover,
  setShowFilterPopover,
  engagement_id = '',
}) => {
  const ref = useOnclickOutside(() => setShowFilterPopover(false));

  return (
    <Popover
      isOpen={showFilterPopover}
      offset={[0, 2]}
      content={
        <div ref={ref}>
          <TasksFilter
            setShowSortPopover={setShowFilterPopover}
            setBoardsFilters={setTaskFilters}
            boardsFilters={taskFilters}
            onClearFilters={() => setTaskFilters({})}
            engagement_id={engagement_id}
          />
        </div>
      }>
      {children}
    </Popover>
  );
};

const PropertyTask = () => {
  const { t } = useTranslation();
  const pageRef = useRef(null);
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { setSideModal } = useContext(OrganisationContext);

  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;
  const { user } = useSelector(state => state.user);
  const { user: internalUser, selectedAccount } = user || {};
  const { id: user_id, forename, surname, name } = selectedAccount || internalUser || {};
  const user_name = name ? name : forename || surname ? [forename, surname].join(' ') : '';

  const [sortBy, setSortBy] = useState([
    {
      id: 'due_date',
      name: t('DUE_DATE'),
      order_by: 'asc',
      order_by_text: { asc: 'Oldest first', desc: 'Newest first' },
    },
    {
      id: 'priority',
      name: t('PRIORITY'),
      order_by: 'desc',
      order_by_text: { asc: 'Lowest first', desc: 'Highest first' },
    },
  ]);
  const [orderBy, setOrderBy] = useState('desc');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [taskFilters, setTaskFilters] = useState({ ...defaultFilters, assignee: [{ id: user_id, name: user_name }] });

  const [propertyTasks, setPropertyTasks] = useState({ content: [] });
  const { content: tasks, last } = propertyTasks || {};
  const debouncedSearch = useDebounce(search, 500);

  const fetchTaskList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    const { due_date, priority, status, assignee } = taskFilters || {};
    const assignee_ids = (assignee || []).map(item => item?.id).join(',');
    const priority_ids = (priority || []).map(item => item?.id).join(',');
    const status_ids = (status || []).map(item => item?.id).join(',');
    const sort_by = sortBy.map(item => `${item.id},${item.order_by}`);

    dispatch(
      getPropertyTaskList({
        params: {
          page: page,
          size: 20,
          search: debouncedSearch ?? undefined,
          engagement_id: engagement_id,
          status: status_ids || 'BLOCKED,TODO,STARTED',
          assignee_id: assignee_ids || undefined,
          sort_by: sort_by,
          order_by: orderBy,
          'due_date.max': due_date || undefined,
          priority: priority_ids || undefined,
        },
      }),
    )
      .then(data => {
        if (merge) {
          setPropertyTasks({ ...data, content: [...propertyTasks.content, ...data.content] });
        } else {
          setPropertyTasks(data);
        }
        pageRef.current = page;
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASKS') }))
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchTaskList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchTaskList(0, false, debouncedSearch, true);
  }, [debouncedSearch, taskFilters]);

  const refetchData = () => {
    fetchTaskList(0, false, debouncedSearch, false);
  };

  const handleShowAddTask = () => {
    setSideModal({
      type: 'add-new-task',
      taskType: 'job-task',
      content: {
        fromJobTasks: true,
        onSuccess: () => {
          setPropertyTasks({ content: [] });
          refetchData();
        },
      },
    });
  };

  return (
    <motion.div
      key={'property-task'}
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      style={{ height: '100vh', overflow: 'auto' }}
      className="pxy-6 flex-column flex-1">
      <div className="flex justify-between">
        <div className="flex align-center col-gap-4">
          <InputSearch placeholder={t('SEARCH')} onChange={setSearch} />
          <BoardFilterWrapper
            setTaskFilters={setTaskFilters}
            setShowFilterPopover={setShowFilterPopover}
            taskFilters={taskFilters}
            showFilterPopover={showFilterPopover}
            engagement_id={engagement_id}>
            <Button
              fontSize="14px"
              size="average"
              width="90px"
              height="40px"
              borderRadius="100px"
              className="primary-blue specified-width  px-4 py-2_5"
              afterIcon={<FilterIcon className="mr-0 primary-text" height={16} width={16} />}
              label={t('FILTER')}
              onClick={() => setShowFilterPopover(prev => !prev)}
            />
          </BoardFilterWrapper>
        </div>
        <div className="flex items-center col-gap-4">
          <Button
            fontSize="14px"
            size="average"
            width="116px"
            height="40px"
            borderRadius="100px"
            lableSize="w-64 line-height-20"
            className="primary specified-width  px-4 py-2_5 ml-3"
            afterIcon={<AddIcon className="mr-0 white-text" height={16} width={16} />}
            label={t('ADD_NEW')}
            onClick={handleShowAddTask}
          />
        </div>
      </div>
      <AppliedFilters
        sortBy={sortBy}
        orderBy={orderBy}
        boardsFilters={taskFilters}
        setBoardsFilters={setTaskFilters}
        canRemoveSort={false}
        containerClassname="pt-4"
        onClearAllFilters={() => setTaskFilters({})}
      />
      <TaskListWrapper className="flex-column flex-1  radius-1 mt-6 overflow-hidden">
        <SkeletonTransition
          loading={loading}
          loaderClassName="item flex items-center justify-center flex-1 mb-6"
          height={'100%'}
          containerClassName="line-height-1 h-full w-full">
          {(tasks || []).length > 0 ? (
            <div className="w-full card radius-1_5 border overflow-scroll">
              <div className="flex-column w-fit-content min-w-full h-full">
                <TableHeader headers={taskTableHeaders} />
                <InfiniteScrollV2
                  hasMore={!last}
                  fetchMoreData={fetchMoreData}
                  infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                  {(tasks || []).map(task => (
                    <TaskListItem
                      task={task}
                      key={task.id}
                      arrows={true}
                      isPropertyTaskList={true}
                      fetchTasks={refetchData}
                    />
                  ))}
                </InfiniteScrollV2>
              </div>
            </div>
          ) : debouncedSearch ? (
            <div className="flex-column flex-1 mb-6 items-center justify-center">
              <NoData
                title={t('NO_RESULTS_FOUND')}
                description={t('NO_RESULTS_DESCRIPTION')}
                className="search-terms"
                EmptyIcon={NoDocumentIcon}
                iconClassName="mb-6 relative"
              />
            </div>
          ) : (
            <div className="flex-column flex-1 mb-6 items-center justify-center">
              <NoData
                title={t('NO_TASKS')}
                className="search-terms"
                description={t('NO_TASKS_HERE')}
                EmptyIcon={NoDocumentIcon}
                iconClassName="mb-6 relative"
              />
            </div>
          )}
        </SkeletonTransition>
      </TaskListWrapper>
    </motion.div>
  );
};

const TaskListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(200px, 1fr) minmax(80px, 100px) minmax(80px, 100px)
      minmax(100px, 120px) minmax(100px, 120px) minmax(80px, 100px) 32px;
    column-gap: 48px;
    padding: 0 31px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }

  .search-terms {
    max-width: 343px;
  }
`;

export default PropertyTask;
