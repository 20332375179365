import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useTheme } from 'styled-components';
import { getFormattedNumberStyle } from '../../helpers/utils';

const BarGraph = ({
  data,
  dummyData = [],
  useDummy = false,
  barSize = 24,
  dataKey = 'consumption_kwh',
  XAxisDataKey = 'monthName',
  isShowValue = false,
}) => {
  const theme = useTheme();
  const axisColor = useDummy ? theme.natural_400 : theme.natural_800;
  const graphColor = useDummy ? theme.natural_200 : theme.primary_500;
  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return (
      <text
        className="natural-200-text font-12"
        x={x + width / 2}
        y={y}
        fill={theme.natural_500}
        textAnchor="middle"
        dy={-6}>
        {getFormattedNumberStyle(value || 0, 'decimal', 1, 1)}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={useDummy ? dummyData : data} margin={{ left: -30, top: 15, bottom: -15 }}>
        <CartesianGrid vertical={false} strokeDasharray="0" stroke="#ECECEC" />
        <XAxis dataKey={XAxisDataKey} tick={{ fontSize: 10, fill: axisColor }} tickLine={false} axisLine={false} />
        <YAxis
          tickFormatter={value => `${value} kWh`}
          tick={{ fontSize: 10, fill: axisColor }}
          tickLine={false}
          axisLine={false}
          width={100}
          tickMargin={0}
        />
        <Bar
          dataKey={dataKey}
          fill={graphColor}
          barSize={barSize}
          radius={[4, 4, 4, 4]}
          label={isShowValue && !useDummy ? renderCustomBarLabel : null}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraph;
