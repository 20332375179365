import { useGoogleMap } from '@react-google-maps/api';
import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as AddPanelIcon } from '../../assets/icons/add-panel.svg';
import { ReactComponent as AIIcon } from '../../assets/icons/ai-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as GridIcon } from '../../assets/icons/grid.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/close-image.svg';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import { OrganisationContext } from '../../context/organisationContext';
import { getFormattedNumberStyle, getRoofDetailsFromManualOrImported } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import PolygonWrapper from '../../pages/solar-api/polygon-wrapper';
import { getRoofPanelInformation } from '../../store/features/propertySlice';

const SegmentPolygon = ({
  segmentPolygonRef,
  segmentId,
  boundary,
  isSelected,
  currentlyGraggingSegment = false,
  onSegmentBoundryUpdate,
  segmentColor,
  index = 0,
}) => {
  const map = useGoogleMap();
  const polygonId = `segment-boundary-${segmentId}`;
  const paths = boundary?.slice(0, boundary.length - 1).map(({ lat, lon, lng }) => ({
    lat: parseFloat(lat),
    lng: lon ? parseFloat(lon) : parseFloat(lng),
  }));

  const onBoundaryEdit = (paths, polygonId) => {
    const updatedPath = paths.map(path => ({ lat: path.lat, lon: path.lng }));
    const polygonArea = google.maps.geometry.spherical.computeArea(paths?.map(path => new google.maps.LatLng(path)));
    onSegmentBoundryUpdate(updatedPath, polygonArea);
  };

  return (
    <PolygonWrapper
      polygonId={polygonId}
      draggable={isSelected}
      editable={isSelected}
      paths={paths}
      setPaths={onBoundaryEdit}
      onLoadPolygon={polygon => (segmentPolygonRef.current = polygon)}
      onUnmountPolygon={() => (segmentPolygonRef.current = null)}
      options={{
        map: map,
        strokeWeight: 1,
        strokeColor: segmentColor,
        fillColor: segmentColor,
        strokeOpacity: 1,
        geodesic: false,
        zIndex: index + 1,
        editable: isSelected,
        draggable: isSelected,
        visible: isSelected || currentlyGraggingSegment,
      }}
    />
  );
};

const ImportedPanelPolygons = ({ panels, segment, panelPolygonRef, isAllPanelSelect, isVisible = true }) => {
  const map = useGoogleMap();
  const theme = useTheme();

  const selectingPanel = useRef(null);
  const selectTimerRef = useRef(null);

  const { azimuth, pitch, id: segment_id } = segment;
  const pitchElevation = parseFloat(Math.sin(pitch / 360)).toFixed(2);

  const onPanelClick = (_polygon, polygonId, segment_id, removeIfSelected = true) => {
    // const isPanelDeleteSelected = selectedInstrument?.key === 'DELETE';
    // const isPointerSelected = selectedInstrument?.key === 'POINTER';

    if (isPanelDeleteSelected) {
      // onPanelSelectForDelete(polygonId, segment_id, true);
    }
    if (isPointerSelected) {
      // const isSelected = selectedPanels.includes(polygonId);
      if (isSelected) {
        // setSelectedPanels(selectedPanels.filter(panel => panel !== polygonId));
      } else {
        // setSelectedPanels([...selectedPanels, polygonId]);
      }
    }
  };

  const onPanelMouseDown = (polygon, polygonId, segment_id) => {
    onPanelClick(polygon, polygonId, segment_id, false);

    selectingPanel.current = true;
    if (selectTimerRef.current) {
      clearTimeout(selectTimerRef.current);
    }
    selectTimerRef.current = setTimeout(() => {
      selectTimerRef.current = false;
    }, 3000);
  };

  const onPanelMouseUp = (polygon, polygonId, segment_id) => {
    selectTimerRef.current = false;
    if (selectTimerRef.current) {
      clearTimeout(selectTimerRef.current);
    }
  };

  const onPanelMouseOver = (polygon, polygonId, segment_id) => {
    if (selectTimerRef.current) {
      onPanelMouseDown(polygon, polygonId, segment_id);
    }
  };

  return panels?.map(panel => {
    const {
      id,
      center,
      height: panelHeight,
      width: panelWidth,
      orientation,
      roof,
      isTobeDeleted = false,
      isSelected = false,
    } = panel;
    const isLandScape = orientation === 'LANDSCAPE';
    const isPanelToDrag = isAllPanelSelect && isVisible;
    let height = parseFloat(panelHeight / 2).toFixed(2);
    let width = parseFloat(panelWidth / 2).toFixed(2);

    if (isLandScape) {
      const previousHeight = height;

      height = width;
      width = previousHeight;
    }

    height = height - pitchElevation;
    const centerPosition = {
      lat: center?.lat,
      lng: center?.lon,
    };

    const top = google.maps.geometry.spherical.computeOffset(centerPosition, height, azimuth + 0);
    const right = google.maps.geometry.spherical.computeOffset(centerPosition, width, azimuth + 90);
    const left = google.maps.geometry.spherical.computeOffset(centerPosition, width, azimuth + 270);
    const bottom = google.maps.geometry.spherical.computeOffset(centerPosition, height, azimuth + 180);

    const topRight = google.maps.geometry.spherical.computeOffset(top, width, azimuth + 90);
    const bottomRight = google.maps.geometry.spherical.computeOffset(right, height, azimuth + 180);
    const bottomLeft = google.maps.geometry.spherical.computeOffset(bottom, width, azimuth + 270);
    const topLeft = google.maps.geometry.spherical.computeOffset(left, height, azimuth + 0);
    const zoom = map.getZoom();

    return (
      <PolygonWrapper
        key={id}
        polygonId={id}
        draggable={false}
        editable={false}
        paths={[topRight, bottomRight, bottomLeft, topLeft]}
        onLoadPolygon={polygon => (panelPolygonRef.current = polygon)}
        onUnmountPolygon={() => (panelPolygonRef.current = null)}
        onPolygonMouseUp={(polygon, polygonId) => onPanelMouseUp(polygon, polygonId)}
        onPolygonMouseDown={(polygon, polygonId) => onPanelMouseDown(polygon, polygonId)}
        onPolygonMouseOver={(polygon, polygonId) => onPanelMouseOver(polygon, polygonId)}
        options={{
          map: map,
          strokeWeight: zoom > 19 ? 2 : zoom > 18 ? 0.6 : 0.3,
          strokePosition: google.maps.StrokePosition.INSIDE,
          fillColor: isTobeDeleted ? '#ab1c20' : theme.natural_900,
          strokeColor: isPanelToDrag || isSelected ? theme.primary_500 : theme.white,
          fillOpacity: isPanelToDrag || isSelected ? 0.9 : 0.9,
          strokeOpacity: 1,
          geodesic: false,
          zIndex: 1000,
          draggable: false,
          visible: isVisible,
        }}
      />
    );
  });
};

const Segment = ({ segment, index, roof_id, property_id, segmentColor }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const panelsOriginalRef = useRef([]);

  const { showErrorToast } = useError();

  const segmentPolygonRef = useRef(null);
  const panelPolygonRef = useRef(null);

  const { setModal } = useContext(OrganisationContext);

  const pitchOriginal = useMemo(
    () => getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false) || 0,
    [segment],
  );
  const azimuthOriginal = useMemo(
    () => getRoofDetailsFromManualOrImported(segment, 'azimuth', false, false, false) || 0,
    [segment],
  );

  const [segmentPanels, setSegmentPanels] = useState([]);
  const [segmentDetails, setSegmentDetails] = useState({ ...segment, pitch: pitchOriginal, azimuth: azimuthOriginal });
  const [isSelected, setIsSelected] = useState(false);
  const [isAllPanelSelect, setIsAllPanelSelect] = useState(false);
  const [segmentEditType, setSegmentEditType] = useState(null);
  const [isSegmentToSave, setIsSegmentToSave] = useState(false);

  const { id: segment_id, pitch, azimuth, panel_grid, imported, manual } = segmentDetails;
  const { boundary: importedBoundary } = imported || {};
  const { boundary: manualBoundary } = manual || {};

  const segmentFormat = useMemo(() => {
    return panel_grid?.length > 0 ? 'MANUAL' : 'IMPORTED';
  }, [panel_grid]);

  const panels_count = useMemo(() => {
    return segmentPanels?.length || 0;
  }, [segmentPanels]);

  const isManualSegment = useMemo(() => {
    return segmentFormat === 'MANUAL';
  }, [segmentFormat]);

  const fetchRoofPanels = (property_id, segment_id) => {
    dispatch(
      getRoofPanelInformation({
        id: property_id,
        roof_id: segment_id,
        params: {
          source: segmentFormat,
        },
      }),
    )
      .then(data => {
        setSegmentPanels(data);
        panelsOriginalRef.current = data;
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PANEL_DETAILS') }));
  };

  useEffect(() => {
    if (segment_id && property_id) {
      fetchRoofPanels(property_id, segment_id);
    }
  }, [property_id, segment_id, segmentFormat]);

  const onCancelSegment = () => {
    setSegmentDetails({ ...segment, pitch: pitchOriginal, azimuth: azimuthOriginal });
    setIsSegmentToSave(false);
  };

  const onSaveSegment = () => {
    // save segment changes and update parent state
  };

  const onSegmentAzimuthChange = (value, prevAzimuth) => {
    // update segment azimuth
    setSegmentDetails({ ...segmentDetails, azimuth: value });
  };

  const onDeleteRoofSegment = () => {
    // delete roof segment and update parent state
  };

  const onChangeAIToGrid = (segment_id, roof_id) => {};

  const onChangeGridToAI = (segment_id, roof_id) => {};

  const onSegmentBoundryUpdate = (updatedPath, polygonArea) => {
    // update segment boundary
    setSegmentDetails({
      ...segmentDetails,
      manual: {
        ...manual,
        boundary: [...updatedPath, updatedPath[0]],
        area: polygonArea,
      },
    });
    setIsSegmentToSave(true);
  };

  return (
    <div className={classNames('pxy-3 border radius-2 row-gap-3 flex-column', isSelected && 'selected-segment')}>
      <SegmentPolygon
        segmentId={segment_id}
        segmentColor={segmentColor}
        onSegmentBoundryUpdate={onSegmentBoundryUpdate}
        isSelected={isSelected}
        boundary={manualBoundary}
        index={index}
        currentlyGraggingSegment={false}
        segmentPolygonRef={segmentPolygonRef}
      />
      {!isManualSegment && (
        <ImportedPanelPolygons
          panels={segmentPanels}
          segment={segmentDetails}
          panelPolygonRef={panelPolygonRef}
          isAllPanelSelect={isAllPanelSelect}
        />
      )}
      <div className="pb-3 border-bottom flex items-center col-gap-2">
        <div className="dot" style={{ backgroundColor: segmentColor }} />
        <label className="inter-500-text flex-1">Section {index + 1}</label>
        {isSegmentToSave ? (
          <div className="flex items-center col-gap-1">
            <span
              className="flex justify-center items-center cross-icon border radius-100 cursor pxy-1"
              onClick={onCancelSegment}>
              <CrossIcon className="natural-500-text" height={12} width={12} />
            </span>
            <span
              className="flex justify-center items-center bg-primary-500 border radius-100 cursor pxy-1"
              onClick={onSaveSegment}>
              <RightIcon color="#FFFFFF" height={12} width={12} />
            </span>
          </div>
        ) : (
          <div className="flex items-center col-gap-2">
            <div className="flex items-center col-gap-1 grid-ai-container bg-natural-50 radius-3">
              <IconContainer
                Icon={GridIcon}
                backgroundColor={isManualSegment ? 'primary_500' : 'transparent'}
                iconColor={isManualSegment ? 'white' : 'natural_500'}
                iconContainerClassname="radius-50-percent cursor"
                onClick={() => onChangeAIToGrid(id, whole_roof?.id)}
              />
              <IconContainer
                Icon={AIIcon}
                backgroundColor={!isManualSegment ? 'primary_500' : 'transparent'}
                iconColor={!isManualSegment ? 'white' : 'natural_500'}
                iconContainerClassname="radius-50-percent cursor"
                onClick={() => onChangeGridToAI(id, whole_roof?.id)}
                disabled={!importedBoundary}
              />
            </div>
            <IconContainer
              Icon={AddPanelIcon}
              backgroundColor={isAllPanelSelect ? 'primary_50' : 'natural_100'}
              iconColor={isAllPanelSelect ? 'primary_500' : 'natural_500'}
              iconContainerClassname="radius-50-percent cursor"
              onClick={() => {
                setIsAllPanelSelect(!isAllPanelSelect);
              }}
            />
            <IconContainer
              Icon={EyeIcon}
              backgroundColor={isSelected ? 'primary_50' : 'natural_100'}
              iconColor={isSelected ? 'primary_500' : 'natural_500'}
              iconContainerClassname="radius-50-percent cursor"
              onClick={() => setIsSelected(!isSelected)}
            />
            <IconContainer
              Icon={DeleteIcon}
              backgroundColor="natural_100"
              iconColor="natural_500"
              iconContainerClassname="radius-50-percent cursor"
              onClick={onDeleteRoofSegment}
            />
          </div>
        )}
      </div>
      <div className="flex items-center col-gap-2">
        <div className="flex-column row-gap-2 w-full">
          <div className="flex items-center justify-between flex-1 col-gap-4 segment-detail-grid">
            <div className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1">
              <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                0<span className="inter-500-text natural-400-text ml-1">obstacles</span>
              </label>
            </div>
            {segmentEditType === 'PITCH' ? (
              <InputElement
                type="number"
                autoFocus
                value={pitch}
                variant="size_28"
                inputMode="numeric"
                className="input-pitch"
                showNumberArrows={true}
                onBlur={() => setSegmentEditType(null)}
                onChange={value => setSegmentDetails({ ...segmentDetails, pitch: value })}
                onWheel={e => e.target.blur()}
              />
            ) : (
              <div
                className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1"
                onClick={() => setSegmentEditType('PITCH')}>
                <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                  {getFormattedNumberStyle(pitch, 'decimal')}°
                  <span className="inter-500-text natural-400-text ml-1">pitch</span>
                </label>
              </div>
            )}
          </div>
          <div className="items-center justify-between w-full col-gap-4 segment-detail-grid">
            {segmentEditType == 'AZIMUTH' ? (
              <InputElement
                type="number"
                autoFocus
                value={azimuth}
                variant="size_28"
                placeholder="0"
                className="input-azimuth"
                showNumberArrows={true}
                onBlur={e => {
                  let value = parseFloat(e.target.value);
                  if (value > 360) {
                    value = parseFloat(value % 360).toFixed(2);
                  }
                  if (value < 0) {
                    value = parseFloat(360 + (value % 360)).toFixed(2);
                  }
                  onSegmentAzimuthChange(value, azimuth);
                  setSegmentEditType(null);
                }}
                onChange={value => onSegmentAzimuthChange(value, azimuth)}
                onWheel={e => e.target.blur()}
              />
            ) : (
              <div
                className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1"
                onClick={e => {
                  e.stopPropagation();
                  setSegmentEditType('AZIMUTH');
                }}>
                <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                  <span className="inter-500-text natural-400-text mr-1">Azimuth</span>
                  {getFormattedNumberStyle(azimuth, 'decimal')}°
                </label>
              </div>
            )}
            <div className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1">
              <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                {panels_count || 0}
                <span className="inter-500-text natural-400-text ml-1">panels</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Segment;
