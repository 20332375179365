import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { Avatar } from '../../components/common/avatar-group';
import IconContainer from '../../components/common/icon-container';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';
import { updateTaskProgress } from '../../store/features/taskSlice';
import { addToast } from '../../store/features/toastSlice';

const statusOptions = [
  { label: 'To do', value: 'TODO' },
  { label: 'In-progress', value: 'STARTED' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Done', value: 'COMPLETE' },
];

const SubTaskItem = props => {
  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const {
    content: { selectedTask, isSubTask },
  } = sideModal;
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { task, subTasks, index, parent_task_id, isJobDetailsTask, fetchTaskSubTasks, fetchTask } = props;
  const { start_date, status } = task?.progress || {};
  const lastIndex = subTasks?.length - 1;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const selectedStatus = statusOptions.find(item => item?.value === status);

  const getStatusStyles = status => {
    switch (status) {
      case 'COMPLETE':
        return {
          color: theme.success_500,
          background: theme.success_50,
        };
      case 'BLOCKED':
        return {
          color: theme.primary_500,
          background: theme.primary_50,
        };
      case 'STARTED':
        return {
          color: theme.purple_500,
          background: theme.purple_50,
        };
      default:
        return {
          color: theme.natural_600,
          background: theme.natural_150,
        };
    }
  };

  const StatusLabel = ({ data, isMainContent = false }) => {
    const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

    if (isMainContent) {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
            width: 'fit-content',
            height: '24px',
          }}
          onClick={() => setTooltipOpen(true)}
          className="select-label flex items-center justify-between font-12 px-3 text-center gap-1">
          <label style={{ color }} className="font-12 inter-500-text">
            {data?.label}
          </label>
          <IconContainer
            Icon={ArrowDownIcon}
            iconColor={color}
            backgroundColor={background}
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
          />
        </label>
      );
    } else {
      return (
        <label
          style={{
            background,
            color,
            borderRadius: '50px',
            height: '24px',
          }}
          className="flex items-center justify-center font-12 px-3 text-center w-full gap-1 font-12 fw-500">
          {data?.label}
        </label>
      );
    }
  };

  const dateFormat = data => {
    const currentDate = moment();
    const isSameDay = currentDate.isSame(data, 'day');
    return (
      <div className="flex-column justify-center row-gap-2 task-items overflow-hidden">
        <label className={`inter-400-text natural-700-text line-height-20 font-14 ${isSameDay && 'color-blue'}`}>
          {!isSameDay && start_date ? moment.unix(start_date).format('DD MMM') : 'Today'}
        </label>
      </div>
    );
  };

  const handleShowTaskDetails = () => {
    setSideModal({
      type: 'view-task-details',
      content: {
        taskDetails: task,
        isSubTask: true,
        isJobDetailsTask: isJobDetailsTask,
        parent_task_id: parent_task_id,
        onSuccess: () => {
          fetchTasks && fetchTasks();
        },
      },
    });
  };

  const onStatusUpdate = async progressData => {
    if (progressData?.status === status) {
      return setEditKey(null);
    } else {
      try {
        const request = {
          progress: {
            start_date: task?.progress?.start_date || null,
            completed_date: task?.progress?.completed_date || null,
            status: progressData?.status || null,
            assignee: task?.assignee?.id ? { id: task?.assignee?.id } : null,
            priority: task?.priority || null,
          },
        };
        const updateValue = await dispatch(updateTaskProgress({ id: task?.id, request }));
        dispatch(addToast({ error: false, text: t('STATUS_UPDATED_SUCCESSFULLY') }));
        fetchTask();
        fetchTaskSubTasks();
      } catch (error) {
        dispatch(
          addToast({ error: true, text: error?.response?.data?.error_description || t('ERROR_WHILE_UPDATE_TASK') }),
        );
      }
    }
  };

  return (
    <div
      className={classNames(
        'flex items-center justify-between min-w-full cursor sidebar-close-ignore',
        lastIndex !== index && 'border-bottom pb-3',
      )}
      onClick={() => !tooltipOpen && handleShowTaskDetails()}>
      <div className="flex items-center gap-2 w-70">
        <div className="" onClick={e => e.stopPropagation()}>
          <CustomTooltip
            wrapperClassName="status-tooltip min-w-fit-content"
            tooltipClassname="custom-scrollbar thin-scrollbar"
            id={`task-status-tooltip-${task?.id}`}
            place="bottom"
            clickable
            isOpen={tooltipOpen}
            onBlurTooltip={() => setTooltipOpen(false)}
            hidden={!tooltipOpen}
            noArrow={true}
            onClick={e => e.stopPropagation()}
            content={statusOptions
              ?.filter(statusVal => statusVal?.value !== status)
              ?.map((data, index) => (
                <div
                  key={data?.label}
                  onClick={() => {
                    onStatusUpdate({ status: data?.value });
                    setTooltipOpen(false);
                  }}
                  className="px-4 py-2 font-14 border-bottom cursor proposal-config">
                  <StatusLabel data={data} />
                </div>
              ))}>
            <div
              onClick={() => setTooltipOpen(!tooltipOpen)}
              className={classNames('radius-1_5 w-auto cursor px-1 py-0_5')}>
              <StatusLabel data={selectedStatus} isMainContent={true} />
            </div>
          </CustomTooltip>
        </div>
        <label className="inter-400-text natural-700-text line-height-20 font-14 text-ellipsis">{task?.name}</label>
      </div>
      <div className="flex items-center gap-2">
        <div className="flex items-center">
          {task?.assignee?.name && (
            <div className="flex items-center col-gap-1 one-line">
              <Avatar avatar={task?.assignee} variant="medium" />
            </div>
          )}
        </div>
        <div className="flex-column justify-center row-gap-2 task-items overflow-hidden">
          {start_date ? dateFormat(start_date) : ''}
        </div>
      </div>
    </div>
  );
};

export default SubTaskItem;
