import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import Popover from '../components/common/popover';
import FilterPopover from '../components/common/popover/add-product-filter-popover';
import JobTypeList from '../components/job-type-list';
import PageHeader from '../components/page-header';
import { OrganisationContext } from '../context/organisationContext';
import useDebounce from '../helpers/useDebounceHook';
import { initModal } from '../helpers/utils';
import { useError } from '../hooks/useError';
import { addNewJobType, getJobTypeList } from '../store/features/jobSlice';
import { addToast } from '../store/features/toastSlice';

const jobTypeDrawStatusFilter = [
  { id: 'ALL', value: 'ALL', label: 'All', selected: true },
  { id: 'ACTIVE', value: 'ACTIVE', label: 'Active' },
  { id: 'INACTIVE', value: 'INACTIVE', label: 'Inactive' },
];

const initialState = {
  status: jobTypeDrawStatusFilter.at(0),
  soldAs: {
    single: false,
    bundle: false,
  },
};

const AddNewJobType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(0);
  const { showErrorToast } = useError();

  const { hasPermission, modal, setModal, setSideModal } = useContext(OrganisationContext);
  const { content } = modal || {};
  const { onSuccess } = content || {};
  const [search, setSearchText] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);
  const [productFilters, setProductFilters] = useState(initialState);
  const debouncedSearch = useDebounce(search, 500);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const { user } = useSelector(state => state.user);
  const { organizations } = user || {};
  const { id: organization_id } = organizations[0] || {};

  const fetchJobTypeList = (page, debouncedSearch, showLoading = true, merge = false) => {
    setShowLoading(showLoading);
    dispatch(
      getJobTypeList({
        params: {
          is_default: true,
          search: debouncedSearch,
        },
      }),
    )
      .then(data => {
        setJobTypes(data);

        pageRef.current = page;
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_JOB_TYPE_LIST') });
        setJobTypes([]);
      })
      .finally(() => setShowLoading(false));
  };

  const fetchMoreData = () => {
    fetchJobTypeList(pageRef.current + 1, debouncedSearch, false, true);
  };

  useEffect(() => {
    fetchJobTypeList(0, debouncedSearch, true, false);
  }, [debouncedSearch]);

  const addJobType = async () => {
    if (showLoading) {
      return;
    }
    setShowLoading(true);
    const request = selectedJobTypes?.map(jobType => ({
      job_type: {
        id: jobType?.id,
      },
    }));
    dispatch(addNewJobType({ organization_id: organization_id, request: request }))
      .then(({ data }) => {
        dispatch(
          addToast({
            error: false,
            text: t('JOB_TYPES_ADDED_SUCCESSFULLY'),
            id: uuid(),
          }),
        );
        onSuccess && onSuccess();
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_ADDING_JOB_TYPES') });
      })
      .finally(() => {
        setShowLoading(false);
        setModal(initModal);
      });
  };

  const SortWrapper = useCallback(
    ({ children }) => (
      <Popover
        isOpen={showFilterPopover}
        offset={[0, 2]}
        content={
          <FilterPopover
            jobTypeDrawStatusFilter={jobTypeDrawStatusFilter}
            productFilters={productFilters}
            setProductFilters={setProductFilters}
            onClearFilters={() => {
              fetchJobTypeList(0, debouncedSearch, true, false);
            }}
            onApplyFilters={() => fetchJobTypeList(0, debouncedSearch, true, false)}
            setShowFilterPopover={setShowFilterPopover}
          />
        }>
        {children}
      </Popover>
    ),
    [showFilterPopover, productFilters],
  );

  const addNewJobTypeManually = () => {
    setModal({
      type: 'add-new-job-type-manually',
      content: {},
    });
  };

  return (
    <AddNewJobTypeWrapper className="flex-column items-center py-9 px-10">
      <div className="w-full flex justify-between items-center">
        <label className="font-20 inter-600-text text-black text-start">{t('ADD_NEW_JOB_TYPE')}</label>
        <CrossIcon className="cursor" onClick={() => setModal(initModal)} width={24} height={24} />
      </div>

      <div className="flex-column w-full items-start justify-start flex-1 add-product-table">
        <PageHeader
          wrapperClassName="py-6"
          showSearchOnLeft={true}
          onAddClick={addNewJobTypeManually}
          showSearch
          searchText={search}
          addButtonLabel={t('ADD_MANUALLY')}
          onSearchChange={setSearchText}
          btnClassName="secondary"
          btnIconClassName="add-icon"
          SortWrapper={SortWrapper}
          showAddJobTypeFilter
          onAddJobTypeFilterClick={() => setShowFilterPopover(true)}
        />
        <JobTypeList
          debouncedSearch={debouncedSearch}
          fetchMoreData={fetchMoreData}
          showLoading={showLoading}
          jobTypes={jobTypes || []}
          hasMore={false}
          isFromAddJobType={true}
          setSelectedJobTypes={setSelectedJobTypes}
          selectedJobTypes={selectedJobTypes}
        />
      </div>

      <div className="flex items-center justify-between w-full mt-10">
        <Button
          className={`default specified-width`}
          label={t('CANCEL')}
          disabled={showLoading}
          onClick={() => setModal(initModal)}
          secondary={true}
          size="large"
          borderRadius="100px"
          width="176px"
        />
        <Button
          className={`primary specified-width`}
          label={t('ADD')}
          disabled={showLoading || !selectedJobTypes?.length > 0}
          onClick={() => addJobType()}
          size="large"
          borderRadius="100px"
          width="176px"
        />
      </div>
    </AddNewJobTypeWrapper>
  );
};

const AddNewJobTypeWrapper = styled.div`
  width: 100%;
  min-width: 94dvw;
  max-width: 94dvw;

  .error-border {
    border-color: ${({ theme }) => theme.additionalRed};

    .organisations-border {
      border-color: ${({ theme }) => theme.additionalRed} !important;
    }
  }
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: 24px minmax(150px, 1fr) minmax(200px, 3fr);
    column-gap: 36px;
    padding: 16px 24px;
  }

  .input,
  .textarea {
    color: #171717;
  }
  .add-icon {
    color: ${({ theme }) => theme?.primary_500};
  }
  .add-product-table {
    max-height: 600px;
    min-height: 400px;
  }
`;

export default AddNewJobType;
