import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as CurrentTimeIcon } from '../../assets/icons/current-time-icon.svg';
import { ReactComponent as EmptyDocumentIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import IconContainer from '../../components/common/icon-container';
import SkeletonTransition from '../../components/common/skeleton-transition';
import { OrganisationContext } from '../../context/organisationContext';
import { useWindowSize } from '../../hooks/useWindowSize';

const AppointmentItem = ({
  positionRefs,
  appointment,
  selector,
  i,
  isResourceSelected,
  onDeleteResource,
  isShowAddress,
  onRefresh = () => {},
}) => {
  const theme = useTheme();
  const { setSideModal } = useContext(OrganisationContext);

  const {
    isNew,
    start_date: appointmentStartDate,
    end_date: appointmentEndDate,
    duration: appointmentDuration,
    job_name: appointmentJobName,
    actutal_date,
    expected_date,
    activity,
    service: appointmentService,
    status,
  } = appointment;
  const { engagement } = activity || {};
  const start_date = isNew ? appointmentStartDate : actutal_date?.start_date ?? expected_date?.start_date;
  const duration = isNew ? appointmentDuration : actutal_date?.duration ?? expected_date?.duration;
  const end_date = isNew ? appointmentEndDate : moment.unix(start_date).add(duration, 'minutes');
  const job_name = isNew ? appointmentJobName : activity?.name;
  const service_name = appointmentService?.name;
  const formatted_address = engagement?.property?.formatted_address;
  const startDateHour = moment(start_date * 1000).hour();
  const is_started = status === 'STARTED' || status === 'COMPLETE';
  const appointment_bg = status === 'COMPLETE' ? '#4CAF50' : status === 'STARTED' ? '#2196F3' : '#FFC107';

  const [showActivityDetails, setShowActivityDetails] = useState(false);

  // TODO: when window width changes, calculate the left position of the appointment.
  // This is a temporary fix, need to find a better solution
  const { width: _windowWidth } = useWindowSize();

  let left =
    positionRefs.current[moment(start_date * 1000).hour()]?.offsetLeft +
    (moment(start_date * 1000).minute() / 60) * positionRefs.current[moment(start_date * 1000).hour()]?.offsetWidth -
    positionRefs.current[8]?.offsetLeft;
  left = left + 7;

  const top = i * 88 + 6 - 0;

  const width =
    selector === 'DAY'
      ? (positionRefs.current[moment(start_date * 1000).hour()]?.offsetWidth * duration) / 60 - 14
      : positionRefs.current[moment(start_date * 1000).isoWeekday()]?.offsetWidth;

  const onActivityClick = () => {
    setShowActivityDetails(true);
    setSideModal({
      type: 'view-activity-details',
      content: {
        activity: appointment,
        onSuccess: onRefresh,
        onClose: () => setShowActivityDetails(false),
      },
    });
  };

  return (
    <Fragment>
      {start_date && end_date && startDateHour > 7 && startDateHour < 19 && (
        <div
          className={classNames('appointment radius-1 border shadow', showActivityDetails && 'appointment-selected')}
          style={{
            background: appointment_bg,
            borderColor: showActivityDetails ? appointment_bg : 'transparent',
            width: width,
            top: top,
            left: left,
          }}>
          <div className="flex-column h-full appointment-address-tooltip">
            <div className="flex pxy-2 h-full cursor" onClick={() => onActivityClick()}>
              <div className="flex-column overflow-hidden w-full">
                <label
                  className="inter-500-text font-12 mb-1 one-line"
                  style={{ color: is_started ? 'white' : theme.natural_900 }}>
                  {job_name}
                </label>
                <label
                  className="inter-400-text font-12 mb-1 one-line"
                  style={{ color: is_started ? 'white' : theme.natural_900 }}>
                  {service_name}
                </label>
                <label
                  className="inter-400-text font-12 one-line"
                  style={{ color: is_started ? theme.natural_300 : theme.natural_500 }}>
                  {moment(start_date * 1000).format('H:mm')}
                  {` - ${moment(start_date * 1000 + duration * 60 * 1000).format('H:mm')}`}
                </label>
                {isShowAddress && (
                  <label
                    className="inter-400-text font-12 mt-1 one-line"
                    style={{ color: is_started ? 'white' : theme.natural_900 }}>
                    {formatted_address}
                  </label>
                )}
              </div>
              {showActivityDetails && (
                <div className="flex items-start justify-center h-full">
                  <IconContainer
                    Icon={CloseIcon}
                    backgroundColor="transparent"
                    iconHeight={10}
                    iconWidth={10}
                    iconColor={is_started ? 'white' : theme.natural_900}
                    onClick={e => onDeleteResource(appointment)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const ActivitySchedulerV2 = ({
  appointments,
  onAddResource,
  selectedDate,
  loading,
  onDeleteResource,
  appointmentDuration = 60,
  isShowAddress = false,
  onRefresh = () => {},
}) => {
  const positionRefs = useRef({});
  const appointmentBoxWidth = 120;
  const [positions, setPositions] = useState([]);

  const currentMoment = moment();
  const isToday = moment.unix(selectedDate).isSame(currentMoment, 'day');

  // i have use "isBetween" method this method check inclusivity/exclusivity boundaries when checking if a moment is between two other moments so that's reason i have add the '[)'
  // Link :- https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
  const isWorkingHours = currentMoment.isBetween(
    moment().hour(8).minute(0).second(0),
    moment().hour(18).minute(0).second(0),
    'hour',
    '[)',
  );

  useEffect(() => {
    setPositions(
      [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(l => ({
        id: l,
        label: `${l}:00`,
      })),
    );
  }, [selectedDate]);

  const onPositionClick = (e, position, userAppointment) => {
    if (userAppointment.isTeamOrOrganization) {
      return;
    }
    const startTime = moment(selectedDate * 1000)
      .hour(position.id)
      .minutes(0)
      .seconds(0);
    const endTime = startTime.clone().add(appointmentDuration, 'minutes');
    const newAppointment = {
      start_date: startTime.unix(),
      duration: appointmentDuration,
      end_date: endTime.unix(),
      isNew: true,
    };
    onAddResource(userAppointment, newAppointment);
  };

  let left =
    positionRefs.current[moment().hour()]?.offsetLeft +
    (moment().minute() / 60) * positionRefs.current[moment().hour()]?.offsetWidth -
    positionRefs.current[8]?.offsetLeft;

  return (
    <ActivitySchedulerWrapper
      className={classNames('flex border radius-2', appointments.length > 0 ? '' : 'overflow-hidden h-full')}>
      <div className="date-grid-wrapper h-auto min-w-full">
        <div
          className={classNames('flex-column flex-1 min-h-full', appointments.length > 0 ? 'h-auto' : 'h-full')}
          id="appointment-position-id">
          <div className="flex" style={{ position: 'sticky', top: 0, zIndex: 9 }}>
            <div className="flex flex-1 bg-natural-50 appointments-top">
              <div className="flex items-center min-w-full">
                {positions.map(position => (
                  <div
                    key={position.id}
                    ref={element => (positionRefs.current[position.id] = element)}
                    className="flex flex-1 px-1 py-3"
                    style={{ minWidth: `${appointmentBoxWidth}px` }}>
                    <label className="font-12 inter-600-text natural-700-text">{position.label}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <SkeletonTransition loading={loading} height={'800px'}>
            {appointments.length > 0 ? (
              <div className="flex scroller appointments-scroll overflow-auto" id="appointments-scroll-id">
                <div className="flex-column flex-1 appointment-lis-wrapper">
                  <div className={`flex-column flex-1 appointments-list relative`} id="client-scroll-id">
                    {isToday && isWorkingHours && (
                      <div className="current-time-schedule absolute h-full" style={{ left: left }}>
                        <CurrentTimeIcon width={10} height={10} className="absolute" />
                      </div>
                    )}

                    {!loading &&
                      appointments.map((userAppointment, i) => (
                        <div key={`first-appointment-${i}`} className="flex appointments-list-container min-w-full">
                          <div
                            key={`third-appointment-${i}`}
                            className="flex-column user-appointment flex-3 min-w-full"
                            style={{
                              height: userAppointment.isTeamOrOrganization ? '88px' : '88px',
                            }}>
                            <div className="flex items-center flex-1" id="hour-position-id">
                              {positions.map((position, index) => {
                                if (loading) {
                                  return (
                                    <div
                                      key={position.id}
                                      className="flex-1"
                                      style={{
                                        padding: '0.25rem',
                                        marginBottom: '0.5rem',
                                        width: '120px',
                                      }}>
                                      <Skeleton key={position.id} height="100%" />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className={classNames(
                                        'flex flex-1 h-full add-item',
                                        index !== 0 && 'border-left',
                                      )}
                                      id={position.id}
                                      onClick={e => onPositionClick(e, position, userAppointment)}
                                      style={{
                                        minWidth: `${appointmentBoxWidth}px`,
                                        borderBottom: i < appointments.length - 1 ? '1px solid rgb(231, 239, 242)' : '',
                                      }}></div>
                                  );
                                }
                              })}
                            </div>
                            {userAppointment?.resources?.map((appointment, j) => {
                              return (
                                <AppointmentItem
                                  key={appointment?.appointment_id || appointment?.id}
                                  appointment={appointment}
                                  duration={appointment.duration}
                                  i={i}
                                  positionRefs={positionRefs}
                                  selector={'DAY'}
                                  isResourceSelected={false}
                                  isShowAddress={isShowAddress}
                                  onDeleteResource={onDeleteResource}
                                  onRefresh={onRefresh}
                                />
                              );
                            })}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center no-resources px-6 py-6 flex-1 radius-1_5">
                <div className="flex-column" style={{ width: '303px', height: '196px' }}>
                  <IconContainer
                    Icon={EmptyDocumentIcon}
                    backgroundColor="transparent"
                    iconHeight={110}
                    iconWidth={160}
                    iconClassName="mb-6"
                  />
                  <label className="inter-500-text natural-900-text text-center mb-1">{'No resources available'}</label>
                  <label className="inter-400-text natural-500-text text-center font-12">
                    there are no resources found for the applied filters, please check the filters applied and try again
                  </label>
                </div>
              </div>
            )}
          </SkeletonTransition>
        </div>
      </div>
    </ActivitySchedulerWrapper>
  );
};

const ActivitySchedulerWrapper = styled.div`
  .appointment {
    position: absolute;
    height: 76px;
    transition: width 500ms ease-in-out, box-shadow 500ms ease-in-out, left 500ms ease-in-out;
    box-shadow: none;

    :hover {
      box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
        inset 0px 0px 0px 1px white;
    }
  }

  .appointment-selected {
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
      inset 0px 0px 0px 1px white;
  }

  .users-name {
    position: sticky;
    left: 0px;
    z-index: 5;
  }
  .current-time-schedule {
    border-right: 1px dashed ${({ theme }) => theme.error_500};
    top: 0px;
    z-index: 1;
    width: 5px;
    svg {
      left: -0.5px;
    }
  }

  .custom-tooltip {
    padding: 0;
  }
`;

export default ActivitySchedulerV2;
