import { AnimatePresence, motion } from 'framer-motion';
import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import DeletePPA from '../components/system-overview/delete-ppa';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import AddAccount from './accounts/add-account';
import ActivityAction from './activity/activity-delete';
import ActivityCancel from './activity/activity-remove';
import AddActivity from './activity/add-activity';
import AddContact from './add-new-contact';
import AddNewJobType from './add-new-job-type';
import AddNewJobTypeManually from './add-new-job-type-manually';
import AddNewProduct from './add-new-product';
import AddNewProductManually from './add-new-product-manually';
import AddProperty from './add-new-property';
import AddNewRole from './add-new-role';
import AddNewTeam from './add-new-team';
import AddTeamUser from './add-new-user-team';
import AddTeamMember from './add-team-member';
import AutomationAction from './automation-action';
import AutomationCancel from './automation-cancel';
import AutomationLogAction from './automation-log-action';
import AddAction from './automation/add-action';
import AddAutomation from './automation/add-automation';
import AddTrigger from './automation/add-trigger';
import DeleteAction from './automation/delete-action';
import UpdateTrigger from './automation/update-trigger';
import AddCosts from './costs/add-costs';
import DeleteCosts from './costs/delete-costs';
import AddEditEmployee from './employee-popup/add-edit-employee';
import AddIntegration from './integration/add-integration';
import IntegrationActions from './integration/integration-actions';
import InviteUser from './invite-user';
import JobPartnerDelete from './job-partner-delete';
import AddJob from './jobs/add-job';
import DeleteJobType from './jobs/delete-job-type';
import ExitJobDetails from './jobs/exit-job-details';
import JobsFileAction from './jobsfile-delete';
import { ModalWrapper } from './modal.styled';
import NotesAction from './notes-delete';
import AddNewPaymentTerms from './payment-term/add-new-payment-terms';
import UpdatePaymentTerms from './payment-term/update-payment-terms';
import InsideModal from './popup-inside';
import AddBrand from './product/add-brand';
import AddFamily from './product/add-family';
import AddFeature from './product/add-feature';
import DeleteProduct from './product/delete-product';
import DeleteProductMedia from './product/delete-product-media';
import UploadMedia from './product/upload-media';
import DeleteProject from './projects/delete-project';
import AddQuote from './quotes/add-quote';
import DeleteQuote from './quotes/delete-quote';
import SendQuote from './quotes/send-quote';
import RoleActions from './roles-action';
import DeleteActionRoofSegment from './roof/delete-action-roof-segment';
import AddProject from './setting/projects/add-project';
import AddQuoteTemplate from './setting/quote-template/add-quote-template';
import DeleteQuoteTemplate from './setting/quote-template/delete-quote-template-item';
import MoveToStage from './stage/move-to-stage';
import RedirectToStage from './stage/redirect-to-stage';
import ShareProposal from './system-overview-popup/share-proposal';
import TakePhoto from './take-photo';
import DeleteTask from './tasks/delete-task';
import TeamActions from './team-actions';
import TeamUserActions from './team-member-popup/team-user-remove';
import UserActions from './user-actions';

const PopupComponents = {
  'add-integration': AddIntegration,
  'integration-action': IntegrationActions,
  'invite-user': InviteUser,
  'user-action': UserActions,
  'automation-action': AutomationAction,
  'automation-log-action': AutomationLogAction,
  'add-new-team': AddNewTeam,
  'add-new-role': AddNewRole,
  'add-team-member': AddTeamMember,
  'add-new-team-user': AddTeamUser,
  'team-action': TeamActions,
  'team-user-action': TeamUserActions,
  'roles-action': RoleActions,
  'add-automation': AddAutomation,
  'automation-cancel': AutomationCancel,
  'move-to-stage': MoveToStage,
  'add-job': AddJob,
  'redirect-to-stage': RedirectToStage,
  'note-action': NotesAction,
  'jobfiles-action': JobsFileAction,
  'add-activity': AddActivity,
  'add-property': AddProperty,
  'add-new-contact': AddContact,
  'activity-action': ActivityAction,
  // 'quotes-action': QuotesAction,
  'add-trigger': AddTrigger,
  'update-trigger': UpdateTrigger,
  'add-action': AddAction,
  'delete-action': DeleteAction,
  'add-quote': AddQuote,
  'delete-quote': DeleteQuote,
  'delete-activity': ActivityCancel,
  'add-account': AddAccount,
  'delete-costs': DeleteCosts,
  'add-new-payment-term': AddNewPaymentTerms,
  'update-payment-term': UpdatePaymentTerms,
  'add-costs': AddCosts,
  'add-edit-employee': AddEditEmployee,
  'send-quote': SendQuote,
  'delete-project': DeleteProject,
  'delete-quote-template': DeleteQuoteTemplate,
  'add-quote-template': AddQuoteTemplate,
  'add-project': AddProject,
  'exit-job-details': ExitJobDetails,
  'delete-partner': JobPartnerDelete,
  'add-feature': AddFeature,
  'add-family': AddFamily,
  'add-brand': AddBrand,
  'delete-roof-segment-action': DeleteActionRoofSegment,
  'share-proposal': ShareProposal,
  'take-photo': TakePhoto,
  'upload-product-media': UploadMedia,
  'delete-ppa': DeletePPA,
  'add-new-product': AddNewProduct,
  'add-new-product-manually': AddNewProductManually,
  'add-new-job-type': AddNewJobType,
  'add-new-job-type-manually': AddNewJobTypeManually,
  'delete-product': DeleteProduct,
  'delete-job-type': DeleteJobType,
  'delete-product-media': DeleteProductMedia,
  'delete-task': DeleteTask,
};

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[modal.type];
    if (Component) {
      return <Component />;
    }
    return <Fragment />;
  };

  if (!modal.type) return null;

  return (
    <AnimatePresence>
      <ModalWrapper
        initial={{ opacity: 0, background: 'transparent' }}
        animate={{ opacity: 1, background: 'rgba(4, 32, 48, 0.8)' }}
        exit={{ opacity: 0, background: 'transparent' }}
        transition={{ duration: 0.2 }}
        key="modal-wrapper-id"
        id="modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center' }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
          key="modal-content-id"
          ref={ref}
          className="modal-content"
          id="modal-content-id"
          style={{
            borderRadius: modal.content?.noRadius ? '0' : '16px',
            marginTop: modal.content?.top ? '80px' : '0',
          }}>
          {setPopupComponent()}
          <InsideModal />
        </motion.div>
      </ModalWrapper>
    </AnimatePresence>
  );
};

export default Modal;
