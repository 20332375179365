import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-done.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/property/location-icon.svg';
import { ReactComponent as WeithersIcon } from '../../assets/icons/property/weithers.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-outline.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { Avatar } from '../../components/common/avatar-group';
import Checkbox from '../../components/common/checkbox';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import Dropdown from '../../components/common/select-dropdown';
import TimeSelectorElement from '../../components/common/time-selector/time-selector-element';
import { OrganisationContext } from '../../context/organisationContext';
import { getContactValueByType, getFormattedDate, initSideModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import {
  createActivityResorces,
  createTasks,
  deleteActivityResorces,
  getActivityTasks,
  getAvailability,
  getOrganizationServices,
  getTaskConfigGroups,
  updateActivity,
  updateActivityResorces,
} from '../../store/features/activitiesSlice';
import { addToast } from '../../store/features/toastSlice';

const ActivityName = ({ name, isEditing, updateActivity }) => {
  if (isEditing) {
    return (
      <div className="flex flex-1 items-center">
        <InputElement
          className="flex-1"
          variant="size_32"
          value={name}
          onChange={value => updateActivity('name', value)}
        />
      </div>
    );
  }
  return (
    <div className="flex items-center">
      <label className="inter-500-text font-16">{name}</label>
    </div>
  );
};

const ActivityTime = ({ activity, isEditing, updateActivity }) => {
  const dispatch = useDispatch();
  const { start_date, end_date } = activity;

  const startTime = start_date ? moment.unix(start_date).format('HH:mm') : '00:00';
  const endTime = end_date ? moment.unix(end_date).format('HH:mm') : '00:00';

  const getActivityFormattedDate = () => {
    return (
      getFormattedDate(start_date, 'dddd DD MMMM, YYYY', false) +
      ' ' +
      getFormattedDate(start_date, 'HH:mm', false) +
      ' - ' +
      getFormattedDate(end_date, 'HH:mm', false)
    );
  };

  const onUpdateTime = (type, date, time) => {
    const splitedTime = time?.split(':') || [];
    const [hour, minute] = splitedTime;
    if (hour < 8 || hour > 18) {
      return;
    }
    const updatedTime = moment.unix(date).set({ hour: hour, minute: minute }).unix();
    const updatedActivity = { ...activity, [type]: updatedTime };

    const { start_date, end_date } = updatedActivity;
    const difference = moment.unix(end_date).diff(moment.unix(start_date), 'minutes');
    if (difference < 15) {
      dispatch(
        addToast({ error: true, text: 'The duration of the activity should be at least 15 minutes', id: nanoid() }),
      );
      return;
    }
    updateActivity(type, updatedTime);
  };

  if (isEditing) {
    return (
      <div className="flex items-center">
        <IconContainer Icon={ClockIcon} />
        <div className="flex row-gap-2 items-center justify-between">
          <label className="inter-400-text ml-2">{getFormattedDate(start_date, 'dddd DD MMMM, YYYY', false)}</label>
          <div className="flex col-gap-2 items-center ml-2">
            <TimeSelectorElement
              showClockIcon={false}
              containerClassname="p-0"
              selectedTime={startTime}
              setSelectedTime={time => onUpdateTime('start_date', start_date, time)}
              isRequired={true}
              disabled={true}
            />
            <span>-</span>
            <TimeSelectorElement
              containerClassname="p-0"
              showClockIcon={false}
              selectedTime={endTime}
              setSelectedTime={time => onUpdateTime('end_date', end_date, time)}
              isRequired={true}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <IconContainer Icon={ClockIcon} />
      <label className="inter-400-text ml-2">{getActivityFormattedDate()}</label>
    </div>
  );
};

const ActivityService = ({ service, updateActivity, services = [] }) => {
  return (
    <div className="flex items-center col-gap-4">
      <Dropdown
        options={services}
        isCustomSearchable={false}
        isClearable={false}
        name="Service"
        className="w-50"
        placeholder="Select service"
        isSearchable={false}
        onChange={value => updateActivity('service', value)}
        value={service}
        customStyle={{ control: { minHeight: '44px', borderRadius: '6px' }, valueContainer: { fontSize: '14px' } }}
      />
    </div>
  );
};

const AddResources = ({ activity, service, availability, setAvailability }) => {
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { start_date, end_date } = activity;

  const [selectedResources, setSelectedResources] = useState(
    availability.filter(res => res.selected).map(res => res.user.id),
  );

  useEffect(() => {
    setSelectedResources(availability.filter(res => res.selected).map(res => res.user.id));
  }, [availability]);

  const onResourceAddRemove = resource => {
    const {
      user: { id },
    } = resource;
    if (selectedResources.includes(id)) {
      setAvailability(availability.map(res => (res.user.id === id ? { ...res, selected: false } : res)));
      setSelectedResources(selectedResources.filter(res => res !== id));
    } else {
      const resourceStartDate = start_date;
      const resourceEndDate = end_date;

      setAvailability(
        availability.map(res =>
          res.user.id === id
            ? { ...res, selected: true, start_date: resourceStartDate, end_date: resourceEndDate, service: service }
            : res,
        ),
      );
      setSelectedResources([...selectedResources, id]);
    }
  };

  const onUpdateTime = (type, resource, date, time) => {
    const splitedTime = time?.split(':') || [];
    const [hour, minute] = splitedTime;
    if (hour < 8 || hour > 18) {
      return;
    }
    const updatedTime = moment.unix(date).set({ hour: hour, minute: minute }).unix();
    const updatedResource = { ...resource, [type]: updatedTime };

    const { start_date: resourceStartDate, end_date: resourceEndDate } = updatedResource;

    const difference = moment.unix(resourceEndDate).diff(moment.unix(resourceStartDate), 'minutes');
    if (difference < 15) {
      dispatch(
        addToast({ error: true, text: 'The duration of the resource should be at least 15 minutes', id: nanoid() }),
      );
      return;
    }

    if (start_date > resourceStartDate || end_date < resourceEndDate) {
      showErrorToast({ default_message: 'Resource time should be within activity time', id: nanoid() });
      return;
    }
    setAvailability(availability.map(res => (res.user.id === resource.user.id ? updatedResource : res)));
  };

  return (
    <div className="flex-column row-gap-6 flex-1">
      {!service && (
        <div className="flex items-center bg-natural-100 radius-2 flex-1 justify-center">
          <label className="inter-400-text natural-500-text">{'Please select service'}</label>
        </div>
      )}
      {service && (
        <div className="flex-column radius-2 flex-1 row-gap-2">
          {availability?.length === 0 && (
            <div className="flex items-center bg-natural-100 radius-2 flex-1 justify-center">
              <label className="inter-400-text natural-500-text">{'No resources available'}</label>
            </div>
          )}
          {availability?.map((resource, index) => {
            const startTime = resource.start_date ? moment.unix(resource.start_date).format('HH:mm') : '00:00';
            const endTime = resource.end_date ? moment.unix(resource.end_date).format('HH:mm') : '00:00';
            return (
              <div key={resource.id} className="flex-column row-gap-2 pxy-3 bg-white radius-1_5 border">
                <div className="flex items-center">
                  <Avatar variant="size_32" avatar={resource?.user} backgroundColorIndex={index} />
                  <div className="flex-column flex-1">
                    <label className="inter-500-text flex-1 ml-2">{resource?.user?.name}</label>
                  </div>
                  <Checkbox
                    is_checked_done
                    checked={selectedResources.includes(resource?.user.id)}
                    onChange={() => onResourceAddRemove(resource)}
                  />
                </div>
                {selectedResources.includes(resource?.user.id) && (
                  <div className="flex col-gap-2 items-center ml-2">
                    <TimeSelectorElement
                      showClockIcon={false}
                      containerClassname="p-0"
                      selectedTime={startTime}
                      setSelectedTime={time => onUpdateTime('start_date', resource, resource.start_date, time)}
                      isRequired={true}
                    />
                    <span>-</span>
                    <TimeSelectorElement
                      containerClassname="p-0"
                      showClockIcon={false}
                      selectedTime={endTime}
                      setSelectedTime={time => onUpdateTime('end_date', resource, resource.end_date, time)}
                      isRequired={true}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ActivityResources = ({
  activity,
  resources,
  updateActivity,
  isEditing,
  service,
  services = [],
  isResourceOrTaskAdding,
  availability,
  setAvailability,
}) => {
  const getResourceFormattedDate = useCallback(resource => {
    const { expected_date, actutal_date } = resource;
    const start_date = actutal_date?.start_date ?? expected_date?.start_date;
    const duration = actutal_date?.duration ?? expected_date?.duration;
    const end_date = moment.unix(start_date).add(duration, 'minutes').unix();

    return getFormattedDate(start_date, 'HH:mm', false) + ' - ' + getFormattedDate(end_date, 'HH:mm', false);
  }, []);

  const onDeleteResourceClick = resource => {
    const newResources = resources.filter(res =>
      resource.id ? res.id !== resource.id : res.user.id !== resource.user.id,
    );
    updateActivity('temp_resources', newResources);
  };

  if (isEditing) {
    return (
      <div className="flex-column row-gap-6 flex-1">
        {isResourceOrTaskAdding && (
          <ActivityService service={service} updateActivity={updateActivity} services={services} />
        )}
        {resources?.length > 0 ? (
          <div className={classNames('flex-column radius-2 row-gap-2', !isResourceOrTaskAdding && 'flex-1')}>
            {resources?.map((resource, index) => (
              <div key={resource.id} className="flex items-center pxy-3 bg-white radius-1_5 border">
                <Avatar variant="size_32" avatar={resource?.user} backgroundColorIndex={index} />
                <label className="inter-500-text flex-1 ml-2">{resource?.user?.name}</label>
                <label className="inter-400-text mr-2">{resource?.service?.name}</label>
                <IconContainer
                  Icon={CloseIcon}
                  backgroundColor="transparent"
                  onClick={() => onDeleteResourceClick(resource)}
                />
              </div>
            ))}
          </div>
        ) : !isResourceOrTaskAdding ? (
          <div className="flex items-center bg-natural-100 radius-2 flex-1 justify-center">
            <label className="inter-400-text natural-500-text">{'No resources'}</label>
          </div>
        ) : null}
        {isResourceOrTaskAdding && (
          <div className="flex-column flex-1">
            <label className="inter-400-text font-14 mb-4">{'Suggestions:'}</label>
            <AddResources
              activity={activity}
              service={service}
              availability={availability}
              setAvailability={setAvailability}
            />
          </div>
        )}
      </div>
    );
  }

  if (!resources?.length) {
    return (
      <div className="flex items-center bg-natural-100 radius-2 flex-1 justify-center">
        <label className="inter-400-text natural-500-text">{'No resources'}</label>
      </div>
    );
  }
  return (
    <div className="flex-column flex-1 row-gap-4">
      {resources?.map((resource, index) => (
        <div key={resource.id} className="flex items-start">
          <Avatar variant="size_32" avatar={resource?.user} backgroundColorIndex={index} />
          <div className="flex-column flex-1 row-gap-1">
            <label className="inter-500-text font-16 ml-2">{resource?.user?.name}</label>
            <div className="flex items-center ml-2">
              <WeithersIcon width={14} height={14} className="natural-400-text" />
              <label className="inter-400-text ml-1">{resource?.service?.name}</label>
            </div>
          </div>
          <div className="flex items-center">
            <IconContainer Icon={ClockIcon} backgroundColor="transparent" />
            <label className="inter-400-text ml-2">{getResourceFormattedDate(resource)}</label>
          </div>
        </div>
      ))}
    </div>
  );
};

const ActivityTasks = ({ activity, isEditing, updateActivity, isResourceOrTaskAdding }) => {
  const { tasks, task_group } = activity;

  const onDeleteTaskClick = task => {
    const newTasks = tasks.filter(res => res.id !== task.id);
    updateActivity('tasks', newTasks);
  };

  if (isEditing) {
    return (
      <div className="flex-column flex-1 row-gap-4 w-full">
        {!tasks?.length && !isResourceOrTaskAdding && (
          <div className="flex items-center bg-natural-100 radius-2 flex-1 justify-center">
            <label className="inter-400-text natural-500-text">{'No tasks'}</label>
          </div>
        )}
        {tasks?.map((task, index) => (
          <div key={`${task.id}-${index}`} className="flex items-center relative">
            <div className="task-drag-handle" />
            <div className="flex flex-1 col-gap-2 items-start border pl-6 pr-2 py-3 radius-1_5">
              <label className="inter-500-text font-12 flex-1">{task.name}</label>
              <label className="inter-500-text font-12">{task?.service?.name}</label>
              <div style={{ width: '16px', height: '16px' }} />
            </div>
            <IconContainer
              iconContainerClassname="flex items-center cursor"
              Icon={CloseIcon}
              backgroundColor="transparent"
              onClick={() => onDeleteTaskClick(task)}
            />
          </div>
        ))}
        {isResourceOrTaskAdding && (
          <SearchableDropdown
            name="Task configs"
            placeholder="Select task configs"
            classNamePrefix="task-config"
            value={task_group}
            isMulti={true}
            onChange={value => updateActivity('task_group', value)}
            isCustomSearchable={false}
            isSearchable={false}
            defaultAdditional={{
              page: 0,
              fetchFunction: getTaskConfigGroups,
              pageable: false,
              formatOptions: options => {
                const groupByTaskConfigGroup = options?.reduce((acc, item) => {
                  const {
                    task_config_group: { id, name },
                  } = item || { task_config_group: { id: null, name: '' } };

                  let taskConfigGroup = acc[id];
                  if (taskConfigGroup) {
                    const taskConfig = taskConfigGroup;
                    taskConfigGroup = { label: name, options: [...taskConfig.options, item] };
                  } else {
                    taskConfigGroup = { label: name, options: [item] };
                  }
                  acc[id] = taskConfigGroup;
                  return acc;
                }, {});
                return Object.values(groupByTaskConfigGroup);
              },
            }}
            customComponent={{
              ValueContainer: ({ children, ...props }) => {
                const { selectProps, isMulti } = props;
                return (
                  <components.ValueContainer {...props}>
                    {isMulti && selectProps?.value?.length > 0 ? (
                      <div className="flex items-center w-full">
                        <span className="flex items-center inter-400-text natural-900-text option-text one-line">
                          {selectProps?.value?.map(o => o.label).join(', ')}
                        </span>
                      </div>
                    ) : (
                      children
                    )}
                  </components.ValueContainer>
                );
              },
              GroupHeading: props => {
                const {
                  selectProps,
                  data: { label, options },
                } = props || { selectProps, data: { label: '', options: [] } };
                const { value, onChange } = selectProps;

                const optionIds = options?.map(v => v.id) || [];
                const isAllSelected = optionIds.every(v => value?.some(o => o.id === v));

                const onCheckboxChange = () => {
                  if (isAllSelected) {
                    onChange(value.filter(v => !optionIds.includes(v.id)));
                  } else {
                    const valueWithoutOptions = value?.filter(v => !optionIds.includes(v.id)) || [];
                    onChange([...valueWithoutOptions, ...options]);
                  }
                };

                return (
                  <div
                    className="flex items-center w-full px-3 py-3 border-bottom radius-1 cursor"
                    onClick={onCheckboxChange}>
                    <Checkbox
                      variant="small"
                      is_checked_done
                      checked={isAllSelected}
                      onCheckboxChange={onCheckboxChange}
                      isStopPropagation
                    />
                    <label className={classNames('inter-400-text ml-2', isAllSelected && 'inter-500-text')}>
                      {label}
                    </label>
                  </div>
                );
              },
            }}
          />
        )}
      </div>
    );
  }

  if (!tasks?.length) {
    return (
      <div className="flex items-center bg-natural-100 radius-2 flex-1 justify-center">
        <label className="inter-400-text natural-500-text">{'No tasks'}</label>
      </div>
    );
  }

  return (
    <div className="flex-column flex-1 row-gap-4">
      {tasks?.map(task => (
        <div key={task.id} className="flex items-start pl-4 pr-2 py-3 border radius-1_5">
          <div className="flex col-gap-2 items-start flex-1">
            <label className="inter-500-text font-12 flex-1">{task.name}</label>
            <label className="inter-500-text font-12">{task?.service?.name}</label>
            <div style={{ width: '16px', height: '16px' }} />
          </div>
        </div>
      ))}
    </div>
  );
};

const ActivityDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userOrganization } = useSelector(state => state.user);

  const { showErrorToast } = useError();

  const { sideModal, setSideModal } = useContext(OrganisationContext);

  const { activity: appointment, onSuccess, onClose } = sideModal?.content;

  const [activity, setActivity] = useState(appointment);
  const [activityEdit, setActivityEdit] = useState(appointment);
  const [selectedTab, setSelectedTab] = useState('RESOURCES');
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isResourceOrTaskAdding, setIsResourceOrTaskAdding] = useState(false);
  const [services, setServices] = useState([]);
  const [availability, setAvailability] = useState([]);

  const { isNew, name, job_name: appointmentJobName, engagement, resources, start_date, end_date } = activity;
  const { property, contact } = engagement || {};
  const job_name = isNew ? appointmentJobName : engagement.name;
  const formatted_address = property?.formatted_address;

  const fetchActivityTasks = () => {
    setLoading(true);
    dispatch(getActivityTasks({ params: { activity_id: activity?.id, size: 100 } }))
      .then(data => {
        const { content } = data;
        setActivity({ ...activity, tasks: content });
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchOrganizationServices = organizations_id => {
    dispatch(getOrganizationServices({ organization_id: organizations_id }))
      .then(data => {
        const services = data.map(service => ({
          ...service,
          label: service.name,
          value: service.id,
        }));
        setServices(services);
      })
      .catch(() => {});
  };

  const fetchAvalability = services => {
    const servicesIds = services.map(service => service.id).join(',');

    dispatch(
      getAvailability({
        params: {
          ...(servicesIds && { service_id: servicesIds }),
          resource_type: 'USER',
          'activity_date.start': start_date,
          'activity_date.end': end_date,
        },
      }),
    )
      .then(data => {
        const addedResourcesIds = activityEdit.temp_resources.map(resource => resource.user.id);
        const availabilityWithoutAdded = data.filter(resource => !addedResourcesIds.includes(resource.user.id));
        setAvailability(availabilityWithoutAdded);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (isEditing && isResourceOrTaskAdding && activityEdit.service) {
      fetchAvalability([activityEdit.service]);
    }
  }, [isEditing, isResourceOrTaskAdding, activityEdit.service, activityEdit.temp_resources]);

  useEffect(() => {
    if (activity?.id) {
      fetchActivityTasks();
    }
  }, [activity?.id]);

  useEffect(() => {
    if (userOrganization?.id) {
      fetchOrganizationServices(userOrganization?.id);
    }
  }, [userOrganization?.id]);

  const onResourceOrTaskAddInEdit = () => {
    if (selectedTab === 'RESOURCES') {
      const { temp_resources } = activityEdit;
      const selectedAvailabilityResources = availability.filter(res => res.selected);
      setActivityEdit({
        ...activityEdit,
        service: null,
        resources: [...temp_resources, ...selectedAvailabilityResources],
        temp_resources: [...temp_resources, ...selectedAvailabilityResources],
      });
      setAvailability([]);
    } else {
      const { task_group, tasks } = activityEdit;
      const updatedTasksList = task_group?.map(task => ({ ...task, isNew: true }));
      setActivityEdit({ ...activityEdit, tasks: [...(tasks || []), ...updatedTasksList], task_group: [] });
    }
    setIsResourceOrTaskAdding(false);
  };

  const onSaveResources = async (resourcesToAdd, resourcesToUpdate, resourcesToRemove) => {
    if (!resourcesToAdd?.length && !resourcesToUpdate?.length && !resourcesToRemove?.length) {
      return;
    }
    try {
      if (resourcesToAdd?.length) {
        await Promise.all(
          resourcesToAdd.map(async resource => {
            const { organization, user, service, team, slot, start_date, end_date } = resource;
            const request = {
              organization: { id: organization?.id },
              team: team ? { id: team?.id } : null,
              user: { id: user?.id },
              slot: null,
              service: service ? { id: service?.id } : null,
              expected_date: {
                start_date: start_date,
                duration: moment.unix(end_date).diff(moment.unix(start_date), 'minutes'),
              },
            };
            dispatch(createActivityResorces({ request: request, activity_id: activity?.id }));
          }),
        );
      }
      if (resourcesToUpdate?.length) {
        await Promise.all(
          resourcesToUpdate.map(async resource => {
            const { organization, user, service, team, slot, start_date, end_date, status } = resource;
            const request = {
              status: status,
              organization: { id: organization?.id },
              team: team ? { id: team?.id } : null,
              user: { id: user?.id },
              slot: null,
              service: service ? { id: service?.id } : null,
              expected_date: {
                start_date: start_date,
                duration: moment.unix(end_date).diff(moment.unix(start_date), 'minutes'),
              },
            };
            dispatch(
              updateActivityResorces({ request: request, activity_id: activity?.id, resource_id: resource?.id }),
            );
          }),
        );
      }
      if (resourcesToRemove?.length) {
        await Promise.all(
          resourcesToRemove.map(async resource => {
            const { id } = resource;
            dispatch(deleteActivityResorces({ resource_id: id, activity_id: activity?.id }));
          }),
        );
      }
    } catch (error) {
      showErrorToast({ error, default_message: t('FAILED_UPDATE_RESOURCES'), id: nanoid() });
    }
  };

  const onSaveTasks = async () => {
    const { engagement, id: activity_id, start_date } = activity;
    const { tasks } = activityEdit;

    const task_group = tasks?.filter(task => task.isNew);

    try {
      if (task_group?.length) {
        await Promise.all(
          task_group.map(async (task, index) => {
            const request = {
              name: task?.name,
              description: task?.description,
              start_date: start_date,
              due_date: null,
              completed_date: null,
              status: 'TODO',
              task_type: 'SCHEDULE',
              rank: 'VERY_LOW',
              priority: index + 1,
              evidence: [],
              service: { id: task?.service?.id },
              engagement: { id: engagement?.id },
              activity: { id: activity_id },
              config: { id: task?.id },
            };
            dispatch(createTasks({ request })).catch(error => {
              showErrorToast({ error, default_message: t('FAILED_ADD_TASK'), id: nanoid() });
            });
          }),
        );
      }
    } catch (error) {
      showErrorToast({ error, default_message: t('FAILED_ADD_TASK'), id: nanoid() });
    }
  };

  const onSaveActivity = async () => {
    const { name, start_date, end_date, temp_resources: resources } = activityEdit;
    const { resources: prevResources } = activity;

    const selectedAvailabilityResources = resources.filter(res => res.selected && !res.id);
    const duration = moment.unix(end_date).diff(moment.unix(start_date), 'minutes');

    if (!resources?.length && !selectedAvailabilityResources?.length) {
      showErrorToast({ default_message: t('PLEASE_ADD_RESOURCE'), id: nanoid() });
      return;
    }

    const resourcesToRemove = prevResources.filter(
      r =>
        !resources?.some(res => res.user.id === r.user.id) &&
        !selectedAvailabilityResources?.some(res => res.user.id === r.user.id),
    );
    const resourcesToAdd = selectedAvailabilityResources.filter(
      r => !prevResources.some(res => res.user.id === r.user.id),
    );
    const resourcesToUpdate = selectedAvailabilityResources
      .filter(r => prevResources.some(res => res.user.id === r.user.id))
      .reduce((acc, resource) => {
        const prevResource = prevResources.find(res => res.user.id === resource.user.id);
        const updatedResource = {
          ...prevResource,
          start_date: resource.start_date,
          end_date: resource.end_date,
        };
        acc.push(updatedResource);
        return acc;
      }, []);

    const updateActivityRequest = {
      ...activity,
      name: name,
      expected_date: {
        start_date: start_date,
        duration: duration,
      },
    };

    setIsSaving(true);
    await onSaveResources(resourcesToAdd, resourcesToUpdate, resourcesToRemove);
    await onSaveTasks();
    dispatch(updateActivity({ activity_id: activity?.id, request: updateActivityRequest }))
      .then(data => {
        dispatch(addToast({ error: false, text: t('ACTIVITY_UPDATED_SUCCESSFULLY'), id: nanoid() }));
        onSuccess && onSuccess();
        onClose && onClose();
        setSideModal(initSideModal);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('FAILED_UPDATE_ACTIVITY'), id: nanoid() });
      })
      .finally(() => setIsSaving(false));
  };

  const renderDataWithIcon = useCallback((Icon, data, valueClassname = '') => {
    return (
      <div className="flex items-center">
        <IconContainer Icon={Icon} />
        <label className={classNames('inter-400-text ml-2', !data && 'natural-400-text', valueClassname)}>
          {data || 'N/A'}
        </label>
      </div>
    );
  }, []);

  return (
    <ActivityDetailsWrapper className="flex-1 flex-column">
      <div className="flex-column flex-1">
        <div className="pxy-6 border-bottom flex items-center justify-between">
          <label className="inter-600-text font-20 one-line">{job_name}</label>
          {isEditing ? (
            <div className="flex col-gap-4 justify-between">
              <IconContainer
                Icon={CloseIcon}
                backgroundColor={isSaving ? 'natural_50' : 'transparent'}
                iconWidth={14}
                iconHeight={14}
                iconColor={isSaving ? 'natural_400' : 'natural_900'}
                iconContainerClassname="border radius-50-percent pxy-2 cursor"
                onClick={() => {
                  if (isSaving) return;
                  setIsEditing(false);
                  setActivity(activity);
                }}
              />
              <IconContainer
                Icon={CheckIcon}
                iconWidth={14}
                iconHeight={14}
                backgroundColor={isSaving ? 'primary_300' : 'primary_500'}
                iconContainerClassname="radius-50-percent pxy-2 cursor"
                onClick={() => {
                  if (isSaving) return;
                  onSaveActivity();
                }}
              />
            </div>
          ) : (
            <div className="flex col-gap-4 justify-end">
              <IconContainer
                Icon={EditIcon}
                iconWidth={14}
                iconHeight={14}
                iconColor="primary_500"
                backgroundColor="primary_50"
                iconContainerClassname="radius-50-percent pxy-2 cursor"
                onClick={() => {
                  setIsEditing(true);
                  setActivityEdit({ ...activity, temp_resources: resources });
                }}
              />
              <IconContainer
                Icon={CloseIcon}
                iconWidth={14}
                iconHeight={14}
                iconColor="natural_900"
                backgroundColor="transparent"
                iconContainerClassname="cursor"
                onClick={() => {
                  onClose && onClose();
                  setSideModal(initSideModal);
                }}
              />
            </div>
          )}
        </div>
        <div className="flex-column row-gap-4 px-6 py-4 border-bottom">
          <ActivityName
            name={isEditing ? activityEdit?.name : name}
            isEditing={isEditing}
            updateActivity={(key, value) => setActivityEdit({ ...activityEdit, [key]: value })}
          />
          <div className="flex-column row-gap-1">
            {renderDataWithIcon(UserIcon, contact?.name)}
            {renderDataWithIcon(MobileIcon, getContactValueByType(contact?.phones || [], 'contact_type', 'MOBILE'))}
          </div>
          <ActivityTime
            isEditing={isEditing}
            activity={isEditing ? activityEdit : activity}
            updateActivity={(key, value) => setActivityEdit({ ...activityEdit, [key]: value })}
          />
          {formatted_address && renderDataWithIcon(LocationIcon, formatted_address, 'three-lines')}
        </div>
        <div className="flex-column flex-1 pxy-6">
          <div className="flex items-center justify-between border-bottom">
            <div className="flex gap-5">
              <div
                key={'RESOURCES'}
                onClick={() => {
                  setSelectedTab('RESOURCES');
                }}
                className={classNames('px-4 pb-4 pt-1 cursor', selectedTab === 'RESOURCES' && 'selected-tab')}>
                <label className="inter-500-text natural-700-text">{'Resources'}</label>
              </div>
              <div
                key={'TASKS'}
                onClick={() => {
                  setSelectedTab('TASKS');
                }}
                className={classNames('px-4 pb-4 pt-1 cursor', selectedTab === 'TASKS' && 'selected-tab')}>
                <label className="inter-500-text natural-700-text">{'Tasks'}</label>
              </div>
            </div>
            {isEditing && !isResourceOrTaskAdding && (
              <IconContainer
                Icon={AddIcon}
                iconColor="primary_500"
                backgroundColor="primary_50"
                iconContainerClassname="pxy-2 radius-50-percent cursor"
                onClick={() => setIsResourceOrTaskAdding(true)}
              />
            )}
            {isEditing && isResourceOrTaskAdding && (
              <IconContainer
                Icon={CheckIcon}
                iconWidth={14}
                iconHeight={14}
                backgroundColor={'primary_500'}
                iconContainerClassname="radius-50-percent pxy-2 cursor"
                onClick={onResourceOrTaskAddInEdit}
              />
            )}
          </div>
          {loading && (
            <div className="flex items-center bg-natural-100 radius-2 flex-1 justify-center mt-4">
              <Skeleton
                baseColor="#CDCDCD"
                highlightColor="#E8E8E8"
                height={'100%'}
                width={'100%'}
                containerClassName="line-height-1 h-full w-full"
              />
            </div>
          )}
          {!loading && selectedTab === 'RESOURCES' && (
            <div className="flex flex-1 mt-6">
              <ActivityResources
                activity={isEditing ? activityEdit : activity}
                service={isEditing ? activityEdit.service : activity.service}
                services={services}
                resources={isEditing ? activityEdit.temp_resources : resources}
                isEditing={isEditing}
                updateActivity={(key, value) => setActivityEdit({ ...activityEdit, [key]: value })}
                isResourceOrTaskAdding={isResourceOrTaskAdding}
                availability={availability}
                setAvailability={setAvailability}
              />
            </div>
          )}
          {!loading && selectedTab === 'TASKS' && (
            <div className="flex flex-1 mt-6">
              <ActivityTasks
                activity={isEditing ? activityEdit : activity}
                isEditing={isEditing}
                updateActivity={(key, value) => setActivityEdit({ ...activityEdit, [key]: value })}
                isResourceOrTaskAdding={isResourceOrTaskAdding}
              />
            </div>
          )}
        </div>
      </div>
    </ActivityDetailsWrapper>
  );
};

const ActivityDetailsWrapper = styled.div`
  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }

  .task-drag-handle {
    position: absolute;
    height: 100%;
    width: 20px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: ${({ theme }) => theme.natural_100};
    border: 1px solid ${({ theme }) => theme.natural_200};
    cursor: grab;
  }

  .task-config__menu {
    .option-wrapper {
      padding: 12px 20px;
    }
  }
`;

export default ActivityDetails;
