import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort.svg';
import { ReactComponent as InviteIcon } from '../../assets/icons/user-plus.svg';
import { ReactComponent as ConfigurationIcon } from '../../assets/images/configuration.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getUserInitials } from '../../helpers/utils';
import OwnerGroup from '../board-details/filter/owner-group';
import AvatarGroup from '../common/avatar-group';
import Button from '../common/button/button';
import InputSearch from '../common/input-search';
import Pagination from '../common/pagination/pagination';
import ProductFilter from '../product-list/filter';

const PageHeader = ({
  productFilters,
  family,
  setFamily,
  owners,
  setOwners,
  productDrawStatusFilter,
  onApplyFilters,
  onClearFilters,
  boardsFilters,
  setBoardsFilters,
  setProductFilters,
  productList,
  setProducts,
  wrapperClassName = 'pxy-6',
  names = [],
  filter = {},
  setFilter = () => {},
  searchText,
  onSearchChange,
  showFilter,
  showSearch,
  showAddNewBtn,
  filterObject = [],
  showPagination = false,
  pagination = {},
  onAddClick = () => {},
  addBtnPermission = '',
  showInviteBtn,
  inviteBtnLabel = 'Invite',
  addButtonLabel = '',
  inviteBtnPermission = '',
  onInviteClick = () => {},
  inviteIcon = '',
  headerTitle = '',
  headerTitleIcon = null,
  showConfiguration = false,
  showSort = false,
  onSortClick = () => {},
  onAvatarClick = () => {},
  showAvatars = false,
  avatars = [],
  showSearchOnLeft = false,
  showBoardsFilter = false,
  showBoardAvatars = false,
  showBoardFilterPopover = false,
  showAddProductFilter = false,
  btnClassName,
  btnIconClassName,
  onBoardFilterClick = () => {},
  onAddProductFilterClick = () => {},
  SortWrapper = ({ children }) => <>{children}</>,
  BoardOwnersWrapper = ({ children }) => <>{children}</>,
  BoardFilterWrapper = ({ children }) => <>{children}</>,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermission, checkPermission } = useContext(OrganisationContext);

  return (
    <PageHeaderWrapper
      className={classNames('flex items-center w-full border-bottom-light overflow-x-scroll', wrapperClassName)}>
      <div className={classNames('flex flex-1 items-center header-title', headerTitle && 'overflow-hidden')}>
        {headerTitleIcon && headerTitleIcon}
        {headerTitle && <label className="inter-700-text natural-900-text font-24 one-line">{headerTitle}</label>}
        {names.map((name, i) => (
          <div key={i} className="flex items-center">
            <label
              className={`${
                i < names.length - 1
                  ? 'regular-text grey-text inter-400-text'
                  : 'regular-text inter-400-text color-light-black-1'
              } cursor`}
              onClick={() => (name.onClick ? name.onClick() : name.path ? navigate(name.path) : {})}>
              {name.text}
            </label>
            {i < names.length - 1 && <label className="ml-1 mr-1 inter-400-text natural-300-text">/</label>}
          </div>
        ))}
        {showSearchOnLeft && showSearch && (
          <InputSearch
            placeholder={'Search'}
            value={searchText}
            onChange={onSearchChange}
            className="input-search-left"
          />
        )}
      </div>
      {showConfiguration && (
        <div className="flex items-center justify-center pxy-2 ml-6 cursor icon-container">
          <ConfigurationIcon />
        </div>
      )}
      {!showSearchOnLeft && showSearch && (
        <InputSearch placeholder={'Search'} value={searchText} onChange={onSearchChange} className="ml-6" />
      )}

      {showFilter && (
        <ProductFilter
          family={family}
          productDrawStatusFilter={productDrawStatusFilter}
          productFilters={productFilters}
          productList={productList}
          setFamily={setFamily}
          setProductFilters={setProductFilters}
          setProducts={setProducts}
          onApplyFilters={onApplyFilters}
          onClearFilters={onClearFilters}
        />
      )}

      {showBoardFilterPopover && (
        <BoardFilterWrapper>
          <div
            className="flex items-center justify-center h-full py-2 px-4 ml-3 cursor icon-container sort"
            onClick={onBoardFilterClick}>
            <label className="inter-500-text primary-text mr-1">{t('FILTER')}</label>
            <FilterIcon className="primary-text" />
          </div>
        </BoardFilterWrapper>
      )}

      {showSort && (
        <SortWrapper>
          <div
            className="flex items-center justify-center h-full py-2 px-4 ml-3 cursor icon-container sort"
            onClick={onSortClick}>
            <label className="inter-500-text primary-text mr-1">{t('SORT')}</label>
            <SortIcon className="primary-text" />
          </div>
        </SortWrapper>
      )}
      {showAddProductFilter && (
        <SortWrapper>
          <div
            className="flex items-center justify-center h-full py-2 px-4 ml-3 cursor icon-container sort"
            onClick={onAddProductFilterClick}>
            <label className="inter-500-text primary-text mr-1">{t('FILTER')}</label>
            <FilterIcon className="primary-text" />
          </div>
        </SortWrapper>
      )}
      {showInviteBtn && (
        <Button
          fontSize="14px"
          size="medium"
          width="auto"
          height="40px"
          borderRadius="100px"
          className={`primary specified-width ml-3 ${!hasPermission(inviteBtnPermission) && 'disabled'}`}
          style={{ padding: '10px 16px' }}
          afterIcon={inviteIcon ? inviteIcon : <InviteIcon className="after-icon white-text" height={16} width={16} />}
          label={inviteBtnLabel}
          onClick={() => checkPermission(() => onInviteClick(), inviteBtnPermission)}
        />
      )}

      {showBoardAvatars && (
        <BoardOwnersWrapper>
          <OwnerGroup
            groupWrapperClassname="ml-3"
            avatars={avatars}
            getAvatarName={avatar => getUserInitials(avatar ?? {})}
            onAvatarClick={onAvatarClick}
            boardsFilters={boardsFilters}
            setBoardsFilters={setBoardsFilters}
          />
        </BoardOwnersWrapper>
      )}

      {showAvatars && (
        <AvatarGroup
          groupWrapperClassname="ml-3"
          avatars={avatars}
          getAvatarName={avatar => getUserInitials(avatar.user ?? {})}
        />
      )}
      {showAddNewBtn && (!addBtnPermission || hasPermission(addBtnPermission)) && (
        <Button
          fontSize="14px"
          size="average"
          width="auto"
          borderRadius="100px"
          className={classNames('primary specified-width px-4 py-2_5 ml-3', btnClassName)}
          afterIcon={
            <AddIcon className={classNames('after-icon white-text', btnIconClassName)} height={13} width={13} />
          }
          label={addButtonLabel ?? t('ADD_NEW')}
          onClick={() => checkPermission(() => onAddClick(), addBtnPermission)}
        />
      )}
      {showPagination && (
        <Pagination
          className="ml-6"
          selectedPage={pagination.selectedPage}
          setSelectedPage={page => {
            pagination.setSelectedPage && pagination.setSelectedPage(page);
          }}
          totalPages={pagination.totalPages}
        />
      )}
    </PageHeaderWrapper>
  );
};

const PageHeaderWrapper = styled.div`
  .header-title {
    min-width: 150px;
  }

  .icon-container {
    background: ${({ theme }) => theme.primary_50};
    border-radius: 100px;
  }

  .invite-button {
    background: #2496ff;
    padding: 10px 16px;

    label {
      color: #fff;
    }
  }

  .filter,
  .sort {
    height: 40px;
  }

  .input-search {
    .input {
      width: 280px;
    }
  }

  .input-search-left {
    .input {
      width: 340px;
    }
  }

  .draw-count-down {
    background: ${({ theme }) => theme.ZenPositiveSecondary};
    border: 1px solid ${({ theme }) => theme.ZenPositive};
    border-radius: 16px;
    padding: 10px 24px;
  }
`;

export default PageHeader;
