import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/refresh-icon.svg';
import { ReactComponent as RightIcon } from '../../assets/images/arrow-right.svg';
import { ReactComponent as PencilIcon } from '../../assets/images/edit-icons.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText, getFormattedNumberStyle, initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getPropertyDetails } from '../../store/features/propertySlice';
import {
  createEvidence,
  getDesignImages,
  getEventLogEvidence,
  getEvidenceConfigs,
  getSolarHourlyData,
  getSolarOerviewData,
  getSystemDetailsData,
  sendQuote,
} from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import CustomTooltip from '../common/tooltip-new';
import SystemPPA from './system-ppa';
import SystemQuote from './system-quote';
import ViewPdf from './view-pdf';

const CustomDesignOption = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={classNames(
        'px-4 py-3 inter-400-text font-14 line-height-20 flex-column cursor row-gap-1',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <label className="natural-900-text inter-500-text two-lines">{data.name}</label>
      <label className="natural-500-text inter-400-text font-12">{data.quote?.name}</label>
    </div>
  );
};

const PanelInverterBatteryDetails = ({
  design_data,
  designMultiOptions,
  initialSelectedOption,
  setMultiDesign,
  multiDesign,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { panels, inverters, batteries } = design_data || {};

  return (
    <div className="flex flex-1 col-gap-2 justify-end">
      <div className="flex col-gap-4 radius-1 bg-natural-50 px-4 py-3 items-center">
        <div className="flex items-center col-gap-4 flex-1">
          <label className="inter-400-text natural-500-text flex-1 font-14 line-height-20">Panels</label>
          <label className="inter-500-text natural-900-text font-14 line-height-20">{panels || '0'}</label>
        </div>
        <div className="vertical-line" />
        <div className="flex items-center col-gap-4 flex-1">
          <label className="inter-400-text natural-500-text flex-1 font-14 line-height-20">Invertors</label>
          <label className="inter-500-text natural-900-text font-14 line-height-20">{inverters || '0'}</label>
        </div>
        <div className="vertical-line" />

        <div className="flex items-center col-gap-4 flex-1">
          <label className="inter-400-text natural-500-text flex-1 font-14 line-height-20">Batteries</label>
          <label className="inter-500-text natural-900-text font-14 line-height-20">{batteries || '0'}</label>
        </div>
      </div>
      {/* <Dropdown
        className="w-fit-content design-selector-dropdown"
        options={designMultiOptions}
        placeholder={t('SELECT_DESIGN')}
        value={initialSelectedOption}
        onChange={option => {
          setMultiDesign({ ...multiDesign, designpanel: option });
        }}
        customStyle={{
          control: {
            width: '182px',
            height: '40px',
            minHeight: '40px',
            fontSize: '14px',
            backgroundColor: theme.natural_100,
            borderColor: theme.natural_200,
          },
          menu: { minWidth: '330px' },
        }}
        customComponent={{ Option: CustomDesignOption }}
      /> */}
    </div>
  );
};

const HeaderView = ({ systemDetails, sloarDetails, solar_id_param }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { design_data } = systemDetails || {};
  const [multiDesign, setMultiDesign] = useState({});

  useEffect(() => {
    const currentPath = location.pathname;
    const parts = currentPath.split('/');
    const currentUrlId = parts[parts.length - 1];
    const selectedPanelId = multiDesign?.designpanel?.id;

    if (selectedPanelId && currentUrlId !== selectedPanelId) {
      parts[parts.length - 1] = selectedPanelId;
      const newPath = parts.join('/');
      navigate(newPath);
    }
  }, [location, multiDesign, navigate]);

  const designMultiOptions = sloarDetails?.map(design => ({ ...design, label: design.name, value: design.id }));
  const initialSelectedOption = designMultiOptions?.find(option => option.id === solar_id_param);

  const renderSystemDetails = (label, value, valueMetric) => {
    return (
      <div className="flex-column radius-1 bg-natural-50 px-4 py-3 row-gap-1">
        <label className="inter-400-text natural-500-text font-12 one-line">{label}</label>
        <div className="flex items-center col-gap-1">
          <label className="inter-700-text font-28 natural-900-text line-height-36">{value}</label>
          <label className="inter-700-text font-24 natural-900-text line-height-32">{valueMetric}</label>
        </div>
      </div>
    );
  };

  return (
    <HeaderWrapper className="flex-column bg-white w-full radius-3 pxy-6 row-gap-4">
      <div className="flex items-center justify-between">
        <label className="inter-600-text font-20">{t('SYSTEM_DETAILS')}</label>
        <PanelInverterBatteryDetails
          design_data={design_data}
          designMultiOptions={designMultiOptions}
          initialSelectedOption={initialSelectedOption}
          setMultiDesign={setMultiDesign}
          multiDesign={multiDesign}
        />
      </div>
      <div className="flex-1 col-gap-2 system-header-details">
        {renderSystemDetails(
          t('SYSTEM_SIZE_DC'),
          getFormattedNumberStyle(design_data?.system_size || '0', 'decimal', 2, 2),
          'kW',
        )}
        {renderSystemDetails(
          t('ANNUAL_PRODUCTION_MCS'),
          getFormattedNumberStyle(design_data?.annual_production || '0', 'decimal', 0, 0),
          'kWh',
        )}
        {renderSystemDetails(
          t('ANNUAL_EXPORT'),
          getFormattedNumberStyle(design_data?.annual_export || '0', 'decimal', 0, 0),
          'kWh',
        )}
        {renderSystemDetails(
          t('ANNUAL_CONSUMPTION'),
          getFormattedNumberStyle(design_data?.autoconsumption || '0', 'decimal', 0, 0),
          'kWh',
        )}
      </div>
    </HeaderWrapper>
  );
};

const CardView = ({ systemDetails, updateSystemDetailsQuotes, solar_id_param, fetchSytemDetails }) => {
  const { t } = useTranslation();

  const { propertyDetails } = useSelector(state => state.property);
  const { property_class } = propertyDetails || {};
  const { class_type } = property_class || {};

  const [selectedTab, setSelectedTab] = useState('QUOTE');

  const isResidential = useMemo(() => class_type === 'RESIDENTIAL', [class_type]);

  const tabs = useMemo(
    () =>
      [
        { key: 'QUOTE', name: t('QUOTE') },
        { key: 'PPA', name: t('PPA'), hidden: isResidential },
      ].filter(tab => !tab.hidden),
    [isResidential],
  );

  return (
    <CardViewWrapper className="flex-column flex-1 bg-white w-full radius-3 py-6 overflow-hidden">
      <div className="flex gap-5 border-bottom mx-6">
        {tabs.map(tab => (
          <div
            key={tab.key}
            onClick={() => {
              setSelectedTab(tab.key);
            }}
            className={classNames('pb-4 px-2 cursor', selectedTab === tab.key && 'selected-tab')}>
            <label className="inter-500-text natural-700-text">{tab.name}</label>
          </div>
        ))}
      </div>
      {selectedTab === 'PPA' && (
        <div className="flex-column flex-1 overflow-auto custom-scrollbar thin-scrollbar px-6">
          <SystemPPA solar_id={solar_id_param} systemDetails={systemDetails} />
        </div>
      )}
      {selectedTab === 'QUOTE' && (
        <div className="flex-column flex-1 overflow-hidden">
          <SystemQuote
            systemDetails={systemDetails}
            updateSystemDetailsQuotes={updateSystemDetailsQuotes}
            fetchSytemDetails={fetchSytemDetails}
          />
        </div>
      )}
    </CardViewWrapper>
  );
};

const ChartView = ({ data, loadingGraph, showConsumptionMSG }) => {
  const theme = useTheme();

  const dataWithNetConsumption = useMemo(() => {
    return data?.map(item => ({
      ...item,
      net_consumption: -item.grid,
    }));
  }, [data]);

  return (
    <ResponsiveContainer className={'mt-8'} width="100%" height={250}>
      {loadingGraph ? (
        <Skeleton height={110} />
      ) : dataWithNetConsumption?.length > 0 ? (
        <LineChart data={dataWithNetConsumption} margin={{ left: -50, right: 10 }}>
          <CartesianGrid vertical={false} strokeDasharray="0" stroke="#ECECEC" />
          <XAxis
            tickFormatter={value => {
              return value;
            }}
            tickLine={false}
            axisLine={false}
            tick={{ fontSize: 10, fill: '#404040' }}
          />
          <YAxis
            type="number"
            domain={([_dataMin, dataMax]) => {
              return [-(dataMax + 0.2), dataMax + 0.2];
            }}
            allowDataOverflow={true}
            width={85}
            tickMargin={-2}
            allowDecimals={true}
            tick={{ fontSize: 10, fill: '#404040' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={value => {
              return getFormattedNumberStyle(value, 'decimal', 2, 2);
            }}
          />
          <Tooltip
            labelFormatter={value => {
              return `${
                value === 0 ? '12 AM' : value < 12 ? `${value} am` : `${value - 12 === 0 ? 12 : value - 12} pm`
              }`;
            }}
            labelStyle={{
              color: theme.natural_900,
              fontSize: '14px',
              fontFamily: 'Inter',
            }}
            position={{ x: 30, y: -180 }}
            reverseDirection={{ x: true, y: true }}
            formatter={(value, name) => [`${name}: ${value.toFixed(2)}`]}
            contentStyle={{ borderRadius: '6px', fontSize: '14px', fontFamily: 'Inter' }}
            wrapperStyle={{ zIndex: 20 }}
          />
          <Legend
            iconType="circle"
            divisor={2}
            verticalAlign="bottom"
            formatter={value => {
              return <span className="inter-400-text font-12">{capitalize(formatText(value, ' '))}</span>;
            }}
            wrapperStyle={{
              bottom: '0px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              justifyContent: 'between',
              alignItems: 'center',
            }}
          />
          <Line type="natural" dot={false} dataKey="consumption" stroke={theme.primary_500} />
          <Line type="natural" dot={false} dataKey="generation" stroke={theme.error_500} />
          <Line type="natural" dot={false} dataKey="net_consumption" stroke={theme.success_500} />
          <Line type="natural" dot={false} dataKey="grid" stroke={theme.orange_500} />
          <Line type="natural" dot={false} dataKey="battery" stroke={theme.purple_500} />
        </LineChart>
      ) : showConsumptionMSG?.length > 0 ? (
        <div className="w-full flex items-center justify-center text-center" style={{ height: '200px' }}>
          <label className="inter-500-text natural-500-text font-12">{showConsumptionMSG}</label>
        </div>
      ) : (
        ''
      )}
    </ResponsiveContainer>
  );
};

const ImageSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % images?.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + images?.length) % images?.length);
  };

  return (
    <SliderWrapper className="slider relative">
      <div className="image-slider">
        {images?.length > 1 && (
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
        )}
        <img
          src={images[currentSlide]?.media?.url}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt={`Slide ${currentSlide}`}
        />
        {images?.length > 1 && (
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
        )}
      </div>
    </SliderWrapper>
  );
};

const RightDetailsView = ({
  hourlySolarGraphData,
  eventLogData,
  engagement_id,
  solar_id_param,
  selectedMonth,
  setSelectedMonth,
  loadingGraph,
  designImages,
  showConsumptionMSG,
  refetchEventLogEvidence,
  systemDetails,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { board_id, workitem_id, quote_id } = useParams();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { setModal } = useContext(OrganisationContext);
  const { propertyDetails } = useSelector(state => state.property);
  const { property_class } = propertyDetails || {};
  const { class_type } = property_class || {};
  const { quote } = systemDetails || {};
  const { status, code, recipients } = quote || {};
  const recipient = (recipients && recipients[0]?.contact) || {};
  const isResidential = useMemo(() => class_type === 'RESIDENTIAL', [class_type]);
  const isEstimate = useMemo(() => status === 'ESTIMATE', [status]);

  const latestData = eventLogData?.content?.sort((a, b) => b.performed_on - a.performed_on);
  const latestEvent = latestData && latestData[0];
  const lastSentDate = latestEvent?.performed_on ? moment.unix(latestEvent?.performed_on).format('DD.MM.YYYY') : null;

  const graphDate = moment().month(selectedMonth).format('MMM YYYY');

  const [evidenceConfigData, setEvidenceConfigData] = useState();
  const [selectEvidence, setSelectEvidence] = useState({});
  const [generatePdfData, setGeneratePdfData] = useState({});
  const [evidencLoading, setEvidenceLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const fetchEvidenceConfig = () => {
    setEvidenceLoading(true);
    dispatch(getEvidenceConfigs({ params: { evidence_category_tag: 'SOLAR', evidence_config_tag: 'PROPOSAL' } }))
      .then(data => {
        setEvidenceConfigData(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_EVIDENCE_CONFIG') }))
      .finally(() => setEvidenceLoading(false));
  };

  const handleSelectEvidence = data => {
    setEvidenceLoading(true);
    setSelectEvidence(data);
    setTooltipOpen(false);
    const request = {
      evidence_config: {
        id: data?.id,
      },
    };
    dispatch(addToast({ error: false, text: t('GENERATEING_PDF_WAIT'), id: 'GENERATEING_PDF' }));
    dispatch(createEvidence({ engagementId: engagement_id, designId: solar_id_param, request }))
      .then(data => {
        const { status } = data || { status: null };
        if (status === 'FAILED') {
          showErrorToast({ default_message: t('FAILED_TO_GENERATE_PDF') });
          setEvidenceLoading(false);
          setGeneratePdfData({});
        } else {
          setGeneratePdfData(data);
        }
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('FAILED_TO_GENERATE_PDF') });
        setEvidenceLoading(false);
      });
  };

  const onShareProposal = () => {
    setModal({
      type: 'share-proposal',
      content: {
        eventLogData: eventLogData,
        solar_id: solar_id_param,
        property_id: propertyDetails.id,
        onSuccess: () => {
          refetchEventLogEvidence();
        },
      },
    });
  };

  const onEditDesign = () => {
    const { pathname } = location;
    const pathNameWithoutSystem = pathname.split('/system-overview')?.[0];
    navigate(pathNameWithoutSystem);
  };

  const onTakePhoto = () => {
    const { pathname } = location;
    const pathNameWithoutSystem = pathname.split('/system-overview')?.[0];
    navigate(`${pathNameWithoutSystem}?design_id=${solar_id_param}&take_photo=true`);
  };

  useEffect(() => {
    fetchEvidenceConfig();
  }, []);

  function sendQuoteToRecipient() {
    dispatch(sendQuote({ quote_id: quote?.id }))
      .then(data => {
        dispatch(addToast({ error: false, text: t('QUOTE_CODE_HAS_BEEN_SENT', { code: code }), id: nanoid() }));
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_QUOTE_STATUS') });
      })
      .finally(() => {});
  }

  const sendQuoteModal = () => {
    setModal({
      type: 'send-quote',
      content: {
        recipient,
        sendQuoteToRecipient,
        onSuccess: () => setModal(initModal),
      },
    });
  };

  return (
    <RightDetailsWrapper className="flex-1 bg-white pxy-6 radius-3 min-h-full h-fit-content max-h-full overflow-scroll custom-scrollbar thin-scrollbar">
      <div className="flex items-center justify-between border-bottom pb-2">
        <label className="inter-600-text font-16">Design Details</label>
        <Button
          label={t('EDIT_DESIGN')}
          className="secondary specified-width"
          size="medium"
          afterIcon={<PencilIcon className="primary-500-text" width={14} height={14} />}
          onClick={onEditDesign}
          fontSize="12px"
          width="120px"
        />
      </div>
      <div className="flex-column items-center row-gap-2 mt-5">
        <div className="flex items-center gap-3 w-full relative">
          <CustomTooltip
            wrapperClassName="evidence-tooltip w-full"
            tooltipClassname="custom-scrollbar thin-scrollbar"
            id={'generate-pdf-tooltip'}
            place="bottom"
            clickable
            isOpen={tooltipOpen}
            onBlurTooltip={() => setTooltipOpen(false)}
            hidden={!tooltipOpen}
            noArrow={true}
            content={evidenceConfigData?.map((data, index) => (
              <div
                key={data?.id}
                onClick={() => handleSelectEvidence(data)}
                className="px-4 py-3 font-14 border-bottom cursor proposal-config">
                <label className="inter-400-text natural-900-text">{data?.name}</label>
              </div>
            ))}>
            <Button
              label={t('GENERATE_PDF')}
              className="primary"
              size="medium"
              disabled={evidenceConfigData?.length <= 0}
              loading={evidencLoading}
              afterIcon={<RefreshIcon className="white-text" width={14} height={14} />}
              onClick={() => setTooltipOpen(true)}
              fontSize="12px"
            />
          </CustomTooltip>
          <div className="w-full">
            <ViewPdf
              design_id={solar_id_param}
              generatePdfData={generatePdfData}
              onPdfGeneratedFailed={() => {
                showErrorToast({ default_message: t('FAILED_TO_GENERATE_PDF') });
                setEvidenceLoading(false);
                setGeneratePdfData({});
              }}
              onPdfGeneratedFinished={() => {
                dispatch(addToast({ error: false, text: t('PDF_GENERATED_SUCCESSFULLY') }));
                setEvidenceLoading(false);
                setGeneratePdfData({});
              }}
              onPdfGenerationStart={() => {
                setEvidenceLoading(true);
              }}
            />
          </div>
        </div>
        <Button
          label={t('SHARE_PROPOSAL')}
          className="secondary"
          size="medium"
          afterIcon={<MailIcon className="primary-500-text" width={14} height={14} />}
          onClick={onShareProposal}
          fontSize="12px"
        />
        {lastSentDate && (
          <div className="bg-natural-100 px-2 py-0_5 flex justify-between items-center w-full gap-6 radius-full">
            <div className="inter-400-text natural-500-text font-10 line-height-20 one-line flex items-center gap-1">
              <lable>{t('LAST_SENT_TO')}</lable>
              <CustomTooltip
                wrapperClassName="lastsent-tooltip one-line"
                id={1}
                place="bottom"
                noArrow={true}
                content={
                  <div className="flex-column row-gap-1">
                    <label className="inter-400-text natural-500-text font-10">{latestEvent?.event_data?.email}</label>
                  </div>
                }>
                <label className="one-line"> {latestEvent?.event_data?.email}</label>
              </CustomTooltip>
            </div>
            <label className="inter-400-text natural-500-text font-10">{lastSentDate || 'No data'}</label>
          </div>
        )}
        {isResidential && isEstimate && (
          <Button
            label={t('SEND_ESTIMATE')}
            className="secondary mt-2"
            size="medium"
            onClick={sendQuoteModal}
            fontSize="12px"
          />
        )}
      </div>
      {designImages?.length > 0 && (
        <div className="flex-column items-center row-gap-2 mt-5">
          <ImageSlider images={designImages} />
          <div className="flex items-center justify-between bg-natural-50 radius-3 w-full">
            <label className="inter-600-text natural-700-text font-14 flex-1">{t('AREAL_LANDSCAPE')}</label>
            <Button
              label={t('RE_TAKE')}
              className="specified-width"
              bgColor="transparent"
              borderColor={theme.primary_500}
              color={theme.primary_500}
              width="80px"
              height="25px"
              size="medium"
              onClick={onTakePhoto}
              fontSize="12px"
              afterIcon={<CameraIcon />}
            />
          </div>
        </div>
      )}
      {!designImages?.length && (
        <div className="flex items-center justify-center bg-natural-50 radius-3 take-photo-wrapper mt-5">
          <Button
            label={t('TAKE_PHOTO')}
            className="specified-width"
            bgColor="transparent"
            borderColor={theme.primary_500}
            color={theme.primary_500}
            width="140px"
            size="medium"
            onClick={onTakePhoto}
            afterIcon={<CameraIcon />}
          />
        </div>
      )}
      <div className="border-bottom pt-5 mb-5"></div>
      <div className="flex-column col-gap-2">
        <div className="flex flex-1 items-center">
          <IconContainer
            disabled={selectedMonth === 0}
            Icon={RightIcon}
            iconContainerClassname="cursor"
            iconClassName="rotate-180"
            onClick={() => setSelectedMonth(selectedMonth - 1)}
          />

          <label className="inter-600-text natural-700-text font-14 flex-1 text-center">{graphDate}</label>
          <IconContainer
            Icon={RightIcon}
            disabled={selectedMonth === 11}
            iconContainerClassname="cursor"
            onClick={() => setSelectedMonth(selectedMonth + 1)}
          />
        </div>
        <ChartView data={hourlySolarGraphData} loadingGraph={loadingGraph} showConsumptionMSG={showConsumptionMSG} />
      </div>
    </RightDetailsWrapper>
  );
};

const SystemOverview = ({ property_id, fromProperty }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { property_id: property_id_param, solar_id: solar_id_param } = useParams();
  const { workitemDetails } = useSelector(state => state.board);
  const { sloarDetails } = useSelector(state => state.quotes);

  const propertyId = fromProperty ? property_id_param : property_id;
  const engagement_id = workitemDetails?.engagement?.id;

  const [propertyDetailsLoading, setPropertyDetailsLoading] = useState(true);
  const [hourlySolarGraphData, setHourlySolarGraphData] = useState([]);
  const [systemDetails, setSystemDetails] = useState({});
  const [eventLogData, setEventLogData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [loading, setLoading] = useState(true);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [showConsumptionMSG, setShowConsumptionMSG] = useState('');
  const [designImages, setDesignImages] = useState([]);

  const fetchHourlyData = () => {
    setLoadingGraph(true);
    dispatch(
      getSolarHourlyData({
        engagementId: engagement_id,
        designId: solar_id_param,
        params: {
          month: selectedMonth + 1,
        },
      }),
    )
      .then(data => {
        setHourlySolarGraphData(data);
      })
      .catch(() => setShowConsumptionMSG(t('UPLOAD_CONSUMPTION_VALUE')))
      .finally(() => setLoadingGraph(false));
  };

  const fetchSytemDetails = async () => {
    await dispatch(
      getSystemDetailsData({
        engagementId: engagement_id,
        designId: solar_id_param,
      }),
    )
      .then(data => {
        setSystemDetails(data);
      })
      .catch(error => {
        setSystemDetails({});
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_SYSTEM_DETAILS') });
      });
  };

  const fetchEventLogEvidence = async () => {
    await dispatch(
      getEventLogEvidence({
        params: {
          event_type: `EVIDENCE_EMAIL`,
          'entity.SOLAR_DESIGN': solar_id_param,
        },
      }),
    )
      .then(data => {
        setEventLogData(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_EVENT_LOG') }));
  };

  const fetchSolarOverviewData = async () => {
    await dispatch(getSolarOerviewData({ engagement_id }))
      .then(data => {})
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_SOLAR_DESIGNS') }));
  };

  const fetchDesignImages = async () => {
    await dispatch(getDesignImages({ engagement_id: engagement_id, solar_design_id: solar_id_param }))
      .then(data => {
        setDesignImages(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_DESIGN_IMAGES') }));
  };

  const fetchAllData = async () => {
    try {
      setLoading(true);
      await fetchSytemDetails();
      await fetchEventLogEvidence();
      await fetchSolarOverviewData();
      await fetchDesignImages();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (engagement_id && solar_id_param) {
      fetchAllData();
    }
  }, [engagement_id, solar_id_param]);

  useEffect(() => {
    if (engagement_id && solar_id_param) {
      fetchHourlyData();
    }
  }, [engagement_id, solar_id_param, selectedMonth]);

  const fetchPropertyDetails = property_id => {
    setPropertyDetailsLoading(true);
    dispatch(getPropertyDetails({ property_id: property_id }))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_DETAILS') }))
      .finally(() => setPropertyDetailsLoading(false));
  };

  useEffect(() => {
    const propertyId = fromProperty ? property_id_param : property_id;
    if (propertyId) {
      if (fromProperty) {
        fetchPropertyDetails(propertyId);
      } else {
        setPropertyDetailsLoading(false);
      }
    }
  }, [property_id_param, fromProperty, property_id]);

  const updateSystemDetailsQuotes = newQuoteData => {
    setSystemDetails({ ...systemDetails, quote: newQuoteData });
  };

  return (
    <SystemOverviewWrapper className="flex flex-1 col-gap-4 pxy-4 overflow-y-hidden overflow-x-scroll custom-horizontal">
      {loading ? (
        <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
      ) : (
        <Fragment>
          <div className="flex-column items-center row-gap-4 flex-1">
            <HeaderView systemDetails={systemDetails} sloarDetails={sloarDetails} solar_id_param={solar_id_param} />
            <CardView
              systemDetails={systemDetails}
              updateSystemDetailsQuotes={updateSystemDetailsQuotes}
              solar_id_param={solar_id_param}
              fetchSytemDetails={fetchSytemDetails}
            />
          </div>
          <RightDetailsView
            hourlySolarGraphData={hourlySolarGraphData}
            showConsumptionMSG={showConsumptionMSG}
            engagement_id={engagement_id}
            solar_id_param={solar_id_param}
            eventLogData={eventLogData}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            loadingGraph={loadingGraph}
            designImages={designImages}
            refetchEventLogEvidence={fetchEventLogEvidence}
            systemDetails={systemDetails}
          />
        </Fragment>
      )}
    </SystemOverviewWrapper>
  );
};

const SystemOverviewWrapper = styled.div`
  background-color: ${({ theme }) => theme.natural_100};
  .vertical-line {
    height: 20px;
    width: 1px;
    background-color: ${({ theme }) => theme.natural_300};
  }

  .lastsent-tooltip {
    .custom-tooltip {
      padding: 6px 12px;
      box-shadow: 2px 8px 20px rgba(5, 49, 73, 0.14);
    }
  }

  .evidence-tooltip {
    .custom-tooltip {
      padding: 0;
      width: 276px;
      height: auto;
      top: 38px !important;
      right: 0 !important;
      left: 0 !important;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }

    .proposal-config {
      &:hover {
        background-color: ${({ theme }) => theme.natural_100};
      }
    }
  }
`;

const SliderWrapper = styled.div`
  height: 200px;
  width: 100%;
  .image-slider {
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .prev,
    .next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: #f5f5f5;
      color: #404040;
      border-radius: 50%;
      font-size: 12px;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
    }

    .prev {
      left: -12px;
    }

    .next {
      right: -12px;
    }
  }
`;

const HeaderWrapper = styled.div`
  .system-header-details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const RightDetailsWrapper = styled.div`
  min-width: 324px;
  max-width: 324px;

  .take-photo-wrapper {
    height: 200px;
  }
`;

const CardViewWrapper = styled.div`
  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }
  .table-header-ppa {
    background-color: ${({ theme }) => theme.natural_50};
  }

  .table-header-ppa {
    height: 100%;
    min-height: 32px;
    display: grid;
    grid-template-columns: 2fr 200px 200px 200px;
    column-gap: 40px;
    padding: 6px 24px;
    position: relative !important;
  }

  .input-width {
    width: 96px;
  }

  .slider-range {
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider-range:hover {
    opacity: 1;
  }

  .slider-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #2496ff;
    cursor: pointer;
  }

  .slider-range::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #2496ff;
    border: 1px solid white;
    cursor: pointer;
  }
`;

export default SystemOverview;
