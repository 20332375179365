import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { MessageProvider } from '../../context/MessageContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { useAblyMessage } from '../../hooks/useAblyMessage';
import { useError } from '../../hooks/useError';
import {
  getBoardAccessList,
  getBoardStageList,
  setBoardAccessList,
  setBoardStageList,
} from '../../store/features/boardSlice';
import SkeletonTransition from '../common/skeleton-transition';
import BoardStages from './board-stages';

const BoardDetails = ({ debouncedSearch = '', boardsFilters, orderBy, sortBy }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { board_id } = useParams();

  const { showErrorToast } = useError();

  const user = getItemFromLocalStorage('user');
  const userSassProvider = user?.saas_provider;

  const { boardStageList } = useSelector(state => state.board);

  const [loading, setLoading] = useState(true);

  const message = useAblyMessage({ channel_name: `board:${userSassProvider?.id}:${board_id}` });

  const fetchBoardStageList = () => {
    setLoading(true);
    dispatch(getBoardStageList({ board_id: board_id }))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_BOARD_DETAILS') }))
      .finally(() => setLoading(false));
  };

  const fetchBoardAccessList = () => {
    dispatch(getBoardAccessList({ board_id: board_id })).catch(error =>
      showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_BOARD_ACCESS') }),
    );
  };

  useEffect(() => {
    fetchBoardStageList();
    fetchBoardAccessList();
    return () => {
      dispatch(setBoardStageList([]));
      dispatch(setBoardAccessList([]));
    };
  }, [board_id]);

  return (
    <BoardDetailsWrapper className="flex-column flex-1  radius-1 mt-6 mx-6 overflow-hidden">
      <MessageProvider value={{ message }}>
        <SkeletonTransition
          loading={loading}
          customLoaderComponent={
            <div className="item flex items-center justify-center flex-1 col-gap-4">
              <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
              <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
              <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
              <Skeleton height={'100%'} containerClassName="line-height-1 h-full flex-1" />
            </div>
          }>
          {(boardStageList || []).length > 0 ? (
            <BoardStages
              debouncedSearch={debouncedSearch}
              boardsFilters={boardsFilters}
              orderBy={orderBy}
              sortBy={sortBy}
            />
          ) : (
            <Fragment />
          )}
        </SkeletonTransition>
      </MessageProvider>
    </BoardDetailsWrapper>
  );
};

export const BoardDetailsWrapper = styled.div``;

export default BoardDetails;
