import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../assets/icons/CrossIcons.svg';
import Button from '../components/common/button/button';
import InputElement from '../components/common/input';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../helpers/utils';
import { useError } from '../hooks/useError';
import { createNewTeam, getServices, getTeamsList } from '../store/features/teamsManagmentSlice';
import { addToast } from '../store/features/toastSlice';

const AddNewTeam = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showErrorToast } = useError();

  const { services } = useSelector(state => state.teamManagement);

  const { modal, setModal } = useContext(OrganisationContext);
  const { title } = modal?.content || {};

  const [team, setTeam] = useState({});
  const [error, setError] = useState({ name: false });
  const [loading, setLoading] = useState(false);

  const optionValue =
    services &&
    services.map(({ id, name, ...rest }) => ({
      value: id,
      label: name,
      ...rest,
    }));

  const checkAddNewTeamErrors = () => {
    if (!team?.name?.trim() || !team?.description?.trim()) {
      setError({
        name: !team?.name?.trim(),
        description: !team?.description?.trim(),
      });
      showErrorToast({ default_message: t('ADD_NEW_TEAM_VALIDATION') });
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkAddNewTeamErrors()) {
      return;
    }
    setLoading(true);
    const request = {
      name: team.name,
      description: team.description,
      services: null,
      is_enabled: false,
      is_default: false,
    };
    dispatch(createNewTeam({ request }))
      .then(({ data }) => {
        dispatch(
          addToast({
            error: false,
            text: t('TEAM_ADDED'),
            id: uuid(),
          }),
        );
        dispatch(getTeamsList({ params: { search: null, page: 0, size: 15 } }));
        setLoading(false);
        setModal(initModal);
        navigate(`/settings/security/teams/${data?.id}`);
      })
      .catch(error => {
        setLoading(false);
        showErrorToast({ error, default_message: t('ERROR_WHILE_ADDING_TEAM') });
      });
  };

  useEffect(() => {
    dispatch(getServices());
  }, []);

  return (
    <AddNewTeamWrapper className="flex-column items-center py-9 px-10">
      <div className="w-full flex justify-between items-center">
        <label className="font-20 inter-600-text text-black text-start line-height-28">{title}</label>
        <CrossIcon className="cursor" onClick={() => setModal(initModal)} width={24} height={24} />
      </div>

      <div className="w-full flex-column gap-5px mt-8">
        <InputElement
          name={t('TEAM_NAME')}
          onChange={value => {
            setTeam({ ...team, name: value });
            setError({ ...error, name: false });
          }}
          error={error.name}
          placeholder={t('TYPE_TEAM_FULL_NAME')}
          value={team?.name}
          className={`${error.name && 'error-border'}`}
        />
      </div>
      <div className="w-full flex-column gap-5px mt-6">
        <InputElement
          name={t('DESCRIPTION')}
          onChange={value => {
            setTeam({ ...team, description: value });
            setError({ ...error, description: false });
          }}
          placeholder={t('DESCRIPTION_PLACEHOLDER_TEAM')}
          rows={4}
          error={error.description}
          value={team.description || ''}
          type="textarea"
        />
      </div>

      <div className="flex col-gap-5 justify-center mt-12 w-full">
        <Button
          className={`default flex-1 `}
          label={t('CANCEL')}
          disabled={loading}
          onClick={() => setModal(initModal)}
          secondary={true}
          size="large"
          borderRadius="100px"
        />
        <Button
          className={`primary flex-1`}
          label={t('ADD')}
          disabled={loading}
          onClick={onDone}
          size="large"
          borderRadius="100px"
        />
      </div>
    </AddNewTeamWrapper>
  );
};

const AddNewTeamWrapper = styled.div`
  width: 455px;

  .error-border {
    border-color: ${({ theme }) => theme.additionalRed};

    .organisations-border {
      border-color: ${({ theme }) => theme.additionalRed} !important;
    }
  }

  .input,
  .textarea {
    color: #171717;
  }
`;

export default AddNewTeam;
