import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as Alertdisable } from '../assets/icons/Alertdisable.svg';
import { ReactComponent as Checkmark } from '../assets/images/check.svg';
import Checkbox from '../components/common/checkbox';
import InputElement from '../components/common/input';
import { OrganisationContext } from '../context/organisationContext';
import { capitalize, formatText, initSideModal, uppercase } from '../helpers/utils';
import { useError } from '../hooks/useError';
import { UpdateUserRole, getPermissionGroups, getRolePermissions } from '../store/features/roleUserSlice';
import { addToast } from '../store/features/toastSlice';
import { updateUserRole } from '../store/features/userManagementSlice';
import SideModelHeader from './sidemodel-header';

const RoleDetails = ({ detailsData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { userOrganization } = useSelector(state => state.user);
  const { role: userRole } = userOrganization;

  const { setSideModal } = useContext(OrganisationContext);

  const { RolePermission, PermissionTick } = useSelector(state => state?.rolesUser);

  const { roles: roleDetails, onDeleteRole } = detailsData || {};

  const [showSave, setShowSave] = useState(false);

  const [role, setRole] = useState({ ...roleDetails });
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const permissionsDependedOnParent = RolePermission?.reduce((acc, group) => {
    const permissions = group?.permissions || [];
    permissions.forEach(permission => {
      const { dependent_permissions, permission: permission_name } = permission;
      dependent_permissions.forEach(dependentPermission => {
        acc[dependentPermission] = [...(acc[dependentPermission] || []), permission_name];
      });
    });
    return acc;
  }, {});

  const childPermissions = RolePermission?.reduce((acc, group) => {
    const permissions = group?.permissions || [];
    permissions.forEach(permission => {
      const { dependent_permissions, permission: permission_name } = permission;
      if (!dependent_permissions.length) {
        return acc;
      }
      acc[permission_name] = [...(acc[permission_name] || []), ...dependent_permissions];
    });
    return acc;
  }, {});

  useEffect(() => {
    const PrTickData = PermissionTick?.map(data => data?.permission);
    setSelectedPermissions(PrTickData);
  }, [PermissionTick, RolePermission]);

  const getRoleDetails = async () => {
    setLoading(true);
    await dispatch(getPermissionGroups()).catch(() => setLoading(false));
    await dispatch(getRolePermissions({ role_id: roleDetails?.id })).catch(() => setLoading(false));
    setLoading(false);
  };

  useEffect(() => {
    if (roleDetails?.id) {
      getRoleDetails();
      setRole({ ...roleDetails });
    }
  }, [roleDetails?.id]);

  const isAllPermissionInGroupSelected = permissions => {
    return permissions.every(permission => selectedPermissions?.includes(permission.permission));
  };

  const checkAddNewRoleErrors = () => {
    const { name, description, tier } = role;
    if (!name?.trim() || !description?.trim() || !(tier >= 0)) {
      setError({
        name: !name?.trim(),
        description: !description?.trim(),
        tier: !(tier >= 0),
      });
      showErrorToast({ default_message: 'Please enter a name, description and tier' });
      return true;
    } else if (tier < userRole?.tier) {
      setError({
        name: false,
        description: false,
        tier: true,
      });
      showErrorToast({ default_message: 'You cant add roles with tier above you.' });
      return true;
    }
    return false;
  };

  const onUpdateData = async () => {
    try {
      if (checkAddNewRoleErrors() || loading) {
        return;
      }
      const permissionsWithChild = Object.keys(childPermissions).reduce((acc, permission) => {
        if (selectedPermissions.includes(permission) || acc.includes(permission)) {
          acc = [...acc, ...childPermissions[permission]];
        }
        return acc;
      }, []);
      const newPermissions = [...selectedPermissions, ...permissionsWithChild];
      const request = newPermissions.filter((p, index) => index === newPermissions.indexOf(p)) || [];

      const { name, description, tier } = role;

      setLoading(true);
      const roleUpdateRequest = {
        ...roleDetails,
        permissions: request,
        name,
        description,
        tier,
      };
      const roleReponse = await dispatch(
        UpdateUserRole({
          roleId: roleDetails?.id,
          organization_id: userOrganization?.id,
          request: roleUpdateRequest,
        }),
      );
      setRole({ ...roleReponse });
      setShowSave(false);
      setLoading(false);
      dispatch(updateUserRole(roleReponse));
      dispatch(
        addToast({
          error: false,
          title: 'Role Details and Permission updated',
          text: `Role ${name} updated successfully.`,
          id: uuid(),
        }),
      );
    } catch (error) {
      showErrorToast({ error, default_message: 'Error while updating the role Permission' });
      setLoading(false);
    }
  };

  const renderDetail = (label, labelText, value) => {
    return (
      <>
        {label === 'ROLE_TIER' ? (
          <div className="flex items-center col-gap-2 w-full cursor relative">
            <div className="font-14 inter-400-text line-height-20 natural-900-text one-line hover-margin regular-text">
              {t('ROLE_TIER')} : {value}
            </div>
            <div className="flex items-center justify-center">
              <Alertdisable width={16} height={16} />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between w-full cursor relative">
            <div className="flex-column w-full">
              <span className="inter-400-text grey-text-04 font-12 mb-1">{labelText}</span>
              <div className="flex items-center">
                <label className="font-14 inter-400-text line-height-20 natural-900-text one-line flex-1 hover-margin regular-text">
                  {value}
                </label>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const onPermissionSelect = (permissionData, isSelected) => {
    const { dependent_permissions, permission } = permissionData;
    const parentPermissions = permissionsDependedOnParent[permission];
    if (parentPermissions) {
      const isAnyParentSelected = parentPermissions.some(child => selectedPermissions.includes(child));
      if (isAnyParentSelected) {
        const errorText = `You can not remove ${permissionData?.name} permission as it is dependent on other permissions`;
        showErrorToast({ default_message: errorText });
        return;
      }
    }
    if (isSelected) {
      setSelectedPermissions(prev => prev.filter(p => p !== permission));
    } else {
      const newPermissionsWithoutDependent = selectedPermissions.filter(s => !dependent_permissions.includes(s));
      setSelectedPermissions([...newPermissionsWithoutDependent, permission, ...dependent_permissions]);
    }
  };

  const renderRolePermissions = RolePermission => (
    <div
      className={`flex-column row-gap-4 px-6 overflow-auto custom-scrollbar thin-scrollbar ${
        showSave ? 'role-permission-edit' : 'role-permission-scroll'
      }`}>
      {RolePermission?.map((dataPermission, index) => (
        <div key={index} className="border radius-1_5 h-auto">
          <div className="py-4 px-5_5 border-bottom flex items-center justify-between">
            <label className="inter-600-text font-14 line-height-20 natural-900-text">
              {uppercase(formatText(dataPermission?.name), '')}
            </label>
            {showSave && (
              <Checkbox
                onChange={() => {
                  if (isAllPermissionInGroupSelected(dataPermission?.permissions)) {
                    setSelectedPermissions(prev =>
                      prev.filter(p => !dataPermission?.permissions.map(data => data.permission).includes(p)),
                    );
                  } else {
                    setSelectedPermissions(prev => [
                      ...prev,
                      ...dataPermission?.permissions.map(data => data.permission),
                    ]);
                  }
                }}
                checked={isAllPermissionInGroupSelected(dataPermission?.permissions)}
                is_checked_done={true}
              />
            )}
          </div>
          <div className="py-6 px-5_5 flex-column row-gap-4">
            {dataPermission?.permissions?.map((subData, innerIndex) => {
              const isSelected = selectedPermissions?.includes(subData.permission);
              return (
                <div key={innerIndex} className="flex items-center justify-between h-24px">
                  <p
                    className={`inter-400-text font-14 line-height-20 ${
                      isSelected || showSave ? 'natural-900-text' : 'natural-400-text'
                    }`}>
                    {capitalize(formatText(subData?.name), '')}
                  </p>

                  {showSave ? (
                    <Checkbox
                      onChange={() => {
                        onPermissionSelect(subData, isSelected);
                      }}
                      checked={isSelected}
                      className="select-checkbox"
                      is_checked_done={true}
                    />
                  ) : (
                    isSelected && <Checkmark width={14} height={14} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <RoleDetailstWrapper className={`flex-column ${loading && 'h-full'} `}>
      {loading ? (
        <div className="item flex items-center justify-center flex-1 w-full">
          <Skeleton height={'100%'} containerClassName="line-height-1 h-full w-full" />
        </div>
      ) : (
        <>
          <SideModelHeader
            title={t('ROLE_DETAILS')}
            onClose={() => (showSave ? setShowSave(false) : setSideModal(initSideModal))}
            onSaveUpdate={() => {
              onUpdateData();
            }}
            isLoading={loading}
            showSave={showSave}
            permission={'ROLES_MANAGE'}
            handleEdit={() => setShowSave(true)}
            handleDelete={onDeleteRole}
          />
          <div className="flex-column flex-1 overflow-hidden">
            <div className="pxy-6 flex-column row-gap-4 border-bottom">
              {showSave ? (
                <>
                  <InputElement
                    name={t('NAME')}
                    placeholder="Name"
                    value={role.name || ''}
                    onChange={value => {
                      setRole({ ...role, name: value });
                      setError({ ...error, name: false });
                    }}
                    error={error.name}
                  />
                </>
              ) : (
                renderDetail('NAME', t('NAME'), role?.name)
              )}
              {showSave ? (
                <>
                  <InputElement
                    type="textarea"
                    name={t('DESCRIPTION')}
                    placeholder="Enter description here"
                    value={role.description || ''}
                    onChange={value => {
                      setRole({ ...role, description: value });
                      setError({ ...error, description: false });
                    }}
                    error={error.description}
                  />
                </>
              ) : (
                renderDetail('DESCRIPTION', t('DESCRIPTION'), role?.description)
              )}
              {showSave ? (
                <>
                  <InputElement
                    name={t('ROLE_TIER')}
                    placeholder="Type role tier"
                    type="number"
                    value={role.tier >= 0 ? role.tier : ''}
                    onChange={value => {
                      setRole({ ...role, tier: value });
                      setError({ ...error, tier: false });
                    }}
                    error={error.tier}
                  />
                </>
              ) : (
                renderDetail('ROLE_TIER', t('ROLE_TIER'), role?.tier)
              )}
            </div>
            <div className="py-6 flex-column row-gap-4 flex-1 overflow-hidden">
              <div className="px-6">
                <label className="inter-600-text font-14 line-height-20 natural-900-text">
                  {uppercase(t('ROLE_PERMISSIONS'), '')}
                </label>
              </div>
              {renderRolePermissions(RolePermission)}
            </div>
          </div>
        </>
      )}
    </RoleDetailstWrapper>
  );
};

export const RoleDetailstWrapper = styled.div`
  overflow: hidden;

  .update-detail-contaner {
    display: flex;
    padding: 12px;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    .input {
      height: 40px;
    }
  }

  .edit-delete-icon {
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: ${({ theme }) => theme.primary_500};
  }
  .close-icon {
    path {
      fill: #171717;
    }
  }
`;

export default RoleDetails;
