import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, getFormattedDate, initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { deleteTask } from '../../store/features/taskSlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteTask = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showErrorToast } = useError();
  const { modal, setModal } = useContext(OrganisationContext);

  const { task, title, descriptions, status, priority, isFromActivity = false, onSuccess } = modal.content;
  const { id: task_id, name, subtask_count, due_date, assignee, engagement, description } = task;
  const { name: assignee_name } = assignee || {};
  const {
    name: job_name,
    property: { formatted_address },
  } = engagement || {};

  const [loading, setLoading] = useState(false);

  const onDone = async () => {
    setLoading(true);
    try {
      await dispatch(deleteTask({ task_id: task_id }));

      dispatch(addToast({ error: false, text: t('TASK_DELETED_SUCCESSFULLY'), id: nanoid() }));
      onSuccess && onSuccess();
      setModal(initModal);
    } catch (error) {
      const errorMessage = t('ERROR_WHILE_DELETING_TASK');
      const textFromError = getErrorDescription(error, errorMessage);
      showErrorToast({ error, default_message: textFromError, id: nanoid() });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteTaskWrapper className="flex-column items-center radius-3 pxy-6">
        <div className="flex-column content-wrapper gap-5 items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text natural-400-text text-center font-14">{descriptions}</p>
          </div>
        </div>
        <div className="flex-column border radius-1_5 py-4 px-5 w-full row-gap-2">
          <div className="flex items-center justify-between gap-5">
            <label className="inter-600-text font-16 natural-900-text one-line w-70">{name}</label>
            <div className="min-w-fit-content">{status}</div>
          </div>
          {!isFromActivity && (
            <div className="flex items-center gap-1 mt-3">
              <label className="inter-400-text font-14 natural-900-text line-height-20">{t('PRIORITY')}:</label>
              {priority}
            </div>
          )}
          {isFromActivity && (
            <label className="inter-400-text font-14 natural-500-text line-height-20">{description}</label>
          )}
          <div className="flex items-center gap-1">
            <label className="inter-400-text font-14 natural-900-text line-height-20">{t('SUBTASKS')}:</label>
            <label className="inter-400-text font-14 natural-500-text line-height-20">{subtask_count}</label>
          </div>
          {!isFromActivity && (
            <div className="flex items-center gap-1">
              <label className="inter-400-text font-14 natural-900-text line-height-20">{t('DATE')}:</label>
              <label className="inter-400-text font-14 natural-500-text line-height-20">
                {getFormattedDate(due_date, 'DD/MM/YYYY', false)}
              </label>
            </div>
          )}
          <div className="flex items-center gap-1">
            <label className="inter-400-text font-14 natural-900-text line-height-20">{t('ASSIGNEE')}:</label>
            <label className="inter-400-text font-14 natural-500-text line-height-20">{assignee_name}</label>
          </div>
          {!isFromActivity && (
            <div className="flex-column px-2 py-1_5 radius-1 bg-natural-100 one-line">
              <label className="inter-400-text font-14 natural-900-text one-line line-height-20">{job_name}</label>
              <label className="inter-400-text font-12 natural-500-text one-line line-height-20">
                {formatted_address}
              </label>
            </div>
          )}
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative')}
            label={t('DELETE')}
            disabled={loading}
            loading={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </DeleteTaskWrapper>
    </CSSTransition>
  );
};

export const DeleteTaskWrapper = styled.div`
  width: 420px;
  gap: 24px;
`;

export default DeleteTask;
