import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { useError } from '../../hooks/useError';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import InputElement from '../common/input';
import MenuV2 from '../common/menu/menu-v2';
import Dropdown from '../common/select-dropdown';
import TableHeader from '../common/table-header';

const classificationOptions = [
  { value: 'WIDTH', label: 'Width' },
  { value: 'HEIGHT', label: 'Height' },
  { value: 'DEPTH', label: 'Depth' },
  { value: 'SPEED', label: 'Speed' },
  { value: 'CAPACITY', label: 'Capacity' },
  { value: 'POWER_OUT', label: 'Power Out' },
];

const unitOptions = [
  { value: 'KG', label: 'kg' },
  { value: 'CM', label: 'cm' },
  { value: 'WATT', label: 'watt' },
  { value: 'KWH', label: 'kWh' },
];

const ProductSpecificationItem = ({
  specification,
  showBorderTop,
  isEdit,
  onEdit,
  onDelete,
  onCancel,
  onSave,
  onChangeClassification,
  onChangeSpecification,
  onChangeUnit,
  is_product_owner,
}) => {
  const { classification, specification: spec, units, id } = specification;

  const classificationOption = classificationOptions.find(option => option.value === classification) || null;
  const unitOption = unitOptions.find(option => option.value === units) || null;

  return (
    <div className={classNames('data-container items-center relative', showBorderTop && 'border-top')}>
      {isEdit ? (
        <>
          <Dropdown
            options={classificationOptions}
            value={classificationOption}
            onChange={option => onChangeClassification(id, option.value)}
            placeholder="Select"
            menuPortalTarget={document.body}
            customStyle={{
              control: { height: '32px', minHeight: '32px', borderRadius: '6px' },
              dropdownIndicator: { margin: '0' },
            }}
          />
          <InputElement
            value={spec}
            type="number"
            showNumberArrows={false}
            placeholder={'Enter'}
            onChange={value => onChangeSpecification(id, value)}
            style={{ height: '32px', minHeight: '32px', borderRadius: '6px', padding: '2px 16px' }}
          />
          <Dropdown
            options={unitOptions}
            value={unitOption}
            onChange={option => onChangeUnit(id, option.value)}
            placeholder="Select"
            menuPortalTarget={document.body}
            customStyle={{
              control: { height: '32px', minHeight: '32px', borderRadius: '6px' },
              dropdownIndicator: { margin: '0' },
            }}
          />
          <div className="flex justify-center" />
          <div className="flex justify-center items-center pxy-2 radius-3 absolute bg-white col-gap-2 action-container z-1">
            <IconContainer
              Icon={CrossIcon}
              backgroundColor="white"
              iconContainerClassname="radius-full border cursor"
              iconColor="natural_400"
              onClick={() => onCancel(id)}
            />
            <IconContainer
              Icon={RightIcon}
              backgroundColor="primary_500"
              iconContainerClassname="radius-full cursor"
              iconColor="white"
              onClick={onSave}
            />
          </div>
        </>
      ) : (
        <>
          <label className="inter-500-text">{classificationOption?.label || ''}</label>
          <label className="inter-500-text">{spec}</label>
          <label className="inter-500-text">{unitOption?.label || ''}</label>
          {is_product_owner && (
            <div className="flex justify-center">
              <MenuV2
                iconClassname="rotate-90"
                menuList={[
                  { name: 'Edit', onClick: () => onEdit(id), permission: 'PRODUCTS_MANAGE' },
                  { name: 'Delete', onClick: () => onDelete(id), permission: 'PRODUCTS_MANAGE' },
                ]}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ProductSpecification = ({ specifications, updateProductInfo, is_product_owner }) => {
  const { t } = useTranslation();
  const { showErrorToast } = useError();
  const theme = useTheme();

  const validateSpecification = ({ classification, specification, units }) => classification && specification && units;

  const updateSpecifications = (id, updates) => {
    const updatedSpecifications = specifications.map(item => (item.id === id ? { ...item, ...updates } : item));
    updateProductInfo('specifications', updatedSpecifications);
  };

  const handleSave = () => {
    const updatedSpecifications = specifications
      .map(item => {
        if (item.isEdit) {
          if (!validateSpecification(item)) {
            showErrorToast({ default_message: 'Classification, specifications, and unit are required' });
            return item;
          }
          return { ...item, isEdit: false };
        }
        return item;
      })
      .filter(Boolean);

    updateProductInfo('specifications', updatedSpecifications);
  };

  const handleEdit = id => {
    const item = specifications.find(spec => spec.id === id);
    if (item) {
      updateSpecifications(id, {
        isEdit: true,
        originalValues: { classification: item.classification, specification: item.specification, units: item.units },
      });
    }
  };

  const handleCancel = id => {
    const item = specifications.find(spec => spec?.id === id);
    if (item?.isNew) {
      updateProductInfo(
        'specifications',
        specifications.filter(item => item?.id !== id),
      );
    } else {
      updateSpecifications(id, { ...item?.originalValues, isEdit: false });
    }
  };

  const handleDelete = id => {
    updateProductInfo(
      'specifications',
      specifications.filter(item => item?.id !== id),
    );
  };

  const handleAddSpecification = () => {
    updateProductInfo('specifications', [...specifications, { id: nanoid(), isEdit: true, isNew: true }]);
  };

  return (
    <ProductSpecificationWrapper>
      <div>
        {specifications.length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll mt-4">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader
                headers={[
                  { name: 'CLASSIFICATION', key: 'CLASSIFICATION' },
                  { name: 'SPECIFICATION', key: 'SPECIFICATION' },
                  { name: 'UNIT', key: 'UNIT' },
                  ...(is_product_owner ? [{ name: '', key: 'MENU' }] : []),
                ]}
                headerClassName={classNames(!is_product_owner && 'specifications-grid')}
              />
              {specifications
                .filter(specification => !specification.isDelete)
                .map(specification => (
                  <ProductSpecificationItem
                    key={specification.id}
                    specification={specification}
                    showBorderTop={specifications.length > 1}
                    isEdit={specification.isEdit}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onCancel={handleCancel}
                    onSave={handleSave}
                    onChangeClassification={(id, value) => updateSpecifications(id, { classification: value })}
                    onChangeSpecification={(id, value) => updateSpecifications(id, { specification: value })}
                    onChangeUnit={(id, value) => updateSpecifications(id, { units: value })}
                    is_product_owner={is_product_owner}
                  />
                ))}
              {is_product_owner && (
                <div className="add-new-specification pxy-4 border-top">
                  <label
                    className="inter-400-text primary-500-text flex items-center cursor"
                    onClick={handleAddSpecification}>
                    <IconContainer
                      Icon={AddIcon}
                      iconColor="primary_500"
                      backgroundColor="transparent"
                      iconContainerClassname="p-0 mr-1"
                      iconHeight={14}
                      iconWidth={14}
                    />
                    {t('ADD_SPECIFICATION')}
                  </label>
                </div>
              )}
            </div>
          </div>
        ) : (
          !is_product_owner && (
            <label className="inter-400-text font-14 line-height-20 natural-400-text">No Specifications</label>
          )
        )}
        {specifications.length === 0 && is_product_owner && (
          <Button
            label={t('ADD_SPECIFICATION')}
            icon={<AddIcon />}
            onClick={handleAddSpecification}
            size="medium"
            className="w-fit-content primary-500-text py-2_5 pr-4"
            bgColor={theme.white}
            borderColor={theme.white}
            color={theme.primary_500}
          />
        )}
      </div>
    </ProductSpecificationWrapper>
  );
};

const ProductSpecificationWrapper = styled.div`
  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) 40px;
    column-gap: 16px;
    padding: 16px;
  }

  .specifications-grid {
    grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr);
  }

  .action-container {
    right: 0;
    bottom: -34px;
    box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }
`;

export default ProductSpecification;
