import { ReactComponent as AddIcon } from '@assets/icons/add-icon.svg';
import { ReactComponent as EmptyIcon } from '@assets/icons/empty-document.svg';
import { ReactComponent as MobileIcon } from '@assets/icons/phone.svg';
import { ReactComponent as ImageBlank } from '@assets/icons/property/blank-image.svg';
import { ReactComponent as MailIcon } from '@assets/icons/property/mail.svg';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { createJobPartners, getJobTypeOrganizations } from '../../store/features/jobSlice';
import { getPartnersList } from '../../store/features/partnersSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import MenuV2 from '../common/menu/menu-v2';
import NoData from '../common/no-data';
import SearchableDropdown from '../common/searchable-dropdown';
import SkeletonTransition from '../common/skeleton-transition';

const CustomOption = props => {
  const { innerProps, data } = props;
  const { organization } = data || {};
  const { name, email, phone, addresses } = organization || {};
  const { t } = useTranslation();

  return (
    <div className={'w-full px-4 py-3 cursor border-bottom option-wrapper'} {...innerProps}>
      <div className="flex items-center justify-between pb-1 gap-3 select-primary-contact">
        <span className={'inter-600-text natural-900-text font-14 line-height-20'}>{name}</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="line-height-20">
          <span className={classNames('inter-400-text natural-400-text font-12 ', email && 'natural-700-text')}>
            {email || capitalize(formatText(t('NO_EMAIL')))}
          </span>
        </div>
        <div style={{ width: '1px', height: '12px', background: '#D4D4D4' }}></div>
        <span className={classNames('inter-400-text natural-400-text font-12 ', phone && 'natural-700-text')}>
          {phone || capitalize(formatText(t('NO_PHONE_NUMBER')))}
        </span>
      </div>
      <div>
        <span className={classNames('inter-400-text natural-400-text font-12 ', addresses && 'natural-700-text')}>
          {addresses || capitalize(formatText(t('NO_ADDRESS')))}
        </span>
      </div>
    </div>
  );
};

const SingleValue = props => {
  const { data } = props;
  const { organization } = data || {};
  const { name, email } = organization || {};

  return (
    <components.SingleValue {...props}>
      <div className="flex items-center line-height-20">
        <span className="flex items-center line-height-20 inter-400-text natural-900-text mr-4  one-line">{name}</span>
        {email && (
          <div className="flex gap-2 items-center line-height-20 one-line">
            <IconContainer Icon={MailIcon} backgroundColor="transparent" />
            {email}
          </div>
        )}
      </div>
    </components.SingleValue>
  );
};

const PartnerItem = ({ partner, engagement_id, setPartnersList }) => {
  const { organization, created_on } = partner;
  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);

  return (
    <div className="pxy-4 border radius-1_5 flex col-gap-2 justify-between">
      <div className="flex gap-4 align-center">
        <div className="border radius-1_5 flex items-center justify-center">
          {organization?.image?.url ? (
            <img alt="logo" src={organization.image?.url} className="w-full h-full logo-image" />
          ) : (
            <ImageBlank />
          )}
        </div>
        <div className="flex-column gap-1 justify-center">
          <label className="inter-600-text font-16 line-height-24 natural-900-text">{organization?.name}</label>
          <div className="flex gap-4 items-center">
            <div className="flex items-center gap-2 ">
              <IconContainer
                iconHeight={16}
                iconWidth={16}
                backgroundColor="transparent"
                Icon={MailIcon}
                iconColor={'natural_700'}
              />
              <label
                className={classNames('inter-400-text  natural-400-text', organization?.email && 'natural-900-text')}>
                {organization?.email || capitalize(formatText(t('NO_EMAIL')))}
              </label>
            </div>
            <div className="line-vertical"></div>
            <div className="flex items-center gap-2">
              <IconContainer
                iconHeight={16}
                iconWidth={16}
                backgroundColor="transparent"
                Icon={MobileIcon}
                iconColor={'natural_700'}
              />
              <label
                className={classNames('inter-400-text natural-400-text', organization?.phone && 'natural-900-text')}>
                {organization?.phone || capitalize(formatText(t('NO_PHONE_NUMBER')))}
              </label>
            </div>
          </div>
          <label
            className={classNames(
              'inter-400-text natural-400-text',
              organization?.formatted_address && 'natural-500-text',
            )}>
            {organization?.formatted_address || capitalize(formatText(t('NO_ADDRESS')))}
          </label>
        </div>
      </div>
      <div className="flex gap-6 h-fit-content items-center">
        <div className="flex gap-4">
          <label className="inter-400-text natural-400-text flex nowrap col-gap-1">
            Allocated date:
            <span className="natural-900-text">{created_on ? moment.unix(created_on).format('DD/MM/YYYY') : '-'}</span>
          </label>
        </div>
        <MenuV2
          menuWrapperClassname="menu-wrapper"
          menuList={[
            {
              name: t('REMOVE'),
              onClick: () => {
                setModal({
                  type: 'delete-partner',
                  content: {
                    title: t('DELETE_PARTNER') + '?',
                    description: t('ARE_YOU_SURE_TO_DELETE_THIS_PARTNER'),
                    partner: partner,
                    engagement_id: engagement_id,
                    onSuccess: id => setPartnersList(partnerList => partnerList.filter(partner => partner.id !== id)),
                  },
                });
              },
            },
          ]}
          iconClassname="rotate-90 flex"
        />
      </div>
    </div>
  );
};

const AddPartner = ({ setIsAdding, engagement_id, job_type_id, alreadyAddedPartners, setPartnersList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const [search, setSearch] = useState('');
  const [value, setValue] = useState(null);
  const [addingPartner, setAddingPartner] = useState(false);

  const onDone = () => {
    const request = {
      organization: { id: value.organization.id },
      user: value?.user?.id ? { id: value?.user?.id } : null,
    };
    setAddingPartner(true);
    dispatch(createJobPartners({ request: request, id: engagement_id }))
      .then(data => {
        setPartnersList(prev => [data, ...prev]);
        setIsAdding(false);
        dispatch(addToast({ error: false, text: t('ADDING_PARTNERS_LIST_SUCCESSFULLY') }));
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_ADDING_PARTNERS_LIST') }))
      .finally(() => setAddingPartner(false));
  };

  return (
    <div className="pxy-4 border radius-1_5 flex justify-between min-w-fit-content">
      <div className="flex gap-4 align-center flex-1">
        <div className="border radius-1_5 w-72px h-72px flex items-center justify-center">
          <ImageBlank />
        </div>
        <SearchableDropdown
          name="Select partner"
          inputValue={search}
          onInputChange={setSearch}
          placeholder="Start entering partner name"
          customStyle={{ control: { minWidth: 300, maxWidth: '70%' }, menu: { minWidth: 300, maxWidth: '70%' } }}
          isSearchable={true}
          onChange={option => setValue(option)}
          isCustomSearchable={false}
          value={value}
          defaultAdditional={{
            page: 0,
            fetchFunction: getJobTypeOrganizations,
            pageable: true,
            payload: {
              id: job_type_id,
            },
            params: {
              engagement_id: engagement_id,
            },
            job_type_id: job_type_id,
            pageable: false,
          }}
          filterOption={option => !alreadyAddedPartners.includes(option?.data?.organization?.id)}
          customComponent={{ Option: CustomOption, SingleValue: SingleValue }}
        />
      </div>
      <div className="flex-column gap-4 add-partner-actions">
        <Button
          className={classNames('primary flex-1')}
          label={t('ADD')}
          fontSize={'12px'}
          lableSize={'line-height-20'}
          disabled={!value}
          loading={addingPartner}
          onClick={onDone}
          size="medium"
          borderRadius="100px"
        />
        <Button
          className={classNames('primary-grey flex-1')}
          label={t('CANCEL')}
          fontSize={'12px'}
          lableSize={'line-height-20'}
          onClick={() => setIsAdding(false)}
          size="medium"
          borderRadius="100px"
        />
      </div>
    </div>
  );
};

const PropertyJobPartners = () => {
  const [loading, setLoading] = useState(true);
  const [partnersList, setPartnersList] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  const { t } = useTranslation();
  const { jobDetails } = useSelector(state => state.property);

  const engagement_id = jobDetails?.engagement?.id;
  const job_type_id = jobDetails?.job_type?.id;

  const dispatch = useDispatch();
  const { showErrorToast } = useError();

  const { setModal, hasPermission } = useContext(OrganisationContext);

  const fetchPartnersList = () => {
    setLoading(true);
    dispatch(
      getPartnersList({
        engagement_id: engagement_id,
      }),
    )
      .then(data => {
        setPartnersList(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_FETCHING_PARTNERS_LIST') }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPartnersList();
  }, []);

  return (
    <ProprtyJobPartnersWrapper
      key="property-job-partner"
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      className="pxy-6 flex-column row-gap-5 flex-1 overflow-hidden">
      <div className="flex justify-between ">
        <div className="flex align-center"></div>
        {hasPermission('PARTNERS_MANAGE') && (
          <Button
            fontSize="14px"
            width="117px"
            size="average"
            borderRadius="100px"
            lableSize="font-14 inter-500-text w-63px line-height-20"
            className="primary specified-width px-4 py-2_5 ml-1_5"
            afterIcon={<AddIcon className="white-text mr-0" height={16} width={16} />}
            label={t('ADD_NEW')}
            onClick={() => setIsAdding(true)}
          />
        )}
      </div>
      <div className="w-full flex-column row-gap-6 flex-1  overflow-auto">
        <SkeletonTransition
          loading={loading}
          loaderClassName="item w-full flex items-center justify-center flex-1 mb-6"
          height={'50vh'}
          containerClassName="line-height-1 flex-1"
          baseColor="#CDCDCD"
          highlightColor="#E8E8E8">
          {partnersList.length > 0 || isAdding ? (
            <>
              {isAdding && (
                <AddPartner
                  t={t}
                  setIsAdding={setIsAdding}
                  engagement_id={engagement_id}
                  job_type_id={job_type_id}
                  alreadyAddedPartners={partnersList.map(partner => partner.organization?.id)}
                  setPartnersList={setPartnersList}
                />
              )}
              {partnersList.map(partner => (
                <PartnerItem
                  t={t}
                  key={partner.id}
                  partner={partner}
                  engagement_id={engagement_id}
                  setModal={setModal}
                  setPartnersList={setPartnersList}
                />
              ))}
            </>
          ) : (
            <NoData
              title={t('NO_PARTNERS')}
              description={t('NO_PARTNERS_HERE')}
              className="flex-1"
              EmptyIcon={EmptyIcon}
              iconClassName="relative"
            />
          )}
        </SkeletonTransition>
      </div>
    </ProprtyJobPartnersWrapper>
  );
};

export default PropertyJobPartners;

const ProprtyJobPartnersWrapper = styled(motion.div)`
  .menu-btn {
    height: 24px;
    width: 24px;

    &:hover {
      border-radius: 6px;
    }
    .menu-btn-icon {
      height: 20px;
      width: 20px;
    }
  }

  .line-vertical {
    width: 1px;
    height: 20px;
    background-color: ${({ theme }) => theme.natural_200};
  }

  .add-partner-actions {
    width: 96px;
  }

  .logo-image {
    width: auto;
    object-fit: contain;
    aspect-ratio: 1;
    max-width: 70px;
    max-height: 70px;
  }
`;
