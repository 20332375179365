import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
// import { useQuery } from '../../hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { useError } from '../../hooks/useError';
import { getPropertyList, setPropertyList } from '../../store/features/propertySlice';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import PropertyListItem from './property-list-item';

const propertyTableHeaders = [
  { name: 'ADDRESS', key: 'ADDRESS' },
  { name: 'TYPE', key: 'TYPE' },
  { name: 'PRIMARY CONTACT', key: 'PRIMARY_CONTACT' },
  // { name: 'SECONDARY CONTACT', key: 'SECONDARY_CONTACT' },
  { name: 'JOBS', key: 'JOBS' },
  { name: '', key: 'MENU' },
];

const PropertyList = ({ debouncedSearch, orderBy, sortBy }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const { showErrorToast } = useError();

  // const query = useQuery();
  // const param_property_id = query.get('property_id');

  const { userOrganization } = useSelector(state => state.user);
  const { propertyList } = useSelector(state => state.property);
  const { content: property, last } = propertyList || {};

  const [loading, setLoading] = useState(true);

  const fetchPropertyList = (page, merge, debouncedSearch, orderBy, sortBy, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getPropertyList({
        merge,
        params: {
          page: page,
          size: 20,
          search: debouncedSearch ?? undefined,
          order_by: orderBy ?? undefined,
          sort_by: sortBy ?? undefined,
        },
      }),
    )
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTIES') });
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchPropertyList(pageRef.current + 1, true, debouncedSearch, orderBy, sortBy, false);
  };

  useEffect(() => {
    fetchPropertyList(0, false, debouncedSearch, orderBy, sortBy, true);
  }, [userOrganization, debouncedSearch, orderBy, sortBy]);

  // const getFocusedPropertyItem = () => {
  //   const selectedPropertyItemEle = document.getElementById(param_property_id);
  //   if (selectedPropertyItemEle) {
  //     selectedPropertyItemEle.scrollIntoView({
  //       behavior: 'smooth',
  //     });
  //   } else {
  //     fetchMoreData();
  //   }
  // };

  useEffect(() => {
    return () => {
      dispatch(setPropertyList([]));
    };
  }, []);

  // useEffect(() => {
  //   if (param_property_id) {
  //     getFocusedPropertyItem();
  //   }
  // }, [property]);

  return (
    <PropertyListWrapper className="flex-column flex-1  radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={loading}
        loaderClassName="item flex items-center justify-center flex-1 mb-6"
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {(property || []).length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-full">
              <TableHeader headers={propertyTableHeaders} />
              <InfiniteScrollV2
                hasMore={!last}
                fetchMoreData={fetchMoreData}
                infiniteScrollClassName="custom-scrollbar thin-scrollbar">
                {(property || []).map(property => (
                  <PropertyListItem property={property} key={property.id} />
                ))}
              </InfiniteScrollV2>
            </div>
          </div>
        ) : debouncedSearch ? (
          <div className="flex-column flex-1 mb-6 items-center justify-center">
            <NoData
              title={t('NO_RESULTS_FOUND')}
              description={t('NO_RESULTS_DESCRIPTION')}
              className="search-terms"
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        ) : (
          <div className="flex-column flex-1 mb-6 items-center justify-center">
            <NoData
              title={t('NO_PROPERTIES')}
              className="search-terms"
              description={t('NO_PROPERTIES_HERE')}
              EmptyIcon={NoDocumentIcon}
              iconClassName="mb-6 relative"
            />
          </div>
        )}
      </SkeletonTransition>
    </PropertyListWrapper>
  );
};

const PropertyListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 0.5fr) 112px minmax(260px, 0.5fr) minmax(260px, 0.5fr) 40px;
    column-gap: 48px;
    padding: 0 24px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 300px;
  }

  .search-terms {
    max-width: 343px;
  }
`;

export default PropertyList;
