import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useBlocker, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { isUserTagSassOperator } from '../../helpers/utils';
import { useAblyMessage } from '../../hooks/useAblyMessage';
import { useError } from '../../hooks/useError';
import RoofDetailsV2 from '../../pages/properties/RoofDetailsV2';
import { getWorkitemDetails, setWorkitemDetails } from '../../store/features/boardSlice';
import {
  getJobDetails,
  getPropertyContacts,
  getPropertyDetails,
  getPropertyEpcs,
  setJobDetails,
  setPropertyContacts,
  setPropertyDetails,
  setPropertyEpcs,
  setRoofInformation,
} from '../../store/features/propertySlice';
import BreadcrumbWrapper from '../common/breadcrumb-wrapper';
import ContactDetails from '../property-details-components/contact-details';
import MainPropertyDetails from '../property-details-components/main-property-details';
import PropertyJobDetails from '../property-details-components/property-job-details';
import PropertyLeadSourceGen from '../property-details-components/property-job-lead-source-gen';
import QuoteDetails from '../quote-details';
import SystemOverview from '../system-overview';
import WorkitemHeader from './workitem-header';

const WorkitemDetails = ({ selectedBoard = {}, fromProperty = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { workitem_id, board_id } = useParams();

  const { showErrorToast } = useError();

  const user = getItemFromLocalStorage('user');
  const sass_provider = user?.saas_provider;
  const tenant_provider = user?.organizations?.[0];

  const { workitemDetails } = useSelector(state => state.board);
  const { jobDetails } = useSelector(state => state.property);
  const { propertyDetails } = useSelector(state => state.property);
  const { quoteDetails } = useSelector(state => state.quotes);

  const message = useAblyMessage({
    channel_name: `workitem:${sass_provider?.id}:${tenant_provider?.id}:${workitem_id}`,
  });

  const [workitemLoading, setWorkitemLoading] = useState(true);
  const [propertyDetailsLoading, setPropertyDetailsLoading] = useState(true);
  const [roofInfoLoading, setRoofInfoLoading] = useState(false);
  const [propertyContactsLoading, setPropertyContactsLoading] = useState(true);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);
  const [propertyEpcsLoading, setPropertyEpcsLoading] = useState(true);
  const [skipBlocker, setSkipBlocker] = useState(false);

  const fetchPropertyDetails = async property_id => {
    setPropertyDetailsLoading(true);
    await dispatch(getPropertyDetails({ property_id: property_id }))
      .then(() => setPropertyDetailsLoading(false))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_DETAILS') }));
  };

  const fetchPropertyContacts = async property_id => {
    setPropertyContactsLoading(true);
    await dispatch(getPropertyContacts({ property_id: property_id }))
      .then(() => setPropertyContactsLoading(false))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_CONTACTS') }));
  };

  const fetchJobDetails = async job_id => {
    await dispatch(getJobDetails({ job_id: job_id }))
      .then(() => setJobDetailsLoading(false))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_JOBS') }));
  };

  const fetchWorkitemDetails = async (fetchProperty = true, fetchJob = true) => {
    const workitemData = await dispatch(getWorkitemDetails({ workitem_id: workitem_id }));
    if (fetchProperty && workitemData?.engagement?.property?.id) {
      fetchPropertyDetails(workitemData?.engagement?.property?.id);
      fetchPropertyContacts(workitemData?.engagement?.property?.id);
      fetchPropertyEpcs(workitemData?.engagement?.property?.id);
    }
    if (fetchJob && workitemData?.engagement?.id) {
      fetchJobDetails(workitemData?.engagement?.id);
    }
    setWorkitemLoading(false);
  };

  const fetchPropertyEpcs = async property_id => {
    setPropertyEpcsLoading(true);
    await dispatch(getPropertyEpcs({ property_id: property_id }))
      .then(() => setPropertyEpcsLoading(false))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_EPCS') }));
  };

  useEffect(() => {
    fetchWorkitemDetails();
    return () => {
      dispatch(setWorkitemDetails({}));
      dispatch(setPropertyDetails({}));
      dispatch(setPropertyContacts([]));
      dispatch(setPropertyEpcs([]));
      dispatch(setJobDetails({}));
      dispatch(setRoofInformation([]));
    };
  }, [workitem_id]);

  useEffect(() => {
    const {
      data: { action },
    } = message || {};

    if (action === 'PROGRESSED') {
      fetchWorkitemDetails(false, false);
    }
  }, [message]);

  return (
    <Routes>
      <Route
        element={
          <Fragment>
            <BreadcrumbWrapper
              breadcrumbs={
                fromProperty
                  ? [
                      { text: t('PROPERTIES'), path: '/properties' },
                      { text: propertyDetails?.formatted_address, path: `/properties/property/${propertyDetails?.id}` },
                      {
                        text: workitemDetails?.engagement?.name,
                        path: `/properties/property/${propertyDetails?.id}/workitems/${workitem_id}`,
                      },
                      {
                        text: t('QUOTE'),
                        path: `/properties/property/${propertyDetails?.id}/workitems/${workitem_id}?selectedTab=QUOTES`,
                      },
                      { text: quoteDetails?.code },
                    ]
                  : [
                      { text: selectedBoard.name, path: `/boards/board/${board_id}` },
                      {
                        text: workitemDetails?.engagement?.name,
                        path: `/boards/board/${board_id}/workitem/${workitem_id}`,
                      },
                      {
                        text: t('QUOTE'),
                        path: `/boards/board/${board_id}/workitem/${workitem_id}?selectedTab=QUOTES`,
                      },
                      { text: quoteDetails?.code },
                    ]
              }
            />
            <QuoteDetails />
          </Fragment>
        }
        path="quotes/:quote_id"
      />
      <Route
        element={
          <Fragment>
            <BreadcrumbWrapper
              breadcrumbs={
                fromProperty
                  ? [
                      { text: t('PROPERTIES'), path: '/properties' },
                      { text: propertyDetails?.formatted_address, path: `/properties/property/${propertyDetails?.id}` },
                      {
                        text: workitemDetails?.engagement?.name,
                        path: `/properties/property/${propertyDetails?.id}/workitems/${workitem_id}`,
                      },
                      {
                        text: t('ROOF_DETAILS'),
                        path: `/properties/property/${propertyDetails?.id}/workitems/${workitem_id}/roof-details`,
                      },
                      {
                        text: 'System Overview',
                      },
                    ]
                  : [
                      { text: selectedBoard.name, path: `/boards/board/${board_id}` },
                      {
                        text: workitemDetails?.engagement?.name,
                        path: `/boards/board/${board_id}/workitem/${workitem_id}`,
                      },
                      {
                        text: t('ROOF_DETAILS'),
                        path: `/boards/board/${board_id}/workitem/${workitem_id}/roof-details`,
                      },
                      {
                        text: 'System Overview',
                      },
                    ]
              }
            />
            <SystemOverview property_id={propertyDetails?.id} />
          </Fragment>
        }
        path="roof-details/system-overview/:solar_id"
      />
      <Route
        element={
          <Fragment>
            <BreadcrumbWrapper
              breadcrumbs={
                fromProperty
                  ? [
                      { text: t('PROPERTIES'), path: '/properties' },
                      { text: propertyDetails?.formatted_address, path: `/properties/property/${propertyDetails?.id}` },
                      {
                        text: workitemDetails?.engagement?.name,
                        path: `/properties/property/${propertyDetails?.id}/workitems/${workitem_id}`,
                      },
                      {
                        text: t('ROOF_DETAILS'),
                      },
                    ]
                  : [
                      { text: selectedBoard.name, path: `/boards/board/${board_id}` },
                      {
                        text: workitemDetails?.engagement?.name,
                        path: `/boards/board/${board_id}/workitem/${workitem_id}`,
                      },
                      {
                        text: t('ROOF_DETAILS'),
                      },
                    ]
              }
            />
            <RoofDetailsV2 property_id={propertyDetails?.id} fetchSolarDetails />
          </Fragment>
        }
        path="roof-details"
      />
      <Route
        index
        element={
          <WorkitemDetailsWrapper className="flex-column flex-1 overflow-hidden">
            <div className="flex-column row-gap-6 overflow-hidden">
              <WorkitemHeader setSkipBlocker={setSkipBlocker} loading={workitemLoading} />
              <div className="property flex-1 col-gap-6 overflow-hidden">
                <div className="flex-column row-gap-6 mb-4 flex-1 overflow-scroll">
                  <Fragment>
                    <PropertyJobDetails
                      loading={propertyDetailsLoading}
                      fromProperty={fromProperty}
                      showViewDetails={true}
                    />
                    {/* <PropertyDetails loading={propertyDetailsLoading} showViewDetails={true} /> */}
                    <ContactDetails loading={propertyContactsLoading} />
                    <>
                      <PropertyLeadSourceGen
                        loading={jobDetailsLoading}
                        dataJob={jobDetails?.lead_source}
                        title={t('LEAD_SOURCE')}
                      />
                      {isUserTagSassOperator() && (
                        <PropertyLeadSourceGen
                          loading={jobDetailsLoading}
                          dataJob={jobDetails?.lead_generator}
                          title={t('LEAD_GENERATOR')}
                        />
                      )}
                    </>
                  </Fragment>
                </div>
                <div className="flex-column row-gap-6 mb-4 flex-1 overflow-scroll">
                  <MainPropertyDetails
                    loading={propertyDetailsLoading || propertyEpcsLoading || roofInfoLoading}
                    fromProperty={fromProperty}
                  />
                </div>
              </div>
            </div>
            <BlockerPrompt skipBlocker={skipBlocker} />
          </WorkitemDetailsWrapper>
        }
      />
    </Routes>
  );
};

const BlockerPrompt = ({ skipBlocker }) => {
  const { workitem_id } = useParams();
  let blocker = useBlocker(({ nextLocation }) => {
    if (skipBlocker) return false;
    const nextPath = nextLocation.pathname;
    return !nextPath.includes(workitem_id);
  });
  const { setModal } = useContext(OrganisationContext);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setModal({
        type: 'exit-job-details',
        content: {
          blocker,
        },
      });
    }
  }, [blocker]);

  return null;
};

const WorkitemDetailsWrapper = styled.div`
  transition: all 100ms ease-in;
  padding: 24px 24px 0;
  background-color: ${({ theme }) => theme.natural_150};

  .property {
    display: grid;
    grid-template-columns: minmax(313px, 20%) 1fr;
  }
`;

export default WorkitemDetails;
