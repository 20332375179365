import AccountsPartners from '../pages/accounts-partners';
import Activities from '../pages/activities';
import AutomationDetails from '../pages/automation-details';
import AutomationLogDetail from '../pages/automation-log-detail';
import AutomationLogs from '../pages/automation-logs';
import Automations from '../pages/automations';
import Boards from '../pages/boards';
import Integrations from '../pages/integrations';
import Persons from '../pages/persons';
import Properties from '../pages/properties';
import Scheduling from '../pages/scheduling';
import Settings from '../pages/settings';
import PlatformConfiguration from '../pages/settings/platformConfiguration';
import JobTypes from '../pages/settings/platformConfiguration/job-types';
import Product from '../pages/settings/platformConfiguration/product';
import Products from '../pages/settings/platformConfiguration/products';
import QuoteTemplates from '../pages/settings/platformConfiguration/quote-templates';
// import ProductConfiguration from '../pages/settings/projectConfiguration';
import Project from '../pages/settings/projectConfiguration/project';

import Security from '../pages/settings/security';
import Teams from '../pages/settings/security/teams';
import UserRoles from '../pages/settings/security/user-roles';
import Users from '../pages/settings/security/users';
import Tasks from '../pages/tasks';
import TeamName from '../pages/team-name';

export const ROUTE_PATHS = [
  {
    id: 'ROUTE_PROPERTIESINDEX',
    path: '/properties',
    Component: Properties,
    permission: ['PROPERTIES_VIEW'],
  },
  {
    id: 'ROUTE_PROPERTIES',
    path: '/properties/*',
    Component: Properties,
    permission: ['PROPERTIES_VIEW'],
  },
  {
    id: 'ROUTE_ACCOUNTS',
    path: '/accounts/*',
    Component: AccountsPartners,
    permission: ['ACCOUNTS_VIEW'],
    params: {
      account_type: 'ACCOUNTS',
      add_btn_label: 'Add new account',
      view_permission: 'ACCOUNTS_VIEW',
      manage_permission: 'ACCOUNTS_MANAGE',
    },
  },
  {
    id: 'ROUTE_PARTNERS',
    path: '/partners/*',
    Component: AccountsPartners,
    permission: ['PARTNERS_VIEW'],
    params: {
      account_type: 'PARTNERS',
      add_btn_label: 'Add new partner',
      view_permission: 'PARTNERS_VIEW',
      manage_permission: 'PARTNERS_MANAGE',
    },
  },
  {
    id: 'ROUTE_CONTACTS',
    path: '/contacts',
    Component: Persons,
    // permission: ['ACTIVITIES_VIEW'],
  },
  {
    id: 'JOBS_ROUTE',
    path: '/boards/board/:board_id/*',
    Component: Boards,
    permission: ['BOARDS_VIEW'],
    params: {
      board_type: 'JOB',
    },
  },
  {
    id: 'LEADS_ROUTE',
    path: '/boards/lead/:board_id/*',
    Component: Boards,
    permission: ['BOARDS_VIEW'],
    params: {
      board_type: 'LEAD',
    },
  },
  {
    id: 'DEALS_ROUTE',
    path: '/boards/deal/:board_id/*',
    Component: Boards,
    permission: ['BOARDS_VIEW'],
    params: {
      board_type: 'DEAL',
    },
  },
  {
    id: 'ROUTE_TASKS',
    path: '/tasks',
    Component: Tasks,
    permission: ['TASKS_VIEW', 'TASKS_VIEW_OWN', 'TASKS_MANAGE'],
  },
  {
    id: 'ROUTE_ACTIVITIES',
    path: '/activities',
    Component: Activities,
    permission: ['ACTIVITIES_VIEW'],
  },
  {
    id: 'ROUTE_SCHEDULES',
    path: '/scheduling',
    Component: Scheduling,
    permission: ['ACTIVITIES_VIEW'],
  },
  {
    id: 'ROUTE_INTEGRATIONS',
    path: '/platforms/integrations/*',
    Component: Integrations,
    permission: ['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE'],
  },
  {
    id: 'ROUTE_AUTOMATIONS',
    path: '/platforms/automations',
    Component: Automations,
    permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
  },
  {
    id: 'ROUTE_AUTOMATIONS_LOG_DETAILS',
    path: '/platforms/automations/:automation_id/automation-logs/:id',
    Component: AutomationLogDetail,
    permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
  },
  {
    id: 'ROUTE_AUTOMATION_LOGS',
    path: '/platforms/automation-logs',
    Component: AutomationLogs,
    permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
  },
  {
    id: 'ROUTE_SETTINGS',
    path: '/settings',
    Component: Settings,
    permission: ['USERS_VIEW'],
  },
  {
    id: 'ROUTE_SECURITY',
    path: '/settings/security',
    Component: Security,
  },

  {
    id: 'ROUTE_USERS',
    path: '/settings/security/users/*',
    Component: Users,
  },
  {
    id: 'ROUTE_TEAMS',
    path: '/settings/security/teams',
    Component: Teams,
  },
  {
    id: 'ROUTE_ROLES',
    path: '/settings/security/roles',
    Component: UserRoles,
  },
  {
    id: 'ROUTE_AUTOMATION_LOG_DETAILS',
    path: '/platforms/automation-logs/:id',
    Component: AutomationLogDetail,
    permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
  },
  {
    id: 'ROUTE_TEAM_DETAILS',
    path: '/settings/security/teams/:id',
    Component: TeamName,
    permission: ['TEAMS_VIEW'],
  },
  {
    id: 'ROUTE_AUTOMATION_DETAILS',
    path: '/platforms/automations/:id',
    Component: AutomationDetails,
    permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
  },
  // {
  //   id: 'ROUTE_PRODUCT_CONFIGURATION',
  //   path: '/settings/product-configuration',
  //   Component: ProductConfiguration,
  // },
  {
    id: 'ROUTE_PROJECT',
    path: '/settings/platform-configuration/project',
    Component: Project,
  },
  {
    id: 'ROUTE_PRODUCT',
    path: '/settings/platform-configuration/products',
    Component: Products,
  },
  {
    id: 'ROUTE_PRODUCT',
    path: '/settings/platform-configuration/products/:id',
    Component: Product,
  },
  {
    id: 'ROUTE_JOB_TYPE',
    path: '/settings/platform-configuration/job-types',
    Component: JobTypes,
  },
  // {
  //   id: 'ROUTE_PROFILE',
  //   path: '/profile/:user_id',
  //   Component: ProfileDetails,
  // },
  {
    id: 'ROUTE_PLATFORM_CONFIGURATION',
    path: '/settings/platform-configuration',
    Component: PlatformConfiguration,
  },
  {
    id: 'ROUTE_PLATFORM',
    path: '/settings/platform-configuration/quote-templates',
    Component: QuoteTemplates,
  },
];
