import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useDebounce from '../../../../helpers/useDebounceHook';
import { useError } from '../../../../hooks/useError';
import { getAutomationDetailsLogs } from '../../../../store/features/automationsSlice';
import InputSearch from '../../../common/input-search';
import AutomationTable from './automation-table';

const AutomationLog = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const pageRef = useRef(0);

  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);
  const [showLoading, setShowLoading] = useState(true);

  const fetchAutomationDetailsLogs = (page, merge = false, debouncedSearch, isLoading = true) => {
    setShowLoading(isLoading);
    dispatch(
      getAutomationDetailsLogs({
        id: id,
        merge,
        params: {
          page: page,
          size: 15,
          automation_log_id: debouncedSearch ? debouncedSearch : null,
        },
      }),
    )
      .then(() => {
        pageRef.current = page;
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_AUTOMATION_LOG') }))
      .finally(() => {
        setShowLoading(false);
      });
  };

  const fetchMoreData = () => {
    fetchAutomationDetailsLogs(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchAutomationDetailsLogs(0, false, debouncedSearch, true);
  }, [debouncedSearch]);

  return (
    <AutomationLogWrapper className="flex-column items-start justify-start w-full h-full overflow-hidden">
      <div className="input-search-wrapper items-center justify-between w-full mt-6 px-6">
        <InputSearch placeholder={'Search'} value={searchText} onChange={setSearchText} />
      </div>
      <AutomationTable debouncedSearch={debouncedSearch} showLoading={showLoading} fetchMoreData={fetchMoreData} />
    </AutomationLogWrapper>
  );
};

const AutomationLogWrapper = styled.div``;

export default AutomationLog;
