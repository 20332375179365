import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import { ReactComponent as CenterAlignIcon } from '../../../assets/icons/center-alignment.svg';
import { ReactComponent as LeftAlignIcon } from '../../../assets/icons/left-alignment.svg';
import { ReactComponent as RightAlignIcon } from '../../../assets/icons/right-alignment.svg';
import { ReactComponent as UploadFileIcon } from '../../../assets/icons/upload-file.svg';
import { ReactComponent as Loader } from '../../../assets/images/loading.svg';
import Button from '../../../components/common/button/button';
import IconContainer from '../../../components/common/icon-container';
import Switch from '../../../components/common/switch';
import CustomTooltip from '../../../components/common/tooltip-new';
import { OrganisationContext } from '../../../context/organisationContext';
import { capitalize, formatText, getErrorFieldJoined, initModal, uppercase } from '../../../helpers/utils';
import { useError } from '../../../hooks/useError';
import { uploadFile } from '../../../store/features/fileuploadSlice';
import { addQuotesTemplate } from '../../../store/features/quotesSlice';
import { getServices } from '../../../store/features/teamsManagmentSlice';
import { addToast } from '../../../store/features/toastSlice';

const maxFileSize = 100 * 1024;
const imageExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/svg'];

const AddQuoteTemplate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textareaRef = useRef([]);

  const { showErrorToast } = useError();

  const { modal, setModal } = useContext(OrganisationContext);
  const { title, onSuccess } = modal?.content || {};

  const [error, setError] = useState({ name: false });
  const [loading, setLoading] = useState(false);
  const [quoteDetail, setQuoteDetail] = useState({});
  const [onFile, setOnFile] = useState(false);
  const [alignmentImage, setAlignmentImage] = useState({ alignmentType: 'LEFT' });
  const [file, setFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const updateTextareaHeight = index => {
    if (textareaRef.current[index]) {
      textareaRef.current[index].style.height = '0px';
      const scrollHeight = textareaRef.current[index].scrollHeight;
      textareaRef.current[index].style.height = scrollHeight + 'px';
    }
  };

  useEffect(() => {
    textareaRef.current.forEach((ref, index) => {
      updateTextareaHeight(index);
    });
  }, [quoteDetail.terms, quoteDetail.contact_details]);

  const checkAddNewQuoteErrors = () => {
    const { name } = quoteDetail;
    if (!name?.trim()) {
      const error = {
        name: !name?.trim(),
      };
      const errorFields = getErrorFieldJoined(error, (key, value) => (value, key), ' and ');
      const errorText = `Please add ${errorFields}`;

      setError(error);
      showErrorToast({ default_message: errorText, id: nanoid() });
      return true;
    }
    setError({});
    return false;
  };

  const onAddFile = addedFile => {
    if (!imageExtensions.includes(addedFile.type)) {
      showErrorToast({ default_message: t('ONLY_IMAGE_UPLOAD_ALLOW') });
      return setFile(null);
    }

    if (addedFile.size > maxFileSize) {
      showErrorToast({ default_message: t('PLEASE_UPLOADFILE_MAX_SIZE') });
      return setFile(null);
    }
    const newFile = {
      file_data: addedFile,
      name: addedFile.name,
      size: addedFile.size,
      type: addedFile.type,
      url: URL.createObjectURL(addedFile),
      id: nanoid(),
    };

    setFileUploading(true);

    dispatch(
      uploadFile({
        file: newFile?.file_data,
        onSuccess: data => {
          setFileUploading(false);
          setFile({ ...newFile, media_id: data?.media_id, media_url: data?.url, created_on: moment().unix() });
        },
        onError: error => {
          showErrorToast({ error, default_message: t('ERROR_WHILE_UPLOADING_FILE', { name: newFile?.name }) });
          setFileUploading(false);
        },
        setProgress,
      }),
    );
  };

  const onFileDrop = e => {
    e.preventDefault();
    onAddFile(e.dataTransfer?.files[0]);
    setOnFile(true);
  };

  const onFileAdd = e => {
    e.preventDefault();
    onAddFile(e.target.files[0]);
    e.target.value = null;
    setOnFile(true);
  };

  const removeFile = () => {
    setFile(null);
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkAddNewQuoteErrors()) {
      return;
    }
    setLoading(true);
    const request = {
      name: quoteDetail?.name || '',
      description: quoteDetail?.description || '',
      terms: quoteDetail?.terms || '',
      contact_information: quoteDetail?.contact_details || '',
      is_default: quoteDetail?.isDefault || 'false',
      image: {
        id: file?.media_id,
        alignment: alignmentImage?.alignmentType || 'LEFT',
      },
    };
    dispatch(addQuotesTemplate({ request }))
      .then(data => {
        dispatch(
          addToast({
            error: false,
            text: t('QUOTE_TEMPLATE_CREATE_SUCCESSFULLY', { quotetemplatename: quoteDetail?.name }),
            id: uuid(),
          }),
        );
        if (data) {
          onSuccess && onSuccess();
          setLoading(false);
          setModal(initModal);
        }
      })
      .catch(error => {
        setLoading(false);
        showErrorToast({ error, default_message: t('ERROR_CREATING_QUOTE_TEMPLATE') });
      });
  };

  const renderInput = (key, label, placeholder, value, type, index) => {
    return (
      <div className="flex-column w-full input-container relative">
        <div className="mb-1 one-line">
          <label className="w-full inter-500-text natural-900-text line-height-20 font-14">{label}</label>
        </div>
        {type === 'textarea' ? (
          <>
            <textarea
              value={value}
              className="inter-400-text natural-900-text  w-full textarea px-2 py-1  border"
              ref={el => (textareaRef.current[index] = el)}
              placeholder={placeholder}
              onChange={({ target: { value } }) => setQuoteDetail({ ...quoteDetail, [key]: value })}
            />
          </>
        ) : (
          <input
            className="inter-400-text natural-900-text input one-line w-full px-2 py-1 border input-element"
            value={value}
            placeholder={placeholder}
            type={type}
            onChange={({ target: { value } }) => setQuoteDetail({ ...quoteDetail, [key]: value })}
          />
        )}
      </div>
    );
  };

  const AlignmentOption = ({ alignmentType, aligntooltip, setAlignmentImage, Icon, iconContainerClassname }) => (
    <div>
      <CustomTooltip
        wrapperClassName="file-align-tooltip w-fit-content h-fit-content"
        id={aligntooltip}
        place="bottom"
        noArrow={true}
        content={<label className="inter-500-text natural-700-text font-12 line-height-20">{aligntooltip}</label>}>
        <IconContainer
          iconContainerClassname={classNames(``, alignmentImage?.alignmentType === alignmentType && 'bg-blue')}
          Icon={Icon}
          iconColor={alignmentImage?.alignmentType === alignmentType ? 'white' : 'natural_500'}
          backgroundColor="natural_100"
          iconWidth={16}
          iconHeight={16}
          onClick={() => setAlignmentImage({ ...alignmentImage, alignmentType })}
        />
      </CustomTooltip>
    </div>
  );

  const FileUploadContainer = ({ file, onFileDrop, onFileAdd, removeFile, onFile }) => {
    return (
      <>
        <div className="flex col-gap-4 items-center">
          <div
            className={classNames(
              'border-dotted radius-1_5 flex-column items-center justify-center image-upload-box',
              onFile ? 'on-droparea' : 'off-droparea',
            )}
            onDragLeave={e => {
              e.preventDefault();
              setOnFile(false);
            }}
            onDragOver={e => {
              e.preventDefault();
              setOnFile(true);
            }}
            onDrop={onFileDrop}>
            {fileUploading ? (
              <>
                <Loader width={40} height={40} />
              </>
            ) : file ? (
              <div className="relative h-full w-full">
                <img src={file.url} alt="Uploaded" className="radius-1_5" style={{ width: '100%', height: '100%' }} />
                <div className="absolute top-0 right-0 cross-icon">
                  <CrossIcon className="cursor natural-700-text" onClick={removeFile} width={12} height={12} />
                </div>
              </div>
            ) : (
              <div>
                <IconContainer
                  Icon={UploadFileIcon}
                  iconColor="natural_300"
                  backgroundColor="transparent"
                  iconWidth={32}
                  iconHeight={32}
                />
                <div>
                  <input type="file" id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
                  <label
                    htmlFor="file-upload-input"
                    className="primary-text font-12 inter-500-text line-height-20 cursor">
                    {t('BROWSE')}
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="flex-column">
            <label className="font-14 inter-500-text natural-900-text line-height-20 ">{t('UPLOAD_IMAGE')}</label>
            <label className="font-12 inter-400-text natural-400-text line-height-20">
              {t('MAXIMUM_FILE_SIZE_100KB')}
            </label>
            <div className="flex items-center col-gap-2 pt-2">
              <label className="font-12 inter-500-text natural-900-text line-height-20 ">{t('ALIGNMENT')}:</label>
              <div className="flex items-center col-gap-2 ">
                <AlignmentOption
                  alignmentType={uppercase(formatText(t('LEFT')))}
                  setAlignmentImage={setAlignmentImage}
                  Icon={LeftAlignIcon}
                  aligntooltip={t('LEFT_ALIGN')}
                  iconContainerClassname="bg-blue"
                />

                <AlignmentOption
                  alignmentType={uppercase(formatText(t('CENTRE')))}
                  setAlignmentImage={setAlignmentImage}
                  Icon={CenterAlignIcon}
                  aligntooltip={t('CENTRE_ALIGN')}
                  iconContainerClassname="bg-blue"
                />
                <AlignmentOption
                  alignmentType={uppercase(formatText(t('RIGHT')))}
                  setAlignmentImage={setAlignmentImage}
                  Icon={RightAlignIcon}
                  aligntooltip={t('RIGHT_ALIGN')}
                  iconContainerClassname="bg-blue"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    dispatch(getServices());
  }, []);

  return (
    <AddQuoteTemplateWrapper className="flex-column justify-between items-center  ">
      <div className="px-10 pt-9 w-full flex justify-between items-center">
        <label className="font-20 inter-600-text text-black text-start line-height-28">{title}</label>
        <CrossIcon className="cursor" onClick={() => setModal(initModal)} width={24} height={24} />
      </div>
      <div className="px-10  h-auto w-full scrollable-section">
        <div className="w-full flex-column row-gap-6 mt-8 h-full  pb-7">
          <div className="w-50">
            {renderInput('name', t('TEMPLATE_NAME'), t('ENTER_NAME_QUOTES_TEMPLATE'), quoteDetail?.name, 'text')}
          </div>
          <div className="w-50">
            {renderInput(
              'description',
              t('DESCRIPTION'),
              t('ENTER_DESCRIPTION_QUOTES_TEMPLATE'),
              quoteDetail?.description,
              'textarea',
            )}
          </div>
          <div className="w-50">
            <FileUploadContainer
              file={file}
              onFileDrop={onFileDrop}
              setOnFile={setOnFile}
              onFileAdd={onFileAdd}
              removeFile={removeFile}
              onFile={onFile}
            />
          </div>
          <div className="w-full">
            {renderInput('terms', t('TERMS'), t('ENTER_TERMS_QUOTES_TEMPLATE'), quoteDetail?.terms, 'textarea', 0)}
          </div>
          <div className="w-full">
            {renderInput(
              'contact_details',
              t('CONTACT_DETAILS'),
              t('ENTER'),
              quoteDetail?.contact_details,
              'textarea',
              1,
            )}
          </div>
          <div className="flex items-center justify-between w-50 pb-6">
            <label className="inter-500-text natural-900-text mr-1">{capitalize(formatText(t('DEFAULT')))}</label>
            <Switch
              enabled={quoteDetail.isDefault}
              onClick={() => {
                setQuoteDetail({ ...quoteDetail, isDefault: !quoteDetail.isDefault });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex col-gap-5 justify-between w-full px-10 py-6 border-top popup-button-sec">
        <Button
          className={`default w-100px`}
          label={t('CANCEL')}
          disabled={loading}
          onClick={() => setModal(initModal)}
          secondary={true}
          size="large"
          borderRadius="100px"
        />
        <Button
          className={`primary w-100px`}
          label={t('ADD')}
          disabled={loading}
          loading={loading}
          onClick={onDone}
          size="large"
          borderRadius="100px"
        />
      </div>
    </AddQuoteTemplateWrapper>
  );
};

const AddQuoteTemplateWrapper = styled.div`
  width: 720px;
  max-height: 90vh;

  .scrollable-section {
    height: calc(100% - 161px);
    scrollbar-width: auto;
    overflow: auto;
    &::-webkit-scrollbar-track {
      scrollbar-width: auto;
      width: 10px;
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      scrollbar-width: auto;
      width: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      scrollbar-width: auto;
      background: rgb(36, 150, 255);
      border-radius: 10px;
      width: 10px;
    }
  }

  .input-element {
    outline: none;
    height: 44px;
    border: 1px solid ${({ theme }) => theme.lightGrey};
    border-radius: 6px;
    padding: 12px 16px;
  }

  .cross-icon {
    z-index: 999;
    background: ${({ theme }) => theme.white};
    border-radius: 100px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-align-tooltip {
    .custom-tooltip {
      min-width: auto;
      padding: 8px 12px;
      background-color: #fff;
      box-shadow: 0px 8px 15px -3px #1018281a;
    }
  }
  .popup-button-sec {
    background-color: ${({ theme }) => theme.white};
    border-radius: 0px 0px 16px 16px;
  }
  .error-border {
    border-color: ${({ theme }) => theme.additionalRed};

    .organisations-border {
      border-color: ${({ theme }) => theme.additionalRed} !important;
    }
  }
  .image-upload-box {
    width: 160px;
    height: 80px;
  }
  .off-droparea {
    border-color: ${({ theme }) => theme.natural_300};
    background-color: ${({ theme }) => theme.natural_50};
    padding: 15px;
  }
  .on-droparea {
    border: 1px solid ${({ theme }) => theme.natural_300};
  }

  .textarea {
    min-height: 87px !important;
    color: #171717;
  }
  .textarea::placeholder {
    opacity: 1 !important;
    color: ${({ theme }) => theme.natural_400} !important;
  }
`;

const FileUploadWrapper = styled.div`
  .progress-container {
    height: 4px;
    background-color: ${({ theme }) => theme.natural_200};
    border-radius: 100px;

    .progress-bar {
      background-color: ${({ theme }) => theme.primary_500};
      border-radius: 100px;
    }
  }

  .uploaded-file {
    .uploading {
      height: 48px;
      width: 48px;
      border-radius: 6px;
    }

    .uploaded {
      height: 24px;
      width: 24px;
      border-radius: 2px;
    }
  }

  .edit-file-input {
    .input-element {
      height: 24px;
      padding: 2px 8px;
    }
  }
`;

export default AddQuoteTemplate;
