import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { getEngagementCustomForms } from '../../../store/features/jobSlice';
import CustomWidgetForm from './custom-widget-form';

const JobDetailsCustomForms = ({ engagement }) => {
  const dispatch = useDispatch();

  const { id: engagement_id } = engagement || {};

  const [customForms, setCustomForms] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchEngagementCustomForms = () => {
    if (engagement_id) {
      setLoading(true);
      dispatch(getEngagementCustomForms({ engagement_id }))
        .then(data => {
          setCustomForms(data);
        })
        .catch(error => {
          console.log('error', error);
          setCustomForms([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    fetchEngagementCustomForms();
  }, [engagement_id]);

  if (loading) {
    return <Skeleton height={100} />;
  }

  if (customForms.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-column row-gap-2">
      {customForms.map(form => (
        <CustomWidgetForm key={form.id} form={form} engagement_id={engagement_id} />
      ))}
    </div>
  );
};

export default JobDetailsCustomForms;
