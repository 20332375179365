import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/menu/arrow-right-next.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { useError } from '../../hooks/useError';
import { getTaskSubTasks } from '../../store/features/taskSlice';
import { Avatar } from '../common/avatar-group';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import MenuV2 from '../common/menu/menu-v2';
import { statusOptions } from './task-list-item';

const TaskListItem = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { setSideModal, setModal } = useContext(OrganisationContext);
  const { id: taskId, task, arrows = false, fetchTasks, activityId } = props;
  const [showAccordion, setShowAccordion] = useState(null);
  const [subTasks, setSubTasks] = useState([]);

  const fetchSubTasks = () => {
    dispatch(getTaskSubTasks({ task_id: task?.id }))
      .then(data => {
        setSubTasks(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_SUB_TASKS') });
        setSubTasks([]);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (task?.id && task?.subtask_count > 0) {
      fetchSubTasks();
    }
  }, [task?.id]);

  const isAccordionOpened = task => {
    return task.id === showAccordion;
  };

  const handleShowTaskDetails = (data, isSubTask = false) => {
    setSideModal({
      type: 'view-task-details',
      content: {
        taskDetails: data,
        isSubTask: isSubTask,
        fromActivity: true,
        activityId: activityId,
        onSuccess: () => fetchTasks(),
      },
    });
  };

  const TaskContent = ({ taskData }) => {
    const {
      progress: { status },
    } = taskData || {};

    const selectedStatus = statusOptions.find(item => item?.value === status);

    const onDelete = () => {
      setModal({
        type: 'delete-task',
        content: {
          task: taskData,
          title: t('DELETE_TASK'),
          descriptions: t('ARE_YOU_SURE_TO_DELETE_THIS_TASK'),
          status: <StatusLabel data={selectedStatus} />,
          isFromActivity: true,
          onSuccess: () => {
            fetchTasks && fetchTasks();
          },
        },
      });
    };

    const getStatusStyles = status => {
      switch (status) {
        case 'COMPLETE':
          return {
            color: theme.success_500,
            background: theme.success_50,
          };
        case 'BLOCKED':
          return {
            color: theme.primary_500,
            background: theme.primary_50,
          };
        case 'STARTED':
          return {
            color: theme.purple_500,
            background: theme.purple_50,
          };
        default:
          return {
            color: theme.natural_700,
            background: theme.natural_150,
          };
      }
    };

    const StatusLabel = ({ data, isMainContent = false }) => {
      const { color, background } = useMemo(() => getStatusStyles(data?.value, theme), [data?.value, theme]);

      if (isMainContent) {
        return (
          <label
            style={{
              background,
              color,
              borderRadius: '50px',
              width: 'fit-content',
              height: '24px',
            }}
            onClick={e => {
              e.stopPropagation();
              setTooltipOpen(true);
            }}
            className="select-label flex items-center justify-between font-12 px-3 text-center gap-1">
            <label style={{ color }} className="font-12 inter-500-text fw-500">
              {data?.label}
            </label>
            <IconContainer
              Icon={ArrowDownIcon}
              iconColor={color}
              backgroundColor={background}
              iconContainerClassname="p-0"
              iconWidth={14}
              iconHeight={14}
            />
          </label>
        );
      } else {
        return (
          <label
            style={{
              background,
              color,
              borderRadius: '50px',
              height: '24px',
            }}
            className="flex items-center justify-center font-12 px-3 text-center w-full gap-1 font-12 fw-500">
            {data?.label}
          </label>
        );
      }
    };

    return (
      <>
        <div className="flex items-center overflow-hidden gap-4 py-3">
          <div className="flex flex-column items-start gap-1 overflow-hidden">
            <label className="inter-500-text font-14 natural-900-text one-line w-full">{taskData?.name}</label>
            <span className="inter-400-text font-14 natural-400-text text-wrap one-line w-full">
              {task?.description}
            </span>
          </div>
        </div>

        <div className="flex-column justify-center row-gap-2 task-items overflow-hidden py-3">
          <p className="inter-400-text font-14 natural-400-text">{taskData?.subtask_count}</p>
        </div>

        <div className="">
          <Avatar
            backgroundColor={theme.primary_500}
            avatar={taskData?.assignee?.id ? taskData?.assignee : { name: '' }}
            variant="semi_large"
            avatarWrapperClassname="assign-user"
          />
        </div>

        <div className="task-items py-3">
          <Button
            fontSize="12px"
            size="average"
            height="20"
            borderRadius="100px"
            className="primary-blue specified-width px-2_5 py-1"
            afterIcon={<AddIcon className="mr-0 blue-primary-icon" height={10} width={10} />}
            label={t('VIEW')}
            lableSize="line-height-20"
          />
        </div>
        <MenuV2
          menuList={[{ name: t('DELETE'), onClick: () => onDelete() }]}
          menuClassName=" h-fit-content property-menu"
          iconClassname="my-4"
        />
      </>
    );
  };

  return (
    <TaskListItemWrapper
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
      key={taskId}
      className="relative w-full">
      {task?.subtask_count && arrows ? (
        isAccordionOpened(task) ? (
          <ArrowDownIcon
            width={20}
            height={20}
            className={classNames('absolute arrow-icon cursor')}
            onClick={e => {
              task?.subtask_count && setShowAccordion('');
              e.stopPropagation();
            }}
          />
        ) : (
          <ArrowLeftIcon
            width={20}
            height={20}
            className={classNames('grey-text-04 absolute arrow-icon cursor')}
            onClick={e => {
              task?.subtask_count && setShowAccordion(task?.id);
              e.stopPropagation();
            }}
          />
        )
      ) : (
        <Fragment />
      )}
      <div
        onClick={() => handleShowTaskDetails(task, false)}
        className={classNames(
          'data-container cursor items-center sidebar-close-ignore',
          !isAccordionOpened(task) && 'border-bottom',
        )}>
        <TaskContent taskData={task} />
      </div>

      {isAccordionOpened(task) &&
        subTasks &&
        subTasks.map(taskData => (
          <div
            key={taskData?.id}
            className={classNames('tasks-list flex-column gap-3', isAccordionOpened(task) && 'opened-subtask')}>
            <div
              onClick={() => handleShowTaskDetails(taskData, true)}
              className="data-container cursor items-center border-bottom sidebar-close-ignore">
              <TaskContent taskData={taskData} />
            </div>
          </div>
        ))}
    </TaskListItemWrapper>
  );
};

const TaskListItemWrapper = styled(motion.div)`
  .arrow-icon {
    left: 14px;
    top: 20px;
  }

  &:hover {
    background: ${({ theme }) => theme.natural_50};
  }
  .status-tooltip {
    position: relative;
    .custom-tooltip {
      position: fixed !important;
      padding: 0;
      height: auto;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      border-radius: 6px;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }

    .proposal-config {
      &:hover {
        background-color: ${({ theme }) => theme.natural_100};
      }
    }
    .assign-user {
      border: none;
      transition: all 0.1s;
      &:hover {
        box-shadow: #d3eafe 0px 1px 4px, #d3eafe 0px 0px 0px 3px;
      }
    }
  }
`;

export default TaskListItem;
