import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as EditPensil } from '../../assets/icons/edit-pensil.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import CommonStepper from '../../components/common/stepper-common';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText, initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getAccountsListItems } from '../../store/features/accountsSlice';
import { createJob, getStagesListData, getWorkflowList } from '../../store/features/jobSlice';
import { getPropertyContacts } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import { getUsers } from '../../store/features/userManagementSlice';
import JobsInfo from './jobs-Info';

const JobDetails = ({ job, setJob, error, setError, property }) => {
  const { t } = useTranslation();

  const {
    leadOrganization,
    project,
    leadUser,
    job_type,
    assignOrganization,
    assignUser,
    workflow,
    workflowStartAt,
    primary_contact,
  } = job;

  const [searchedLeadOrganization, setSearchedLeadOrganization] = useState('');
  const [searchedJobType, setSearchedJobType] = useState('');
  const [searchedLeadUser, setSearchedLeadUser] = useState('');
  const [searchedAssignOrganization, setSearchedAssignOrganization] = useState('');
  const [searchedAssignUser, setSearchedAssignUser] = useState('');
  const [searchedWorkflow, setSearchedWorkflow] = useState('');
  const [startAt, setStartAt] = useState('');
  const { userOrganization } = useSelector(state => state.user);
  const propertyId = property?.id;

  const onJobUpdate = (key, value) => {
    let additionalUpdate = {};
    if (key === 'job_type' || key === 'project') {
      additionalUpdate = {
        workflow: null,
      };
    }
    if (key === 'leadOrganization') {
      additionalUpdate = {
        leadUser: null,
      };
    }
    if (key === 'assignOrganization') {
      additionalUpdate = {
        assignUser: null,
      };
    }
    if (key === 'workflow') {
      additionalUpdate = {
        workflowStartAt: null,
      };
    }
    setJob({ ...job, ...additionalUpdate, [key]: value });
    setError({ ...error, [key]: error?.[key] && !value });
  };

  const renderContactInfo = (contacts, contactType) => {
    if (contacts && contacts.length > 0) {
      const filteredContacts = contactType
        ? contacts.filter(contact => contact.contact_type === contactType)
        : contacts;

      return (
        <>
          {filteredContacts.map(contact => (
            <div key={contact.id}>
              <span className={'inter-400-text natural-700-text font-12 option-text'}>{contact.value}</span>
              {contact?.formatted_address && (
                <div className="title-one-line one-line">
                  <span key={contact.id} className={'inter-400-text natural-700-text font-12 option-text '}>
                    {contact?.formatted_address}
                  </span>
                </div>
              )}
            </div>
          ))}
        </>
      );
    }
    return null;
  };

  const CustomOption = props => {
    const { innerProps, data } = props;

    return (
      <div className={'w-full px-4 py-3 cursor border-bottom option-wrapper'} {...innerProps}>
        <div className="flex items-center justify-between pb-1_5 gap-3 select-primary-contact">
          <span className={'inter-400-text natural-900-text font-14 option-text'}>
            {data.contact_type === 'COMPANY' ? data.company_name : data.label}
          </span>
          <div className="bg-pink radius-4 px-3 py-0_5 line-height-1 ">
            <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
              {capitalize(formatText(data?.property_contact_type))}
            </label>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {renderContactInfo(data.emails, null)}
          <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
          {renderContactInfo(data.phones, 'MOBILE')}
          <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
          {renderContactInfo(data.phones, 'LANDLINE')}
        </div>
        <div>
          <span className={'inter-400-text natural-900-text font-14 option-text'}>
            {renderContactInfo(data.addresses, null)}
          </span>
        </div>
      </div>
    );
  };

  const CustomOptionSelect = props => {
    const { data, ...otherProps } = props;

    return (
      <div className={'w-full  cursor select-option-primary'} {...otherProps}>
        <div className="flex items-center gap-3 select-primary-contact absolute input-center">
          <span className={'inter-400-text natural-900-text font-14 option-text'}>
            {data.contact_type === 'COMPANY' ? data.company_name : data.label}
          </span>
          <div className="bg-pink radius-4 px-3 py-0_5 line-height-1 ">
            <label className={'inter-400-text color-purple font-12 option-text line-height-20'}>
              {capitalize(formatText(data?.property_contact_type))}
            </label>
          </div>
          <div className="flex items-center gap-2">
            {renderContactInfo(data.emails, null)}
            <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
            {renderContactInfo(data.phones, 'MOBILE')}
            <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
            {renderContactInfo(data.phones, 'LANDLINE')}
            {renderContactInfo(data.addresses, null) && (
              <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
            )}
          </div>
          <div>
            <span className={'inter-400-text natural-900-text font-14 option-text'}>
              {renderContactInfo(data.addresses, null)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-column row-gap-8">
      <div>
        <div>
          <SearchableDropdown
            isClearable
            inputValue={searchedJobType}
            onInputChange={setSearchedJobType}
            placeholder={t('SELECT_PRIMARY_CONTACT')}
            name={t('PRIMARY_CONTACT')}
            value={primary_contact}
            customClass={false}
            onChange={option => onJobUpdate('primary_contact', option)}
            defaultAdditional={{
              page: 0,
              fetchFunction: getPropertyContacts,
              pageable: false,
              payload: { property_id: propertyId },
            }}
            isSearchable={true}
            isCustomSearchable={false}
            error={error.primary_contact && !primary_contact}
            customComponent={{ Option: CustomOption, SingleValue: CustomOptionSelect }}
          />
        </div>
      </div>
      <div>
        <p className="inter-600-text font-16 color-neutral-900 mb-4">{t('LEAD_GEN_SOURCE')}</p>
        <div className="job-details row-gap-5 col-gap-5">
          <SearchableDropdown
            isClearable
            inputValue={searchedLeadOrganization}
            onInputChange={setSearchedLeadOrganization}
            className="w-full"
            placeholder={t('SELECT')}
            name={t('ORGANISATION')}
            value={leadOrganization}
            onChange={option => onJobUpdate('leadOrganization', option)}
            isSearchable={true}
            sub_name={t('OPTIONAL')}
            defaultAdditional={{
              page: 0,
              fetchFunction: getAccountsListItems,
              payload: { organization_id: userOrganization?.id },
              params: {
                category_tag: 'LEAD_GENERATION',
              },
              pageable: false,
            }}
            error={error.leadOrganization && !job.leadOrganization}
          />
          <SearchableDropdown
            isClearable
            inputValue={searchedLeadUser}
            onInputChange={setSearchedLeadUser}
            key={leadOrganization?.id}
            className="w-full"
            placeholder={t('SELECT')}
            name={t('USER')}
            sub_name={t('OPTIONAL')}
            value={leadUser}
            onChange={option => onJobUpdate('leadUser', option)}
            isSearchable={true}
            isDisabled={leadOrganization ? false : true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getUsers,
              params: {
                role_tag: 'LEAD_GENERATION',
                organization_id: leadOrganization?.id,
              },
              pageable: true,
            }}
          />
        </div>
      </div>
      <div>
        <p className="inter-600-text font-16 color-neutral-900 mb-4">{t('ASSIGN_PARTNER')}</p>
        <div className="job-details row-gap-5 col-gap-5">
          <SearchableDropdown
            isClearable
            inputValue={searchedAssignOrganization}
            onInputChange={setSearchedAssignOrganization}
            className="w-full"
            placeholder={t('SELECT')}
            name={t('ORGANISATION')}
            sub_name={t('OPTIONAL')}
            value={assignOrganization}
            onChange={option => onJobUpdate('assignOrganization', option)}
            isSearchable={true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getAccountsListItems,
              pageable: false,
              payload: { organization_id: userOrganization?.id },
              params: {
                relationship_type: 'PARTNER',
              },
            }}
            error={error.assignOrganization && !job.assignOrganization}
          />
          <SearchableDropdown
            isClearable
            key={assignOrganization?.id}
            inputValue={searchedAssignUser}
            onInputChange={setSearchedAssignUser}
            className="w-full"
            placeholder={t('SELECT')}
            name={t('USER')}
            sub_name={t('OPTIONAL')}
            value={assignUser}
            onChange={option => onJobUpdate('assignUser', option)}
            isSearchable={true}
            isDisabled={assignOrganization ? false : true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getUsers,
              params: { organization_id: assignOrganization?.id },
              pageable: true,
            }}
          />
        </div>
      </div>
      <div>
        <p className="inter-600-text font-16 color-neutral-900 mb-4">{t('WORKFLOW')}</p>
        <div className="job-details row-gap-5 col-gap-5">
          <SearchableDropdown
            isClearable
            inputValue={searchedWorkflow}
            onInputChange={setSearchedWorkflow}
            className="w-full"
            placeholder={t('SELECT_WORKFLOW')}
            name={t('WORKFLOW')}
            value={workflow}
            menuPlacement="top"
            onChange={option => onJobUpdate('workflow', option)}
            isSearchable={true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getWorkflowList,
              params: { project_id: project.id, job_type_id: job_type.id },
              pageable: false,
            }}
            error={error.workflow && !job.workflow}
          />

          <SearchableDropdown
            isClearable
            isCustomSearchable={false}
            inputValue={startAt}
            key={workflow?.id}
            onInputChange={setStartAt}
            className="w-full"
            placeholder={t('SELECT')}
            name={t('START_AT')}
            value={workflowStartAt}
            menuPlacement="top"
            onChange={option => onJobUpdate('workflowStartAt', option)}
            isSearchable={true}
            defaultAdditional={{
              page: 0,
              fetchFunction: getStagesListData,
              payload: { workflowId: workflow?.id || 'noId' },
              params: { stage_type: 'CREATOR,TRANSITION' },
              pageable: false,
              formatOptions: options => {
                const formattedOptions = options.reduce((acc, curr) => {
                  const { decisions, stage_type, name, id } = curr;
                  const newDecisions = decisions
                    ? decisions.map(d => ({
                        ...d,
                        label: stage_type === 'CREATOR' ? d.stage.name : name,
                        stage_type,
                        stage_id: id,
                      }))
                    : [];
                  return [...acc, ...newDecisions];
                }, []);
                return formattedOptions;
              },
            }}
            isDisabled={workflow ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

const JobSummary = ({ job, setStep, setActiveStep, property }) => {
  const {
    job_type,
    job_name,
    project,
    campaign_type,
    workflow,
    expected_completion,
    deadline,
    job_value,
    job_description,
    job_number,
    po_number,
    leadOrganization,
    leadUser,
    assignOrganization,
    assignUser,
    workflowStartAt,
  } = job;

  const { line1, postcode } = property;

  const jobName = job_name || `${line1}, ${postcode}, ${job_type?.name}`;

  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const renderData = ({
    key,
    value,
    defaultValuePlaceholder,
    renderDataClassName,
    hideTooltip,
    readMore = false,
    isCustom = false,
    tooltipValue,
  }) => {
    const valLength = readMore && typeof value === 'string' && value.length > 70;
    const truncatedValue = valLength ? (showMore ? value : value.slice(0, 65)) : value;

    return (
      <div className="flex justify-between">
        <span className="inter-400-text natural-500-text w-50">{key}</span>
        <span className="inter-400-text w-50 flex justify-end max-w-50 relative">
          <CustomTooltip
            wrapperClassName="flex-column row-gap-2 justify-center overflow-hidden"
            id={hideTooltip ? '' : isCustom ? tooltipValue : value}
            place="top"
            hidden={!value || hideTooltip}
            content={
              <div className="tooltip-container">
                <label className="inter-500-text font-12">{value ? value : defaultValuePlaceholder}</label>
              </div>
            }>
            <span
              className={classNames(
                !showMore && 'hide',
                value ? 'natural-900-text' : 'natural-400-text',
                renderDataClassName,
              )}>
              {readMore
                ? typeof value === 'string'
                  ? truncatedValue
                  : defaultValuePlaceholder
                : value || defaultValuePlaceholder}

              {valLength && (
                <>
                  {!showMore && '...'}
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className="text-button inter-500-text self-end cursor font-12 blue-primary-icon absolute description-show-more-less ">
                    {showMore ? t('SHOW_LESS') : t('SHOW_MORE')}
                  </button>
                </>
              )}
            </span>
          </CustomTooltip>
        </span>
      </div>
    );
  };

  const handleTabChange = (tab, index) => {
    setStep(tab);
    setActiveStep(index);
  };

  return (
    <div className="summary-details col-gap-10">
      <div className="flex-column row-gap-6 left-container-wrapper">
        <div className="flex items-center justify-between border-bottom pb-2">
          <label className="inter-600-text natural-900-text font-16">{t('JOB_INFO')}</label>
          <IconContainer
            onClick={() => handleTabChange('INFO', 0)}
            iconContainerClassname="cursor"
            Icon={EditPensil}
            iconColor="primary_500"
            backgroundColor="primary_50"
          />
        </div>
        <div className="flex-column row-gap-3">
          {renderData({ key: 'Project:', value: project?.name, renderDataClassName: 'one-line' })}
          {renderData({ key: 'Campaign:', value: campaign_type?.name, defaultValuePlaceholder: '-' })}
          {renderData({ key: 'Job type:', value: job_type?.name })}
          {renderData({ key: 'Job name:', value: jobName })}
          {renderData({
            key: 'Job description:',
            value: job_description,
            hideTooltip: true,
            readMore: true,
            renderDataClassName: !showMore && 'three-lines',
          })}
          {renderData({
            key: 'Job Number:',
            value: job_number,
            defaultValuePlaceholder: 'Generated on creation',
            hideTooltip: !job_number,
          })}
          {renderData({ key: 'PO Number:', value: po_number, defaultValuePlaceholder: '-', hideTooltip: !po_number })}
          {renderData({ key: 'Estimated job value:', value: job_value, defaultValuePlaceholder: '-' })}
          {renderData({
            key: 'Expected completion:',
            value: expected_completion && moment.unix(expected_completion).format('DD/MM/YYYY'),
            defaultValuePlaceholder: '-',
            hideTooltip: true,
          })}
          {renderData({
            key: 'Deadline:',
            value: deadline && moment.unix(deadline).format('DD/MM/YYYY'),
            defaultValuePlaceholder: '-',
            hideTooltip: true,
          })}
        </div>
      </div>
      <div className="flex-column row-gap-6 right-container-wrapper">
        <div className="flex items-center justify-between border-bottom pb-2">
          <label className="inter-600-text natural-900-text font-16">{t('JOB_DETAILS')}</label>
          <IconContainer
            onClick={() => handleTabChange('DETAILS', 1)}
            iconContainerClassname="cursor"
            Icon={EditPensil}
            iconColor="primary_500"
            backgroundColor="primary_50"
          />
        </div>
        <div className="flex-column row-gap-3">
          {renderData({
            key: 'Lead Gen Source:',
            isCustom: true,
            tooltipValue: leadOrganization?.name,
            value: (
              <div className="flex-column justify-end items-end">
                {leadOrganization?.name ? (
                  <>
                    <p className="font-14">{leadOrganization.name}</p>
                    {leadUser?.name && <span className="font-14 color-gray-secondary">{leadUser?.name}</span>}
                  </>
                ) : (
                  <p className="font-14 natural-400-text">{t('NO_LEAD_GENERATOR_SELECTED')}</p>
                )}
              </div>
            ),
          })}
          {renderData({
            key: 'Assign partner:',
            isCustom: true,
            tooltipValue: assignOrganization?.name,
            value: (
              <div className="flex-column justify-end items-end">
                {assignOrganization?.name ? (
                  <>
                    <p className="font-14">{assignOrganization.name}</p>
                    {assignUser?.name && <span className="font-14 color-gray-secondary">{assignUser?.name}</span>}
                  </>
                ) : (
                  <p className="font-14 natural-400-text">{t('NO_PARTNER_SELECTED')}</p>
                )}
              </div>
            ),
          })}
          {renderData({ key: 'Workflow:', value: workflow.name, hideTooltip: true })}
          {renderData({
            key: 'Start at:',
            value: workflowStartAt?.label,
            defaultValuePlaceholder: 'Creator',
            hideTooltip: true,
          })}
        </div>
      </div>
    </div>
  );
};

const AddJob = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { modal, setModal } = useContext(OrganisationContext);
  const { property, onSuccess } = modal?.content || {};

  const [job, setJob] = useState({});
  const [step, setStep] = useState('INFO');
  const [error, setError] = useState({});
  const [detailsError, setDetailsError] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const checkJobErrors = () => {
    const { project, job_type } = job;
    if (!project || !job_type) {
      setError({
        project: !project,
        job_type: !job_type,
      });
      showErrorToast({ default_message: t('PLEASE_FILL_THE_ALL_FIELDS') });
      return true;
    }
    return false;
  };

  const checkDetailsErrors = () => {
    const { workflow, primary_contact } = job;
    if (!workflow || !primary_contact?.id) {
      setDetailsError({
        workflow: !workflow,
        primary_contact: !primary_contact?.id,
      });
      showErrorToast({ default_message: t('PLEASE_FILL_THE_ALL_FIELDS') });
      return true;
    }
    return false;
  };

  const onDone = async () => {
    const { line1, postcode } = property || {};
    const {
      assignOrganization,
      campaign_type,
      assignUser,
      job_type,
      job_name,
      job_number = '',
      job_value,
      job_description = '',
      expected_completion,
      deadline,
      po_number = '',
      leadUser,
      primary_contact,
      leadOrganization,
      project,
      workflow,
      workflowStartAt,
    } = job;
    const formattedJobValue = job_value?.replace('£', '') || null;
    const jobName = job_name ? job_name.trim() : `${line1}, ${postcode}, ${job_type?.name}`;
    const engagementDates =
      expected_completion || deadline
        ? {
            expected_completion: expected_completion || null,
            deadline: deadline || null,
          }
        : null;
    const engagementValue = formattedJobValue
      ? {
          budget: formattedJobValue,
          contracted_amount: formattedJobValue,
          currency: 'GBP',
        }
      : null;

    const { stage_type, stage_id, id: route_id, stage } = workflowStartAt || {};
    const workflowRequest = {
      id: workflow.id,
      stage: stage?.id
        ? stage_type === 'CREATOR'
          ? {
              id: stage?.id,
              route: {
                id: route_id,
              },
            }
          : { id: stage_id }
        : null,
    };

    setLoading(true);
    const request = {
      job_type: { id: job_type.id },
      engagement: {
        parent: null,
        quote: null,
        agent: null,
        primary_contact: primary_contact?.id
          ? {
              ...primary_contact,
              phones: (primary_contact?.phones || [])
                .map(p => ({ ...p, contact_value: p.value }))
                .filter(p => p.contact_value),
            }
          : null,
        workflow: workflowRequest,
        campaign: campaign_type?.id ? { id: campaign_type.id } : null,
        property: { id: property.id },
        name: jobName,
        project: { id: project.id },
        engagement_value: engagementValue,
        engagement_dates: engagementDates,
      },
      job_number: job_number,
      po_number: po_number,
      job_description: job_description,
      partners: assignOrganization
        ? [
            {
              organization: {
                id: assignOrganization.id,
              },
              user: assignUser?.id
                ? {
                    id: assignUser.id,
                  }
                : null,
            },
          ]
        : null,
      lead_gen: leadOrganization
        ? {
            user: leadUser?.id
              ? {
                  id: leadUser.id,
                }
              : null,
            organization: {
              id: leadOrganization.id,
            },
          }
        : null,
    };
    try {
      await dispatch(createJob({ request }));
      setLoading(false);
      onSuccess && onSuccess();
      setModal(initModal);
      dispatch(addToast({ error: false, text: t('JOB_CREATE_SUCCESSFULLY') }));
    } catch (error) {
      setLoading(false);
      showErrorToast({ error, default_message: t('ERROR_WHILE_CREATING_JOB') });
    }
  };

  const onNextOrDone = () => {
    if (step === 'INFO') {
      if (checkJobErrors()) return;
      setActiveStep(activeStep + 1);
      setStep('DETAILS');
    } else if (step === 'DETAILS') {
      if (checkDetailsErrors()) return;
      setActiveStep(activeStep + 1);
      setStep('SUMMARY');
    } else {
      onDone();
    }
  };
  const onBackDetail = () => {
    if (step === 'DETAILS') {
      setActiveStep(activeStep - 1);
      setStep('INFO');
    } else if (step === 'SUMMARY') {
      setActiveStep(activeStep - 1);
      setStep('DETAILS');
    }
  };

  const steps = [{ title: 'Info' }, { title: 'Details' }, { title: 'Summary' }];

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddJobWrapper className="flex-column">
        <div className="px-10 pt-8 pb-5 border-bottom flex items-center justify-between">
          <p className="inter-700-text natural-900-text font-24 mb-2">{t('ADD_NEW_JOB')}</p>
          <div className="flex items-center h-40px">
            <CommonStepper steps={steps} actvieStep={activeStep} />
          </div>
        </div>
        <div className="px-10 py-6">
          {step === 'INFO' && (
            <JobsInfo job={job} setJob={setJob} setError={setError} error={error} property={property} />
          )}
          {step === 'DETAILS' && (
            <JobDetails job={job} setJob={setJob} setError={setDetailsError} error={detailsError} property={property} />
          )}
          {step === 'SUMMARY' && (
            <JobSummary job={job} setActiveStep={setActiveStep} setStep={setStep} property={property} />
          )}
        </div>
        <div className="flex px-10 py-6 justify-between w-full border-top">
          <Button
            className={`primary-grey specified-width ${loading && 'disabled'}`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
            width="100px"
          />
          <div className="flex items-center gap-3">
            {(step === 'SUMMARY' || step === 'DETAILS') && (
              <Button
                className="secondary specified-width"
                label={t('BACK')}
                disabled={loading}
                onClick={onBackDetail}
                size="large"
                borderRadius="100px"
                width="100px"
              />
            )}
            <Button
              className="primary specified-width"
              label={step === 'DETAILS' || step === 'INFO' ? t('NEXT') : t('ADD')}
              disabled={loading}
              onClick={onNextOrDone}
              size="large"
              borderRadius="100px"
              width="100px"
            />
          </div>
        </div>
      </AddJobWrapper>
    </CSSTransition>
  );
};

const AddJobWrapper = styled.div`
  width: 900px;

  .span-2 {
    grid-column: span 2;
  }

  .input-center {
    top: 50%;
    transform: translateY(-50%);
  }

  .input-element.textarea-element {
    height: 44px;
    max-height: 200px;
  }

  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  .summary-details,
  .job-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .right-container-wrapper,
  .left-container-wrapper {
    max-width: 390px;
  }

  .tooltip-container {
    max-width: 300px;
  }

  .job-value-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .project-info {
    .custom-tooltip {
      padding: 8px 12px 8px 10px;
      border-radius: 6px;
      background: #404040;
      label {
        color: ${({ theme }) => theme.white};
      }
    }
  }
  .description-show-more-less {
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.white};
  }
  .three-lines {
    word-break: break-all !important;
  }

  .text-ellipsis-multi-line {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    max-height: 150px;
    overflow: auto;
    &.hide {
      overflow: hidden;
      -webkit-line-clamp: 3;
    }
  }
`;

export default AddJob;
