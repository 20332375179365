import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/up-arrow.svg';
import { capitalize, formatText, getFormattedDate } from '../../../helpers/utils';
import IconContainer from '../../common/icon-container';

const AppliedFilters = ({
  boardsFilters,
  setBoardsFilters = () => {},
  setOrderBy = () => {},
  setSortBy = () => {},
  onClearAllFilters = () => {},
  sortBy,
  orderBy,
  showClearAll = true,
  canRemoveFilters = true,
  canRemoveSort = true,
  containerClassname = 'px-6 pt-6 pb-2',
}) => {
  const { t } = useTranslation();
  const { priority, status, due_date, assignee } = boardsFilters || {};

  const priorityValue = (priority || []).map(item => item?.name).join(', ') || '';
  const statusValue = (status || []).map(item => item?.name).join(', ') || '';
  const assigneeValue = (assignee || []).map(item => item?.name).join(', ') || '';

  const handleRemoveFilters = key => {
    const { [key]: _, ...updatedFilters } = boardsFilters;
    setBoardsFilters(updatedFilters);
  };

  const RenderContent = ({ icon, name, onRemoveClick, value }) => {
    return (
      <div className="inter-400-text natural-400-text font-12 one-line flex items-center gap-1 px-2 py-1 border radius-full">
        {!!icon && (
          <IconContainer
            Icon={icon}
            iconHeight={12}
            iconWidth={12}
            iconColor={'natural_700'}
            backgroundColor="transparent"
            iconContainerClassname="p-0"
          />
        )}
        <div>
          {name}: <span className="inter-500-text natural-700-text font-12">{value}</span>
        </div>
        {canRemoveFilters && (
          <IconContainer
            Icon={CrossIcon}
            iconHeight={12}
            iconWidth={12}
            iconColor={'natural_700'}
            backgroundColor="transparent"
            iconContainerClassname="p-0 cursor"
            onClick={onRemoveClick}
          />
        )}
      </div>
    );
  };

  return (
    <div className={classNames(containerClassname)}>
      <div className="flex items-center gap-4 pb-2 overflow-x-auto w-full overflow-scroll custom-horizontal">
        <div className="flex items-center gap-2">
          <label className="inter-500-text natural-700-text font-12 one-line">Sorted by:</label>
          {sortBy?.length > 0 && (
            <div className="flex items-center gap-3">
              {sortBy.map(sort => (
                <div
                  key={sort.id}
                  className="inter-400-text natural-700-text font-12 one-line flex items-center gap-1 px-2 py-1 border radius-full">
                  <IconContainer
                    Icon={ArrowDownIcon}
                    iconHeight={12}
                    iconWidth={12}
                    iconColor={'natural_700'}
                    backgroundColor="transparent"
                    iconContainerClassname={classNames('p-0', { 'rotate-180': sort.order_by === 'desc' })}
                  />
                  <div className="inter-400-text natural-400-text font-12 col-gap-1 flex">
                    {capitalize(formatText(sort.name, ' '))} :
                    <span className="inter-500-text natural-700-text font-12">
                      {sort?.order_by_text?.[sort.order_by] || 'Ascending'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {(priorityValue || statusValue || assigneeValue || due_date) && (
          <div className="flex items-center gap-2">
            <label className="inter-500-text natural-700-text font-12 one-line">Filterd by:</label>
            <div className="flex items-center gap-3">
              {priorityValue && (
                <RenderContent
                  name={'Priority is'}
                  onRemoveClick={() => handleRemoveFilters('priority')}
                  value={priorityValue}
                />
              )}
              {assigneeValue && (
                <RenderContent
                  name={'Assignee is'}
                  onRemoveClick={() => handleRemoveFilters('assignee')}
                  value={assigneeValue}
                />
              )}
              {statusValue && (
                <RenderContent
                  name={'Status is'}
                  onRemoveClick={() => handleRemoveFilters('status')}
                  value={statusValue}
                />
              )}
              {due_date && (
                <RenderContent
                  name={'Due date is'}
                  onRemoveClick={() => handleRemoveFilters('due_date')}
                  value={getFormattedDate(due_date, 'Do MMM, YYYY', false)}
                />
              )}
            </div>
          </div>
        )}
        {showClearAll && (priority || status || due_date) && (
          <div className="cursor inter-500-text primary-500-text underline font-12" onClick={onClearAllFilters}>
            <div className="w-48px">{t('CLEAR_ALL')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppliedFilters;
