import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as NoDocumentIcon } from '../../assets/icons/empty-document.svg';
import { useError } from '../../hooks/useError';
import { getIntegrationList, setIntegrationList } from '../../store/features/integrationsSlice';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';
import TableHeader from '../common/table-header';
import IntegrationListItem from './integration-list-item';

const integrationTableHeaders = [
  { name: 'INTEGRATION', key: 'INTEGRATION' },
  { name: 'DESCRIPTION', key: 'DESCRIPTION' },
  { name: 'PROVIDER', key: 'PROVIDER' },
  { name: 'ENABLED', key: 'ENABLED' },
  { name: '', key: 'MENU' },
];

const IntegrationList = ({ debouncedSearch = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { integrationList } = useSelector(state => state.integrations);

  const [loading, setLoading] = useState(true);

  const fetchIntegrationList = (debouncedSearch, showLoading = true) => {
    setLoading(showLoading);
    dispatch(
      getIntegrationList({
        storeData: true,
        params: {
          search: debouncedSearch,
        },
      }),
    )
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_INEGRATIONS') }))
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchIntegrationList(debouncedSearch, false);
  };

  useEffect(() => {
    fetchIntegrationList(debouncedSearch, true);
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(setIntegrationList([]));
    };
  }, []);

  return (
    <IntegrationListWrapper className="flex-column flex-1  radius-1 mt-6 mx-6 overflow-hidden">
      <SkeletonTransition
        loading={loading}
        loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
        height={'100%'}
        containerClassName="line-height-1 h-full w-full">
        {(integrationList || []).length > 0 ? (
          <div className="w-full card radius-1_5 border overflow-scroll">
            <div className="flex-column w-fit-content min-w-full h-auto">
              <TableHeader headers={integrationTableHeaders} />
              <div className="flex-column flex-1 overflow-scroll">
                {(integrationList || []).map((integration, index) => (
                  <IntegrationListItem
                    isFirst={index === 0}
                    integration={integration}
                    key={integration.id}
                    menuPortal={integrationList?.length < 3}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : !debouncedSearch ? (
          <div className="flex-column flex-1 mb-6 items-center justify-center">
            <NoData
              title={t('NO_RESULTS_FOUND')}
              description={t('NO_RESULTS_DESCRIPTION')}
              EmptyIcon={NoDocumentIcon}
            />
          </div>
        ) : (
          <div className="flex-column flex-1 mb-6 items-center justify-center">
            <NoData
              title={t('NO_INTEGRATIONS')}
              description={t('NO_INTEGRATIONS_DESCRIPTION')}
              EmptyIcon={NoDocumentIcon}
            />
          </div>
        )}
      </SkeletonTransition>
    </IntegrationListWrapper>
  );
};

export const IntegrationListWrapper = styled.div`
  border-radius: 6px;
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(300px, 2fr) 200px 80px 40px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 52px;
    background-color: ${({ theme }) => theme.natural_50};
  }

  .no-result-container,
  .no-data-container {
    width: 500px;
  }
`;

export default IntegrationList;
