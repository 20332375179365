import { ReactComponent as CrossIcon } from '@assets/icons/CrossIcons.svg';
import { ReactComponent as UploadFileIcon } from '@assets/icons/upload-file.svg';
import { ReactComponent as Loader } from '@assets/images/loading.svg';
import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as PlaceholderImage } from '../assets/icons/camera-icon.svg';
import { ReactComponent as CenterAlignIcon } from '../assets/icons/center-alignment.svg';
import { ReactComponent as LeftAlignIcon } from '../assets/icons/left-alignment.svg';
import { ReactComponent as RightAlignIcon } from '../assets/icons/right-alignment.svg';
import IconContainer from '../components/common/icon-container';
import Status from '../components/common/status';
import Switch from '../components/common/switch';
import CustomTooltip from '../components/common/tooltip-new';
import { OrganisationContext } from '../context/organisationContext';
import {
  bytesToSize,
  capitalize,
  capitalizeFirstLetterOfWords,
  formatText,
  getErrorFieldJoined,
  initSideModal,
} from '../helpers/utils';
import { useError } from '../hooks/useError';
import { uploadFile } from '../store/features/fileuploadSlice';
import { getQuoteTemplateDetail, updateQuotesTemplate } from '../store/features/quotesSlice';
import { addToast } from '../store/features/toastSlice';
import SideModelHeader from './sidemodel-header';

const imageExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/svg'];
const maxFileSize = 100 * 1024;

const FileUploadContainer = ({ setValue, value, file, setFile }) => {
  const { showErrorToast } = useError();

  const onChange = value => {
    setValue(quote => ({ ...quote, image: { ...quote.image, alignment: value } }));
  };
  const { alignment } = value || {};
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [onFile, setOnFile] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onAddFile = addedFile => {
    if (!imageExtensions.includes(addedFile.type)) {
      showErrorToast({ default_message: t('ONLY_IMAGE_UPLOAD_ALLOW') });
      return setFile(null);
    }

    if (addedFile.size > maxFileSize) {
      showErrorToast({ default_message: t('PLEASE_UPLOADFILE_MAX_SIZE') });
      return setFile(null);
    }
    const newFile = {
      file_data: addedFile,
      name: addedFile.name,
      size: addedFile.size,
      type: addedFile.type,
      url: URL.createObjectURL(addedFile),
      id: nanoid(),
    };

    setFileUploading(true);

    dispatch(
      uploadFile({
        file: newFile?.file_data,
        onSuccess: data => {
          setFileUploading(false);
          setFile({ ...newFile, media_id: data?.media_id, media_url: data?.url, created_on: moment().unix() });
        },
        onError: error => {
          showErrorToast({ error, default_message: t('ERROR_WHILE_UPLOADING_FILE', { name: newFile?.name }) });
          setFileUploading(false);
        },
        setProgress,
      }),
    );
  };

  const onFileDrop = e => {
    e.preventDefault();
    onAddFile(e.dataTransfer?.files[0]);
    setOnFile(true);
  };

  const onFileAdd = e => {
    e.preventDefault();
    onAddFile(e.target.files[0]);
    e.target.value = null;
    setOnFile(true);
  };

  const removeFile = () => {
    setFile(null);
  };

  return (
    <>
      <div className="flex col-gap-4 items-center">
        <div
          className={classNames(
            'border-dotted radius-1_5 flex-column items-center justify-center image-upload-box',
            onFile ? 'on-droparea' : 'off-droparea',
          )}
          onDragLeave={e => {
            e.preventDefault();
            setOnFile(false);
          }}
          onDragOver={e => {
            e.preventDefault();
            setOnFile(true);
          }}
          onDrop={onFileDrop}>
          {fileUploading ? (
            <>
              <Loader width={40} height={40} />
            </>
          ) : file ? (
            <div className="relative h-full w-full">
              <img src={file.url} alt="Uploaded" className="radius-1_5" style={{ width: '100%', height: '100%' }} />
              <div className="absolute top-0 right-0 cross-icon">
                <CrossIcon className="cursor natural-700-text" onClick={removeFile} width={12} height={12} />
              </div>
            </div>
          ) : (
            <div>
              <IconContainer
                Icon={UploadFileIcon}
                iconColor="natural_300"
                backgroundColor="transparent"
                iconWidth={32}
                iconHeight={32}
              />
              <div>
                <input type="file" id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
                <label
                  htmlFor="file-upload-input"
                  className="primary-text font-12 inter-500-text line-height-20 cursor">
                  {t('BROWSE')}
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="flex-column">
          <label className="font-14 inter-500-text natural-900-text line-height-20 ">{t('UPLOAD_IMAGE')}</label>
          <label className="font-12 inter-400-text natural-400-text line-height-20">
            {t('MAXIMUM_FILE_SIZE_100KB')}
          </label>
          <div className="flex items-center col-gap-2 pt-2">
            <label className="font-12 inter-500-text natural-900-text line-height-20">{t('ALIGNMENT')}</label>
            {['LEFT', 'CENTRE', 'RIGHT'].map(item => (
              <CustomTooltip
                wrapperClassName="file-align-tooltip w-fit-content h-fit-content"
                id={item}
                place="bottom"
                noArrow={true}
                key={item}
                content={
                  <label className="inter-500-text natural-700-text font-12 line-height-20">{t(`${item}_ALIGN`)}</label>
                }>
                <IconContainer
                  onClick={() => onChange(item)}
                  iconContainerClassname="cursor"
                  Icon={LeftAlignIcon}
                  iconColor={alignment === item ? 'white' : ''}
                  backgroundColor={alignment === item ? 'primaryBlue' : 'natural_100'}
                  iconWidth={16}
                  iconHeight={16}
                />
              </CustomTooltip>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
const ImageViewBox = ({ imageUrl, imageName, fileSize, alignment, t }) => {
  return (
    <div className="flex col-gap-4">
      <div className="border-dotted radius-1_5 flex-column items-center justify-center image-upload-box">
        {imageUrl ? (
          <img src={imageUrl} alt="Uploaded" className="radius-1_5" style={{ width: '100%', height: '100%' }} />
        ) : (
          <IconContainer
            iconContainerClassname="placeholder-image"
            Icon={PlaceholderImage}
            iconColor=""
            backgroundColor="transparent"
            iconWidth={52}
            iconHeight={52}
          />
        )}
      </div>
      <div className="flex-column">
        <label className="font-14 inter-500-text natural-900-text line-height-20">
          {imageName ? imageName : t('UPLOAD_IMAGE')}
        </label>
        <label className="font-12 inter-400-text natural-400-text line-height-20">
          {fileSize ? bytesToSize(fileSize) : t('NO_FILES')}
        </label>
        <AlignmentLabel alignment={alignment} t={t} />
      </div>
    </div>
  );
};

const AlignmentLabel = ({ alignment, t }) => {
  const alignments = {
    LEFT: {
      label: t('LEFT_ALIGNMENT'),
      icon: LeftAlignIcon,
    },
    CENTRE: {
      label: t('CENTRE_ALIGNMENT'),
      icon: CenterAlignIcon,
    },
    RIGHT: {
      label: t('RIGHT_ALIGNMENT'),
      icon: RightAlignIcon,
    },
  };
  const alignmentInfo = alignments[alignment];

  return alignmentInfo ? (
    <div className="flex items-center col-gap-2 pt-2">
      <IconContainer
        iconContainerClassname=""
        Icon={alignmentInfo?.icon}
        iconColor=""
        backgroundColor="natural_100"
        iconWidth={16}
        iconHeight={16}
      />
      <label className="font-12 inter-400-text natural-900-text line-height-20">{alignmentInfo.label}</label>
    </div>
  ) : null;
};

const QuoteTemplateDetails = ({ detailsData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const textareaRef = useRef([]);

  const { showErrorToast } = useError();

  const { setSideModal } = useContext(OrganisationContext);

  const { quoteTemplateData, handleDeleteQuoteTemplate, updateList } = detailsData || {};

  const [showSave, setShowSave] = useState(false);
  const [quoteTemplateUser, setQuoteTemplateUser] = useState({});
  const [quoteData, setQuoteData] = useState();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (quoteTemplateUser) {
      setQuoteData({ ...quoteTemplateUser });
      setFile(quoteTemplateUser?.image?.media || null);
    }
  }, [quoteTemplateUser]);

  const updateTextareaHeight = index => {
    if (textareaRef.current[index]) {
      textareaRef.current[index].style.height = '0px';
      const scrollHeight = textareaRef.current[index].scrollHeight;
      textareaRef.current[index].style.height = scrollHeight + 'px';
    }
  };

  useEffect(() => {
    textareaRef.current.forEach((ref, index) => {
      updateTextareaHeight(index);
    });
  }, [quoteData?.terms]);

  const renderInput = (key, label, placeholder, value, type, index) => {
    return (
      <div className="flex-column w-full input-container relative">
        <div className="mb-1 one-line">
          <label className="w-full inter-500-text natural-900-text line-height-20 font-14">{label}</label>
        </div>
        {type === 'textarea' ? (
          <>
            <textarea
              value={value}
              className={classNames(
                'inter-400-text natural-900-text  w-full textarea px-2 py-1  border',
                (label === 'Terms' || label === 'Contact Details') && 'textarea-scroll',
              )}
              ref={el => (textareaRef.current[index] = el)}
              placeholder={placeholder}
              onChange={({ target: { value } }) => {
                setQuoteData({ ...quoteData, [key]: value });
              }}
            />
          </>
        ) : (
          <input
            className="inter-400-text natural-900-text input one-line w-full px-2 py-1 border input-element"
            value={value}
            placeholder={placeholder}
            type={type}
            onChange={({ target: { value } }) => {
              setQuoteData({ ...quoteData, [key]: value });
            }}
          />
        )}
      </div>
    );
  };

  const fetchProfileDetails = async quote_template_id => {
    setLoading(true);
    dispatch(getQuoteTemplateDetail({ quote_template_id: quote_template_id }))
      .then(data => setQuoteTemplateUser(data))
      .catch(error =>
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_QUOTE_TEMPLATE_DETAILS'), id: nanoid() }),
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (quoteTemplateData?.id) {
      fetchProfileDetails(quoteTemplateData?.id);
    }
  }, [quoteTemplateData?.id]);

  const renderDetail = (label, labelText, value, is_default) => {
    return (
      <>
        <div className="flex items-center justify-between w-full cursor relative">
          <div className="flex-column w-full">
            <span className="inter-400-text grey-text-04 font-12 mb-1">{labelText}</span>

            <div className="flex items-center">
              <label
                className={classNames(
                  'font-14 inter-400-text line-height-20 natural-400-text  flex-1 white-space-pre',
                  value !== 'No data' && 'natural-900-text',
                  labelText === 'name' && 'one-line',
                )}>
                {value}
              </label>
              {is_default && (
                <Status
                  withDot={false}
                  withDottedBorder
                  status={is_default ? 'DEFAULT' : ''}
                  statusText={is_default ? capitalizeFirstLetterOfWords(formatText(t('DEFAULT'))) : ''}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const checkAddNewQuoteErrors = () => {
    const { name } = quoteData;
    if (!name?.trim()) {
      const error = {
        name: !name?.trim(),
      };
      const errorFields = getErrorFieldJoined(error, (key, value) => (value, key), ' and ');
      const errorText = `Please add ${errorFields}`;

      setError(error);
      showErrorToast({ default_message: errorText, id: nanoid() });
      return true;
    }
    setError({});
    return false;
  };

  const onUpdateData = () => {
    if (loading) {
      return;
    }
    if (checkAddNewQuoteErrors()) {
      return;
    }
    setLoading(true);
    const request = {
      name: quoteData?.name || '',
      description: quoteData?.description || '',
      terms: quoteData?.terms || '',
      contact_information: quoteData?.contact_information || '',
      is_default: quoteData?.is_default || false,
      image: file
        ? {
            alignment: quoteData?.image?.alignment || 'LEFT',
            id: file?.media_id || file?.media_external_id,
          }
        : null,
    };
    dispatch(updateQuotesTemplate({ request, id: quoteTemplateData?.id }))
      .then(data => {
        dispatch(
          addToast({
            error: false,
            text: t('QUOTE_TEMPLATE_UPDATED_SUCCESSFULLY', { quotetemplatename: quoteData?.name }),
            id: nanoid(),
          }),
        );
        updateList(quoteTemplateData?.id, data);
        setQuoteData(data);
        setQuoteTemplateUser(data);
        setLoading(false);
        setShowSave(false);
      })
      .catch(error => {
        setLoading(false);
        dispatch(
          addToast({
            error: true,
            text: t('ERROR_UPDATING_QUOTE_TEMPLATE'),
            id: nanoid(),
          }),
        );
      });
  };

  const handleDelete = () => {
    handleDeleteQuoteTemplate(quoteTemplateData);
    setSideModal(initSideModal);
  };

  return (
    <QuoteTemplateDetailstWrapper className={`flex-column ${loading && 'h-full'} `}>
      {loading ? (
        <div className="item flex items-center justify-center flex-1 w-full">
          <Skeleton height={'100%'} containerClassName="line-height-1 h-full w-full" />
        </div>
      ) : (
        <>
          <SideModelHeader
            title={t('QUOTE_DETAILS')}
            onClose={() => (showSave ? setShowSave(false) : setSideModal(initSideModal))}
            onSaveUpdate={() => onUpdateData()}
            isLoading={loading}
            showSave={showSave}
            permission={'QUOTE_TEMPLATES_VIEW'}
            handleEdit={() => setShowSave(true)}
            isIconButtons
            handleDelete={() => handleDelete()}
          />
          <div className="flex-column flex-1 overflow-hidden">
            <div className="pxy-6 flex-column row-gap-6 overflow-auto custom-scrollbar thin-scrollbar">
              {showSave ? (
                <>
                  {renderInput('name', t('TEMPLATE_NAME'), t('ENTER_NAME_QUOTES_TEMPLATE'), quoteData.name, 'text')}

                  {renderInput(
                    'description',
                    t('DESCRIPTION'),
                    t('ENTER_DESCRIPTION_QUOTES_TEMPLATE'),
                    quoteData?.description,
                    'textarea',
                  )}

                  <FileUploadContainer value={quoteData?.image} setValue={setQuoteData} file={file} setFile={setFile} />
                  {renderInput('terms', t('TERMS'), t('ENTER_TERMS_QUOTES_TEMPLATE'), quoteData?.terms, 'textarea')}

                  {renderInput(
                    'contact_information',
                    t('CONTACT_DETAILS'),
                    t('ENTER'),
                    quoteData?.contact_information,
                    'textarea',
                  )}

                  <div className="flex items-center justify-between w-50 pb-6">
                    <label className="inter-500-text natural-900-text mr-1">
                      {capitalize(formatText(t('DEFAULT')))}
                    </label>
                    <Switch
                      enabled={quoteData.is_default}
                      onClick={() => {
                        setQuoteData({ ...quoteData, is_default: !quoteData.is_default });
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {renderDetail(
                    'name',
                    t('TEMPLATE_NAME'),
                    quoteTemplateUser?.name || t('NO_DATA'),
                    quoteTemplateUser?.is_default,
                  )}
                  {renderDetail('description', t('DESCRIPTION'), quoteTemplateUser?.description || t('NO_DATA'), false)}

                  <ImageViewBox
                    imageUrl={quoteTemplateUser?.image?.media?.url}
                    imageName={quoteTemplateUser?.image?.media?.name}
                    fileSize={quoteTemplateUser?.image?.media?.filesize}
                    alignment={quoteTemplateUser?.image?.alignment}
                    t={t}
                  />

                  {renderDetail('terms', t('TERMS'), quoteTemplateUser?.terms || t('NO_DATA'), false)}
                  {renderDetail(
                    'contact_information',
                    t('CONTACT_DETAILS'),
                    quoteTemplateUser?.contact_information || t('NO_DATA'),
                    false,
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </QuoteTemplateDetailstWrapper>
  );
};

export const QuoteTemplateDetailstWrapper = styled.div`
  overflow: hidden;

  .update-detail-contaner {
    display: flex;
    padding: 12px;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    .input {
      height: 40px;
    }
  }
  .image-upload-box {
    width: 160px;
    height: 80px;
  }
  .placeholder-image {
    opacity: 0.3;
  }
  .textarea::placeholder {
    opacity: 1 !important;
    color: ${({ theme }) => theme.natural_400} !important;
  }
  .edit-delete-icon {
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: ${({ theme }) => theme.primary_500};
  }
  .close-icon {
    path {
      fill: #171717;
    }
  }
  .textarea-scroll {
    height: 148px !important;
  }
  .cross-icon {
    z-index: 999;
    background: ${({ theme }) => theme.white};
    border-radius: 100px;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default QuoteTemplateDetails;
