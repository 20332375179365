import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';
import api from '../services/index';

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState: {
    quoteDetails: {},
    quoteDetailsInitial: {
      data: {},
      productDetails: [],
      attachments: [],
      productFamilies: [],
    },
  },
  reducers: {
    setQuoteDetails: (state, action) => {
      state.quoteDetails = action.payload;
    },
    setQuotesPaymentTerm: (state, action) => {
      state.quoteDetails.payment_terms_templates = action.payload;
    },
    setQuoteAttachments: (state, action) => {
      state.quoteDetails.attachments = action.payload;
    },
    setProductFamilies: (state, action) => {
      state.productFamilies = action.payload;
    },
    setQuoteDetailsTerm: (state, action) => {
      state.quoteDetails.term = action.payload;
    },
    setSloarDetails: (state, action) => {
      state.sloarDetails = action.payload;
    },
  },
});

const getFormattedQuoteDetails = (quoteDetails, attachments = []) => {
  const { recipients, products } = quoteDetails;
  const [recipient] = recipients || [];
  return {
    ...quoteDetails,
    attachments,
    products: products.map(p => ({ ...p, isNew: false })),
    recipient: { ...recipient, ...recipient?.contact },
  };
};

export const getQuotesList =
  ({ engagement_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/quotes`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getSolarOerviewData =
  ({ engagement_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/solar_designs`);
      dispatch(setSloarDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getSolarHourlyData =
  ({ engagementId, designId, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagementId}/solar_designs/${designId}/hourly`, {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getSystemDetailsData =
  ({ engagementId, designId }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagementId}/solar_designs/${designId}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEventLogEvidence =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/event_logs`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFinancialData =
  ({ sid }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/solar_designs/${sid}/financials`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFinancialDesign =
  ({ id, fid }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/solar_designs/${id}/financials/${fid}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEvidenceConfigs = payload => async dispatch => {
  try {
    const { params } = payload;
    const { data } = await api.get(`/evidence_configs`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createEvidence =
  ({ engagementId, designId, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/engagements/${engagementId}/solar_designs/${designId}/evidence`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getQuoteDetails =
  ({ quote_id, forFetchOnly = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/quotes/${quote_id}`);
      if (!forFetchOnly) {
        const formattedData = getFormattedQuoteDetails(data);
        dispatch(setQuoteDetails(formattedData));
        return Promise.resolve(formattedData);
      } else {
        return Promise.resolve(data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateQuoteDetails =
  ({ quote_id, request }) =>
  async (dispatch, getState) => {
    try {
      const { data } = await api.put(`/quotes/${quote_id}`, request);
      const attachments = getState().quotes?.quoteDetails?.attachments || [];
      const formattedData = getFormattedQuoteDetails(data, attachments);
      dispatch(setQuoteDetails(formattedData));
      return Promise.resolve(formattedData);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateQuoteStatus =
  ({ quote_id, request }) =>
  async (dispatch, getState) => {
    try {
      const { data } = await api.put(`/quotes/${quote_id}/status`, request);
      const attachments = getState().quotes?.quoteDetails?.attachments || [];
      const formattedData = getFormattedQuoteDetails(data, attachments);
      dispatch(setQuoteDetails(formattedData));
      return Promise.resolve(formattedData);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createQuote =
  ({ engagement_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/engagements/${engagement_id}/quotes`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const sendQuote =
  ({ quote_id }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/quotes/${quote_id}/send`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteQuote =
  ({ quote_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/quotes/${quote_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEngagementContacts =
  ({ engagement_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/contacts`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProductList =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/products`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProductInfo =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/products/${id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteProduct =
  ({ product_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/products/${product_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteProductMedia =
  ({ product_id, media_id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/products/${product_id}/media/${media_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getSuppliersList =
  ({ product_id, params }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/products/${product_id}/pricing`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateProductPrice =
  ({ product_id, price_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.put(`/api/products/${product_id}/pricing/${price_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createProductPrice =
  ({ product_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/products/${product_id}/pricing`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProductSpecificationsInfo =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/products/${id}/specifications`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const setProductInfo =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.put(`/api/products/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const setProductSpecifications =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/products/${id}/specifications`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateProductSpecifications =
  ({ product_id, id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.put(`/api/products/${product_id}/specifications/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteProductSpecification =
  ({ product_id, id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/products/${product_id}/specifications/${id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getBrands =
  ({}) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get('/api/brands');
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProductMedia =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/products/${id}/media`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createProductMedia =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/products/${id}/media`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addNewProductManually =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/products`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addNewProduct =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/organizations/${id}/products`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteOrganizationProduct =
  ({ id, product_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/products/${product_id}/organizations/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getTaxationSchemes =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/taxation_schemes`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPaymentTerms =
  ({ forFetchOnly }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/payment_term_templates`);
      !forFetchOnly && dispatch(setQuotesPaymentTerm(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addPaymentTerms =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/payment_term_templates`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updatePaymentTerms =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/payment_term_templates/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateQuotePaymentTerm =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/quotes/${id}/payment_terms_templates`, request);
      dispatch(setQuotesPaymentTerm(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getQuoteTemplate =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/quote_templates`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteQuoteTemplate =
  ({ quote_template_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/quote_templates/${quote_template_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addQuotesTemplate =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/quote_templates`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const updateQuotesTemplate =
  ({ request, id }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/quote_templates/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getQuoteTemplateDetail =
  ({ quote_template_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/quote_templates/${quote_template_id}`);
      dispatch(setQuoteDetailsTerm(data?.terms || null));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createQuoteAttachments =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/quotes/${id}/attachments`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getQuoteAttachments =
  ({ id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/quotes/${id}/attachments`);
      dispatch(setQuoteAttachments(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.resolve(error);
    }
  };

export const deleteQuoteAttachment =
  ({ quote_id, attachment_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/quotes/${quote_id}/attachments/${attachment_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateQuoteAttachment =
  ({ quote_id, attachment_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/quotes/${quote_id}/attachments/${attachment_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProductFamilies =
  ({ params = {}, setToRedux = true }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/families`, { params: params });
      setToRedux && dispatch(setProductFamilies(data));
      return Promise.resolve(data);
    } catch (error) {
      dispatch(setProductFamilies([]));
      return Promise.reject(error);
    }
  };

export const getProductComponents =
  ({ product_id, params }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/products/${product_id}/components`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      Promise.reject(error);
    }
  };

export const sendProposal =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/evidence/${id}/send`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addSolarDesignImages =
  ({ engagementId, designId, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/engagements/${engagementId}/solar_designs/${designId}/images`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getDesignImages =
  ({ engagement_id, solar_design_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/solar_designs/${solar_design_id}/images`, {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      Promise.reject(error);
    }
  };

export const updateFinancial =
  ({ request, solar_design_id, finacial_id }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/solar_designs/${solar_design_id}/financials/${finacial_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      Promise.reject(error);
    }
  };

export const createFinancial =
  ({ request, solar_design_id }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/solar_designs/${solar_design_id}/financials`, request);
      return Promise.resolve(data);
    } catch (error) {
      Promise.reject(error);
    }
  };

export const deleteFinancial =
  ({ finacial_id, solar_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/solar_designs/${solar_id}/financials/${finacial_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getSolarOptions =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/solar_options`, {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      Promise.reject(error);
    }
  };

export const {
  setQuoteDetails,
  setQuoteDetailsTerm,
  setQuotesPaymentTerm,
  setQuoteAttachments,
  setProductFamilies,
  setSloarDetails,
} = quotesSlice.actions;
export default quotesSlice.reducer;
