import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useError } from '../../hooks/useError';
import { updatePropertyDetails } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import GoogleMaps from '../common/google-maps';

const PropertyDetailsMap = ({ setShowmapScreen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { propertyDetails } = useSelector(state => state.property);

  const [location, setLocation] = useState(propertyDetails.location);
  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    const request = { ...propertyDetails, location: location };
    dispatch(updatePropertyDetails({ id: propertyDetails.id, request }))
      .then(res => {
        dispatch(addToast({ error: false, text: t('LOCATION_UPDATE') }));
        setShowmapScreen(false);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_LOCATION_UPDATE') }));
  };
  useEffect(() => {
    if (propertyDetails?.location) return setLocation(propertyDetails.location);
  }, [propertyDetails?.location]);

  return (
    <MapScreenWrapper className="card flex-1 mb-6 flex-column">
      <div className="pxy-4 border-bottom">
        <label className="inter-600-text natural-900-text font-16 ">{t('PROPERTY_DETAILS')}</label>
      </div>
      <div className="px-6 py-4 relative w-full h-full">
        <div className="absolute bg-white p-3 mxy-3 z-1 radius-1_5">
          <label className="inter-400-text natural-500-text line-height-20">{t('PLEASE_PLACE_PIN_ON_PROPERTY')}</label>
          <div className="flex gap-3 items-center mt-3">
            <div className="px-2 radius-1 py-0_5 bg-natural-100 flex-1">
              <span className="inter-400-text natural-500-text line-height-20 font-12">LAT: </span>
              <span className="inter-400-text natural-900-text line-height-20 font-12">
                {location?.lat?.toFixed(3)}
              </span>
            </div>
            <div className="px-2 radius-1 py-0_5 bg-natural-100 flex-1">
              <span className="inter-400-text natural-500-text line-height-20 font-12">LON: </span>
              <span className="inter-400-text natural-900-text line-height-20 font-12">
                {location?.lon?.toFixed(3)}
              </span>
            </div>
          </div>
          <div className="justify-between items-center mt-5">
            <Button
              className={classNames('primary-grey specified-width', loading && 'disabled')}
              label={t('CANCEL')}
              width="112px"
              disabled={loading}
              onClick={() => {
                setShowmapScreen(false);
                setLocation(propertyDetails?.location);
              }}
              size="medium"
              borderRadius="100px"
            />
            <Button
              className={classNames('primary specified-width', loading && 'disabled')}
              disabled={loading}
              width="112px"
              label={t('CONFIRM')}
              onClick={() => handleDone()}
              size="medium"
              borderRadius="100px"
              lableSize={'font-12'}
            />
          </div>
        </div>
        <GoogleMaps
          coordinates={[location] || [propertyDetails.location]}
          isSolar={false}
          onClick={e => setLocation({ lat: e.latLng.lat(), lon: e.latLng.lng() })}
          additionalOptions={{
            streetViewControl: false,
            mapTypeControl: false,
            draggable: true,
            tilt: 0,
            fullscreenControl: false,
          }}
        />
      </div>
    </MapScreenWrapper>
  );
};

const MapScreenWrapper = styled.div`
  min-height: 600px;
`;

export default PropertyDetailsMap;
