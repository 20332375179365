import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const jobSlice = createSlice({
  name: 'jobs',
  initialState: {},
  reducers: {},
});

export const getJobTypeList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addNewJobType =
  ({ organization_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/organizations/${organization_id}/job_types`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteOrganizationJobType =
  ({ job_type_id, organization_id }) =>
  async dispatch => {
    try {
      const { data } = await api.delete(`/job_types/${job_type_id}/organizations/${organization_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobTypeOrganizations =
  ({ id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types/${id}/organizations`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobTypeActivityTypes =
  ({ job_types_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/job_types/${job_types_id}/activity_types`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getActivityTypeServices =
  ({ activity_type_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/activity_types/${activity_type_id}/services`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createJobPartners =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/engagements/${id}/partners`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProjectList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/projects`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const createProject =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/projects`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getProjectDetails =
  ({ projects_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/projects/${projects_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };
export const updateProjectDetails =
  ({ projects_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/projects/${projects_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getWorkflowList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/workflows`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getStagesListData =
  ({ params, workflowId }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/workflows/${workflowId}/stages`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getJobs =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/jobs`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createJob =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/jobs`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateJob =
  ({ id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/jobs/${id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createWorkitem =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/workitems`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEngagementCustomForms =
  ({ engagement_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/custom_forms`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getEngagementCustomFormsData =
  ({ engagement_id, custom_form_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/engagements/${engagement_id}/custom_forms/${custom_form_id}/custom_data`, {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateEngagementCustomFormsData =
  ({ engagement_id, custom_form_id, request = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.put(
        `/engagements/${engagement_id}/custom_forms/${custom_form_id}/custom_data`,
        request,
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormsPanels =
  ({ version_id, params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/versions/${version_id}/panel_widgets`, {
        params: params,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = jobSlice.actions;
export default jobSlice.reducer;
