import { motion } from 'framer-motion';
import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { OrganisationContext } from '../context/organisationContext';
import { initSideModal } from '../helpers/utils';
import InsideModal from '../popup/popup-inside';
import AddNewPropertySide from './add-property-side';
import AddNewDeal from './deals';
import EditContact from './edit-contact';
import EditUserProfile from './edit-profile';
import AddNewJob from './jobs';
import ProjectDetails from './project-details';
import QuoteTemplateDetails from './quote-template-details';
import UploadQuoteAttachments from './quote/upload-quote-attachments';
import RoleDetails from './role-details';
import ActivityDetails from './scheduling/activity-details';
import { SideModalWrapper } from './sidemodel.styled';
import TaskDetails from './task-details';
import AddNewTask from './tasks/add-new-task';

const PopupComponents = {
  'view-task-details': TaskDetails,
  'view-role-details': RoleDetails,
  'view-activity-details': ActivityDetails,
  'edit-contact': EditContact,
  'edit-user-profile': EditUserProfile,
  'upload-quote-attachment': UploadQuoteAttachments,
  'edit-project-details': ProjectDetails,
  'quote-template-details': QuoteTemplateDetails,
  'add-new-task': AddNewTask,
  'add-new-job': AddNewJob,
  'add-new-deal': AddNewDeal,
  'add-new-property-side': AddNewPropertySide,
};

const SideModel = () => {
  const { sideModal, setSideModal } = useContext(OrganisationContext);

  const { onClose } = sideModal?.content || {};

  const onClick = e => {
    if (e.target === e.currentTarget) {
      onClose && onClose();
      setSideModal(initSideModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      onClose && onClose();
      setSideModal(initSideModal);
    },
    {
      ignoreClass: 'sidebar-close-ignore',
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[sideModal.type];
    if (Component) {
      return <Component detailsData={sideModal?.content} />;
    }
    return <Fragment />;
  };

  if (!sideModal.type) return null;

  return (
    <SideModalWrapper id="sidemodal-wrapper-id" onClick={onClick}>
      <motion.div
        initial={{ x: 600 }}
        animate={{ x: 0, transition: { duration: 0.8 } }}
        key="sidemodal-content-id"
        ref={ref}
        className="sidemodal-content"
        id="sidemodal-content-id">
        {setPopupComponent()}
        <InsideModal />
      </motion.div>
    </SideModalWrapper>
  );
};

export default SideModel;
