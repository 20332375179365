import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as EditPensilIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as ElectricityCordIcon } from '../../assets/icons/electricity-cord.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as LoaderIcon } from '../../assets/images/loading.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import Loader from '../../components/loader';
import { BarGraphSection } from '../../components/property-details-components/electricity-consumption';
import { getFormattedNumberStyle } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import {
  getEnergyConsumption,
  getEnergyConsumptionPatterns,
  importEnergyConsumption,
  updatePropertyDetails,
  updatePropertyDetailsData,
} from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';

const tabs = [
  {
    key: 'YEAR',
    label: 'Annually',
  },
  {
    key: 'QUARTER',
    label: 'Quarterly',
  },
  {
    key: 'MONTH',
    label: 'Monthly',
  },
];

const quarterLabels = {
  1: 'Jan - Mar',
  2: 'Apr - Jun',
  3: 'Jul - Sep',
  4: 'Oct - Dec',
};

const monthLabels = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const UsageValuesComponent = ({
  selectedTab,
  energyUsage,
  fetchEnergyConsumption,
  loadingUsage,
  setEnergyUsage,
  onUpdateUploadedConsumption,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { propertyDetails } = useSelector(state => state.property);
  const { id: property_id } = propertyDetails || {};

  const [usageValues, setUsageValues] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const annualValue = [...(isEditing ? usageValues : energyUsage)].reduce(
    (acc, curr) => acc + parseFloat(curr.consumption_kwh),
    0,
  );
  const quarterValues = [...(isEditing ? usageValues : energyUsage)]
    .filter(q => q.quarter)
    .map(quarter => ({
      ...quarter,
      consumption_kwh: parseFloat(quarter.consumption_kwh),
    }));

  const monthValues = [...(isEditing ? usageValues : energyUsage)]
    .filter(m => m.month)
    .map(month => ({ ...month, consumption_kwh: parseFloat(month.consumption_kwh) }));

  useEffect(() => {
    setIsEditing(false);
    setUsageValues([]);
  }, [selectedTab]);

  const onUpdateYear = value => {
    const firstValue = usageValues[0];
    setUsageValues([{ ...firstValue, consumption_kwh: value }]);
  };

  const onUpdateQuarter = (quarter, value) => {
    const updatedValues = [...usageValues].map(quarterData => {
      if (quarterData.quarter === quarter) {
        quarterData.consumption_kwh = parseFloat(value);
      }
      return quarterData;
    });
    setUsageValues([...updatedValues]);
  };

  const onUpdateMonth = (month, value) => {
    const updatedValues = [...usageValues].map(monthData => {
      if (monthData.month === month) {
        monthData.consumption_kwh = parseFloat(value);
      }
      return monthData;
    });
    setUsageValues([...updatedValues]);
  };

  const onUpdateUsage = () => {
    const request = usageValues.map(item => ({
      month: item?.month || null,
      quarter: item?.quarter || null,
      day: null,
      hour: null,
      half_hour: null,
      consumption_kwh: parseInt(item.consumption_kwh) || 0,
    }));
    setIsUpdating(true);
    dispatch(addToast({ error: false, text: t('UPDATING_CONSUMPTION_PLEASE_WAIT') }));
    dispatch(importEnergyConsumption({ id: property_id, type: 'ELECTRICITY', request }))
      .then(async () => {
        await onUpdateUploadedConsumption(false);
        fetchEnergyConsumption();
        fetchEnergyConsumption(selectedTab);
        fetchEnergyConsumption('MONTH');
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_ELECTRICITY_CONSUMPTION') });
        setUsageValues([]);
      })
      .finally(() => {
        setIsUpdating(false);
        setIsEditing(false);
      });
  };

  return (
    <div className="flex-column row-gap-3 usage-wrapper relative">
      <span className="inter-500-text">Usage values (kWh)</span>
      <div className="flex items-center justify-between col-gap-3 border radius-2 relative">
        {loadingUsage && <Loader />}
        {selectedTab === 'YEAR' && !loadingUsage ? (
          <div className="flex items-center justify-between col-gap-3 px-4 py-3 w-full hover-edit">
            <span className="inter-400-text">Annual Consumption</span>
            {isEditing ? (
              <InputElement
                variant="size_24"
                type="number"
                autoFocus
                className="input-wrapper"
                value={annualValue || ''}
                onChange={value => onUpdateYear(value)}
              />
            ) : (
              <span
                className="inter-400-text cursor value-container"
                onClick={() => {
                  setIsEditing(true);
                  setUsageValues([...energyUsage]);
                }}>
                {getFormattedNumberStyle(annualValue, 'decimal', 0, 0)}
              </span>
            )}
          </div>
        ) : null}
        {selectedTab === 'QUARTER' && !loadingUsage ? (
          <div className="flex-column row-gap-2 px-4 py-3 w-full">
            {quarterValues.map((quarter, index) => (
              <div
                key={`quarter-${quarter.quarter}-${isEditing}`}
                id={`quarter-${quarter.quarter}-${isEditing}`}
                className="flex items-center justify-between col-gap-3 w-full hover-edit">
                <span className="inter-400-text">{quarterLabels[quarter.quarter]}</span>
                {isEditing ? (
                  <InputElement
                    type="number"
                    autoFocus={index === 0}
                    variant="size_24"
                    className="input-wrapper"
                    value={quarter.consumption_kwh || ''}
                    onChange={value => onUpdateQuarter(quarter.quarter, value)}
                  />
                ) : (
                  <span
                    className="inter-400-text cursor value-container"
                    onClick={() => {
                      setIsEditing(true);
                      setUsageValues([...energyUsage]);
                    }}>
                    {getFormattedNumberStyle(quarter.consumption_kwh, 'decimal', 0, 0)}
                  </span>
                )}
              </div>
            ))}
          </div>
        ) : null}
        {selectedTab === 'MONTH' && !loadingUsage ? (
          <div className="flex-column row-gap-2 px-4 py-3 w-full">
            {monthValues.map((months, index) => (
              <div
                key={`month-${months.month}-${isEditing}`}
                id={`month-${months.month}-${isEditing}`}
                className="flex items-center justify-between col-gap-3 w-full hover-edit">
                <span className="inter-400-text">{monthLabels[months.month]}</span>
                {isEditing ? (
                  <InputElement
                    type="number"
                    autoFocus={index === 0}
                    variant="size_24"
                    className="input-wrapper"
                    value={months.consumption_kwh || ''}
                    onChange={value => onUpdateMonth(months.month, value)}
                  />
                ) : (
                  <span
                    className="inter-400-text cursor value-container"
                    onClick={() => {
                      setIsEditing(true);
                      setUsageValues([...energyUsage]);
                    }}>
                    {getFormattedNumberStyle(months.consumption_kwh, 'decimal', 0, 0)}
                  </span>
                )}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {isEditing && (
        <div className="flex justify-center items-end radius-3 update-action mt-1 pxy-2 gap-2_5 absolute">
          <IconContainer
            Icon={CloseIcon}
            iconHeight={16}
            iconWidth={16}
            iconContainerClassname="border bg-white radius-full cursor"
            onClick={() => {
              setIsEditing(false);
              setUsageValues([]);
            }}
          />
          <IconContainer
            Icon={isUpdating ? LoaderIcon : RightIcon}
            iconHeight={16}
            iconWidth={16}
            iconColor="white"
            iconContainerClassname="bg-primary-500 radius-full cursor"
            onClick={() => {
              onUpdateUsage();
            }}
          />
        </div>
      )}
    </div>
  );
};

export const EnergyUsageEditor = ({ setUsageEditorOpened = () => {}, energyUsage, setEnergyUsage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { propertyDetails } = useSelector(state => state.property);
  const {
    id: property_id,
    energy_consumption_pattern,
    property_class,
    details,
    is_uploaded_consumption,
  } = propertyDetails || {};
  const { class_type } = property_class || {};
  const isShowEnergyPattern = !is_uploaded_consumption;

  const [selectedEnergyPattern, setSelectedEnergyPattern] = useState(energy_consumption_pattern);
  const [selectedTab, setSelectedTab] = useState('YEAR');
  const [energyGraphData, setEnergyGraphData] = useState([]);
  const [isPatternUpdating, setIsPatternUpdating] = useState(false);
  const [loadingUsage, setLoadingUsage] = useState(false);

  const fetchEnergyConsumption = tab => {
    setLoadingUsage(true);
    dispatch(
      getEnergyConsumption({
        id: property_id,
        type: 'ELECTRICITY',
        params: {
          unit: tab,
        },
      }),
    )
      .then(data => {
        if (tab === 'MONTH') {
          setEnergyGraphData(data);
        } else if (tab) {
          setEnergyUsage(data);
        }
      })
      .catch(error => {
        if (tab === 'MONTH') {
          setEnergyGraphData([]);
        } else {
          setEnergyUsage([]);
        }
      })
      .finally(() => {
        setLoadingUsage(false);
      });
  };

  const onUpdateUploadedConsumption = async is_uploaded_consumption => {
    const request = {
      ...propertyDetails,
      is_uploaded_consumption: is_uploaded_consumption,
    };

    await dispatch(updatePropertyDetails({ id: property_id, request })).catch(() => {});
  };

  useEffect(() => {
    fetchEnergyConsumption(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    fetchEnergyConsumption('MONTH');
    fetchEnergyConsumption();
  }, []);

  const onUpdateEnergyPattern = value => {
    if (value?.id === energy_consumption_pattern?.id) {
      return;
    }

    const request = {
      ...details,
      energy_consumption_pattern: value ? { id: value?.id } : null,
    };
    setIsPatternUpdating(true);
    dispatch(updatePropertyDetailsData({ property_id: property_id, request }))
      .then(data => {
        fetchEnergyConsumption(selectedTab);
        fetchEnergyConsumption('MONTH', true);
        dispatch(addToast({ error: false, text: t('ENERGY_PATTERN_UPDATED') }));
      })
      .catch(error => {
        setSelectedEnergyPattern(energy_consumption_pattern);
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_ENERGY_PATTERN') });
      })
      .finally(() => {
        setIsPatternUpdating(false);
      });
  };

  useEffect(() => {
    if (selectedEnergyPattern) {
      onUpdateEnergyPattern(selectedEnergyPattern);
    }
  }, [selectedEnergyPattern?.id]);

  return (
    <EnergyUsageEditorWrapper
      className="flex-column radius-3 absolute bg-white"
      initial={{ left: 0 }}
      animate={{ left: 400 }}
      exit={{ x: 0 }}
      transition={{ duration: 0.5 }}>
      <div className="flex-column row-gap-3 overflow-auto">
        <div className="flex items-center justify-between border-bottom w-full pb-2">
          <span className="inter-600-text font-16">Energy usage:</span>
          <div className="flex items-center col-gap-2">
            {/* <Button className="primary specified-width px-3" label="Import" width="fit-content" /> */}
            <IconContainer
              Icon={CloseIcon}
              backgroundColor="transparent"
              iconContainerClassname="p-0 cursor"
              onClick={() => setUsageEditorOpened(false)}
            />
          </div>
        </div>
        <div className="flex-column row-gap-3">
          <div className="flex-column gap-2">
            <label className="inter-500-text">{t('ENERGY_PATTERN')}</label>
            {loadingUsage && <Loader height={20} width={20} />}
            {!loadingUsage &&
              (isShowEnergyPattern ? (
                <SearchableDropdown
                  defaultAdditional={{
                    page: 0,
                    fetchFunction: getEnergyConsumptionPatterns,
                    pageable: false,
                    params: {
                      class_type: class_type,
                    },
                  }}
                  isCustomSearchable={false}
                  value={selectedEnergyPattern}
                  onChange={value => {
                    setSelectedEnergyPattern(value);
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder="Select energy pattern"
                  customStyle={{
                    control: {
                      minHeight: '32px',
                      height: '32px',
                      margin: '0, 4px',
                    },
                    valueContainer: {
                      fontSize: '14px',
                    },
                    menu: {
                      width: 'calc(100% - 8px)',
                      margin: '8px 4px',
                    },
                  }}
                />
              ) : (
                <label className="inter-400-text natural-400-text font-12">{t('NO_ENERGY_PATTERN_REQUIRED')}</label>
              ))}
          </div>
          <div className="flex items-center justify-between col-gap-3">
            {tabs.map(tab => (
              <Button
                key={tab.key}
                className={classNames('specified-width px-3 cursor', {
                  secondary: selectedTab === tab.key,
                  'grey-50': selectedTab !== tab.key,
                })}
                size="medium"
                borderRadius="4px"
                label={tab.label}
                width="100%"
                onClick={() => setSelectedTab(tab.key)}
              />
            ))}
          </div>
          <UsageValuesComponent
            loadingUsage={loadingUsage}
            selectedTab={selectedTab}
            setEnergyUsage={setEnergyUsage}
            energyUsage={selectedTab === 'MONTH' ? [...energyGraphData] : [...energyUsage]}
            fetchEnergyConsumption={fetchEnergyConsumption}
            onUpdateUploadedConsumption={onUpdateUploadedConsumption}
          />
          <BarGraphSection graphData={energyGraphData} className="px-2 py-4 radius-2" barSize={8} />
        </div>
      </div>
    </EnergyUsageEditorWrapper>
  );
};

const SolarEnergyUsage = ({
  usageEditorOpened,
  setUsageEditorOpened,
  energyUsage = [],
  setEnergyUsage,
  systemPanelOutput,
}) => {
  const dispatch = useDispatch();

  const { propertyDetails } = useSelector(state => state.property);
  const { id: property_id } = propertyDetails || {};

  const totalEnergyUsage = (energyUsage || []).reduce((acc, curr) => acc + parseFloat(curr.consumption_kwh), 0);

  const percentageOfTotalEnergy = totalEnergyUsage ? (systemPanelOutput / totalEnergyUsage) * 100 : 0;

  const fetchEnergyConsumption = unit => {
    dispatch(
      getEnergyConsumption({
        id: property_id,
        type: 'ELECTRICITY',
        params: {
          unit: unit,
        },
      }),
    )
      .then(data => {
        setEnergyUsage(data);
      })
      .catch(error => {
        setEnergyUsage([]);
      });
  };

  useEffect(() => {
    fetchEnergyConsumption('YEAR');
  }, []);

  return (
    <SolarEnergyUsageWrapper
      percentageOfTotalEnergy={percentageOfTotalEnergy}
      className="flex items-center justify-between px-3 py-2 mx-4 mb-4 radius-2">
      <div className="flex items-center col-gap-2">
        <IconContainer
          Icon={ElectricityCordIcon}
          backgroundColor="transparent"
          iconContainerClassname="p-0"
          iconColor="success_500"
          iconClassName="icon-color"
        />
        <span className="inter-500-text success-500-text text-span">Energy usage:</span>
        <span className="inter-600-text success-500-text text-span">
          {getFormattedNumberStyle(totalEnergyUsage, 'decimal', 0, 0)} /{' '}
          {getFormattedNumberStyle(percentageOfTotalEnergy, 'decimal', 0, 0)}%
        </span>
      </div>
      <IconContainer
        Icon={EditPensilIcon}
        backgroundColor="transparent"
        iconContainerClassname="p-0 cursor"
        onClick={() => setUsageEditorOpened(!usageEditorOpened)}
      />
    </SolarEnergyUsageWrapper>
  );
};

const SolarEnergyUsageWrapper = styled.div`
  background-color: ${({ theme, percentageOfTotalEnergy }) =>
    percentageOfTotalEnergy < 100 ? theme.orange_50 : theme.success_50};

  .icon-color,
  .text-span {
    color: ${({ theme, percentageOfTotalEnergy }) =>
      percentageOfTotalEnergy < 100 ? theme.orange_500 : theme.success_500};
  }
`;

const EnergyUsageEditorWrapper = styled(motion.div)`
  left: 400px;
  bottom: 12px;
  min-width: 400px;
  max-height: 90%;
  padding: 24px 24px 24px 32px;
  box-shadow: rgba(16, 24, 40, 0.1) 6px 0px 7px 0px inset, rgba(16, 24, 40, 0.1) 0px 2px 4px -2px;

  .usage-wrapper {
    .input-wrapper {
      .input-element {
        width: 100px;
        font-size: 14px;
      }
    }

    .update-action {
      background-color: ${({ theme }) => theme.white};
      z-index: 999;
      box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
      right: 0px;
      bottom: -42px;
    }
    .right-icon {
      background-color: ${({ theme }) => theme.primary_500};
    }
  }
`;

export default SolarEnergyUsage;
