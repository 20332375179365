import classNames from 'classnames';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import MobileInput from '../../components/common/mobile-input';
import PhoneInput from '../../components/common/phone-input';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import CommonStepper from '../../components/common/stepper-common';
import Switch from '../../components/common/switch';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { createAccount, getOrganizationCategories } from '../../store/features/accountsSlice';
import { getPropertyAddress, getPropertyAddressById } from '../../store/features/newPropertySlice';
import { addToast } from '../../store/features/toastSlice';

const addAccountSteps = [
  { key: 'COMPANY_INFO', title: 'Company info' },
  { key: 'ADDRESS', title: 'Address' },
  { key: 'OWNER_INFO', title: 'Owner' },
];
// Formatting Address for post request
const getFormattedAddress = address => {
  const { line1, line2, city, state, postcode, country_code } = address || {};
  return [line1, line2, city, state, postcode, country_code].filter(item => item).join(', ') || '';
};

const getAddressRequest = (address, is_postal, is_billing) => {
  const { line1, line2, city, state, postcode, country_code, location } = address || {};
  return {
    formatted_address: getFormattedAddress(address),
    line1: line1 || '',
    line2: line2 || '',
    line3: null,
    city: city || '',
    state: state || '',
    country_code: country_code || '',
    postcode: postcode || '',
    location: location,
    is_primary: is_postal,
    is_billing: is_billing,
    is_postal: is_postal,
  };
};

const CompanyInfo = ({ companyInfo, setCompanyInfo, error, setError }) => {
  const { t } = useTranslation();
  const { name, email, phone, landline, country_code, category } = companyInfo;

  const [categorySearch, setCategorySearch] = useState('');

  return (
    <div className="flex-column row-gap-6 w-full">
      <InputElement
        name={t('NAME')}
        value={name}
        onChange={value => setCompanyInfo({ ...companyInfo, name: value })}
        error={error.name && !name}
        placeholder={t('EXAMPLE_COMPANY_NAME')}
      />
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-1 inter-500-text natural-900-text">{t('MOBILE')}</label>
        <PhoneInput
          selectedCountry={country_code}
          setSelectedCountry={value => setCompanyInfo({ ...companyInfo, country_code: value })}
          setPhone={value => setCompanyInfo({ ...companyInfo, phone: value })}
          phone={phone}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-1 inter-500-text natural-900-text">{t('LANDLINE')}</label>
        <MobileInput phone={landline} setPhone={value => setCompanyInfo({ ...companyInfo, landline: value })} />
      </div>
      <InputElement
        name={'Email'}
        value={email}
        onChange={value => setCompanyInfo({ ...companyInfo, email: value })}
        error={error.email && !email}
        placeholder={t('ENTER_EMAIL')}
      />
      <SearchableDropdown
        isClearable
        name={t('CATEGORY')}
        menuPlacement="top"
        placeholder={t('CATEGORY_SELECT')}
        inputValue={categorySearch}
        onInputChange={setCategorySearch}
        value={category}
        onChange={option => setCompanyInfo({ ...companyInfo, category: option })}
        isSearchable={true}
        defaultAdditional={{ page: 0, fetchFunction: getOrganizationCategories, pageable: true }}
        error={error.category && !category}
      />
    </div>
  );
};

const AddressInfo = ({
  address,
  setAddress,
  error,
  diffBillingAddress,
  setDiffBillingAddress,
  showBillingAddress,
  setShowBillingAddress,
  setLoadingAddress,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { line1, line2, city, state, postcode } = address;

  const [search, setSearch] = useState('');

  const formatted_address_format = ['line1', 'line2', 'line3', 'city', 'state', 'postcode'];

  const onAddressUpdate = address => {
    const formatted_address = formatted_address_format
      .map(a => address[a])
      .filter(value => Boolean(value))
      .join(', ');
    setAddress({
      ...address,
      formatted_address,
    });
  };

  const onChangeAddress = (key, value) => {
    const updatedAddress = { ...address, [key]: value };
    const formatted_address = formatted_address_format
      .map(a => updatedAddress[a])
      .filter(value => Boolean(value))
      .join(', ');
    setAddress({
      ...updatedAddress,
      formatted_address,
    });
  };

  const onAddressSelect = address => {
    if (!address) {
      onAddressUpdate({});
      return;
    }
    setLoadingAddress(true);
    dispatch(getPropertyAddressById({ id: address.id }))
      .then(data => {
        onAddressUpdate(data);
      })
      .catch(e => {
        return '';
      })
      .finally(() => setLoadingAddress(false));
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map(option => ({ ...option, label: option.address, value: option.id }));
      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <div className="flex-column row-gap-6 w-full ">
      {showBillingAddress ? (
        <button
          className="flex items-center cursor text-button inter-600-text font-16 p-0 self-start"
          onClick={() => setShowBillingAddress(false)}>
          <IconContainer Icon={LeftArrowIcon} iconColor="natural_500" backgroundColor="navBackground" />
          <p>{t('DIFFERENT_BILLING_ADDRESS')}</p>
        </button>
      ) : (
        <p className="inter-600-text font-16 ">{t('ACCOUNT_ADDRESS')}</p>
      )}
      <SearchableDropdown
        isClearable={true}
        loadOptionsOnMenuOpen={false}
        loadOptions={fetchChildOpt}
        className="w-full"
        placeholder={t('SEARCH_FOR_AN_ADDRESS')}
        customStyle={{
          control: { height: '32px', borderRadius: '6px', width: '100%', background: '#fff' },
          valueContainer: { padding: '0 16px' },
        }}
        inputValue={search}
        onInputChange={setSearch}
        value={line1 ? { label: line1 } : null}
        onChange={onAddressSelect}
        isSearchable={true}
        isCustomSearchable={false}
        defaultAdditional={{ page: 0, fetchFunction: getPropertyAddress, pageable: false }}
        name={t('ADDRESS_LINE_ONE')}
        error={error.line1 && !line1}
      />
      <InputElement
        name={t('ADDRESS_LINE_TWO')}
        sub_name={t('OPTIONAL')}
        placeholder={t('ENTER_ADDRESS_DETAILS')}
        value={line2 || ''}
        onChange={value => onChangeAddress('line2', value)}
      />
      <InputElement
        name={t('TOWN_CITY')}
        placeholder={t('EXAMPLE_COMPANY_LONDON')}
        value={city || ''}
        onChange={value => onChangeAddress('city', value)}
        error={error.city && !city}
      />
      <div className="flex gap-6">
        <InputElement
          name={t('COUNTY')}
          sub_name={t('OPTIONAL')}
          placeholder={t('ENTER_COUNTY')}
          value={state || ''}
          onChange={value => onChangeAddress('state', value)}
        />
        <InputElement
          name={t('POSTCODE')}
          placeholder={t('ENTER_POSTCODE')}
          value={postcode || ''}
          onChange={value => onChangeAddress('postcode', value)}
          error={error.postcode && !postcode}
        />
      </div>
      {!showBillingAddress && (
        <div className="justify-between">
          <label className="regular-text main-grey-text mb-2 inter-500-text natural-900-text">
            {t('DIFFERENT_BILLING_ADDRESS')}
          </label>
          <Switch
            enabled={diffBillingAddress}
            onClick={() => {
              setDiffBillingAddress(prev => !prev);
            }}
          />
        </div>
      )}
    </div>
  );
};

const OwnerInfo = ({ ownerInfo, setOwnerInfo, error, sendInvite, setSendInvite }) => {
  const { t } = useTranslation();
  const { name, email, phone, country_code } = ownerInfo;
  return (
    <div className="flex-column row-gap-6 w-full">
      <InputElement
        name={t('NAME')}
        value={name}
        onChange={value => setOwnerInfo({ ...ownerInfo, name: value })}
        error={error.name && (!name || name?.trim().split(' ').length <= 1)}
        placeholder={'e.g. John Lenon'}
      />
      <div className="w-full flex-column">
        <div className="one-line mb-2">
          <span className="regular-text main-grey-text  inter-500-text natural-900-text">{t('MOBILE')}</span>
          <span className="inter-400-text natural-400-text ml-1">{t('OPTIONAL')}</span>
        </div>
        <PhoneInput
          selectedCountry={country_code}
          setSelectedCountry={value => setOwnerInfo({ ...ownerInfo, country_code: value })}
          setPhone={value => setOwnerInfo({ ...ownerInfo, phone: value })}
          phone={phone}
        />
      </div>
      <InputElement
        name={t('EMAIL')}
        value={email}
        onChange={value => setOwnerInfo({ ...ownerInfo, email: value })}
        error={error.email && !email}
        placeholder={t('ENTER_EMAIL')}
      />
      <div className="justify-between">
        <label className="regular-text main-grey-text mb-2 inter-500-text natural-900-text">
          {t('SEND_EMAIL_INVITE')}
        </label>
        <Switch enabled={sendInvite} onClick={() => setSendInvite(prev => !prev)} />
      </div>
    </div>
  );
};

const AddAccount = () => {
  const dispatch = useDispatch();

  const { showErrorToast } = useError();

  const { userOrganization } = useSelector(state => state.user);
  const { setModal, modal } = useContext(OrganisationContext);
  const { account_type, onSuccess } = modal?.content || { account_type: 'ACCOUNTS' };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [currentStep, setCurrentStep] = useState('COMPANY_INFO');
  const [companyInfo, setCompanyInfo] = useState({});
  const [address, setAddress] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [diffBillingAddress, setDiffBillingAddress] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [sendInvite, setSendInvite] = useState(false);
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);

  const checkCompanyInfoErrors = () => {
    const { name, email, category } = companyInfo;
    if (!name || !email || !category) {
      setError({
        name: !name,
        email: !email,
        category: !category,
      });
      showErrorToast({ default_message: t('PLEASE_FILL_THE_REQUIRED_FIELDS') });
      return true;
    }
    setError({});
    return false;
  };

  const checkAddressErrors = address => {
    const { line1, line2, city, state, postcode } = address;
    if (!line1 || !city || !postcode) {
      setError({
        line1: !line1,
        // line2: !line2,
        city: !city,
        // state: !state,
        postcode: !postcode,
      });
      showErrorToast({ default_message: t('PLEASE_FILL_THE_REQUIRED_FIELDS') });
      return true;
    }
    setError({});
    return false;
  };

  const checkOwnerInfoError = () => {
    const { name, email } = ownerInfo;
    if (!name || !email) {
      setError({
        name: !name,
        email: !email,
      });
      showErrorToast({ default_message: t('PLEASE_FILL_THE_REQUIRED_FIELDS') });
      return true;
    } else if (name.trim().split(' ').length <= 1) {
      setError({
        name: true,
      });
      showErrorToast({ default_message: t('OWNER_FULL_NAME_ERROR') });
      return true;
    }
    setError({});
    return false;
  };

  const onSubmit = () => {
    setLoading(true);
    const request = {
      name: companyInfo.name,
      parent: [
        {
          id: userOrganization?.id,
          relationship_type: account_type === 'ACCOUNTS' ? 'CHILD' : 'PARTNER',
        },
      ],
      category: { id: companyInfo.category.id },
      code: null,
      unique_tax_reference: null,
      company_registration_number: null,
      location: [
        { ...getAddressRequest(address, true, !diffBillingAddress) },
        diffBillingAddress
          ? { ...getAddressRequest(billingAddress, false, true) }
          : { ...getAddressRequest(address, true, !diffBillingAddress) },
      ].filter(item => item),
      contact_details: [
        {
          contact_type: 'EMAIL',
          subtype: null,
          contact_value: companyInfo.email,
          is_primary: true,
          country_code: null,
        },
        companyInfo?.phone
          ? {
              contact_type: 'MOBILE',
              subtype: null,
              is_primary: false,
              contact_value: companyInfo?.phone ? `${companyInfo?.phone.replaceAll(' ', '')}` : null,
              country_code: '+44',
            }
          : null,
        companyInfo?.landline
          ? {
              contact_type: 'LANDLINE',
              subtype: null,
              is_primary: false,
              contact_value: companyInfo?.landline ? `${companyInfo?.landline.replaceAll(' ', '')}` : null,
              country_code: '+44',
            }
          : null,
      ].filter(item => item),
      account_owner: {
        forename: ownerInfo.name.split(' ')[0]?.trim(),
        surname: ownerInfo.name.split(' ')[1] ? ownerInfo.name.split(' ')[1].trim() : '',
        title: '',
        email: ownerInfo.email,
        phone: ownerInfo.phone ? `${ownerInfo.phone.replaceAll(' ', '')}` : null,
        country_code: ownerInfo.phone ? ownerInfo.country_code || '+44' : null,
        employee_number: null,
        is_owner: true,
        badge_number: null,
        role: null,
      },
      invoice_organization: null,
      payment_organization: null,
      integration: null,
      send_invite: sendInvite,
    };
    dispatch(createAccount({ request }))
      .then(data => {
        setLoading(false);
        setModal(initModal);
        dispatch(
          addToast({
            error: false,
            title: t('ACCOUNT_ADDED'),
            text: t('ACCOUNT_ADDED_SUCCESSFULLY'),
            id: nanoid(),
          }),
        );
        onSuccess && onSuccess(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_ADDING_ACCOUNT') });
        setLoading(false);
      });
  };

  const onBack = () => {
    if (currentStep === 'ADDRESS') {
      if (showBillingAddress) {
        setShowBillingAddress(false);
        return;
      }
      setActiveStep(activeStep - 1);
      setCurrentStep('COMPANY_INFO');
    } else if (currentStep === 'OWNER_INFO') {
      setActiveStep(activeStep - 1);
      setCurrentStep('ADDRESS');
    } else if (currentStep === 'COMPANY_INFO') {
      setModal({});
    }
  };

  const onNextOrDone = () => {
    if (currentStep === 'COMPANY_INFO') {
      if (checkCompanyInfoErrors()) {
        return;
      }
      setActiveStep(activeStep + 1);
      setCurrentStep('ADDRESS');
    } else if (currentStep === 'ADDRESS') {
      if (diffBillingAddress && !showBillingAddress) {
        if (checkAddressErrors(address)) {
          return;
        }
        setShowBillingAddress(true);
        setError({});
        return;
      } else if (showBillingAddress) {
        if (checkAddressErrors(billingAddress)) {
          return;
        }
        setActiveStep(activeStep + 1);
        setCurrentStep('OWNER_INFO');
        setError({});
      } else {
        if (checkAddressErrors(address)) {
          return;
        }
        setActiveStep(activeStep + 1);
        setCurrentStep('OWNER_INFO');
        setError({});
      }
    } else if (currentStep === 'OWNER_INFO') {
      if (checkOwnerInfoError()) {
        return;
      }
      onSubmit();
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddAccountWrapper className="pxy-10">
        <label className="inter-700-text natural-900-text font-28 pb-6 border-bottom flex flex-1">
          Add New {account_type === 'ACCOUNTS' ? 'Account' : 'Partner'}
        </label>
        <div className="flex item-center">
          <CommonStepper steps={addAccountSteps} actvieStep={activeStep} stepperClassName="border-bottom mb-8 px-2" />
        </div>
        {currentStep === 'COMPANY_INFO' && (
          <CompanyInfo companyInfo={companyInfo} setCompanyInfo={setCompanyInfo} error={error} setError={setError} />
        )}
        {currentStep === 'ADDRESS' && showBillingAddress && (
          <AddressInfo
            address={billingAddress}
            setAddress={setBillingAddress}
            error={error}
            setError={setError}
            diffBillingAddress={diffBillingAddress}
            setDiffBillingAddress={setDiffBillingAddress}
            checkAddressErrors={checkAddressErrors}
            showBillingAddress={showBillingAddress}
            setShowBillingAddress={setShowBillingAddress}
            setLoadingAddress={setLoadingAddress}
          />
        )}
        {currentStep === 'ADDRESS' && !showBillingAddress && (
          <AddressInfo
            address={address}
            setAddress={setAddress}
            error={error}
            setError={setError}
            diffBillingAddress={diffBillingAddress}
            setDiffBillingAddress={setDiffBillingAddress}
            checkAddressErrors={checkAddressErrors}
            showBillingAddress={showBillingAddress}
            setShowBillingAddress={setShowBillingAddress}
            setLoadingAddress={setLoadingAddress}
          />
        )}
        {currentStep === 'OWNER_INFO' && (
          <OwnerInfo
            ownerInfo={ownerInfo}
            setOwnerInfo={setOwnerInfo}
            error={error}
            setError={setError}
            sendInvite={sendInvite}
            setSendInvite={setSendInvite}
          />
        )}
        <div className="flex col-gap-6 justify-center mt-10 w-full">
          <Button
            className={classNames('flex-1', loading && 'disabled', {
              'primary-grey': currentStep === 'COMPANY_INFO',
              'primary-white': currentStep !== 'COMPANY_INFO',
            })}
            label={currentStep === 'COMPANY_INFO' ? t('CANCEL') : t('BACK')}
            disabled={loading || loadingAddress}
            onClick={onBack}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary flex-1', loading && 'disabled')}
            label={currentStep === 'OWNER_INFO' ? t('ADD') : t('NEXT')}
            disabled={loading || loadingAddress}
            onClick={onNextOrDone}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AddAccountWrapper>
    </CSSTransition>
  );
};

const AddAccountWrapper = styled.div`
  width: 456px;
`;

export default AddAccount;
