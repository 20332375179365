import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SideModalWrapper = styled(motion.div)`
  position: fixed;
  z-index: 1;
  width: fit-content;
  height: 100vh;
  top: 0;
  right: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  .sidemodal-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    outline: 0;
    position: relative;
    width: 414px;
    min-height: 100vh;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in, opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export const TaskDetailsWrapper = styled.div`
  .tab-item {
    background: ${({ theme }) => theme.natural_50};
  }
  .active-tab {
    border: none;
    background: #ffff;
    label {
      color: ${({ theme }) => theme.primary_500};
    }
  }
  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    margin-bottom: -1px;
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }
  .file-item-img-wrapper {
    height: 32px;
    width: 32px;
    border-radius: 2px;
    background: red;
  }
  .download-wrapper {
    height: 32px;
    width: 32px;
  }
  .user-logo-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: rgb(23, 23, 23);
  }
  .status-tooltip {
    position: relative;
    .custom-tooltip {
      position: fixed !important;
      padding: 0;
      height: auto;
      max-height: 272px !important;
      overflow: auto;
      filter: none;
      border-radius: 6px;
      box-shadow: rgb(179 179 179 / 67%) 0px 0px 3px 0px !important;
    }

    .proposal-config {
      &:hover {
        background-color: ${({ theme }) => theme.natural_100};
      }
    }
    .assign-user {
      border: none;
      transition: all 0.1s;
      &:hover {
        box-shadow: #d3eafe 0px 1px 4px, #d3eafe 0px 0px 0px 3px;
      }
    }
  }
  .task-date-field {
    min-height: 33px;
    padding: 0px 10px;
    :focus {
      border: 1px solid ${({ theme }) => theme.natural_200} !important;
    }
  }
  .hover-edit {
    &:hover {
      background: ${({ theme }) => theme.natural_150};
      border-radius: 6px;
      padding: 2px 4px;
      .description-show-more-less {
        right: 8px;
        bottom: 2px;
      }
    }
  }
  .job-input:focus {
    border: 1px solid ${({ theme }) => theme.natural_200} !important;
    box-shadow: none !important;
  }
  .searchable-select__multi-value {
    display: none;
  }
  .comment-content {
    min-height: 84px;
    textarea {
      height: auto;
      min-height: auto;
      padding: 0;
      border: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .scroll-container textarea {
    min-height: 40px !important;
    height: 104px;
    overflow: auto;
    scrollbar-width: auto;
    ::-webkit-scrollbar {
      width: 5px;
      height: auto;

      &-track {
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 5px;
      }

      &-thumb {
        background: ${({ theme }) => theme.primary_500} !important;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
    }
  }
  .searchable-select {
    &__control--menu-is-open {
      background-color: ${({ theme }) => theme.natural_100};
    }
  }
  .cross-icon {
    width: 32px;
    height: 32px;
  }

  .right-icon {
    background-color: ${({ theme }) => theme.primary_500};
    width: 32px;
    height: 32px;
  }
  .text-hover {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 0;
      height: 1px;
      bottom: -4px;
      left: 0;
      border-radius: 2px;
      transition: 400ms ease;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
    &::after {
      background: #2496ff;
    }
  }
  .icon-action {
    border-radius: 100%;
    padding: 0px;
    width: 32px;
    height: 32px;
  }
  .hover-edit {
    &:hover {
      background: ${({ theme }) => theme.natural_150};
      border-radius: 6px;
      padding: 2px 4px;
      .description-show-more-less {
        right: 8px;
        bottom: 2px;
      }
    }
  }
  .job-update-action {
    background-color: ${({ theme }) => theme.white};
    z-index: 999;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }

  .job-description {
    word-break: break-all;
    padding: 2px 4px;
    &:hover {
      padding: 2px 4px !important;
    }
    .description-show-more-less {
      right: 0;
      bottom: 0;
      background-color: transparent !important;
    }
    p {
      max-height: 260px;
      overflow: auto;
    }
  }
  .status-tooltip {
    position: relative;
    /* .custom-tooltip {
      position: fixed !important;
      padding: 0;
      height: auto;
      width: 364px;
      overflow: auto;
      filter: none;
      border-radius: 6px;
      box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
    } */
  }
  .status-select {
    border-radius: 6px;
    height: 33px;
    padding: 0 10px;
  }

  .date-selector-wrapper {
    .custom-tooltip {
      padding: 12px;
      width: 250px;

      .date-selector {
        width: 230px;
      }
    }
  }
`;

export const TaskWrapper = styled.div`
  .tab-item {
    background: ${({ theme }) => theme.natural_50};
  }
  .active-tab {
    border: none;
    background: #ffff;
    label {
      color: ${({ theme }) => theme.primary_500};
    }
  }
  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }
  .file-item-img-wrapper {
    height: 32px;
    width: 32px;
    border-radius: 2px;
    background: red;
  }
  .download-wrapper {
    height: 32px;
    width: 32px;
  }
  .user-logo-wrapper {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: rgb(23, 23, 23);
  }
  .border-radius-6 {
    border-radius: 6px;
  }

  .status-tooltip {
    position: relative;
    .custom-tooltip {
      position: fixed !important;
      padding: 0;
      height: auto;
      width: 364px;
      overflow: auto;
      filter: none;
      border-radius: 6px;
      box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
    }
  }
  .status-select {
    border-radius: 6px;
    padding: 9px 16px;
  }
`;
