import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { components } from 'react-select';
import { ReactComponent as EditIcon } from '../../../assets/icons/property/edit.svg';
import { ReactComponent as HorizontalDots } from '../../../assets/images/HorizontalDots.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import { useError } from '../../../hooks/useError';
import { setAutomationsDetails, updateAutomationDetails } from '../../../store/features/automationsSlice';
import { addToast } from '../../../store/features/toastSlice';
import { getUsers } from '../../../store/features/userManagementSlice';
import { AutomationDetailsLeftWrapper } from '../../../styles/components/automationDetails/automation-details.styled';
import { Avatar } from '../../common/avatar-group';
import MenuV2 from '../../common/menu/menu-v2';
import Switch from '../../common/switch';
import { EditAutomationField } from './automation-edit-form';

const CustomOption = ({ innerProps, data, isSelected }) => {
  return (
    <div
      className={classNames(
        'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <Avatar avatar={data} variant="small" backgroundColorIndex={data?.optionIndex ?? 1} />
      <span
        className={classNames(
          'flex-1 inter-400-text natural-900-text option-text ml-2',
          isSelected && 'inter-500-text',
        )}>
        {data.name}
      </span>
    </div>
  );
};

const CustomSingleValue = ({ data, ...rest }) => {
  return (
    <components.SingleValue {...rest} className="flex items-center">
      <Avatar avatar={data} variant="medium" backgroundColorIndex={data?.optionIndex ?? 1} />
      <span className="flex-1 inter-400-text natural-900-text option-text ml-2">{data.name}</span>
    </components.SingleValue>
  );
};

const AutomationDetailsLeft = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { id } = useParams();

  const { automationDetails } = useSelector(state => state.automations);

  const { setModal } = useContext(OrganisationContext);

  const [editField, setEditField] = useState({});
  const [error, setError] = useState({});
  const [searchedOwner, setSearchedOwner] = useState('');

  const onCancelUpdateData = () => {
    setEditField({});
    setError({});
  };

  const changeAutomationDetailsStatus = is_enabled => {
    dispatch(setAutomationsDetails({ ...automationDetails, is_enabled: is_enabled }));
  };

  const onChangeEnable = async () => {
    const { is_enabled } = automationDetails;

    setModal({
      type: 'automation-action',
      content: {
        automation: automationDetails,
        top: false,
        is_enabled: is_enabled,
        onSuccess: () => changeAutomationDetailsStatus(!is_enabled),
      },
    });
  };

  const onUpdateData = (field, value) => {
    const { name, description, owner } = automationDetails;
    const request = { name: name, description: description, owner: { id: owner.id }, [field]: value };

    dispatch(updateAutomationDetails({ automationID: id, request: request }))
      .then(data => {
        onCancelUpdateData();
        dispatch(
          addToast({
            error: false,
            title: t('AUTOMATION_UPDATED'),
            text: t('SUCCESSFULLY_AUTOMATION_UPDATED'),
            id: nanoid(),
          }),
        );
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_AUTOMATION_UPDATING') });
      });
  };

  const onDelete = () => {
    setModal({
      type: 'automation-action',
      content: {
        automation: automationDetails,
        top: false,
        isDelete: true,
        onSuccess: () => navigate('/platforms/automations'),
      },
    });
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {}, payload = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map((option, index) => ({
        ...option,
        label: option.name,
        value: option.id,
        optionIndex: index,
      }));

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <AutomationDetailsLeftWrapper className="flex-column border">
      <div className="flex items-center border-bottom px-6 py-5">
        <label className="flex-1 font-18 color-neutral-900  inter-600-text">{automationDetails?.name}</label>
        <MenuV2
          Icon={HorizontalDots}
          menuList={[{ name: t('DELETE'), onClick: () => onDelete(), permission: 'AUTOMATIONS_MANAGE' }]}
        />
      </div>
      <div className="px-3 py-6 flex-column overflow-scroll flex-1">
        <div className="flex-column">
          {editField.type === 'NAME' ? (
            <div className=" update-detail-contaner  relative">
              <EditAutomationField
                data={editField}
                onCancelUpdateData={onCancelUpdateData}
                onUpdateData={() => onUpdateData('name', editField.value)}
                setEditField={setEditField}
                setError={setError}
                error={error}
              />
            </div>
          ) : (
            <div
              className="flex items-center justify-between  w-full cursor hover-edit relative"
              onClick={() => setEditField({ type: 'NAME', value: automationDetails?.name })}>
              <div className="flex-column w-full">
                <span className="inter-400-text grey-text-04  font-12 mb-1">{t('NAME')}</span>
                <div className="flex items-center ">
                  <label className="font-14 inter-400-text  color-neutral-900 one-line flex-1 hover-margin regular-text">
                    {automationDetails?.name}
                  </label>
                </div>
              </div>
              <EditIcon width={14} height={14} className="edit-icon" color="#2496FF" />
            </div>
          )}
        </div>
        <div className="flex-column mt-4">
          {editField.type === 'DESCRIPTION' ? (
            <div className="update-detail-contaner relative">
              <EditAutomationField
                data={editField}
                onCancelUpdateData={onCancelUpdateData}
                onUpdateData={() => onUpdateData('description', editField.value)}
                setEditField={setEditField}
                setError={setError}
                error={error}
              />
            </div>
          ) : (
            <div
              className="flex items-center justify-between w-full cursor hover-edit relative"
              onClick={() => setEditField({ type: 'DESCRIPTION', value: automationDetails?.description })}>
              <div className="flex-column w-full">
                <span className="inter-400-text grey-text-04  font-12 mb-1">{t('DESCRIPTION')}</span>
                <div className="flex items-center ">
                  <label className="font-14 inter-400-text  color-neutral-900 one-line flex-1 hover-margin regular-text">
                    {automationDetails?.description}
                  </label>
                </div>
              </div>
              <EditIcon width={14} height={14} className="edit-icon" color="#2496FF" />
            </div>
          )}
        </div>
        <div className="flex-column mt-4">
          {editField.type === 'OWNER' ? (
            <div className="update-detail-contaner relative">
              <EditAutomationField
                data={editField}
                onCancelUpdateData={onCancelUpdateData}
                onUpdateData={() => onUpdateData('owner', { id: editField.value.id })}
                setEditField={setEditField}
                setError={setError}
                searchedOwner={searchedOwner}
                setSearchedOwner={setSearchedOwner}
                error={error}
                loadOptions={fetchChildOpt}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getUsers,
                  pageable: true,
                }}
                customComponent={{
                  Option: CustomOption,
                  SingleValue: CustomSingleValue,
                }}
              />
            </div>
          ) : (
            <div
              className="flex items-center justify-between  w-full cursor hover-edit relative"
              onClick={() => setEditField({ type: 'OWNER', value: automationDetails?.owner })}>
              <div className="flex-column w-full">
                <span className="inter-400-text grey-text-04 font-12 mb-1">{t('OWNER')}</span>
                <div className="flex items-center col-gap-2 ">
                  <Avatar
                    className="w-24px h-24px"
                    variant="medium"
                    avatar={automationDetails?.owner}
                    backgroundColorIndex={editField?.value?.optionIndex ?? 1}
                  />
                  <label className="font-14 inter-400-text  color-neutral-900 one-line flex-1 hover-margin regular-text">
                    {automationDetails?.owner?.name}
                  </label>
                </div>
              </div>
              <EditIcon width={14} height={14} className="edit-icon" color="#2496FF" />
            </div>
          )}
        </div>
        <div className="flex-column mt-5 px-3">
          <div className="flex items-center justify-between border radius-2 pxy-3">
            <p className="normal-text flex-1">{automationDetails.is_enabled ? t('ENABLED') : t('DISABLED')}</p>
            <div>
              <Switch enabled={automationDetails.is_enabled} disabled={false} onClick={() => onChangeEnable()} />
            </div>
          </div>
        </div>
      </div>
    </AutomationDetailsLeftWrapper>
  );
};

export default AutomationDetailsLeft;
