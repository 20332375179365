import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription, initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { deleteOrganizationJobType } from '../../store/features/jobSlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteJobType = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { user } = useSelector(state => state.user);
  const { organizations } = user || {};
  const { id: organization_id } = organizations?.[0] || {};

  const { modal, setModal } = useContext(OrganisationContext);
  const { jobType, title, descriptions, is_job_type_owner, onSuccess } = modal.content;
  const {
    id: job_type_id,
    name,
    description,
    owner: { id: owner_id },
  } = jobType;

  const [loading, setLoading] = useState(false);

  const onDone = async () => {
    setLoading(true);
    try {
      const deleteAPI = is_job_type_owner ? () => {} : deleteOrganizationJobType;
      await dispatch(deleteAPI({ job_type_id: job_type_id, organization_id: organization_id }));

      const title = is_job_type_owner ? t('JOB_TYPE_DELETED_SUCCESSFULLY') : t('JOB_TYPE_REMOVED_SUCCESSFULLY');
      dispatch(addToast({ error: false, text: title, id: uuid() }));
      onSuccess && onSuccess(jobType);
      setModal(initModal);
    } catch (error) {
      const errorMessage = is_job_type_owner ? t('ERROR_WHILE_DELETING_JOB_TYPE') : t('ERROR_WHILE_REMOVING_JOB_TYPE');
      const textFromError = getErrorDescription(error, errorMessage);
      showErrorToast({ error, default_message: textFromError, id: nanoid() });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteJobTypeWrapper className="flex-column items-center radius-3 pxy-6">
        <div className="flex-column content-wrapper gap-5 items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text natural-400-text text-center font-14">{descriptions}</p>
          </div>
        </div>
        <div className="flex-column border radius-1_5 py-4 px-5 w-full row-gap-2">
          <label className="inter-600-text font-16">{name}</label>
          <label className="inter-400-text natural-500-text one-line">{description}</label>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative')}
            label={is_job_type_owner ? t('DELETE') : t('REMOVE')}
            disabled={loading}
            loading={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </DeleteJobTypeWrapper>
    </CSSTransition>
  );
};

const DeleteJobTypeWrapper = styled.div`
  width: 420px;
  gap: 24px;
`;

export default DeleteJobType;
