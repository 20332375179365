import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import IconContainer from '../../components/common/icon-container';
import QuoteFileHandler from '../../components/quote-details/quote-attachments';
import { OrganisationContext } from '../../context/organisationContext';
import { initSideModal } from '../../helpers/utils';

const UploadQuoteAttachments = props => {
  const { quoteDetails } = useSelector(state => state.quotes);

  const { sideModal, setSideModal } = useContext(OrganisationContext);
  const [uploadedFiles, setUploadedFiles] = useState(quoteDetails.attachments || []);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const { t } = useTranslation();

  return (
    <UploadQuoteAttachmentsWrapper>
      <div className="flex justify-between items-center border-bottom px-6 pt-6_5 pb-5">
        <label className="inter-600-text font-20 line-height-28 natural-900-text">
          {t('ATTACHMENTS')}({uploadedFiles.length})
        </label>
        <IconContainer
          Icon={CloseIcon}
          backgroundColor={'natural_50'}
          iconColor={'natural_400'}
          iconContainerClassname="cursor"
          iconHeight={16}
          iconWidth={16}
          onClick={() => setSideModal(initSideModal)}
        />
      </div>
      <div className="flex flex-column overflow-auto custom-scrollbar thin-scrollbar">
        <QuoteFileHandler
          quoteDetails={quoteDetails}
          setUploadedFiles={setUploadedFiles}
          setUploadingFiles={setUploadingFiles}
          uploadedFiles={uploadedFiles}
          uploadingFiles={uploadingFiles}
        />
      </div>
    </UploadQuoteAttachmentsWrapper>
  );
};

const UploadQuoteAttachmentsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export default UploadQuoteAttachments;
