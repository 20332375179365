import classNames from 'classnames';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CrossIcons.svg';
import IconContainer from '../../common/icon-container';
import InputElement from '../../common/input';
import SearchableDropdown from '../../common/searchable-dropdown';

const MultiValueChooserDropdownIndicator = props => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props} className="">
      <IconContainer
        Icon={AddIcon}
        iconHeight={12}
        iconWidth={12}
        iconContainerClassname="radius-full icon-container"
        backgroundColor="primary_50"
        iconColor="primary_500"
        iconClassName={classNames('transition', {
          'rotate-45': menuIsOpen,
        })}
      />
    </components.DropdownIndicator>
  );
};

const Component = ({
  component_type,
  isMulti = false,
  value = '',
  choices = [],
  onListChange,
  onInputChange,
  ...rest
}) => {
  const updatedChoices = choices.map(choice => ({ ...choice, value: choice.id, label: choice.name }));
  let componentValue = value;

  if (component_type === 'CHOOSERMULTI') {
    componentValue = value?.split(',') || [];
  }

  const onChooserMultiChange = selectedOptions => {
    const newOptionValues = selectedOptions.map(option => option.id)?.join(',');
    onListChange(newOptionValues);
  };

  switch (component_type) {
    case 'CHOOSER':
      return (
        <SearchableDropdown
          {...rest}
          defaultAdditional={{
            defaultOptions: updatedChoices,
            fetchFunction: undefined,
          }}
          isSearchable={false}
          isCustomSearchable={false}
          className="component-chooser"
          value={updatedChoices.find(c => c.id === componentValue)}
          onChange={option => onListChange(option?.id)}
          customStyle={{
            control: { minHeight: '20px', border: 'none', boxShadow: 'none' },
            dropdownIndicator: { padding: '0px', height: '16px', width: '16px' },
            valueContainer: { padding: '2px' },
          }}
        />
      );
    case 'ALPHANUMERIC':
      return (
        <InputElement
          {...rest}
          variant="size_24"
          className="component-input"
          value={componentValue}
          onChange={onInputChange}
        />
      );
    case 'CHOOSERMULTI':
      return (
        <SearchableDropdown
          {...rest}
          defaultAdditional={{
            defaultOptions: updatedChoices,
            fetchFunction: undefined,
          }}
          isMulti
          isSearchable={false}
          isCustomSearchable={false}
          closeMenuOnSelect={false}
          className="component-chooser-multi"
          value={updatedChoices.filter(c => componentValue.includes(c.id))}
          onChange={onChooserMultiChange}
          menuPortalTarget={document.body}
          ignoreMenuDomClick={true}
          customStyle={{
            control: { minHeight: '20px', border: 'none', boxShadow: 'none', borderRadius: '100%' },
            dropdownIndicator: { padding: '0px', height: '16px', width: '16px' },
            valueContainer: { padding: '2px' },
            menu: { minWidth: '200px', maxWidth: '240px', width: 'auto', right: '-30px' },
          }}
          customComponent={{
            ValueContainer: () => null,
            DropdownIndicator: MultiValueChooserDropdownIndicator,
          }}
        />
      );
    default:
      return null;
  }
};

const ChooserMultiValueDisplay = ({ choices = [], value, onListChange = () => {} }) => {
  const updatedChoices = choices.map(choice => ({ ...choice, value: choice.id, label: choice.name }));

  let componentValue = value?.split(',') || [];

  const selectedChoices = updatedChoices.filter(c => componentValue.includes(c.id));

  const onRemoveChoice = choice => {
    const newChoices = selectedChoices.filter(c => c.id !== choice.id);
    onListChange(newChoices.map(c => c.id)?.join(','));
  };

  if (selectedChoices.length === 0) {
    return null;
  }

  return (
    <div className="flex wrap gap-2">
      {selectedChoices.map(choice => (
        <div
          key={choice.id}
          className="flex items-center border px-2 py-1 radius-3 bg-natural-50 w-fit-content col-gap-1">
          <label className="inter-400-text natural-900-text font-12 one-line">{choice.name}</label>
          <IconContainer
            Icon={CloseIcon}
            iconHeight={14}
            iconWidth={14}
            iconContainerClassname="radius-full p-0 cursor"
            backgroundColor="transparent"
            iconColor="natural_900"
            onClick={() => onRemoveChoice(choice)}
          />
        </div>
      ))}
    </div>
  );
};

const WidgetComponent = ({ component, onChangeFormData = () => {}, formData = [] }) => {
  const { id, name, component_type, list, lexicon, label } = component || {};
  const { choices = [] } = list || {};
  const componentValue = formData.find(data => data.panel_widget_component?.id === id) || {};
  const { submitted_data } = componentValue || {};

  const onChangeComponentValue = value => {
    onChangeFormData(component, value, lexicon);
  };

  return (
    <div className="flex-column row-gap-2" key={id}>
      <div className="flex items-center justify-between col-gap-3">
        <label className="inter-400-text natural-400-text one-line">{name}</label>
        <Component
          id={id}
          component_type={component_type}
          choices={choices}
          lexicon={lexicon}
          value={submitted_data}
          onListChange={onChangeComponentValue}
          onInputChange={onChangeComponentValue}
          placeholder={label}
        />
      </div>
      {component_type === 'CHOOSERMULTI' && (
        <ChooserMultiValueDisplay choices={choices} value={submitted_data} onListChange={onChangeComponentValue} />
      )}
    </div>
  );
};

const FormWidget = ({ widget, onChangeFormData = () => {}, formData = [] }) => {
  const { components } = widget || {};

  if (components.length === 0) {
    return <label className="inter-500-text natural-500-text">No components available</label>;
  }
  return (
    <FormWidgetWrapper className="flex flex-column col-gap-2 row-gap-4">
      {components.map(component => (
        <WidgetComponent
          key={component.id}
          component={component}
          onChangeFormData={onChangeFormData}
          formData={formData}
        />
      ))}
    </FormWidgetWrapper>
  );
};

const FormWidgetWrapper = styled.div`
  .component-chooser {
    width: fit-content;
    min-width: 80px;
  }

  .component-input {
    min-width: 100px;
    max-width: 140px;
  }

  .component-chooser-multi {
    width: fit-content;
    min-width: 22px;

    .searchable-select__indicator,
    .icon-container {
      height: 22px;
      width: 22px;
    }
  }
`;

export default FormWidget;
