import { nanoid } from 'nanoid';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
// import { ReactComponent as BundlesIcon } from '../../assets/icons/dashboard-icon.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file-outlate.svg';
import { ReactComponent as RadioIcon } from '../../assets/icons/radio-icon.svg';
import { OrganisationContext } from '../../context/organisationContext';
import IconContainer from '../common/icon-container';
import TableHeader from '../common/table-header';
import CustomTooltip from '../common/tooltip-new';
import QuoteDetailsProductItem from './QuoteDetailsProductItem';
import { QuoteDetailsProductsWrapper } from './wrapper';

const QuoteDetailsProducts = ({
  products,
  onUpdateQuoteProducts,
  isQuoteEditable,
  notEditableQuoteInfo = '',
  integration,
}) => {
  const { t } = useTranslation();
  const { hasPermission } = useContext(OrganisationContext);

  const [costOpened, setCostOpened] = useState(false);

  const quoteProductHeader = useMemo(() => {
    return [
      { name: t('NAME'), key: 'NAME' },
      { name: t('DESCRIPTION'), key: 'DESCRIPTION' },
      { name: t('QTY'), key: 'QTY', classname: 'text-center' },
      { name: t('UNIT_PRICE'), key: 'UNIT_PRICE', classname: 'text-center' },
      { name: t('DISCOUNT'), key: 'DISCOUNT', classname: 'text-center' },
      { name: t('TAX'), key: 'TAX', classname: 'text-center' },
      { name: t('AMOUNT'), key: 'AMOUNT', classname: 'text-center' },
      {
        name: t('COST'),
        key: 'ITEM_DROPDOWN',
        disabled: !hasPermission('PRICE_COST_VIEW'),
        onClick: () => {
          setCostOpened(prev => !prev);
        },
      },
    ];
  }, []);

  const addLineEnum = useMemo(() => {
    return [
      { name: t('ADD_TIME_LABOUR'), key: 'TIME', enumValue: 'TIME', icon: ClockIcon },
      { name: t('ADD_MISC'), key: 'MISC', enumValue: 'OTHER', icon: RadioIcon },
      { name: t('CATALOG'), key: 'CATALOG', enumValue: 'CATALOG', icon: FileIcon },
      // { name: t('BUNDLES'), key: 'BUNDLES', icon: BundlesIcon },
    ];
  }, []);

  const onLineItemAdd = item => {
    const { key, enumValue } = item;
    const productName = key === 'TIME' ? 'Time Labour' : key === 'MISC' ? 'Miscellaneous' : 'Product name';
    const request = {
      total_net: 0,
      unit_price: 0,
      total_amount: 0,
      total_tax: 0,
      discount_rate: 0,
      qty: 1,
      product_type: 'PHYSICAL',
      name: productName,
      unit: 'COUNT',
      line_no: products.length,
      description: '',
      taxation_scheme: {
        rate: 0,
        id: '',
        name: '',
      },
      isNew: true,
      line_item_type: enumValue ?? '',
      id: nanoid(),
    };
    const newQuoteProducts = [...products, request];
    onUpdateQuoteProducts(newQuoteProducts);
  };

  const onRemoveQuoteProduct = product => {
    if (!isQuoteEditable) return;
    const initialQuoteProducts = products.filter(p => p.id !== product.id);
    onUpdateQuoteProducts(initialQuoteProducts);
  };

  const onSaveQuoteProduct = updatedProduct => {
    const updatedProducts = products.map(product => {
      if (product.id === updatedProduct.id) {
        return updatedProduct;
      }
      return product;
    });
    onUpdateQuoteProducts(updatedProducts);
  };

  return (
    <QuoteDetailsProductsWrapper className="w-full overflow-scroll quote-details-products-wrapper">
      <div className="flex-column w-fit-content min-w-full h-auto">
        <TableHeader headers={quoteProductHeader} is_selected={costOpened} />
        {products?.map(product => (
          <QuoteDetailsProductItem
            key={product.id}
            product={product}
            onRemoveProduct={onRemoveQuoteProduct}
            isQuoteEditable={isQuoteEditable}
            onSaveQuoteProduct={onSaveQuoteProduct}
            notEditableQuoteInfo={notEditableQuoteInfo}
            integration={integration}
            costOpened={costOpened}
          />
        ))}
        {products?.length === 0 && (
          <div className="flex justify-center items-center pxy-4 border-bottom">
            <label className="font-14 inter-400-text natural-500-text">{t('NO_PRODUCTS_ADDED')}</label>
          </div>
        )}

        {isQuoteEditable && (
          <div className="flex items-center px-6 pt-3_5 pb-4 border-bottom gap-10 add-line-item-wrapper">
            {addLineEnum?.length > 0 &&
              addLineEnum?.map(item => {
                return (
                  <CustomTooltip
                    wrapperClassName="quote-tooltip h-fit-content"
                    id="quote-tooltip"
                    key={item.key}
                    place="bottom-start"
                    hidden={integration}
                    content={<label className="flex col-gap-1 items-center">{t('YOU_CANNOT_ADD_PRODUCT')}</label>}>
                    <div
                      className="flex items-center gap-2 cursor"
                      onClick={() => (integration ? onLineItemAdd(item) : null)}>
                      {item.icon && (
                        <IconContainer
                          Icon={item.icon}
                          iconWidth={16}
                          iconHeight={16}
                          iconColor="primary_500"
                          backgroundColor="transparent"
                          iconContainerClassname="p-0"
                        />
                      )}
                      <label className="font-14 primary-500-text inter-400-text">{item?.name}</label>
                    </div>
                  </CustomTooltip>
                );
              })}
          </div>
        )}
      </div>
    </QuoteDetailsProductsWrapper>
  );
};

export default QuoteDetailsProducts;
