export const propertyTypeOptions = [
  {
    value: 'HOUSE',
    label: 'House',
  },
  {
    value: 'FLAT',
    label: 'Flat',
  },
  {
    value: 'BUNGALOW',
    label: 'Bungalow',
  },
  {
    value: 'MAINSONETTE',
    label: 'Maisonette',
  },
  {
    value: 'PARK_HOME',
    label: 'Park home',
  },
  {
    value: 'COMMERCIAL',
    label: 'Commercial',
  },
];

export const contactTypeOptions = [
  {
    value: 'OWNER',
    label: 'Owner',
  },
  {
    value: 'LANDLORD',
    label: 'Landlord',
  },
  {
    value: 'TENANT',
    label: 'Tenant',
  },
  {
    value: 'CARER',
    label: 'Carer',
  },
  {
    value: 'THIRD_PARTY',
    label: 'Third party',
  },
  {
    value: 'POWER_OF_ATTORNEY',
    label: 'Power of attorney',
  },
  {
    value: 'SHARED_OWNER',
    label: 'Shared owner',
  },
];

export const companyTypeOptions = [
  {
    value: 'OWNER',
    label: 'Owner',
  },
  {
    value: 'LANDLORD',
    label: 'Landlord',
  },
  {
    value: 'TENANT',
    label: 'Tenant',
  },
  {
    value: 'MANAGEMENT_COMPANY',
    label: 'Management Company',
  },
  {
    value: 'LETTING_COMPANY',
    label: 'Letting Company',
  },
  {
    value: 'SHARED_OWNER',
    label: 'Shared Owner',
  },
];

export const employeeTypeOptions = [
  {
    value: 'TENANT',
    label: 'Tenant',
  },
  {
    value: 'MANAGING_AGENT',
    label: 'Managing Agent',
  },
  {
    value: 'LETTING_AGENT',
    label: 'Letting Agent',
  },
];

export const contactTitleOptions = [
  {
    value: 'MR',
    label: 'Mr',
  },
  {
    value: 'DR',
    label: 'Dr',
  },
  {
    value: 'MRS',
    label: 'Mrs',
  },
  {
    value: 'MS',
    label: 'Ms',
  },
  {
    value: 'MISS',
    label: 'Miss',
  },
];

export const propertySubTypeOptions = [
  {
    value: 'DETACHED',
    label: 'Detached',
  },
  {
    value: 'SEMI_DETACHED',
    label: 'Semi-detached',
  },
  {
    value: 'END_TERRACE',
    label: 'End terrace',
  },
  {
    value: 'MID_TERRACE',
    label: 'Mid terrace',
  },
  {
    value: 'ENCLOSED_END_TERRACE',
    label: 'Enclosed end terrace',
  },
  {
    value: 'ENCLOSED_MID_TERRACE',
    label: 'Enclosed mid terrace',
  },
];

export const conservatoryOptionals = [
  {
    value: 'YES',
    label: 'Yes',
  },
  {
    value: 'NO',
    label: 'No',
  },
];

export const orientationOptions = [
  {
    label: 'Left',
    value: 'LEFT',
  },
  {
    label: 'Right',
    value: 'RIGHT',
  },
];

export const ageBandEnumOptions = [
  {
    label: 'Before 1900',
    value: 'AGE_BEFORE1900',
  },
  {
    label: '1900 - 1929',
    value: 'AGE_1900',
  },
  {
    label: '1930 - 1949',
    value: 'AGE_1930',
  },
  {
    label: '1950 - 1966',
    value: 'AGE_1950',
  },
  {
    label: '1967 - 1975',
    value: 'AGE_1967',
  },
  {
    label: '1976 - 1982',
    value: 'AGE_1976',
  },
  {
    label: '1983 - 1990',
    value: 'AGE_1983',
  },
  {
    label: '1991 - 1995',
    value: 'AGE_1991',
  },
  {
    label: '1996 - 2002',
    value: 'AGE_1996',
  },
  {
    label: '2003 - 2006',
    value: 'AGE_2003',
  },
  {
    label: '2007 - 2011',
    value: 'AGE_2007',
  },
  {
    label: '2012 Onwards',
    value: 'AGE_ONWARDS2012',
  },
];

export const primaryHeatingOptions = [
  { label: 'Air Source Heat Pump', value: 'AIR_HEAT_PUMP' },
  { label: 'Gas boiler', value: 'GAS_BOILER' },
  { label: 'Electric Boiler', value: 'ELECTRIC_BOILER' },
  { label: 'Electric storage heaters', value: 'ELECTRIC_STORAGE' },
  { label: 'Gas room heaters', value: 'GAS_ROOM_HEATER' },
  { label: 'Oil boiler', value: 'OIL_BOILER' },
  { label: 'LPG boiler', value: 'LPG_BOILER' },
  { label: 'Solid fuel room heaters', value: 'SOLIDFUEL_ROOM_HEATER' },
  { label: 'Electric room heaters', value: 'ELECTRIC_ROOM_HEATER' },
  { label: 'Soild fossil fuel boiler', value: 'FOSSIL_FUEL_BOILER' },
  { label: 'Other', value: 'OTHER' },
];

export const secondaryHeatingOption = [{ label: 'None', value: 'NONE' }, ...primaryHeatingOptions];

export const unitOptions = [
  { label: 'Days', value: 'DAY' },
  { label: 'Hours', value: 'HOUR' },
  { label: 'Minutes', value: 'MINUTE' },
];

export const typeOptions = [
  { label: 'Before', value: 'BEFORE' },
  { label: 'After', value: 'AFTER' },
];

export const fieldOptions = [
  { label: 'Appointment date', value: 'appointment.start_date' },
  { label: 'Trigger date', value: 'event.created_on' },
];

export const startAtOptions = [
  { label: 'New', value: 'NEW' },
  { label: 'Quoted', value: 'QUOTED' },
  { label: 'Reviewing', value: 'REVIEWING' },
  { label: 'Negotiation', value: 'NEGOTIATION' },
  { label: 'Won', value: 'WON' },
];

export const costsTypeOptions = [
  {
    value: 'MATERIALS',
    label: 'Materials',
  },
  {
    value: 'LABOUR',
    label: 'Labour',
  },
  {
    value: 'EXPENSE',
    label: 'Expense',
  },
  {
    value: 'QUOTE',
    label: 'Quote',
  },
];

export const productType = [
  {
    value: 'PHYSICAL',
    label: 'Physical',
  },
  {
    value: 'SERVICE',
    label: 'Service',
  },
  {
    value: 'EXPENSE',
    label: 'Expense',
  },
  {
    value: 'EVENT',
    label: 'Event',
  },
  {
    value: 'USAGE',
    label: 'Usage',
  },
  {
    value: 'DIGITAL',
    label: 'Digital',
  },
];

export const soldAs = [
  {
    value: 'BUNDLE',
    label: 'Bundle',
  },
  {
    value: 'SINGLE',
    label: 'Single',
  },
];

export const fileTypes = [
  {
    label: 'Front photo',
    value: 'PHOTO_FRONT',
    id: 'PHOTO_FRONT',
  },
  {
    label: 'Back photo',
    value: 'PHOTO_BACK',
    id: 'PHOTO_BACK',
  },
  {
    label: 'Details view',
    value: 'DETAIL_VIEW',
    id: 'DETAIL_VIEW',
  },
  {
    label: 'Manufacturer Logo',
    value: 'MANUFACTURER_LOGO',
    id: 'MANUFACTURER_LOGO',
  },
];

export const priorityOptions = [
  { name: 'Highest', id: 'VERY_HIGH' },
  { name: 'High', id: 'HIGH' },
  { name: 'Medium', id: 'MEDIUM' },
  { name: 'Low', id: 'LOW' },
  { name: 'Lowest', id: 'VERY_LOW' },
];

export const statusOptions = [
  { name: 'To do', id: 'TODO' },
  { name: 'In-progress', id: 'STARTED' },
  { name: 'Blocked', id: 'BLOCKED' },
  { name: 'Done', id: 'COMPLETE' },
];
