import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import { OrganisationContext } from '../../context/organisationContext';
import { createNumberMask } from '../../helpers/createNumberMask';
import { initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { addPaymentTerms } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const percentageNumberMaskOption = {
  allowDecimal: true,
  allowLeadingZeroes: true,
  includeThousandsSeparator: false,
  integerLimit: 3,
  prefix: '',
  suffix: ' %',
};

const dueAfterNumberMaskOption = {
  allowDecimal: false,
  allowLeadingZeroes: false,
  includeThousandsSeparator: false,
  integerLimit: 3,
  prefix: '',
  suffix: ' days',
};

const AddNewPaymentTerms = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { modal, setModal } = useContext(OrganisationContext);
  const { onSuccess } = modal.content;

  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');
  const [payment, setpayment] = useState([
    {
      name: '',
      id: nanoid(),
      percentage: '',
      description: '',
      due_count: '',
      is_required: true,
      priority: 0,
    },
  ]);

  const onRemoveItem = id => {
    const updatedPaymentTerms = payment.filter(p => p.id !== id);
    setpayment(updatedPaymentTerms);
  };

  const onAddNewItem = () => {
    const newPaymentTerm = {
      id: nanoid(),
      name: '',
      description: '',
      percentage: 0,
      due_count: 0,
      is_required: true,
      priority: payment?.length + 1 || 0,
    };
    setpayment([...payment, newPaymentTerm]);
  };

  const handlePaymentTermFieldChange = (value, id, keyName) => {
    const updatedPaymentTerms = payment.map(pt => (pt.id === id ? { ...pt, [keyName]: value } : pt));
    setpayment(updatedPaymentTerms);
  };

  const handleSubmit = () => {
    if (!input.trim()) {
      dispatch(addToast({ error: false, text: t('ENTER_NAME'), id: nanoid() }));
      return;
    }
    if (loading) {
      return;
    }
    const totalPercentage = payment.reduce((acc, term) => acc + parseFloat(term.percentage || 0), 0);
    if (totalPercentage != 100) {
      dispatch(
        addToast({
          error: true,
          text: t('CURRENT_TERM_NOT_EQUAL_TO_100'),
        }),
      );
      return;
    }
    setLoading(true);
    dispatch(addPaymentTerms({ request: { name: input, description: input, payment_terms: payment } }))
      .then(data => {
        setLoading(false);
        setModal(initModal);
        dispatch(addToast({ error: false, text: t('SUCCESS_PAYMENT_TERMS_ADD'), id: nanoid() }));
        onSuccess && onSuccess(data);
      })
      .catch(error => {
        setLoading(false);
        showErrorToast({ error, default_message: t('ERROR_PAYMENT_TERMS_ADD'), id: nanoid() });
      });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <QuotesActionWrapper>
        <div className="add-new-payment-terms payment-terms">
          <div className="pb-6 mb-9 border-bottom">
            <label className="inter-700-text font-28">{t('NEW_PAYMENT_TERM')}</label>
          </div>
          <InputElement
            className="mb-6"
            onChange={setInput}
            value={input}
            name={t('PAYMENT_TERM_NAME')}
            placeholder={t('EXTENDED_PAYMENT_TERM')}
          />
          {payment?.length > 0
            ? payment.map((item, index) => (
                <div key={item.id}>
                  <div className="row-terms-rows flex items-center gap-2 mb-4">
                    <div className="w-full">
                      <InputElement
                        value={item.name}
                        name={index === 0 && t('NAME')}
                        placeholder={t('EG_DEPOSIT')}
                        defaultValue={item.name}
                        onChange={value => handlePaymentTermFieldChange(value, item.id, 'name')}
                      />
                    </div>
                    <div className="w-full">
                      {index === 0 && (
                        <span className="inter-500-text natural-900-text mb-1 flex">{t('PERCENTAGE')}</span>
                      )}
                      <MaskedInput
                        mask={createNumberMask(percentageNumberMaskOption)}
                        className="inter-400-text natural-900-text one-line input w-full"
                        placeholder={'0'}
                        value={item.percentage}
                        guide={false}
                        onChange={e =>
                          handlePaymentTermFieldChange(
                            parseInt(e.target.value.replace(' %') || 0),
                            item.id,
                            'percentage',
                          )
                        }
                      />
                    </div>
                    <div className="w-full">
                      <InputElement
                        name={index === 0 && t('MILESTONE')}
                        placeholder={t('MILESTONE')}
                        value={item.description}
                        defaultValue={item.description}
                        onChange={value => handlePaymentTermFieldChange(value, item.id, 'description')}
                      />
                    </div>
                    <div className="w-50">
                      {index === 0 && (
                        <span className="inter-500-text natural-900-text mb-1 flex">{t('DUE_AFTER')}</span>
                      )}
                      <InputElement
                        extra_text="days"
                        type="number"
                        placeholder={'0'}
                        value={item.due_count}
                        onChange={value => handlePaymentTermFieldChange(value, item.id, 'due_count')}
                      />
                    </div>
                    <div className="h-full flex items-center justify-center">
                      <IconContainer
                        Icon={CloseIcon}
                        iconColor="natural_900"
                        iconClassName="cursor"
                        backgroundColor="transparent"
                        iconContainerClassname="p-0 mt-5"
                        onClick={() => {
                          onRemoveItem(item.id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
            : null}
          <div className="flex items-center justify-start cursor mt-2">
            <IconContainer
              Icon={AddIcon}
              iconWidth={16}
              iconHeight={16}
              iconColor="primary_500"
              backgroundColor="transparent"
            />
            <label className="inter-400-text primary-text ml-1" onClick={() => onAddNewItem()}>
              {t('ADD_LINE_ITEM')}
            </label>
          </div>
          <div className="flex justify-center mt-12" style={{ 'column-gap': '450px' }}>
            <Button
              className={`default flex-1 specified-width`}
              label={'Cancel'}
              size="large"
              onClick={() => setModal(initModal)}
              width="96px"
            />
            <Button
              className={`primary flex-1 specified-width`}
              label={'Add'}
              loading={loading}
              onClick={handleSubmit}
              disabled={false}
              size="large"
            />
          </div>
        </div>
      </QuotesActionWrapper>
    </CSSTransition>
  );
};

export const QuotesActionWrapper = styled.div`
  width: 900px;
  padding: 40px;
`;

export default AddNewPaymentTerms;
