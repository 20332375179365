import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import MediaDroparea from '../../components/common/media-droparea';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import CustomTooltip from '../../components/common/tooltip-new';
import { FileUpload } from '../../components/property-details-components/property-files';
import { OrganisationContext } from '../../context/organisationContext';
import { fileTypes } from '../../helpers/optionData';
import { initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { createProductMedia, setProductInfo } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const UploadMedia = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { showErrorToast } = useError();

  const { modal, setModal } = useContext(OrganisationContext);

  const { product, onSuccess = () => {} } = modal.content;
  const { id: product_id } = product;

  const [files, setFiles] = useState([]);
  const [primaryFile, setPrimaryFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const [mediaTypeError, setMediaTypeError] = useState(false);

  const allFilesAreUploaded = files.length > 0 && files.every(file => file && file.media_id);

  const onChangeType = (file_id, option_id) => {
    setFiles(prev => [...prev.map(f => (f.id === file_id ? { ...f, product_media_type: option_id } : f))]);
    setMediaTypeError(false);
  };

  const onUpdateProductPrimaryFile = () => {
    if (!primaryFile) {
      onSuccess();
      setModal(initModal);
      return;
    }
    const file = files.find(f => f.id === primaryFile);
    const request = {
      ...product,
      image: {
        id: file.media_id,
      },
    };
    setUpdatingProduct(true);
    dispatch(setProductInfo({ id: product_id, request }))
      .then(() => {
        onSuccess();
        dispatch(addToast({ error: false, text: 'Primary media updated successfully', id: nanoid() }));
        setModal(initModal);
      })
      .catch(error => {
        showErrorToast({ error, default_message: 'Failed to update primary media', id: nanoid() });
      })
      .finally(() => setUpdatingProduct(false));
  };

  const onUpload = async () => {
    const allFilesHasType = files.length > 0 && files.every(file => file.product_media_type);
    if (!allFilesHasType) {
      dispatch(addToast({ text: 'Please set all photo tags before uploading', error: true, id: nanoid() }));
      setMediaTypeError(true);
      return;
    }
    setMediaTypeError(false);
    setLoading(true);
    const promiseResult = await Promise.allSettled(
      files.map(file => {
        const { media_id, product_media_type } = file;
        const request = {
          media: {
            id: media_id,
          },
          variant: null,
          product_media_type: product_media_type,
        };
        return dispatch(createProductMedia({ id: product_id, request }));
      }),
    );
    const success = promiseResult.every(result => result.status === 'fulfilled');
    if (success) {
      dispatch(addToast({ error: false, text: 'Media uploaded successfully', id: nanoid() }));
      onUpdateProductPrimaryFile();
    } else {
      dispatch(addToast({ message: 'Failed to upload media', type: 'error' }));
    }
    setLoading(false);
  };

  return (
    <UploadFileWrapper className="py-7 flex-column row-gap-8">
      <div className="flex items-center justify-between px-8">
        <label className="inter-600-text font-20">Upload media</label>
        <IconContainer
          Icon={CloseIcon}
          iconContainerClassname="cursor"
          iconColor="natural_900"
          backgroundColor="transparent"
          onClick={() => setModal(initModal)}
        />
      </div>
      <div className="flex-column flex-1 px-8 overflow-scroll thin-scrollbar custom-scrollbar">
        <MediaDroparea setFiles={addedFiles => setFiles(prev => [...prev, ...addedFiles])} />
        <div className="flex-column row-gap-4">
          {files.length > 0 &&
            files?.map(file => (
              <FileUpload
                key={file.id}
                file={file}
                setFiles={setFiles}
                showDeletePopup={false}
                wrapperClassName={mediaTypeError && file.media_id && !file.product_media_type ? 'file-error' : ''}>
                <Fragment>
                  <SearchableDropdown
                    defaultAdditional={{
                      defaultOptions: fileTypes,
                    }}
                    ignoreMenuDomClick={true}
                    menuPortalTarget={document.body}
                    isCustomSearchable={false}
                    placeholder="Select"
                    value={fileTypes.find(type => type.id === file.product_media_type)}
                    onChange={option => onChangeType(file.id, option.id)}
                    customStyle={{
                      control: {
                        minHeight: '24px',
                        borderRadius: '4px',
                        backgroundColor: theme.natural_100,
                        borderColor: theme.natural_100,
                      },
                      valueContainer: {
                        padding: '4px 8px',
                      },
                      dropdownIndicator: {
                        padding: '4px',
                        marginRight: '4px',
                      },
                      menu: {
                        width: 'fit-content',
                      },
                    }}
                  />
                  <CustomTooltip
                    tooltipClassname="tooltip"
                    id="primary-file-tooltip"
                    content={<span className="inter-400-text font-12">Make primary image</span>}>
                    <IconContainer
                      Icon={StarIcon}
                      iconHeight={14}
                      iconWidth={14}
                      iconContainerClassname="radius-50-percent star-icon cursor"
                      onClick={() => setPrimaryFile(primaryFile === file.id ? null : file.id)}
                      backgroundColor={primaryFile === file.id ? 'primary_50' : 'natural_100'}
                      iconColor={primaryFile === file.id ? 'primary_500' : 'natural_400'}
                    />
                  </CustomTooltip>
                </Fragment>
              </FileUpload>
            ))}
        </div>
      </div>
      <div className="flex items-center px-8 justify-between">
        <Button
          label="Cancel"
          className="default specified-width py-4 px-6"
          width="fit-content"
          size="average"
          onClick={() => setModal(initModal)}
        />
        <Button
          label="Upload"
          className="primary specified-width py-4 px-6"
          width="fit-content"
          size="average"
          disabled={!allFilesAreUploaded}
          loading={loading || updatingProduct}
          onClick={onUpload}
        />
      </div>
    </UploadFileWrapper>
  );
};

const UploadFileWrapper = styled.div`
  width: 648px;
  max-height: 90dvh;

  .uploaded-file {
    .uploaded {
      width: 32px;
      height: 32px;
      object-fit: fill;
    }
  }

  .file-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .star-icon {
    padding: 5px;
  }

  .file-error {
    border-color: ${({ theme }) => theme.error_500};
  }

  .tooltip {
    padding: 4px 12px;
    border-radius: 4px;
  }
`;

export default UploadMedia;
