import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcons.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';
import { ReactComponent as CornerDown } from '../../../assets/icons/corner-down-right-alt.svg';
import Button from '../../../components/common/button/button';
import InputElement from '../../../components/common/input';
import SearchableDropdown from '../../../components/common/searchable-dropdown';
import { OrganisationContext } from '../../../context/organisationContext';
import { getErrorFieldJoined, initModal } from '../../../helpers/utils';
import { useError } from '../../../hooks/useError';
import { createProject, getJobTypeList, getWorkflowList } from '../../../store/features/jobSlice';
import { addToast } from '../../../store/features/toastSlice';

const JobType = ({ jobType, error, index, setJobTypes, showCloseIcon, allJobs }) => {
  const { job_type, workflow } = jobType;
  const { t } = useTranslation();
  const [searchJob, setSearchJob] = useState('');
  const [searchedWorkflow, setSearchedWorkflow] = useState('');

  const onJobUpdate = option => {
    setJobTypes(jobType => {
      const prevJobs = [...jobType];
      prevJobs[index].job_type = option;
      prevJobs[index].workflow = [''];
      return prevJobs;
    });
  };
  const onWorkflowUpdate = (option, wokflowIndex) => {
    setJobTypes(jobType => {
      const prevJobs = [...jobType];
      prevJobs[index].workflow[wokflowIndex] = option;
      return prevJobs;
    });
  };

  const onAddWorkflow = () => {
    setJobTypes(jobTypes => {
      const updatedJobTypes = jobTypes.map((jobType, idx) => {
        if (idx !== index) {
          return jobType;
        }
        return {
          ...jobType,
          workflow: [...jobType.workflow, ''],
        };
      });
      return updatedJobTypes;
    });
  };

  const removeWorkflow = indexToRemove => {
    setJobTypes(prevData => {
      const updatedItems = prevData.map((item, idx) => {
        if (idx === index) {
          const updatedWorkflow = item.workflow.filter((_, index) => index !== indexToRemove);
          return { ...item, workflow: updatedWorkflow };
        }
        return item;
      });
      return updatedItems;
    });
  };

  return (
    <JobTypeWrapper className="bg-natural-100">
      <div className="p-4">
        <div className="w-full flex justify-between items-center job-label">
          <label className="font-14 inter-500-text color-neutral-900 text-start">
            {t('JOB_TYPE')} {`${index + 1}`}
          </label>
          {showCloseIcon && (
            <CrossIcon
              className="cursor"
              onClick={() => setJobTypes(prev => prev.filter((item, filterIndex) => filterIndex !== index))}
            />
          )}
        </div>
        <SearchableDropdown
          isClearable
          inputValue={searchJob}
          onInputChange={setSearchJob}
          className="w-full"
          placeholder={t('SELECT_JOB_TYPE')}
          value={job_type}
          onChange={option => onJobUpdate(option)}
          filterOption={option => !allJobs.includes(option.value)}
          customStyle={{
            control: {
              borderRadius: '6px',
              '&:hover': {
                boxShadow: null,
              },
            },
          }}
          isSearchable={true}
          defaultAdditional={{
            page: 0,
            fetchFunction: getJobTypeList,
            pageable: false,
          }}
          error={error?.job_type && !job_type}
        />
      </div>
      {job_type && (
        <div className="p-4 border-top">
          {workflow.map((item, workflowIndex) => (
            <div className="flex items-center gap-2 mb-2" key={workflowIndex}>
              <CornerDown />
              <SearchableDropdown
                key={job_type.id}
                inputValue={searchedWorkflow}
                onInputChange={setSearchedWorkflow}
                className="w-full"
                placeholder={t('SELECT_WORKFLOW')}
                filterOption={option => !workflow.find(e => e.value === option.value)}
                value={item}
                menuPlacement="top"
                onChange={option => onWorkflowUpdate(option, workflowIndex)}
                isSearchable={true}
                customStyle={{
                  control: {
                    height: '32px',
                    minHeight: '32px',
                    borderRadius: '6px',
                    '&:hover': {
                      boxShadow: null,
                    },
                  },
                  valueContainer: { padding: '0 16px' },
                }}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getWorkflowList,
                  params: {},
                  pageable: false,
                }}
                error={error?.workflow[workflowIndex] && !workflow[workflowIndex]}
              />
              {workflow.length > 1 && <CrossIcon className="cursor" onClick={() => removeWorkflow(workflowIndex)} />}
            </div>
          ))}
          <button
            className={`text-button inter-500-text cursor font-12 blue-primary-icon items-center flex  h-40px gap-6px`}
            onClick={onAddWorkflow}>
            <AddIcon className="mr-0 blue-primary-icon" height={14} width={14} />
            {t('ADD_WORKFLOW')}
          </button>
        </div>
      )}
    </JobTypeWrapper>
  );
};
const AddProject = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const { showErrorToast } = useError();

  const { onSuccess } = modal?.content;

  const [project, setProject] = useState({});
  const [error, setError] = useState({});
  const [jobTypeError, setjobTypeError] = useState({});
  const [loading, setLoading] = useState(false);

  const [jobTypes, setJobTypes] = useState([{ job_type: null, workflow: [''] }]);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const checkProjectError = () => {
    const { name, description, code } = project;
    const jobErrorObj = jobTypes.map(item => {
      return { job_type: item.job_type === null, workflow: item.workflow.map(work => work === '') };
    });
    if (
      !name ||
      !description ||
      !code ||
      jobErrorObj.some(item => {
        return item.job_type || item.workflow.some(value => value === true);
      })
    ) {
      const error = {
        name: !name,
        description: !description,
        code: !code,
        jobTypes: jobErrorObj.some(item => {
          return item.job_type || item.workflow.some(value => value === true);
        }),
      };
      const errorFields = getErrorFieldJoined(error, (key, value) => (key === 'jobTypes' ? 'job types' : key), ' and ');
      const errorText = `Please add ${errorFields}`;
      setError(error);
      setjobTypeError(jobErrorObj);

      showErrorToast({ default_message: errorText });
      return true;
    }
    setError({});
    setjobTypeError({});
    return false;
  };

  const onDone = () => {
    if (checkProjectError()) {
      return;
    }
    setLoading(true);
    const workflow_job_types = jobTypes.flatMap(jobs =>
      jobs.workflow.map(workflow => ({ job_type: { id: jobs.job_type.id }, workflow: { id: workflow.id } })),
    );
    const request = { name: project.name, description: project.description, code: project.code, workflow_job_types };
    dispatch(createProject({ request: request }))
      .then(data => {
        onSuccess(data);
        dispatch(
          addToast({
            error: false,
            text: t('PROJECT_ADDED_SUCCESSFULLY'),
            id: nanoid(),
          }),
        );
        setLoading(false);
        setModal(initModal);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_CREATING_PROJECT') });
        setLoading(false);
      });
  };

  const handleCodeInput = value => {
    if (value.length <= 6) {
      const regex = /^[A-Za-z0-9]{0,6}$/;
      if (regex.test(value)) {
        setProject({ ...project, code: value.toUpperCase() });
      }
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddPropertyWrapper className="flex-column items-center  py-9">
        <div className="w-full flex justify-between items-center px-10 ">
          <label className="font-20 inter-600-text text-black text-start">{t('NEW_PROJECT')}</label>
          <CrossIcon className="cursor" onClick={() => setModal(initModal)} />
        </div>
        <div className="form-content w-full px-10 mb-8 mt-10 flex-column gap-6">
          <InputElement
            className="w-full"
            name={t('PROJECT_NAME')}
            placeholder={t('ENTER_PROJECT_NAME')}
            value={project?.name}
            onChange={value => {
              setProject({ ...project, name: value });
            }}
            error={error.name && !project?.name}
          />
          <InputElement
            className="w-full scroll-container"
            type="textarea"
            name={t('DESCRIPTION')}
            placeholder={t('ENTER_PROJECT_DESCRIPTION')}
            value={project?.description}
            onChange={value => {
              setProject({ ...project, description: value });
            }}
            error={error.description && !project?.description}
          />
          <InputElement
            className="w-full"
            name={t('CODE')}
            sub_name={`(${t('MAX_6_CHAR')})`}
            placeholder="e.g OTV"
            value={project?.code}
            onChange={handleCodeInput}
            error={error.code && !project?.code}
          />
          {jobTypes.map((jobType, index) => (
            <div className="w-full gap-5px flex-column " key={index}>
              <JobType
                jobType={jobType}
                index={index}
                setJobTypes={setJobTypes}
                error={jobTypeError[index]}
                showCloseIcon={jobTypes.length > 1}
                allJobs={jobTypes.map(item => item.job_type?.id)}
              />
            </div>
          ))}
          <div className="w-full gap-5px flex-column ">
            <Button
              className={`secondary h-40px gap-6px `}
              label={t('ADD_JOB_TYPE')}
              size="customsize"
              onClick={() => setJobTypes(jobTypes => [...jobTypes, { job_type: null, workflow: [''] }])}
              icon={<AddIcon className="mr-0 blue-primary-icon" height={16} width={16} />}
              borderRadius="100px"
            />
          </div>
        </div>

        <div className="flex col-gap-6 justify-center w-full px-10">
          <Button
            className={`default flex-1 ${loading && 'disabled'}`}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={`primary flex-1`}
            label={t('ADD')}
            onClick={onDone}
            primary={true}
            size="large"
            borderRadius="100px"
            loading={loading}
          />
        </div>
      </AddPropertyWrapper>
    </CSSTransition>
  );
};

export default AddProject;

const AddPropertyWrapper = styled.div`
  width: 456px;
  max-height: 680px;

  .form-content,
  .scroll-container textarea {
    overflow: auto;
    scrollbar-width: auto;
    ::-webkit-scrollbar {
      width: 5px;
      height: auto;

      &-track {
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 5px;
      }

      &-thumb {
        background: ${({ theme }) => theme.primary_500} !important;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }
    }
  }
`;

const JobTypeWrapper = styled.div`
  border-radius: 10px;
  .job-label {
    margin-bottom: 5px;
  }
`;
