import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { calculateProductAmounts, calculateTotalAmountOfProducts } from '../../helpers/functions';
import { useError } from '../../hooks/useError';
import { getProductFamilies, setProductFamilies, updateQuoteDetails } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import QuoteDetailsProducts from '../quote-details/QuoteDetailsProducts';
import QuoteTotalValues from '../quote-details/QuoteTotalValues';
import QuoteHeader from './quote-header';

const SystemQuote = ({ systemDetails, updateSystemDetailsQuotes, fetchSytemDetails }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { jobDetails } = useSelector(state => state.property);
  const { job_type } = jobDetails || {};

  const { quote } = systemDetails || {};

  const [quoteDetailsInitial, setQuoteDetailsInitial] = useState(quote);
  const [loading, setLoading] = useState(false);

  const { id: quote_id, products, integration, quote_template } = quoteDetailsInitial || { products: [] };

  const showActionBtn = JSON.stringify(quote) !== JSON.stringify(quoteDetailsInitial);

  const fetchProductFamilies = () => {
    const { quote_category } = job_type || {};
    dispatch(getProductFamilies({ params: { tag: quote_category } })).catch(() => {
      dispatch(setProductFamilies([]));
    });
  };

  useEffect(() => {
    setQuoteDetailsInitial(quote);
  }, [quote]);

  useEffect(() => {
    if (job_type) {
      fetchProductFamilies();
    }

    return () => {
      dispatch(setProductFamilies([]));
    };
  }, [job_type]);

  const onUpdateQuoteProducts = newProducts => {
    setQuoteDetailsInitial({ ...quoteDetailsInitial, products: newProducts });
  };

  const onCancel = () => {
    setQuoteDetailsInitial(quote);
  };

  const onSave = () => {
    const { products, quote_template, integration, ...rest } = quoteDetailsInitial;
    const request = {
      ...rest,
      ...calculateTotalAmountOfProducts(products || []),
      integration: integration?.id ? { id: integration?.id } : null,
      products: calculateProductAmounts(products || [], integration),
      quote_template: quote_template?.id ? { id: quote_template?.id } : null,
    };
    setLoading(true);
    dispatch(updateQuoteDetails({ quote_id, request }))
      .then(data => {
        dispatch(addToast({ error: false, text: t('QUOTE_DETAILS_UPDATED_SUCCESSFULLY'), id: nanoid() }));
        setQuoteDetailsInitial({ ...data });
        updateSystemDetailsQuotes({ ...data });
        fetchSytemDetails();
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_QUOTE_DETAILS') });
      })
      .finally(() => setLoading(false));
  };

  return (
    <SystemQuoteWrapper className="pt-6 flex-column flex-1 row-gap-5 overflow-hidden" id="quote-wrapper">
      <QuoteHeader
        className="px-6"
        quoteDetailsInitial={quoteDetailsInitial}
        setQuoteDetailsInitial={setQuoteDetailsInitial}
        isQuoteEditable={true}
        notEditableQuoteInfo={''}
        isNewQuote={false}
        defaultTemplate={quote_template}
        showActionBtn={showActionBtn}
        onCancel={onCancel}
        onSave={onSave}
        loading={loading}
      />
      <div className="flex-column flex-1 row-gap-4 px-6 overflow-auto custom-scrollbar thin-scrollbar">
        <QuoteDetailsProducts
          isQuoteEditable={true}
          onUpdateQuoteProducts={onUpdateQuoteProducts}
          products={products}
          notEditableQuoteInfo={''}
          integration={integration || {}}
        />
        <QuoteTotalValues products={products} />
      </div>
    </SystemQuoteWrapper>
  );
};

const SystemQuoteWrapper = styled.div`
  .header-container,
  .data-container {
    height: 100%;
    min-height: 40px;
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 2fr) 56px 118px 80px 80px 90px 90px;
    column-gap: 16px;
    padding: 16px;
  }

  .header-container {
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 2fr) 56px 118px 80px 80px 90px 90px;
  }

  .data-container {
    grid-template-rows: 44px 1fr;
    row-gap: 16px;

    .input {
      height: 28px;
      padding: 2px 12px;
      width: calc(100% - 16px);
    }

    .cost-wrapper {
      grid-column: 4 / span 5;
      grid-row: 2;

      .cost-container {
        display: grid;
        grid-template-columns: 118px 80px 80px 90px 90px !important;
        column-gap: 16px;
        grid-template-rows: minmax(32px, 1fr) 28px;
        row-gap: 8px;

        .cost-profit-container {
          grid-row: 2;
          grid-column: 3 / span 3;
          width: fit-content;
          display: grid;
          grid-template-columns: 80px 90px 80px !important;
          column-gap: 16px;
          align-items: center;
          border-radius: 6px;
        }
      }
    }
  }

  .components-container {
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 2fr) 56px 118px 80px 80px 90px 90px;
    margin: 16px;
    column-gap: 16px;
  }

  .quote-details-products-wrapper {
    border: 1px solid ${({ theme }) => theme.natural_200};
    border-radius: 8px;
    overflow: visible !important;
  }

  .add-line-item-wrapper {
    border: none;
  }
  .my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export default SystemQuote;
