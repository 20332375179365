import { ReactComponent as CloseIcon } from '@assets/images/toast-close.svg';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../../common/button/button';
import Checkbox from '../../common/checkbox';
import IconContainer from '../../common/icon-container';
import { Avatar } from './owner-group';

const OwnersPopover = ({ setShowOwnerPopover, owners = [], setBoardsFilters, boardsFilters }) => {
  const { t } = useTranslation();

  const { owners: initialBoardOwners } = boardsFilters;

  const [boardOwners, setBoardOwners] = useState(new Set(initialBoardOwners || []));

  const handleCheckboxChange = avatar => {
    setBoardOwners(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(avatar)) {
        newSelected.delete(avatar);
      } else {
        newSelected.add(avatar);
      }
      return newSelected;
    });
  };

  return (
    <OwnersPopoverWrapper className="popover-content bg-white py-5 radius-2 flex-column">
      <div className="justify-between px-5">
        <label className="inter-500-text font-18 natural-900-text line-height-28">
          {t('OWNERS')} {owners?.length}
        </label>
        <IconContainer
          Icon={CloseIcon}
          iconHeight={16}
          iconWidth={16}
          iconColor="natural_500"
          backgroundColor="transparent"
          iconContainerClassname="cursor"
          onClick={() => setShowOwnerPopover(false)}
        />
      </div>
      <div className="pxy-5">
        {owners.map((avatar, index) => (
          <div className="flex items-center gap-2 mb-4" key={avatar?.id}>
            <Checkbox
              is_checked_done={true}
              checked={boardOwners.has(avatar) || false}
              onChange={() => handleCheckboxChange(avatar)}
              id={`owners-${avatar?.id}`}
            />
            <Avatar
              key={avatar?.id || index}
              variant="large"
              avatarWrapperClassname="workitem-avatar"
              avatar={avatar || {}}
              backgroundColorIndex={index}
            />
            <p htmlFor={`owners-${avatar?.id}`} className="no-select inter-400-text natural-700-text font-16">
              {avatar?.name}
            </p>
          </div>
        ))}
      </div>
      <div className="flex col-gap-10 px-5 justify-center mt-1 ">
        <Button
          className={classNames('primary-grey')}
          label={t('RESET')}
          onClick={() => {
            setBoardsFilters(prev => ({ ...prev, owners: [] }));
            setShowOwnerPopover(false);
          }}
          size="average"
          borderRadius="100px"
        />
        <Button
          className={classNames('primary')}
          label={t('APPLY_SORT')}
          onClick={() => {
            setBoardsFilters(prev => ({ ...prev, owners: Array.from(boardOwners) }));
            setShowOwnerPopover(false);
          }}
          size="average"
          borderRadius="100px"
        />
      </div>
    </OwnersPopoverWrapper>
  );
};

const OwnersPopoverWrapper = styled.div`
  &.popover-content {
    z-index: 10;
    width: 357px;
    box-shadow: 0px 2px 8px -4px rgba(16, 24, 40, 0.1), 0px 6px 17px -3px rgba(16, 24, 40, 0.1);
  }

  .radio-container {
    margin-bottom: 14px;
  }

  .radio-label {
    margin-left: 5px;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
  }
`;

export default OwnersPopover;
