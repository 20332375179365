import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/AlertIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import Button from '../../components/common/button/button';
import Status from '../../components/common/status';
import { OrganisationContext } from '../../context/organisationContext';
import {
  capitalizeFirstLetterOfWords,
  formatText,
  getFormattedDate,
  getFormattedNumber,
  initModal,
} from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { deleteQuote } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const DeleteQuote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { modal, setModal } = useContext(OrganisationContext);
  const { quote, title, description, onSuccess } = modal.content;
  const { id, subtitle, code, created_on, status, total_amount, salesperson } = quote;

  const [loading, setLoading] = useState(false);

  const onDone = () => {
    setLoading(true);

    dispatch(deleteQuote({ quote_id: id }))
      .then(() => {
        dispatch(
          addToast({
            error: false,
            title: t('QUOTE_REMOVED'),
            text: `${SUCCESSFULLY_REMOVED_QUOTE} ${code}.`,
            id: nanoid(),
          }),
        );
        onSuccess && onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_REMOVING_QUOTE'), id: nanoid() });
      })
      .finally(() => setLoading(false));
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <QuotesActionWrapper className="flex-column items-center radius-3 pxy-6">
        <div className="flex-column content-wrapper gap-5 items-center relative">
          <AlertIcon />
          <CrossIcon className="absolute right-0 cursor" onClick={() => setModal(initModal)} />
          <div className="flex-column gap-2 items-center justify-start w-full">
            <label className="inter-500-text font-18 text-center">{title}</label>
            <p className="inter-400-text natural-400-text text-center">{description}</p>
          </div>
        </div>
        <div className="border radius-1_5 py-4 px-5 w-full">
          <div className="flex items-center justify-between pb-3">
            <h2 className="font-16 inter-600-text natural-900-text my-0">{code}</h2>
            <Status
              withDot={false}
              withDottedBorder
              status={status}
              statusText={capitalizeFirstLetterOfWords(formatText(status))}
            />
          </div>
          <div className="flex-column row-gap-2">
            <p className="inter-400-text natural-900-text font-14">
              Prepared by: <span className=" inter-400-text natural-500-text">{salesperson?.name || '-'}</span>
            </p>{' '}
            <p className="inter-400-text natural-900-text font-14">
              Created: <span className=" inter-400-text natural-500-text font-14">{getFormattedDate(created_on)}</span>
            </p>{' '}
            <p className="inter-400-text natural-900-text font-14 ">
              Total:{' '}
              <span className=" inter-400-text natural-500-text font-14">{getFormattedNumber(total_amount)}</span>
            </p>{' '}
          </div>
        </div>
        <div className="action-wrapper flex-column justify-center w-full gap-3">
          <Button
            className={classNames('negative', loading && 'disabled')}
            label={t('REMOVE')}
            disabled={loading}
            onClick={() => onDone()}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary-grey', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
        </div>
      </QuotesActionWrapper>
    </CSSTransition>
  );
};

export const QuotesActionWrapper = styled.div`
  width: 384px;
  gap: 32px;
`;

export default DeleteQuote;
