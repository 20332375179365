import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '../../components/common/button/button';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element';
import UpdateAction from '../../components/common/update-action';
import ActivitySchedulerV2 from '../../popup/activity/activity-scheduler-v2';
import { getActivities } from '../../store/features/activitiesSlice';

const Scheduling = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const todayRef = useRef(moment().startOf('day').unix());

  const [selectedDate, setSelectedDate] = useState(todayRef.current);
  const [activities, setActivities] = useState({ content: [] });
  const [appointments, setAppointments] = useState([]);
  const [loadingAvailabilities, setLoadingActivities] = useState(false);

  const fetchAvailability = () => {
    const activity_start_date = moment.unix(selectedDate).startOf('day').unix();
    const activity_end_date = moment.unix(selectedDate).endOf('day').unix();
    setLoadingActivities(true);
    dispatch(
      getActivities({
        params: {
          'activity_date.start': activity_start_date,
          'activity_date.end': activity_end_date,
          order_by: 'GRID',
          page: 0,
          size: 100,
        },
      }),
    )
      .then(data => {
        setActivities(data);
      })
      .catch(() => {})
      .finally(() => setLoadingActivities(false));
  };

  useEffect(() => {
    fetchAvailability();
  }, [selectedDate]);

  useEffect(() => {
    const newAppointments = activities.content.map(resource => ({
      ...resource,
      activity: resource,
      start_date: resource.expected_date?.start_date,
      end_date: moment.unix(resource.expected_date?.start_date).add(resource.expected_date?.duration, 'minutes').unix(),
    }));

    if (newAppointments.length === 0) {
      setAppointments([{ 0: [] }]);
      return;
    }

    const groupAppointmentsByRows = newAppointments.reduce(
      (acc, appointment) => {
        const start_date = appointment.start_date + 1;
        const end_date = appointment.end_date + 1;
        const keys = Object.keys(acc);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const appointments = acc[key];
          let isAvailable = true;

          appointments.forEach(app => {
            if (app.start_date < start_date && app.end_date > start_date) {
              isAvailable = false;
              return;
            }
            if (app.start_date < end_date && app.end_date > end_date) {
              isAvailable = false;
              return;
            }
            if (start_date < app.start_date && end_date > app.start_date) {
              isAvailable = false;
              return;
            }
            if (start_date < app.end_date && end_date > app.end_date) {
              isAvailable = false;
              return;
            }
          });
          if (isAvailable) {
            acc[key].push(appointment);
            return acc;
          } else {
            if (i === keys.length - 1) {
              acc[parseInt(key) + 1] = [appointment];
              return acc;
            }
          }
        }
        return acc;
      },
      { 0: [] },
    );
    const newAppointments2 = Object.values(groupAppointmentsByRows).map((appointments, index) => ({
      id: index,
      resources: appointments,
    }));
    const appointmentWithEmptyRow = [...newAppointments2, { id: newAppointments2.length, resources: [] }];
    setAppointments(appointmentWithEmptyRow);
  }, [activities]);

  const renderTodayButton = () => {
    if (todayRef.current === selectedDate) {
      return null;
    }
    return (
      <Button
        fontSize="14px"
        className="primary-grey specified-width px-5"
        width="auto"
        size="customsize"
        label={t('TODAY')}
        onClick={() => setSelectedDate(todayRef.current)}
      />
    );
  };

  return (
    <SchedulesWrapper className="flex-column flex-1">
      <SchedulesHeaderWrapper className="flex items-center w-full border-bottom py-4 px-6">
        <div className="flex items-center justify-between flex-1 border-right">
          <label className="inter-600-text natural-900-text font-20">{t('JOBS_LIST')}</label>
          {/* <Button
            size="medium"
            label={t('SHOW')}
            icon={<RightArrowIcon width={14} height={14} className="primary-500-text" />}
            className="secondary mr-6 specified-width"
            width="80px"
            fontSize="12px"
          /> */}
        </div>
        <div className="flex flex-2 ml-6">
          <div className="flex items-center col-gap-6 flex-1">
            <label className="inter-600-text natural-900-text font-20">{t('ACTIVITIES')}</label>
            <div className="w-fit-content">
              <DateSelectorElement
                isDisabled={false}
                className="w-fit-content radius-full"
                selectedDate={selectedDate}
                setSelectedDate={date => setSelectedDate(date)}
                placeholder={t('SELECT')}
                isCalendarIcon={false}
                arrows={true}
                format="ddd DD MMM, YYYY"
              />
            </div>
            {renderTodayButton()}
          </div>
          <UpdateAction
            width="100px"
            size="medium"
            className="w-fit-content"
            btnClassname="specified-width"
            disabled={true}
            primaryBtnProps={{ label: t('SCHEDULE') }}
          />
        </div>
      </SchedulesHeaderWrapper>
      <SchedulesDetailsWrapper className="flex-1 w-full overflow-scroll h-full px-6 mb-4">
        <div className="flex-1 pt-6">
          <ActivitySchedulerV2
            appointments={appointments}
            onAddResource={() => {}}
            isShowAddress={true}
            onDeleteResource={() => {}}
            selectedDate={selectedDate}
            loading={loadingAvailabilities}
            onRefresh={fetchAvailability}
          />
        </div>
      </SchedulesDetailsWrapper>
    </SchedulesWrapper>
  );
};

const SchedulesWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

const SchedulesHeaderWrapper = styled.div`
  .resource-type,
  .service-type {
    width: auto;
  }
  .download-icon {
    border-radius: 100%;
    padding: 0px;
    width: 44px;
    height: 44px;
  }
`;

const SchedulesDetailsWrapper = styled.div`
  * ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  * ::-webkit-scrollbar-thumb {
    background: #a3a3a3;
    border-radius: 10px;
  }
`;

export default Scheduling;
