import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { ReactComponent as ArrowDown } from '../../../assets/icons/down-arrow.svg';
import IconContainer from '../../common/icon-container';
import FormWidget from './form-widget';

const FormPanel = ({ panel, index, onChangeFormData = () => {}, formData = [] }) => {
  const { id: panel_id, widgets, name } = panel || {};

  const [panelOpened, setPanelOpened] = React.useState(true);

  if (widgets.length === 0) {
    return null;
  }

  return (
    <div key={panel_id} className={classNames('flex flex-column pt-2 col-gap-2 border-top', index !== 0 && 'mt-4')}>
      <div
        className="flex items-center cursor py-1 justify-between align-center cursor-pointer"
        onClick={() => setPanelOpened(!panelOpened)}>
        <label className="inter-600-text">{name}</label>
        <IconContainer
          Icon={ArrowDown}
          backgroundColor="transparent"
          iconHeight={20}
          iconWidth={20}
          iconClassName={classNames('transition', {
            'rotate-180': panelOpened,
          })}
        />
      </div>
      <AnimatePresence>
        {panelOpened && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-column col-gap-2">
            {widgets.map(widget => (
              <FormWidget key={widget.id} widget={widget} onChangeFormData={onChangeFormData} formData={formData} />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FormPanel;
