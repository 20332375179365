import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as BedroomIcon } from '../../assets/icons/property/bedroom.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/property/calendar.svg';
import { ReactComponent as ConservatoryIcon } from '../../assets/icons/property/conservatory.svg';
import { ReactComponent as ExtentionIcon } from '../../assets/icons/property/extentions.svg';
import { ReactComponent as HeadtingIcon } from '../../assets/icons/property/heating.svg';
import { ReactComponent as HouseIcon } from '../../assets/icons/property/house.svg';
import { ReactComponent as LeftIcon } from '../../assets/icons/property/left.svg';
import { ReactComponent as LivingRoomIcon } from '../../assets/icons/property/living-room.svg';
import { ReactComponent as MainGasIcon } from '../../assets/icons/property/main-gas.svg';
import { ReactComponent as SizeIcon } from '../../assets/icons/property/size.svg';
import { ReactComponent as StoreyIcon } from '../../assets/icons/property/storeys.svg';
import { ReactComponent as ToiletIcon } from '../../assets/icons/property/toilet.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { ReactComponent as MapIcon } from '../../assets/images/map.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { ageBandEnumOptions, secondaryHeatingOption } from '../../helpers/optionData';
import { capitalize, formatText } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { useQuery } from '../../hooks/useQuery';
import {
  getEnergyConsumption,
  getEnergyConsumptionPatterns,
  getEnergyCosts,
  importEnergyConsumption,
  importRoofInformationFromGoogle,
  updateEnergyCosts,
  updatePropertyDetails,
  updatePropertyDetailsData,
} from '../../store/features/propertySlice';
import { getSolarOerviewData } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import GoogleMaps from '../common/google-maps';
import IconContainer from '../common/icon-container';
import Switch from '../common/switch';
import CustomTooltip from '../common/tooltip-new';
import NotesList from '../notes';
import PropertyDetailsCosts from '../property-details-costs-list';
import PropertQuotes from './Property-quotes';
import ElectricityConsumption from './electricity-consumption';
import PropertyActivities from './property-activities';
import { PropertyDeals } from './property-deals';
import PropertyFiles from './property-files';
import PropertyJobPartners from './property-job-partners';
import { PropertyEngageMents } from './property-jobs';
import PropertyLog from './property-logs';
import PropertySystemDetails from './property-system-details';
import PropertyTask from './property-tasks';
import RoofSegments from './roof-segments';

const MainProperty = ({ setShowmapScreen }) => {
  const { t } = useTranslation();

  const { propertyDetails } = useSelector(state => state.property);
  const { details, property_class } = propertyDetails || {};

  const [slideOpen, setSlideOpen] = useState(true);

  const renderPropertyDetail = ({ icon, containerClassName, text, textEle = null, prefixText = '' }) => {
    return (
      <div className={classNames('flex items-center', containerClassName)}>
        {icon && (
          <IconContainer
            iconContainerClassname="mr-2 flex h-fit-content"
            iconClassName="natural-600-text"
            iconHeight={16}
            iconWidth={16}
            Icon={icon}
            backgroundColor="natural_100"
          />
        )}
        {textEle ? (
          textEle
        ) : (
          <label className="inter-400-text natural-900-text">
            <span className={classNames(!text && 'natural-400-text')}>{text || 'No data'}</span>
            {prefixText && text ? <span className="ml-1">{prefixText}</span> : null}
          </label>
        )}
      </div>
    );
  };

  return (
    <div className="flex-column px-6 py-5 border radius-2">
      <div className="cursor inter-600-text flex items-center justify-between" onClick={() => setSlideOpen(!slideOpen)}>
        <label className="natural-900-text flex-1">{t('MAIN_DETAILS')}</label>
        <Arrow className={classNames('transition', slideOpen ? 'rotate-90' : 'rotate-0')} />
      </div>
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: slideOpen ? 'auto' : 0, transition: { duration: 0.5 } }}
        className="overflow-hidden">
        <div className="pt-3 border-bottom" />
        <CommercialPropertyDetails
          details={details}
          location={propertyDetails.location}
          renderPropertyDetail={renderPropertyDetail}
          setShowmapScreen={setShowmapScreen}
        />
        {/* <ResidentialPropertyDetails renderPropertyDetail={renderPropertyDetail} details={details} /> */}
      </motion.div>
    </div>
  );
};

const ResidentialPropertyDetails = ({ renderPropertyDetail, details }) => {
  const { t } = useTranslation();
  const {
    rooms,
    property_type,
    sub_type,
    storeys,
    sq_mtr,
    is_maingas,
    extensions,
    conservatories,
    secondary_heating,
    orientation,
    age_band,
  } = details || {};

  return (
    <div className="main-property pt-6 items-center justify-center">
      {renderPropertyDetail({ icon: HouseIcon, text: property_type && capitalize(formatText(property_type)) })}
      {renderPropertyDetail({
        icon: HouseIcon,
        text: sub_type && capitalize(formatText(sub_type)),
        containerClassName: 'capitalize-value',
      })}
      {renderPropertyDetail({ icon: LeftIcon, text: orientation ? capitalize(formatText(orientation)) : null })}
      {renderPropertyDetail({
        icon: CalendarIcon,
        text: ageBandEnumOptions.find(a => a.value === age_band)?.label,
      })}
      {renderPropertyDetail({ icon: StoreyIcon, text: storeys, prefixText: t('STOREYS') })}
      {renderPropertyDetail({ icon: BedroomIcon, text: rooms?.bedrooms, prefixText: t('BEDROOMS') })}
      {renderPropertyDetail({ icon: ToiletIcon, text: rooms?.bathrooms, prefixText: t('BATHROOMS') })}
      {renderPropertyDetail({ icon: LivingRoomIcon, text: rooms?.receptions, prefixText: t('RECEPTIONS') })}
      {renderPropertyDetail({ icon: SizeIcon, text: sq_mtr, prefixText: t('SQ_MTR') })}
      {renderPropertyDetail({ icon: ConservatoryIcon, text: conservatories ?? '0', prefixText: t('CONSERVATORY') })}
      {renderPropertyDetail({ icon: ExtentionIcon, text: extensions ?? '0', prefixText: t('EXTENSIONS') })}
      {renderPropertyDetail({
        icon: HeadtingIcon,
        text: secondaryHeatingOption.find(h => h.value === secondary_heating)?.label || t('NO_HEATING'),
      })}
      {renderPropertyDetail({ icon: MainGasIcon, text: `${t('MAIN_GAS')} - ${is_maingas ? t('YES') : t('NO')}` })}
    </div>
  );
};

const CommercialPropertyDetails = ({ renderPropertyDetail, details, location, setShowmapScreen }) => {
  const { t } = useTranslation();

  const { property_type, commercial_type, sub_type, sq_mtr, orientation, storeys, no_units } = details || {};
  const { lat, lon } = location || {};
  const propertySubType = property_type === 'COMMERCIAL' ? commercial_type : sub_type;
  const propertyStoreys = property_type === 'COMMERCIAL' ? no_units : storeys;
  const storyText = property_type === 'COMMERCIAL' ? t('UNITS') : t('STOREYS');

  return (
    <div className="commercial-property pt-6 items-center justify-center">
      <div style={{ width: 250, height: 150 }}>
        <GoogleMaps
          coordinates={[location]}
          isSolar={false}
          additionalOptions={{
            streetViewControl: false,
            mapTypeControl: false,
            draggable: false,
            tilt: 0,
            fullscreenControl: false,
          }}
        />
      </div>
      <div className="other-property row-gap-4">
        {renderPropertyDetail({ icon: HouseIcon, text: property_type && capitalize(formatText(property_type)) })}
        {renderPropertyDetail({
          icon: HouseIcon,
          text: propertySubType && capitalize(formatText(propertySubType)),
          containerClassName: 'capitalize-value',
        })}
        {renderPropertyDetail({ icon: SizeIcon, text: sq_mtr, prefixText: t('SQ_MTR') })}
        {/* {renderPropertyDetail({ icon: LeftIcon, text: orientation ? capitalize(formatText(orientation)) : null })} */}
        {renderPropertyDetail({ icon: StoreyIcon, text: propertyStoreys, prefixText: storyText })}
        {renderPropertyDetail({
          textEle:
            lat && lon ? (
              <div
                className="radius-1 bg-location flex fw-w col-gap-4 py-0_5 px-1_5 cursor line-height-20"
                onClick={() => setShowmapScreen(true)}>
                <p className="font-12 inter-400-text">
                  <span className="natural-500-text">LAT:</span>
                  <span className="natural-900-text ml-1">
                    {lat.toFixed(3)}
                    {/* {lat < 0 ? 'S' : 'N'} */}
                  </span>
                </p>
                <p className="font-12 inter-400-text">
                  <span className="natural-500-text">LON:</span>
                  <span className="natural-900-text ml-1">
                    {lon.toFixed(3)}
                    {/* {lon < 0 ? 'W' : 'E'} */}
                  </span>
                </p>
              </div>
            ) : (
              <label className="inter-400-text natural-900-text">
                <span className="natural-400-text">No data</span>
              </label>
            ),
          containerClassName: 'span2',
        })}
      </div>
    </div>
  );
};

const RoofDetails = ({ isShort }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const navigate = useNavigate();
  const { roofInformation, propertyDetails } = useSelector(state => state.property);
  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;

  const [sloarDetails, setSloarDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSolarDesignData = () => {
    setLoading(true);
    dispatch(
      getSolarOerviewData({
        engagement_id: engagement_id,
      }),
    )
      .then(data => {
        setSloarDetails(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_SOLAR_DESIGNS') }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (engagement_id) {
      fetchSolarDesignData();
    }
  }, [engagement_id]);

  const onSystemOverview = () => {
    navigate(`roof-details/system-overview/${sloarDetails[0]?.id}`);
  };

  const { id: property_id, location } = propertyDetails || {};

  const [importingRoofDetails, setImportingRoofDetails] = useState(false);

  const onImportRoofDetails = () => {
    setImportingRoofDetails(true);
    dispatch(importRoofInformationFromGoogle({ property_id, request: { center: location } }))
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_IMPORTING_ROOF_DETAILS') });
      })
      .finally(() => setImportingRoofDetails(false));
  };

  return (
    <div className="flex-column">
      <div className="flex items-center justify-between">
        <label className="inter-600-text natural-900-text flex-1 font-16">{t('ROOF_INFORMATION')}</label>
        <div className="flex items-center col-gap-4">
          <div className="flex items-center gap-4">
            {isShort && (
              <Button
                size="medium"
                label={t('SYSTEM_OVERVIEW')}
                className="secondary specified-width px-4 py-1"
                width="auto"
                fontSize="12px"
                disabled={!sloarDetails?.length}
                afterIcon={<RightArrowIcon width={14} height={14} className="primary-500-text" />}
                onClick={onSystemOverview}
              />
            )}
            <CustomTooltip
              id="import-roof-details"
              hidden={!roofInformation?.length}
              content={
                <span className="inter-400-text font-12 flex" style={{ maxWidth: '240px' }}>
                  {'You can only import a properties roof(s) when there are no roofs on the property'}
                </span>
              }
              place="top"
              wrapperClassName="import-button-tooltip">
              <Button
                size="medium"
                label={t('IMPORT')}
                className="secondary specified-width"
                width="80px"
                fontSize="12px"
                disabled={roofInformation?.length}
                loading={importingRoofDetails}
                onClick={e => {
                  e.stopPropagation();
                  onImportRoofDetails();
                }}
              />
            </CustomTooltip>
            <Button
              size="medium"
              label={t('VIEW')}
              className="secondary specified-width"
              width="80px"
              fontSize="12px"
              onClick={() => navigate('roof-details')}
            />
          </div>
        </div>
      </div>
      {roofInformation?.map((roof, index) => (
        <RoofSegments key={roof.id} roofInformation={roof} title={`Roof ${index + 1}`} />
      ))}
    </div>
  );
};

const ElectricityConsumptionSec = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { propertyDetails } = useSelector(state => state.property);
  const {
    id: property_id,
    energy_consumption_pattern,
    details,
    property_class,
    is_uploaded_consumption,
  } = propertyDetails || {};
  const { class_type } = property_class || {};
  const isShowEnergyPatterns = !is_uploaded_consumption;

  const [tabInterval, setTabInterval] = useState();
  const [energyConsumption, setEnergyConsumption] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editConsumption, setEditConsumption] = useState(false);
  const [importFileOpen, setImportFileOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [importingEnergyConsumption, setImportingEnergyConsumption] = useState(false);

  const [energyPatterns, setEnergyPatterns] = useState([]);
  const [energyCosts, setEnergyCosts] = useState({});
  const [selectedEnergyPattern, setSelectedEnergyPattern] = useState(energy_consumption_pattern);

  const editMonthData = Array.from({ length: 12 }, (_, index) => ({
    month: index + 1,
    quarter: null,
    day: null,
    hour: null,
    half_hour: null,
    consumption_kwh: 0,
  }));

  const editQuarterData = Array.from({ length: 4 }, (_, index) => ({
    month: null,
    quarter: index + 1,
    day: null,
    hour: null,
    half_hour: null,
    consumption_kwh: 0,
  }));

  const editYearData = [
    {
      month: null,
      quarter: null,
      day: null,
      hour: null,
      half_hour: null,
      consumption_kwh: 0,
    },
  ];

  const fetchConsumption = (unit = undefined, onlyGraphdata = false) => {
    setLoading(true);
    let unitForData = unit;
    dispatch(
      getEnergyConsumption({
        id: property_id,
        type: 'ELECTRICITY',
        params: { unit: unitForData },
      }),
    )
      .then(data => {
        let editConsumptionData = [];
        const firstData = data?.[0];
        if (firstData) {
          unitForData = firstData?.month ? 'MONTH' : firstData?.quarter ? 'QUARTER' : 'YEAR';
        } else {
          unitForData = unitForData || 'YEAR';
        }

        if (unitForData === 'MONTH') {
          editConsumptionData = editMonthData;
        } else if (unitForData === 'QUARTER') {
          editConsumptionData = editQuarterData;
        } else {
          editConsumptionData = editYearData;
        }

        const electricityConsumption = data?.length > 0 ? data : editConsumptionData;

        if (!onlyGraphdata) {
          setEnergyConsumption(electricityConsumption);
        }
        if (unitForData === 'MONTH') {
          setGraphData(data);
        }
        if (!tabInterval && !unit) {
          setTabInterval(unitForData);
        }
      })
      .catch(error => {
        if (onlyGraphdata) {
          setGraphData([]);
        } else {
          setEnergyConsumption([]);
        }
        showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_ELECTRICITY_CONSUMPTION') });
      })
      .finally(() => setLoading(false));
  };

  const fetchEnergyPatters = () => {
    dispatch(
      getEnergyConsumptionPatterns({
        params: {
          class_type: class_type,
        },
      }),
    )
      .then(data => {
        const patterns = data.map(p => ({ ...p, label: p.name, value: p.id }));
        setEnergyPatterns(patterns);
      })
      .catch(() => setEnergyPatterns([]));
  };

  const fetchEnergyCosts = () => {
    dispatch(getEnergyCosts({ property_id: property_id, type: 'ELECTRICITY' }))
      .then(data => {
        setEnergyCosts(data);
      })
      .catch(() => setEnergyCosts({}));
  };

  const onUpdateCosts = costRequest => {
    const previousCosts = energyCosts;
    setEnergyCosts(costRequest);
    dispatch(updateEnergyCosts({ property_id: property_id, type: 'ELECTRICITY', request: costRequest }))
      .then(data => {
        setEnergyCosts(data);
        dispatch(addToast({ error: false, text: t('ENERGY_COSTS_UPDATED') }));
      })
      .catch(error => {
        setEnergyCosts(previousCosts);
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_ENERGY_COSTS') });
      });
  };

  const onUpdateUploadedConsumption = async is_uploaded_consumption => {
    const request = {
      ...propertyDetails,
      is_uploaded_consumption: is_uploaded_consumption,
    };

    await dispatch(updatePropertyDetails({ id: property_id, request })).catch(() => {});
  };

  const onUpdateEnergyPattern = value => {
    if (value?.id === energy_consumption_pattern?.id) {
      return;
    }
    const request = {
      ...details,
      energy_consumption_pattern: value ? { id: value.id } : null,
    };

    dispatch(updatePropertyDetailsData({ property_id: property_id, request }))
      .then(data => {
        fetchConsumption(tabInterval);
        fetchConsumption('MONTH', true);
        dispatch(addToast({ error: false, text: t('ENERGY_PATTERN_UPDATED') }));
      })
      .catch(error => {
        setSelectedEnergyPattern(energy_consumption_pattern);
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_ENERGY_PATTERN') });
      });
  };

  useEffect(() => {
    fetchEnergyPatters();
    fetchEnergyCosts();
    fetchConsumption();
    fetchConsumption('MONTH', true);
  }, []);

  useEffect(() => {
    if (tabInterval) {
      fetchConsumption(tabInterval);
    }
  }, [tabInterval]);

  useEffect(() => {
    if (selectedEnergyPattern?.id) {
      onUpdateEnergyPattern(selectedEnergyPattern);
    }
  }, [selectedEnergyPattern?.id]);

  const onImportFileData = () => {
    const isAnyFileAvailable = files?.length;
    if (!isAnyFileAvailable) {
      showErrorToast({ default_message: 'Upload atleast one file' });
      return;
    }
    const isAllFileUploaded = files.every(f => f.media_id);
    if (!isAllFileUploaded) {
      showErrorToast({ default_message: 'All files are not uploaded, please try again' });
      return;
    }
    const request = files.map(f => f.transformedData).flat();
    setImportingEnergyConsumption(true);
    dispatch(
      addToast({
        error: false,
        text: t('UPDATING_CONSUMPTION_PLEASE_WAIT'),
      }),
    );
    dispatch(importEnergyConsumption({ id: property_id, type: 'ELECTRICITY', request }))
      .then(async () => {
        await onUpdateUploadedConsumption(true);
        dispatch(addToast({ error: false, text: 'Energy consumption imported' }));
        setImportFileOpen(false);
        fetchConsumption();
        fetchConsumption(tabInterval);
        fetchConsumption('MONTH', true);
        setFiles([]);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_IMPORTING_ENERGY_CONSUMPTION') });
      })
      .finally(() => setImportingEnergyConsumption(false));
  };

  return (
    <div className="flex-column">
      <div className="flex items-center justify-between">
        <label className="inter-600-text natural-900-text flex-1 font-16">{t('ELECTRICITY_CONSUMPTION')}</label>
        <div className="flex items-center col-gap-4">
          {!importFileOpen && (
            <div className="flex items-center gap-4">
              <Button
                size="medium"
                label={t('UPLOAD')}
                className="secondary specified-width"
                width="80px"
                fontSize="12px"
                onClick={() => setImportFileOpen(true)}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <ElectricityConsumption
          tabInterval={tabInterval}
          setTabInterval={setTabInterval}
          graphData={graphData}
          energyConsumption={energyConsumption}
          editConsumption={editConsumption}
          setEditConsumption={setEditConsumption}
          property_id={property_id}
          fetchConsumption={fetchConsumption}
          setEnergyConsumption={setEnergyConsumption}
          loading={loading}
          importFileOpen={importFileOpen}
          setImportFileOpen={setImportFileOpen}
          importingEnergyConsumption={importingEnergyConsumption}
          onImportFileData={onImportFileData}
          setFiles={setFiles}
          files={files}
          setLoading={setLoading}
          energyPatterns={energyPatterns}
          selectedEnergyPattern={selectedEnergyPattern}
          setSelectedEnergyPattern={setSelectedEnergyPattern}
          energy_consumption_pattern={energy_consumption_pattern}
          energyCosts={energyCosts}
          onUpdateCosts={onUpdateCosts}
          isShowEnergyPatterns={isShowEnergyPatterns}
          onUpdateUploadedConsumption={onUpdateUploadedConsumption}
        />
      </div>
    </div>
  );
};

const OtherPropertyDetails = () => {
  const { t } = useTranslation();

  const { propertyDetails } = useSelector(state => state.property);
  const { details } = propertyDetails || {};
  const { tenure, local_authority } = details || {};

  const [slideOpen, setSlideOpen] = useState(false);

  return (
    <div className="flex-column px-6 py-5 border radius-2">
      <div className="cursor flex items-center justify-between" onClick={() => setSlideOpen(!slideOpen)}>
        <label className="inter-600-text natural-900-text flex-1">{t('OTHER_DETAILS')}</label>
        <Arrow className={classNames('transition', slideOpen ? 'rotate-90' : 'rotate-0')} />
      </div>
      <motion.div
        animate={{ height: slideOpen ? 'auto' : 0, transition: { duration: 0.5 } }}
        id="other-property-details"
        className="overflow-y-hidden">
        <div className="pb-2 border-bottom" />
        <div className="other-property items-center mt-6 justify-center">
          <div className="flex">
            <label className="inter-400-text natural-500-text mr-1">{t('TENURE')}:</label>
            <label className={classNames('inter-400-text', tenure ? 'natural-900-text' : 'natural-400-text')}>
              {capitalize(formatText(tenure, ' ')) || 'No data'}
            </label>
          </div>
          <div className="flex">
            <label className="inter-400-text natural-500-text mr-1">{t('LOCAL_AUTHORITY')}: </label>
            <label className={classNames('inter-400-text', local_authority ? 'natural-900-text' : 'natural-400-text')}>
              {local_authority ? local_authority?.name : 'No data'}
            </label>
          </div>
          <div className="flex">
            <label className="inter-400-text natural-500-text mr-1">{t('LOCAL_AUTHORITY_CODE')}: </label>
            <label className={classNames('inter-400-text', local_authority ? 'natural-900-text' : 'natural-400-text')}>
              {local_authority ? local_authority?.code : 'No data'}
            </label>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const PropertyTabs = ({
  showMap,
  setShowMap,
  selectedTab,
  setSelectedTab,
  isShowNotes,
  isShort,
  isShowFiles,
  fromProperty,
  solarMap,
  setSolarMap,
  jobDetails,
}) => {
  const { hasPermission, hasMultiplePermission } = useContext(OrganisationContext);
  const { t } = useTranslation();
  const { job_type } = jobDetails || {};
  const quotingCategoryJob = job_type?.quote_category;

  const defaultTabs = [
    ...(isShort ? [{ name: 'Property', key: 'PROPERTY' }] : [{ name: 'Property Details', key: 'PROPERTY' }]),
    ...(!isShort && fromProperty ? [{ name: 'Jobs', key: 'JOBS' }] : []),
    ...(!isShort && !fromProperty ? [{ name: 'Jobs', key: 'JOBS' }] : []),
    // ...(!isShort ? [{ name: t('DEALS'), key: 'DEALS' }] : []),
    // ...(isShort ? [{ name: 'Log', key: 'LOG' }] : []),
    ...(isShort ? [{ name: 'Activities', key: 'ACTIVITIES' }] : []),
    ...(isShort && hasMultiplePermission(['TASKS_VIEW', 'TASKS_VIEW_OWN']) ? [{ name: 'Tasks', key: 'TASKS' }] : []),
    ...(isShowNotes ? [{ name: 'Notes', key: 'NOTES' }] : []),
    ...(isShowFiles ? [{ name: 'Files', key: 'FILES' }] : []),
    ...(isShort ? [{ name: 'Quotes', key: 'QUOTES' }] : []),
    ...(isShort && hasPermission('JOB_COSTS_VIEW') ? [{ name: 'Costs', key: 'COSTS' }] : []),
    ...(isShort && hasPermission('JOB_COSTS_VIEW') ? [{ name: 'Partners', key: 'PARTNERS' }] : []),
  ];
  const tabs = isShort ? [defaultTabs[0], defaultTabs[1], ...defaultTabs.slice(2)] : defaultTabs;

  return (
    <div className="flex px-6 pt-3 border-bottom justify-between items-center">
      <div className="flex gap-5">
        {tabs.map(tab => (
          <div
            key={tab.key}
            onClick={() => {
              setSelectedTab(tab.key);
              setShowMap(false);
            }}
            className={classNames('px-4 py-4_5 cursor', selectedTab === tab.key && 'selected-tab')}>
            <label className="inter-500-text natural-700-text">{tab.name}</label>
          </div>
        ))}
      </div>
      <div className="flex items-center col-gap-4">
        <div className="flex items-center">
          <IconContainer
            iconContainerClassname="flex h-fit-content"
            iconClassName="natural-400-text"
            iconHeight={16}
            iconWidth={16}
            Icon={MapIcon}
            backgroundColor="transparent"
          />
          <label className="inter-400-text natural-700-text mr-1">{t('MAP')}</label>
          <Switch
            enabled={showMap}
            onClick={() => {
              setShowMap(!showMap);
              setSolarMap(false);
            }}
          />
        </div>
        {/* {isShort &&
          (quotingCategoryJob === 'SOLAR' ||
            quotingCategoryJob === 'SOLAR_BATTERY' ||
            quotingCategoryJob === 'BATTERY_ONLY') && (
            <div className="flex items-center">
              <label className="inter-400-text natural-700-text mr-1">Solar</label>
              <Switch
                enabled={solarMap}
                onClick={() => {
                  setSolarMap(!solarMap);
                  setShowMap(false);
                }}
              />
            </div>
          )} */}
      </div>
    </div>
  );
};

const MainPropertyDetails = ({
  loading = false,
  isShowNotes = true,
  isShort = true,
  isShowFiles = true,
  fromProperty = true,
  setShowmapScreen = () => {},
}) => {
  const { propertyDetails, jobDetails } = useSelector(state => state.property);

  const query = useQuery();
  const tab = query.get('selectedTab');

  const [showMap, setShowMap] = useState(false);
  const [solarMap, setSolarMap] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tab ? tab : fromProperty && !isShort ? 'PROPERTY' : 'PROPERTY');
  const [searchParams, setSearchParams] = useSearchParams({
    selectedTab: tab ? tab : fromProperty && !isShort ? 'PROPERTY' : 'PROPERTY',
  });

  if (loading) {
    return (
      <Skeleton
        height={'100%'}
        containerClassName="line-height-1 flex-1"
        baseColor="#CDCDCD"
        highlightColor="#E8E8E8"
      />
    );
  }

  return (
    <MainPropertyDetailsWrapper className="card radius-2 flex-column overflow-hidden flex-1">
      <PropertyTabs
        fromProperty={fromProperty}
        showMap={showMap}
        isShowNotes={isShowNotes}
        setShowMap={setShowMap}
        selectedTab={selectedTab}
        setSelectedTab={key => {
          setSelectedTab(key);
          setSearchParams({ selectedTab: key });
        }}
        isShowFiles={isShowFiles}
        isShort={isShort}
        solarMap={solarMap}
        setSolarMap={setSolarMap}
        jobDetails={jobDetails}
      />
      <Fragment>
        {showMap || solarMap ? (
          <div className="overflow-scroll pxy-6 h-full">
            <div className={classNames('flex items-center justify-center radius-3 entire-map-wrapper')}>
              <GoogleMaps coordinates={[propertyDetails.location]} isSolar={solarMap} />
            </div>
          </div>
        ) : (
          <Fragment>
            {selectedTab === 'PROPERTY' && (
              <motion.div
                key={'main-property-details'}
                initial={{ opacity: 0.3, y: 10 }}
                animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
                className="flex-column pxy-6 row-gap-6 overflow-scroll h-full-vh">
                <MainProperty setShowmapScreen={setShowmapScreen} />
                {isShort && <PropertySystemDetails />}
                <ElectricityConsumptionSec />
                {!isShort && <OtherPropertyDetails />}
              </motion.div>
            )}
            {selectedTab === 'JOBS' && <PropertyEngageMents />}
            {selectedTab === 'DEALS' && <PropertyDeals />}
            {selectedTab === 'NOTES' && <NotesList isFetchNoteList={true} />}
            {selectedTab === 'ACTIVITIES' && <PropertyActivities />}
            {selectedTab === 'FILES' && <PropertyFiles />}
            {selectedTab === 'LOG' && <PropertyLog />}
            {selectedTab === 'TASKS' && <PropertyTask />}
            {selectedTab === 'QUOTES' && <PropertQuotes />}
            {selectedTab === 'COSTS' && <PropertyDetailsCosts />}
            {selectedTab === 'PARTNERS' && <PropertyJobPartners />}
          </Fragment>
        )}
      </Fragment>
    </MainPropertyDetailsWrapper>
  );
};

const MainPropertyDetailsWrapper = styled(motion.div)`
  .selected-tab {
    border-bottom: 1px solid ${({ theme }) => theme.selectedText};
    label {
      color: ${({ theme }) => theme.selectedText};
    }
  }

  .entire-map-wrapper {
    min-width: 100%;
    height: 100%;
  }

  .main-property {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
  }

  .commercial-property {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 24px;
  }

  .other-property {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .span2 {
      grid-column: span 2;
    }
  }

  .bg-location {
    background-color: ${({ theme }) => theme.natural_100};
    :hover {
      background-color: ${({ theme }) => theme.primary_50};
    }
  }

  .other-activity {
    display: flex;
  }

  .epc-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 100px;
  }

  .job-tooltip,
  .import-button-tooltip {
    .custom-tooltip {
      padding: 8px 12px;
    }
  }

  .capitalize-value {
    label {
      text-transform: capitalize;
    }
  }

  .edit-roof {
    min-height: 500px;
  }
`;

export default MainPropertyDetails;
