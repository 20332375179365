import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as SolarPanelIcon } from '../../assets/icons/solar-panel.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as PlatformIcon } from '../../assets/images/sidebar/platforms.svg';
import { useError } from '../../hooks/useError';
import { useQuery } from '../../hooks/useQuery';
import { getPropertyDetails, getPropertyImages, getRoofInformation } from '../../store/features/propertySlice';
import PropertyMap from '../common/google-maps/property-map';
import SkeletonTransition from '../common/skeleton-transition';
import CustomTooltip from '../common/tooltip-new';
import PropertyImageOverlay from './property-image-overlay';
import RoofDesignQuote from './roof-design-quote';
import WebGLOverlay from './webgl-overlay';

const MapPanels = ({ fromProperty, property_id, fetchSolarDetails = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { property_id: property_id_param } = useParams();

  const query = useQuery();
  const takePhoto = query.get('take_photo');
  const designId = query.get('design_id');

  const { propertyDetails } = useSelector(state => state.property);
  const { details, location } = propertyDetails || {};

  const [propertyDetailsLoading, setPropertyDetailsLoading] = useState(false);
  const [roofInfoLoading, setRoofInfoLoading] = useState(false);
  const [propertyImages, setPropertyImages] = useState([]);
  const [roofs, setRoofs] = useState([]);
  const [showPanels, setShowPanels] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);

  const fetchPropertyDetails = property_id => {
    setPropertyDetailsLoading(true);
    dispatch(getPropertyDetails({ property_id: property_id }))
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_DETAILS') }))
      .finally(() => setPropertyDetailsLoading(false));
  };

  const fetchRoofDetailsHandler = property_id => {
    setRoofInfoLoading(true);
    dispatch(getRoofInformation({ id: property_id }))
      .then(data => {
        setRoofs(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_ROOF_DETAILS') }))
      .finally(() => setRoofInfoLoading(false));
  };

  const fetchPropertyImages = property_id => {
    dispatch(getPropertyImages({ id: property_id }))
      .then(data => {
        setPropertyImages(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_PROPERTY_IMAGES') }));
  };

  useEffect(() => {
    const propertyId = fromProperty ? property_id_param : property_id;
    if (propertyId) {
      if (fromProperty) {
        fetchPropertyDetails(propertyId);
      } else {
        setPropertyDetailsLoading(false);
      }
      fetchRoofDetailsHandler(propertyId);
      fetchPropertyImages(propertyId);
    }
  }, [property_id_param, fromProperty, property_id]);

  return (
    <SkeletonTransition
      loading={roofInfoLoading || propertyDetailsLoading}
      loaderClassName={'item flex items-center justify-center flex-1 mb-6'}
      height={'100%'}
      containerClassName="line-height-1 h-full w-full rounded-3">
      <div className="overflow-scroll edit-roof flex-1">
        <div className={'flex items-center justify-center radius-3 entire-map-wrapper h-full relative'}>
          <PropertyMap coordinates={location}>
            <RoofDesignQuote
              showPanels={showPanels}
              roofInformation={roofs}
              property_id={fromProperty ? property_id_param : property_id}
            />
            <PropertyImageOverlay propertyImages={propertyImages} showOverlay={showOverlay} />
            <WebGLOverlay />
          </PropertyMap>
          <ActionWrapper
            top={'8px'}
            as={'button'}
            className="bg-white radius-2 cursor border-0 flex items-center justify-center"
            onClick={() => navigate(-1)}>
            <CustomTooltip
              id={'back'}
              place="left"
              wrapperClassName="tooltip-wrapper pxy-2"
              content={<span className="inter-400-text font-12">Back</span>}>
              <CrossIcon width={16} height={16} className="primary-500-text" />
            </CustomTooltip>
          </ActionWrapper>
          <ActionWrapper
            top={'48px'}
            right={'8px'}
            as={'button'}
            className="bg-white radius-2 cursor border-0 flex items-center justify-center"
            onClick={e => setShowPanels(!showPanels)}>
            <CustomTooltip
              id={'toggle-panels'}
              place="left"
              wrapperClassName="tooltip-wrapper pxy-2"
              content={<span className="inter-400-text font-12">Toggle panels</span>}>
              <SolarPanelIcon
                width={16}
                height={16}
                className={classNames(showPanels ? 'primary-500-text' : 'natural-500-text')}
              />
            </CustomTooltip>
          </ActionWrapper>
          <ActionWrapper
            top={'88px'}
            right={'8px'}
            as={'button'}
            className="bg-white radius-2 cursor border-0 flex items-center justify-center"
            onClick={e => setShowOverlay(!showOverlay)}>
            <CustomTooltip
              id={'toggle-overlay'}
              place="left"
              wrapperClassName="tooltip-wrapper pxy-2"
              content={<span className="inter-400-text font-12">Toggle overlay</span>}>
              <PlatformIcon
                width={16}
                height={16}
                className={classNames(showOverlay ? 'primary-500-text' : 'natural-500-text')}
              />
            </CustomTooltip>
          </ActionWrapper>
        </div>
      </div>
    </SkeletonTransition>
  );
};

const MapPanelsWrapper = styled.div``;

const ActionWrapper = styled.div`
  position: absolute;
  right: ${({ right }) => right || '8px'};
  padding: ${({ padding }) => padding || '0px'};
  top: ${({ top }) => top || '8px'};
  bottom: ${({ bottom }) => bottom || 'none'};
  left: ${({ left }) => left || 'none'};

  .tooltip-wrapper {
    display: flex;
    .custom-tooltip {
      padding: 6px 12px;
      filter: drop-shadow(0px 3px 3px #0000001f);
    }
  }

  .grid-layout {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
`;

export default MapPanels;
