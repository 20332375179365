import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AttachmentIcon } from '../../assets/icons/attachment.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { sendQuote, setQuoteDetails, updateQuoteStatus } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import ButtonWithDropdown from '../common/buttonDropdown/ButtonWithDropdown';
import Dropdown from '../common/select-dropdown';
import Status from '../common/status';
import { acceptedTypeOption, draftTypeOption, estimateTypeOption, rejectedOption, sentTypeOption } from './options';

const getOption = status => {
  if (status === 'DRAFT') return draftTypeOption;
  if (status === 'SENT') return sentTypeOption;
  if (status === 'ACCEPTED') return acceptedTypeOption;
  if (status === 'REJECTED') return rejectedOption;
  if (status === 'ESTIMATE') return estimateTypeOption;
  return [];
};

const QuoteDetailsHeader = ({
  loading,
  quoteDetailsInitial,
  setQuoteDetailsInitial,
  onCancelUpdates,
  onSaveUpdates,
  setIsQuoteEditable,
  setShowActionButtons,
  showActionButtons = false,
  integrations: integrationList,
  isNewQuote,
  showSolarMap,
  setShowSolarMap,
  jobDetails,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { showErrorToast } = useError();

  const { t } = useTranslation();

  const { quoteDetails } = useSelector(state => state.quotes);
  const { setSideModal, setModal } = useContext(OrganisationContext);

  const { id, status, code, recipient, integration } = quoteDetailsInitial || {};
  const { job_type } = jobDetails || {};
  const quotingCategoryJob = job_type?.quote_category;

  const { customer_note } = recipient || {};

  const [statusUpdating, setStatusUpdating] = useState(false);

  const getStatusByStatusType = statusType => {
    if (
      statusType === 'REVISE' ||
      statusType === 'UNMARK_AS_SENT' ||
      statusType === 'UNMARK_AS_REJECTED' ||
      statusType === 'ESTIMATE' ||
      statusType === 'CONVERT_TO_DRAFT'
    ) {
      return 'DRAFT';
    } else if (statusType === 'REJECTED' || statusType === 'SEND_TO_RECIPIENT') {
      return 'REJECTED';
    } else if (statusType === 'ACCEPTED') {
      return 'ACCEPTED';
    } else if (statusType === 'SENT' || statusType === 'UNMARK_AS_ACCEPTED') return 'SENT';
    return statusType;
  };

  function sendQuoteToRecipient() {
    setStatusUpdating(true);
    dispatch(sendQuote({ quote_id: quoteDetailsInitial.id }))
      .then(data => {
        const [recipient] = data.recipients || [];
        const formattedData = {
          ...data,
          recipient: { ...recipient, ...recipient?.contact },
          attachments: quoteDetails.attachments || [],
        };
        dispatch(setQuoteDetails(formattedData));
        setQuoteDetailsInitial(formattedData);
        dispatch(addToast({ error: false, text: t('QUOTE_CODE_HAS_BEEN_SENT', { code: code }), id: nanoid() }));
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_QUOTE_STATUS') });
      })
      .finally(() => {
        setStatusUpdating(false);
        setIsQuoteEditable(false);
      });
  }

  const onUpdateQuoteStatus = (status, message) => {
    if (statusUpdating) {
      return;
    }
    if (showActionButtons) {
      dispatch(
        addToast({
          error: true,
          text: t('PLEASE_SAVE_CHANGES_BEFORE_UPDATING_QUOTE_STATUS'),
          id: nanoid(),
        }),
      );
      return;
    }
    if (status === 'SENT_QUOTE_MODEL') {
      setModal({
        type: 'send-quote',
        content: {
          recipient,
          sendQuoteToRecipient,
          onSuccess: () => setModal(initModal),
        },
      });
      return;
    }
    if (status === 'REVISE') {
      setIsQuoteEditable(true);
      setShowActionButtons(true);
      return;
    }

    setStatusUpdating(true);
    const request = { status: getStatusByStatusType(status) };
    dispatch(updateQuoteStatus({ quote_id: id, request }))
      .then(data => {
        dispatch(addToast({ error: false, text: message(data.code), id: nanoid() }));
        setQuoteDetailsInitial(data);
        setIsQuoteEditable(request.status === 'DRAFT' || request.status === 'ESTIMATE');
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_QUOTE_STATUS') });
      })
      .finally(() => setStatusUpdating(false));
  };

  return (
    <QuoteDetailsHeaderWrapper className="flex items-center justify-between py-4 px-6 border-bottom">
      <div className="flex col-gap-4 items-center">
        <label className="inter-600-text natural-900-text font-18 flex-auto">{t('QUOTE_DETAILS')}</label>
        {!isNewQuote ? (
          <span className="flex-1 inter-500-text primary-500-text line-height-20 font-12 py-0_5 px-3 radius-full cursor bg-primary-50 nowrap">
            {integration?.name || 'None'}
          </span>
        ) : (
          <div className="integration-list-wrapper flex h-full items-start w-full">
            <Dropdown
              onChange={option => {
                setQuoteDetailsInitial(prev => ({ ...prev, integration: option }));
              }}
              options={[{ label: 'None', id: null, name: 'None', value: null, connector: {} }, ...integrationList]}
              getOptionLabel={option => {
                const { connector, name } = option;
                return connector.tech_provider ? `${connector.tech_provider} - ${name}` : name;
              }}
              getOptionValue={option => option.id}
              placeholder={t('SELECT_FROM_LIST')}
              value={integration || null}
              customStyle={{
                menu: { width: 240 },
                control: {
                  minHeight: 26,
                  backgroundColor: theme.primary_50,
                  borderRadius: 100,
                  border: 'none',
                  '&:hover': {
                    borderColor: 'none',
                  },
                },
              }}
            />
          </div>
        )}
        <Status status={status} sub_status={status === 'REJECTED' ? customer_note : ''} />
        {/* {(quotingCategoryJob === 'SOLAR' ||
          quotingCategoryJob === 'SOLAR_BATTERY' ||
          quotingCategoryJob === 'BATTERY_ONLY') && (
          <div className="flex items-center border-left pl-3">
            <label className="inter-500-text natural-700-text mr-3">Solar</label>
            <Switch
              enabled={showSolarMap}
              onClick={() => {
                setShowSolarMap(!showSolarMap);
              }}
            />
          </div>
        )} */}
        <div className="flex items-center border-left pl-3">
          <button className="text-button blue-primary-icon inter-400-text line-height-20 cursor font-14  items-center flex   gap-6px">
            <EyeIcon color="#2496FF" />
            {t('PREVIEW')}
          </button>
        </div>
      </div>
      <div className="flex items-center gap-6 overflow-auto">
        {showActionButtons && (
          <div className="flex justify-end w-full gap-3">
            <Button
              className={`cancel-btn default ${loading && 'disabled'}`}
              label={t('CANCEL')}
              disabled={loading}
              onClick={() => {
                dispatch(
                  addToast({
                    error: false,
                    text: t('CHANGES_TO_QUOTE_CANCELLED', { code: code }),
                    id: nanoid(),
                  }),
                );
                onCancelUpdates();
              }}
              secondary={true}
              borderRadius="100px"
              size="medium"
            />
            <Button
              className="save-button-btn primary"
              label={t('SAVE_CHANGES')}
              disabled={loading}
              loading={loading}
              onClick={onSaveUpdates}
              primary={true}
              borderRadius="100px"
              size="medium"
            />
          </div>
        )}

        <Button
          bgColor={theme.natural_100}
          borderColor={theme.natural_100}
          borderRadius="6px"
          width="fit-content"
          className={classNames('px-4 py-2 specified-width nowrap')}
          disabled={loading}
          icon={<AttachmentIcon className={classNames(quoteDetails.attachments?.length > 0 && 'blue-primary-icon')} />}
          label={`${t('ATTACHMENTS')} (${quoteDetails.attachments?.length || 0})`}
          onClick={() => {
            setSideModal({
              type: 'upload-quote-attachment',
              content: {},
            });
          }}
          secondary={true}
          size="average"
        />

        <ButtonWithDropdown
          size="medium"
          className="secondary"
          options={getOption(status)}
          clickFunction={onUpdateQuoteStatus}
          place={'bottom-end'}
        />
      </div>
    </QuoteDetailsHeaderWrapper>
  );
};

const QuoteDetailsHeaderWrapper = styled.div`
  .integration-list-wrapper {
    min-width: 160px;
    .select__value-container {
      .select__single-value {
        color: ${({ theme }) => theme.primary_500};
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 500;
      }
    }
    .select__indicator {
      svg {
        width: 14px;
        height: 14px;
        color: ${({ theme }) => theme.primary_500};
      }
    }

    .select__placeholder {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme.primary_500};
    }
  }
`;

export default QuoteDetailsHeader;
