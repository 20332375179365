import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ClipBoard } from '../../assets/icons/copy-clipboard-icon.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icons.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcons.svg';
import { ReactComponent as PinIcon } from '../../assets/icons/map-pin.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalizeFirstLetterOfWords, formatText, handleCopyClick } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { updatePropertyDetails } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';
import IconContainer from '../common/icon-container';
import SkeletonTransition from '../common/skeleton-transition';
import Status from '../common/status';
import CustomTooltip from '../common/tooltip-new';

const PropertyDetails = ({ loading = false, showViewDetails = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showErrorToast } = useError();
  const { hasPermission, checkPermission } = useContext(OrganisationContext);

  const { propertyDetails } = useSelector(state => state.property);

  const [isCopy, setIsCopy] = useState('');
  const [propertyData, setPropertyData] = useState({});
  const [isEditPropertName, setIsEditPropertName] = useState(false);

  const { formatted_address, id: property_Id, property_class, name } = propertyData || {};
  const { class_type } = property_class || {};
  const isCommercial = class_type === 'COMMERCIAL';
  const isLand = class_type === 'LAND';

  const clickToViewDetails = () => {
    navigate(`/properties/property/${property_Id}`);
  };

  const onCopyClick = async value => {
    const response = await handleCopyClick(value);
    if (response) {
      setIsCopy(response);
      setTimeout(() => setIsCopy(''), 3000);
    }
  };

  useEffect(() => {
    if (propertyDetails) {
      setPropertyData(propertyDetails);
    }
  }, [propertyDetails]);

  const onUpdateProprtyName = async () => {
    if (name === propertyDetails?.name) {
      setIsEditPropertName(false);
      return;
    }
    const {
      formatted_address,
      city,
      line1,
      line2,
      line3,
      postcode,
      state,
      location,
      country_code,
      is_uploaded_consumption,
    } = propertyData || {};
    const request = {
      formatted_address: formatted_address,
      city: city,
      line1: line1,
      line2: line2,
      line3: line3,
      postcode: postcode,
      state: state,
      name: name,
      location: location,
      country_code: country_code,
      property_class: property_class,
      is_uploaded_consumption: is_uploaded_consumption,
    };

    await dispatch(updatePropertyDetails({ id: property_Id, request }))
      .then(response => {
        dispatch(
          addToast({
            error: false,
            text: t('PROPERTY_NAME_UPDATED_SUCCESSFULLY'),
          }),
        );
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_UPDATING_PROPERTY_NAME') });
      })
      .finally(() => {
        setIsEditPropertName(false);
      });
  };

  const editInputContent = (key, value, label, type) => {
    return (
      <div className="flex-column w-full input-container relative">
        <div className="flex items-center justify-between w-full">
          <input
            className="inter-400-text natural-900-text one-line input w-full px-2 py-1 h-auto job-input border"
            defaultValue={value}
            type={type}
            autoFocus
            onChange={({ target: { value } }) => setPropertyData({ ...propertyData, [key]: value })}
          />
        </div>
        <div className="w-full flex justify-end">
          <div className="flex justify-center items-end radius-3 property-update-action mt-1 border pxy-2 gap-2_5 absolute">
            <span
              className="flex justify-center items-center cross-icon border radius-100 cursor"
              onClick={() => {
                setPropertyData({ ...propertyDetails });
                setIsEditPropertName(false);
              }}>
              <CrossIcon color="#171717" />
            </span>
            <span
              className="flex justify-center items-center right-icon border radius-100 cursor"
              onClick={() => onUpdateProprtyName(label)}>
              <RightIcon color="#fff" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <SkeletonTransition
      loading={loading}
      height={'260px'}
      containerClassName="line-height-1"
      baseColor="#CDCDCD"
      highlightColor="#E8E8E8">
      <PropertyDetailsWrapper
        key={'property-details'}
        initial={{ opacity: 0.3, y: 10 }}
        animate={{ opacity: 1, y: 0, transition: { duration: 0.9 } }}
        className="card radius-2 pxy-6 flex-column">
        <div className="flex items-center justify-between pb-3 border-bottom">
          <label className="inter-600-text natural-900-text font-16">{t('PROPERTY')}</label>
          {class_type && (
            <Status
              withDot={false}
              withDottedBorder={false}
              status={class_type || '-'}
              statusText={capitalizeFirstLetterOfWords(formatText(class_type))}
            />
          )}
          {showViewDetails && (
            <label onClick={clickToViewDetails} className="inter-500-text primary-text cursor">
              {t('VIEW_DETAILS')}
            </label>
          )}
        </div>
        <div className="flex-column row-gap-3 mt-5">
          <div className="flex-column row-gap-1">
            <label className="flex-1 inter-400-text font-12 natural-400-text line-height-20">
              {t('PROPERTY_NAME')}
            </label>
            <div className="flex items-center inter-400-text natural-900-text line-height-20 font-14">
              {isEditPropertName ? (
                editInputContent('name', name, t('PROPERTY_NAME'), 'text')
              ) : (
                <label
                  className={classNames(
                    'flex-1 one-line',
                    name && 'inter-400-text natural-900-text',
                    !name && 'inter-400-text natural-400-text',
                    hasPermission('PROPERTIES_MANAGE') && 'hover-edit radius-1_5 cursor px-1 py-0_5',
                  )}
                  onClick={() => checkPermission(() => setIsEditPropertName(true), 'PROPERTIES_MANAGE')}>
                  {name || 'No name'}
                </label>
              )}
            </div>
          </div>
          <div className="flex-column row-gap-1">
            <label className="flex-1 inter-400-text font-12 natural-400-text line-height-20">{t('ADDRESS')}</label>
            <div className="flex">
              <IconContainer
                iconContainerClassname="mr-2 flex h-fit-content"
                iconClassName="natural-600-text"
                iconHeight={16}
                iconWidth={16}
                Icon={PinIcon}
                backgroundColor="natural_100"
              />
              <label className="flex-1 inter-400-text natural-900-text line-height-20">{formatted_address}</label>
              <CustomTooltip
                wrapperClassName="copy-to-clip-board-tooltip h-fit-content"
                id="tooltip"
                place="bottom-start"
                content={
                  <label className="flex col-gap-1 items-center">
                    {isCopy ? (
                      <>
                        <RightIcon />{' '}
                        <span className="copy-text inter-500-text font-12 white-text">{t('COPIED')}!</span>
                      </>
                    ) : (
                      <>
                        <CopyIcon />{' '}
                        <span className="copy-text inter-500-text font-12 white-text">{t('COPY_TO_CLIPBOARD')}</span>
                      </>
                    )}
                  </label>
                }>
                <IconContainer
                  iconContainerClassname="mr-2 flex h-fit-content"
                  iconClassName="natural-600-text cursor"
                  iconHeight={16}
                  iconWidth={16}
                  Icon={ClipBoard}
                  backgroundColor="natural_100"
                  onClick={() => onCopyClick(formatted_address)}
                />
              </CustomTooltip>
            </div>
          </div>
        </div>
      </PropertyDetailsWrapper>
    </SkeletonTransition>
  );
};

const PropertyDetailsWrapper = styled(motion.div)`
  .copy-to-clip-board-tooltip {
    .custom-tooltip {
      padding: 8px 12px 8px 10px;
      border-radius: 6px;
      background: #404040;
    }
  }

  .hover-edit {
    &:hover {
      background: ${({ theme }) => theme.natural_150};
      border-radius: 6px;
      padding: 2px 4px;
      .description-show-more-less {
        right: 8px;
        bottom: 2px;
      }
    }
  }

  .cross-icon {
    width: 32px;
    height: 32px;
  }

  .right-icon {
    background-color: ${({ theme }) => theme.primary_500};
    width: 32px;
    height: 32px;
  }
  .property-update-action {
    background-color: ${({ theme }) => theme.white};
    z-index: 999;
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }
`;

export default PropertyDetails;
