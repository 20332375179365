import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as EditPensil } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as EmptyIcon } from '../../assets/icons/empty-document.svg';
import { ReactComponent as DeleteIcons } from '../../assets/images/DeleteIcons.svg';
import { ReactComponent as EditIcons } from '../../assets/images/EditIcons.svg';
import { ReactComponent as HorizontalDots } from '../../assets/images/HorizontalDots.svg';
import { ReactComponent as Dot } from '../../assets/images/dot.svg';
import { ReactComponent as Loading } from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { useError } from '../../hooks/useError';
import { addNote, getNoteList, updateNote } from '../../store/features/notesSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../common/button/button';
import InputSearch from '../common/input-search';
import MenuV2 from '../common/menu/menu-v2';
import NoData from '../common/no-data';
import SkeletonTransition from '../common/skeleton-transition';

const NotesList = ({ notesHeader = true, noteListClassName = '', isFetchNoteList = false, activityNotesList = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);
  const textareaRef = useRef(null);

  const { showErrorToast } = useError();

  const localUser = getItemFromLocalStorage('user');

  const { workitemDetails } = useSelector(state => state.board);
  const engagement_id = workitemDetails?.engagement?.id;

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [noteId, setNoteId] = useState('');
  const [value, setValue] = useState({});
  const [previousNoteValue, setPreviousNoteValue] = useState();
  const [isAddNote, setIsAddNote] = useState(false);
  const [notesListItems, setNotesListItems] = useState([]);

  const formatDate = date => {
    const momentDate = moment.unix(date).format('ddd DD MMM YY');
    const hourDate = moment.unix(date).format('HH:mm');
    return (
      <span className="date inter-500-text flex items-center">
        {momentDate} <Dot /> {hourDate}
      </span>
    );
  };

  const fetchNotesList = () => {
    setLoading(true);
    dispatch(getNoteList({ engagement_id: engagement_id }))
      .then(data => {
        setNotesListItems(data);
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_NOTES_LIST') }))
      .finally(() => setLoading(false));
  };

  const clickToAddNotes = async () => {
    if (!value?.note?.trim()) {
      showErrorToast({ default_message: t('CANNOT_ADD_EMPTY_NOTE') });
      return null;
    }
    setIsLoader(true);
    if (value?.note === previousNoteValue) {
      setIsAddNote(false);
      setIsLoader(false);
      setNoteId('');
      setValue({});
      return;
    }
    try {
      const request = {
        note: value?.note,
        activity: null,
        task: null,
      };

      if (noteId) {
        const response = await dispatch(updateNote({ request, note_id: noteId }));
        if (response) {
          dispatch(addToast({ error: false, text: t('NOTE_UPDATED_SUUCESSFULLY') }));
          setIsAddNote(false);
          setIsLoader(false);
          setNoteId('');
          setValue({});
          fetchNotesList();
        }
      } else {
        const response = await dispatch(addNote({ request, engagement_id: engagement_id }));
        if (response) {
          dispatch(addToast({ error: false, text: t('NOTE_ADDED_SUUCESSFULLY') }));
          setIsLoader(false);
          setIsAddNote(false);
          setValue({});
          fetchNotesList();
        }
      }
    } catch (error) {
      showErrorToast({ error, default_message: t('ERROR_WHILE_ADDING_NOTES_LIST') });
      setIsLoader(false);
    }
  };

  const clickToUpdateNotes = note_id => {
    const selectedValue = notesListItems?.content?.find(val => val?.id === note_id);
    setPreviousNoteValue(selectedValue?.note);
    setValue({ ...value, note: selectedValue?.note });
  };

  const onDeleteNote = note_id => {
    setModal({
      type: 'note-action',
      content: {
        notesDetails: 'note',
        isEnable: true,
        note_id: note_id,
        fetchNotesList: fetchNotesList,
        title: `${t('DELETE_NOTE')}?`,
        description: t('ARE_YOU_SURE_TO_DELETE_THIS_NOTE'),
      },
    });
  };

  useEffect(() => {
    if (isFetchNoteList) {
      fetchNotesList();
    }
  }, [isFetchNoteList]);

  useEffect(() => {
    if (activityNotesList?.content?.length > 0) {
      setNotesListItems(activityNotesList);
    } else {
      setNotesListItems([]);
    }
  }, [activityNotesList?.content?.length]);

  useEffect(() => {
    if (textareaRef.current) {
      const textLength = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(textLength, textLength);
    }
  }, [value?.note]);

  return (
    <NotesListWrapper
      className={classNames(
        'flex-column pxy-6',
        isFetchNoteList ? 'overflow-hidden' : 'overflow-scroll',
        noteListClassName,
      )}>
      {notesHeader && (
        <div className="flex justify-between">
          <div className="flex align-center">
            <InputSearch placeholder={'Search'} value={searchText} onChange={setSearchText} />
          </div>
          <Button
            fontSize="14px"
            size="average"
            width="130px"
            borderRadius="100px"
            className="primary specified-width  px-4 py-2_5 ml-3"
            afterIcon={<EditPensil className="white-text mr-0" height={16} width={16} />}
            label={t('ADD_NOTE')}
            lableSize="w-64 line-height-20"
            onClick={() => {
              setIsAddNote(true);
              setValue({});
              setNoteId('');
            }}
          />
        </div>
      )}

      <div className="notes-list-deatils w-full gap-5 flex-column items-start mt-6 overflow-scroll flex-1">
        {isAddNote && (
          <div className="note-wrapper">
            <p className="inter-500-text color-neutral-900">{t('NEW_NOTE')}</p>
            <div className="flex flex-column items-start gap-6 content-text">
              <textarea
                name="note"
                autoFocus
                value={value?.note}
                className="max-w-full"
                onChange={e => setValue({ ...value, note: e.target.value })}
                rows={Math.max((value?.note || '').split('\n').length, 1)}
              />
              <div className="flex justify-end w-full gap-3">
                <Button
                  className={`button-btn cancel-btn ${loading && 'disabled'}`}
                  label={t('CANCEL')}
                  disabled={loading}
                  onClick={() => setIsAddNote(false)}
                  secondary={true}
                  size="medium"
                  borderRadius="100px"
                />
                <Button
                  className="button-btn primary"
                  label={isLoader ? <Loading height={14} /> : t('ADD')}
                  disabled={loading}
                  onClick={() => clickToAddNotes()}
                  primary={true}
                  size="medium"
                  borderRadius="100px"
                />
              </div>
            </div>
          </div>
        )}
        <SkeletonTransition
          loading={loading}
          loaderClassName={'item w-full flex items-center justify-center flex-1 mb-6'}
          height={'50vh'}
          containerClassName="line-height-1 flex-1"
          baseColor="#CDCDCD"
          highlightColor="#E8E8E8">
          {notesListItems?.content?.length > 0 ? (
            notesListItems?.content?.map((notesItem, index) => {
              return (
                <div key={index} className="note-wrapper">
                  <div className="w-full flex items-center justify-between">
                    <p className="inter-500-text color-neutral-900">{notesItem?.created?.user?.name}</p>
                    <div className="flex gap-3">
                      {formatDate(notesItem?.created?.date)}
                      {localUser?.user?.id === notesItem?.created?.user?.id && (
                        <MenuV2
                          menuClassName="notes-manue"
                          menuList={[
                            {
                              name: t('EDIT_NOTE'),
                              icon: EditIcons,
                              iconClassName: 'grey-text-04',
                              withIcon: true,
                              onClick: () => {
                                clickToUpdateNotes(notesItem?.id);
                                setNoteId(notesItem?.id);
                                setIsAddNote(false);
                              },
                              permission: 'USERS_MANAGE',
                            },
                            {
                              name: t('DELETE_NOTE'),
                              icon: DeleteIcons,
                              withIcon: true,
                              iconClassName: 'grey-text-04',
                              onClick: () => onDeleteNote(notesItem?.id),
                              permission: 'USERS_MANAGE',
                            },
                          ]}
                          Icon={HorizontalDots}
                        />
                      )}
                    </div>
                  </div>
                  {noteId && noteId === notesItem?.id ? (
                    <div className="note-wrapper">
                      <p className="inter-500-text color-neutral-900">{t('EDIT_NOTE')}</p>
                      <div className="flex flex-column items-start gap-6 content-text">
                        <textarea
                          ref={textareaRef}
                          name="note"
                          autoFocus
                          value={value?.note}
                          onChange={e => setValue({ ...value, note: e.target.value })}
                          rows={Math.max((value?.note || '').split('\n').length, 1)}
                        />
                        <div className="flex justify-end w-full gap-3">
                          <Button
                            className={`button-btn cancel-btn ${loading && 'disabled'}`}
                            label={t('CANCEL')}
                            disabled={loading}
                            onClick={() => {
                              setIsAddNote(false);
                              setNoteId('');
                              setValue({});
                            }}
                            secondary={true}
                            borderRadius="100px"
                            size="medium"
                          />
                          <Button
                            className="button-btn primary"
                            label={isLoader ? <Loading height={16} /> : t('UPDATE')}
                            disabled={loading}
                            onClick={() => clickToAddNotes()}
                            primary={true}
                            borderRadius="100px"
                            size="medium"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="content-text">
                      <p className="inter-400-text">{notesItem?.note}</p>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <NoData
              title={t('NO_NOTES')}
              description={t('NO_NOTES_HERE')}
              className={isFetchNoteList ? 'no-notes' : 'w-full'}
              EmptyIcon={EmptyIcon}
              iconClassName="relative"
            />
          )}
        </SkeletonTransition>
      </div>
    </NotesListWrapper>
  );
};

const NotesListWrapper = styled.div`
  height: 100vh;
  .notes-list-header {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    border: none;

    .header-title {
      display: none;
    }
  }
  .notes-list-deatils {
    .no-notes {
      height: 50vh;
    }

    .note-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 8px;

      .notes-manue {
        .menu-wrapper {
          display: flex;
          width: 176px;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 6px;
          box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
          padding: 0px;
          .menu-item {
            display: flex;
            padding: 12px 20px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 0px;
            &:hover {
              border-radius: 6px;
            }
            svg {
              margin: 0;
            }
            span {
              color: #171717;
              font-family: Inter;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }
          .menu-item:first-child {
            border-bottom: 1px solid #e5e5e5;
          }
        }
      }

      .date {
        font-size: 12px;
        gap: 6px;
        color: #a3a3a3;
      }
      .content-text {
        padding: 12px 16px;
        align-self: stretch;
        border-radius: 6px;
        background: #eff6ff;
        p {
          color: #404040;
          line-height: 23.8px;
        }
        textarea {
          background: transparent;
          border: 0;
          min-height: 30px;
          height: '100%';
          width: 100%;
          resize: 'none';
        }
        .button-btn {
          max-width: 72px;
          padding: 6px 12px;
          label {
            font-size: 12px;
          }
        }
        .cancel-btn {
          border: 1px solid #e5e5e5;
          background: #fff;
          font-size: 12px;
          color: #404040;
        }
      }
    }
  }
`;

export default NotesList;
