import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import styled from 'styled-components';
import Button from '../../components/common/button/button.js';
import InputElement from '../../components/common/input/index.js';
import MobileInput from '../../components/common/mobile-input/index.js';
import PhoneInput from '../../components/common/phone-input/index.js';
import SearchableDropdown from '../../components/common/searchable-dropdown/index.js';
import Dropdown from '../../components/common/select-dropdown/index.js';
import { OrganisationContext } from '../../context/organisationContext.js';
import { contactTitleOptions, employeeTypeOptions } from '../../helpers/optionData.js';
import { getErrorFieldJoined, initModal } from '../../helpers/utils.js';
import { useError } from '../../hooks/useError.js';
import { createContact } from '../../store/features/newContactSlice.js';
import { getContactDetails } from '../../store/features/newPropertySlice.js';
import { addToast } from '../../store/features/toastSlice.js';

const renderContactInfo = (employees, contactType) => {
  if (employees && employees.length > 0) {
    const filteredContacts = contactType
      ? employees.filter(employee => employee.contact_type === contactType)
      : employees;

    return (
      <>
        {filteredContacts.map((employee, index) => (
          <div className="" key={index}>
            <span className={'inter-400-text natural-700-text font-12 option-text'}>{employee.value}</span>
            {employee?.formatted_address && (
              <div className="title-one-line one-line">
                <span key={employee.id} className={'inter-400-text natural-700-text font-12 option-text '}>
                  {employee?.formatted_address}
                </span>
              </div>
            )}
          </div>
        ))}
      </>
    );
  }
  return null;
};

const CustomOption = props => {
  const { innerProps, data } = props;

  return (
    <div className={'w-full px-4 py-3 cursor border-bottom option-wrapper'} {...innerProps}>
      <div className="flex items-center justify-between pb-1_5 gap-3 select-primary-contact">
        <span className={'inter-400-text natural-900-text font-14 option-text'}>{data.label}</span>
      </div>
      <div className="flex items-center gap-2">
        {renderContactInfo(data.emails, null)}
        <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
        {renderContactInfo(data.phones, 'MOBILE')}
        <div style={{ width: '2px', height: '12px', background: '#D4D4D4' }}></div>
        {renderContactInfo(data.phones, 'LANDLINE')}
      </div>
    </div>
  );
};

const AddEditEmployee = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { setModal, modal } = useContext(OrganisationContext);
  const { onSuccess, companyData, propertyData } = modal?.content || {};

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [employeeData, setEmployeeData] = useState({ isNew: true, contact_id: uuid() });

  const checkEmployeeErrors = () => {
    const { type, title, name, email, mobile, landline } = employeeData;
    const isContactError = !email && !mobile && !landline;
    if (!name?.trim() || isContactError || !type || !title) {
      const error = {
        type: !type,
        title: !title,
        name: !name?.trim(),
        contact: isContactError ? 'at least contact' : false,
      };
      const errorFields = getErrorFieldJoined(error, (key, value) => (key === 'contact' ? value : key), ' and ');
      const errorText = `Please add ${errorFields}`;

      setError(error);
      showErrorToast({ default_message: errorText, id: nanoid() });
      return true;
    }
    setError({});
    return false;
  };

  const onDone = async () => {
    if (checkEmployeeErrors()) {
      return;
    }
    setLoading(true);

    const request = [
      {
        title: employeeData?.title?.label || '',
        forename: employeeData?.name?.split(' ')?.[0],
        surname: employeeData?.name?.split(' ')?.[1],
        property_contact_type: employeeData?.type?.value,
        contact_type: 'EMPLOYEE',
        date_of_birth: null,
        profession: '',
        timezone: null,
        pronouns: '',
        emails: employeeData?.email
          ? [
              {
                value: employeeData?.email,
                is_primary: true,
                email_type: 'WORK',
              },
            ]
          : null,
        phones:
          employeeData?.landline || employeeData?.mobile
            ? [
                employeeData?.landline
                  ? {
                      contact_type: 'LANDLINE',
                      contact_value: employeeData?.landline?.replaceAll(' ', ''),
                      is_primary: true,
                      phone_type: 'WORK',
                      country_code: '+44',
                    }
                  : null,
                employeeData?.mobile
                  ? {
                      contact_type: 'MOBILE',
                      contact_value: employeeData?.mobile?.replaceAll(' ', ''),
                      is_primary: true,
                      phone_type: 'WORK',
                      country_code: employeeData?.country_code || '+44',
                    }
                  : null,
              ].filter(Boolean)
            : null,
        addresses: null,
        contact_period: null,
        shared_percentage: 0,
        company_name: null,
        parent: companyData?.id
          ? {
              id: companyData?.id,
            }
          : null,
        job_title: companyData?.job_title || null,
        isPrimary: true,
        primary: true,
      },
    ];

    try {
      await dispatch(createContact({ id: propertyData?.id, request: request }));
      setLoading(false);
      setModal(initModal);
      dispatch(addToast({ error: false, text: t('ADD_SUCCESSFULLY_EMPLOYEE') }));
      setError({});
      setEmployeeData({ isNew: true, contact_id: uuid() });

      onSuccess && onSuccess();
    } catch (error) {
      setLoading(false);
      showErrorToast({ error, default_message: t('ERROR_WHILE_ADD_EMPLOYEE'), id: nanoid() });
    }
  };

  const onSelectEmployee = contact => {
    const emailPrimary = contact.emails?.find(email => email.is_primary) || contact?.emails?.[0] || {};
    const mobilePrimary =
      contact.phones?.find(phone => phone.is_primary && phone.contact_type === 'MOBILE') ||
      contact?.phones?.find(phone => phone.contact_type === 'MOBILE') ||
      {};
    const landlinePrimary =
      contact.phones?.find(phone => phone.is_primary && phone.contact_type === 'LANDLINE') ||
      contact?.phones?.find(phone => phone.contact_type === 'LANDLINE') ||
      {};
    setEmployeeData({
      contact_type: employeeData.contact_type,
      employees: employeeData.employees || [],
      value: contact?.value,
      label: contact?.label,
      isNew: employeeData?.isNew,
      contact_id: employeeData?.contact_id,
      title: contact.title ? contactTitleOptions.find(t => t.label === contact.title) : null,
      type: contact.type ? employeeTypeOptions.find(t => t.label === contact.type) : null,
      name: contact?.forename || contact?.surname ? `${contact.forename} ${contact.surname}` : '',
      email: emailPrimary?.value || '',
      mobile: mobilePrimary.value || '',
      country_code: mobilePrimary.country_code || '+44',
      landline: landlinePrimary.value || '',
    });
  };

  return (
    <CSSTransition appear classNames="popup-fade " in timeout={300}>
      <AddCostsWrapper className="flex-column pxy-10 overflow-scroll">
        <div className="pb-6 border-bottom">
          <p className="inter-700-text natural-900-text font-28 pb-0_5">{t('ADD_EMPLOYEE')}</p>
          <p className="inter-400-text natural-900-text font-14">{companyData?.company_name}</p>
        </div>
        <Fragment>
          <div className="flex-column row-gap-6">
            <div className="search-address flex justify-between ">
              <SearchableDropdown
                isClearable
                className="w-full"
                placeholder={t('SEARCH_FOR_AN_EMPLOYEE')}
                customStyle={{
                  control: {
                    height: '32px',
                    borderRadius: '100px',
                    background: '#F5F5F5',
                    width: '100%',
                    border: 'none',
                  },
                  valueContainer: { padding: '0 16px' },
                }}
                inputValue={search}
                onInputChange={setSearch}
                value={employeeData?.value ? employeeData : null}
                onChange={option => onSelectEmployee({ ...option, contact_type: 'EMPLOYEE' })}
                isSearchable={true}
                isCustomSearchable={false}
                defaultAdditional={{
                  page: 0,
                  fetchFunction: getContactDetails,
                  pageable: true,
                  params: { contact_type: 'EMPLOYEE', parent_id: companyData?.id },
                }}
                customComponent={{ Option: CustomOption }}
              />
            </div>
            <div className="flex col-gap-6">
              <Dropdown
                className="w-full provider-selector"
                onChange={option => {
                  setEmployeeData({ ...employeeData, type: option });
                }}
                name={t('TYPE')}
                options={employeeTypeOptions}
                placeholder={t('SELECT_FROM_LIST')}
                value={employeeData.type}
                error={error.type && !employeeData.type}
              />

              <Dropdown
                className="w-full provider-selector"
                onChange={option => {
                  setEmployeeData({ ...employeeData, title: option });
                }}
                name={t('TITLE')}
                options={contactTitleOptions}
                placeholder={t('SELECT_FROM_LIST')}
                value={employeeData.title}
                error={error.title && !employeeData.title}
              />
            </div>

            <InputElement
              className="w-full"
              name={t('FULL_NAME')}
              placeholder={t('ENTER_FULL_NAME')}
              value={employeeData.name}
              onChange={value => setEmployeeData({ ...employeeData, name: value })}
              error={error.name && !employeeData.name}
            />

            <div className="flex-column row-gap-6">
              <InputElement
                className="w-full"
                name={t('EMAIL')}
                sub_name={t('PRIMARY')}
                placeholder={t('ENTER_EMAIL')}
                value={employeeData.email}
                onChange={value => setEmployeeData({ ...employeeData, email: value })}
                error={error?.contact && !employeeData.mobile && !employeeData.landline && !employeeData.email}
              />

              <div className="w-full flex-column gap-1">
                <div className="one-line">
                  <span className="inter-500-text natural-900-text">{t('MOBILE')}</span>
                  <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                </div>
                <PhoneInput
                  selectedCountry={employeeData.country_code}
                  setSelectedCountry={country_code =>
                    setEmployeeData({ ...employeeData, country_code: country_code, mobile: '' })
                  }
                  phone={employeeData.mobile}
                  setPhone={phone => setEmployeeData({ ...employeeData, mobile: phone })}
                  error={error?.contact && !employeeData.mobile && !employeeData.landline && !employeeData.email}
                />
              </div>
              <div className="w-full flex-column gap-1">
                <div className="one-l">
                  <span className="inter-500-text natural-900-text">{t('LANDLINE')}</span>
                  <span className="inter-400-text natural-400-text ml-1">{t('PRIMARY')}</span>
                </div>
                <MobileInput
                  phone={employeeData.landline}
                  setPhone={phone => setEmployeeData({ ...employeeData, landline: phone })}
                  error={error?.contact && !employeeData.mobile && !employeeData.landline && !employeeData.email}
                />
              </div>
            </div>
            <div className="flex col-gap-6 justify-center mt-4 w-full">
              <Button
                className={classNames('primary-white flex-1')}
                label={t('CANCEL')}
                disabled={loading}
                onClick={() => setModal(initModal)}
                size="large"
                borderRadius="100px"
              />
              <Button
                className={classNames('primary flex-1')}
                label={t('ADD')}
                disabled={loading}
                loading={loading}
                onClick={onDone}
                size="large"
                borderRadius="100px"
              />
            </div>
          </div>
        </Fragment>
      </AddCostsWrapper>
    </CSSTransition>
  );
};

const AddCostsWrapper = styled.div`
  width: 456px;
  gap: 32px;
  .cost-grid-sec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default AddEditEmployee;
