import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-assignee.svg';
import { getUserInitials } from '../../../helpers/utils';
import IconContainer from '../../common/icon-container';
import CustomTooltip from '../../common/tooltip-new';

const backgroundColors = ['#2496FF', '#8324FF', '#F59E0B', '#22C55E', '#D942FF', '#171717'];

const avatarVariants = {
  size_56: { width: '56px', height: '56px', fontSize: '20px' },
  large: { width: '40px', height: '40px', fontSize: '14px' },
  size_32: { width: '32px', height: '32px', fontSize: '12px' },
  semi_large: { width: '28px', height: '28px', fontSize: '10px' },
  medium: { width: '24px', height: '24px', fontSize: '8px' },
  small: { width: '20px', height: '20px', fontSize: '8px' },
};

export const Avatar = ({
  id,
  avatar,
  backgroundColor,
  backgroundColorIndex = 1,
  zIndex = 1,
  getAvatarName = getUserInitials,
  avatarLabelClassname = '',
  avatarWrapperClassname = '',
  variant = 'large',
  onAvatarClick = () => {},
  hiddenAvatars,
}) => {
  const variantStyle = avatarVariants[variant] || avatarVariants.large;
  const colorIndex = backgroundColorIndex % backgroundColors.length;

  return (
    <OwnerWrapper
      {...variantStyle}
      className={classNames('flex items-center justify-center', avatarWrapperClassname)}
      zIndex={zIndex}
      onClick={onAvatarClick}
      key={id}
      backgroundColor={backgroundColor || backgroundColors[colorIndex]}>
      <label className={classNames(avatar?.name && 'white-text inter-600-text', avatarLabelClassname)}>
        {avatar?.name || hiddenAvatars > 0 ? (
          getAvatarName(avatar ?? {})
        ) : (
          <IconContainer
            Icon={UserIcon}
            iconColor="white"
            backgroundColor="transparent"
            iconContainerClassname="p-0"
            iconWidth={14}
            iconHeight={14}
          />
        )}
      </label>
    </OwnerWrapper>
  );
};

const OwnerGroup = ({
  avatars = [],
  groupWrapperClassname = '',
  getAvatarName = getUserInitials,
  avatarVariant = 'large',
  onAvatarClick = () => {},
  boardsFilters,
  setBoardsFilters,
}) => {
  const { owners } = boardsFilters || {};
  const selectedAvatars = owners || [];
  const selectedOwnersIds = (owners?.length > 0 && owners.map(item => item.id)) || [];
  const filteredAvatars = avatars.filter(item => !selectedOwnersIds.includes(item.id));
  const visibleAvatars = filteredAvatars.slice(0, 3);
  const hiddenAvatars = filteredAvatars.length - visibleAvatars.length;

  const handleAvatarClick = id => {
    const avatar = avatars.find(av => av.id === id);
    if (!avatar) return;

    const isSelected = selectedOwnersIds.includes(id);

    setBoardsFilters(prevFilters => {
      const updatedOwners = isSelected
        ? (prevFilters.owners || []).filter(sel => sel.id !== id)
        : [...(prevFilters.owners || []), avatar];

      return { ...prevFilters, owners: updatedOwners };
    });
  };

  return (
    <OwnerGroupWrapper className={classNames('flex items-center justify-center', groupWrapperClassname)}>
      {selectedAvatars?.length > 0 && (
        <div className="flex items-center justify-center mr-5">
          {selectedAvatars.map((avatar, index) => (
            <div key={avatar.id} onClick={() => handleAvatarClick(avatar.id)}>
              <CustomTooltip
                wrapperClassName="owner-tooltip"
                id={`selected-filter-tooltip-owner-${avatar.id}`}
                place="bottom"
                noArrow={true}
                content={
                  <label className="inter-400-text natural-700-text font-12">
                    <span className="inter-500-text font-12">{avatar?.name}</span>
                  </label>
                }>
                <Avatar
                  id={avatar.id}
                  avatar={avatar}
                  zIndex={index + 1}
                  backgroundColor={backgroundColors[index % backgroundColors.length]}
                  avatarWrapperClassname="avatar selected-avatar"
                  getAvatarName={getAvatarName}
                  variant={avatarVariant}
                />
              </CustomTooltip>
            </div>
          ))}
        </div>
      )}
      {visibleAvatars.map((avatar, index) => (
        <div key={avatar.id} onClick={() => handleAvatarClick(avatar.id)}>
          <CustomTooltip
            wrapperClassName="owner-tooltip"
            id={`filter-tooltip-owner-${avatar.id}`}
            place="bottom"
            noArrow={true}
            content={
              <label className="inter-400-text natural-700-text font-12">
                <span className="inter-500-text font-12">{avatar?.name}</span>
              </label>
            }>
            <Avatar
              id={avatar.id}
              avatar={avatar}
              zIndex={index + 1}
              backgroundColor={backgroundColors[(index + selectedAvatars.length) % backgroundColors.length]}
              avatarWrapperClassname="cursor avatar"
              getAvatarName={getAvatarName}
              variant={avatarVariant}
            />
          </CustomTooltip>
        </div>
      ))}
      <Avatar
        id={'hidden-avatars'}
        zIndex={visibleAvatars.length + 1}
        backgroundColor={hiddenAvatars ? '#E5E5E5' : '#2496FF'}
        getAvatarName={() => `+${hiddenAvatars}`}
        avatarLabelClassname="inter-400-text text-black"
        avatarWrapperClassname="cursor avatar"
        variant={avatarVariant}
        hiddenAvatars={hiddenAvatars}
        onAvatarClick={onAvatarClick}
      />
    </OwnerGroupWrapper>
  );
};

const OwnerGroupWrapper = styled.div`
  .avatar {
    margin-right: -8px;
  }
  .selected-avatar {
    box-shadow: rgb(211, 234, 254) 0px 0px 0px 4px;
  }
  .owner-tooltip {
    .custom-tooltip {
      padding: 8px 12px;
    }
  }
`;

const OwnerWrapper = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background: ${({ backgroundColor }) => backgroundColor};
  z-index: ${({ zIndex }) => zIndex};
  border: 2px solid #fff;
  border-radius: 50%;

  label {
    font-size: ${({ fontSize }) => fontSize};
  }
`;

export default OwnerGroup;
