import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert-blue.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import { OrganisationContext } from '../../context/organisationContext';
import { initModal } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { updatePaymentTerms } from '../../store/features/quotesSlice';
import { addToast } from '../../store/features/toastSlice';

const UpdatePaymentTerms = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const { modal, setModal } = useContext(OrganisationContext);
  const { onSuccess, payment_terms_template } = modal.content;

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { id, label, value, ...rest } = payment_terms_template || {};
    dispatch(updatePaymentTerms({ id: id, request: rest }))
      .then(data => {
        setLoading(false);
        dispatch(addToast({ error: false, text: t('PAYMENT_TERMS_UPDATE'), id: nanoid() }));
        setModal(initModal);
        onSuccess && onSuccess(data);
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_PAYMENT_TERMS_UPDATE'), id: nanoid() });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="relative">
      <CSSTransition appear classNames="popup-fade" in timeout={300}>
        <QuotesActionWrapper>
          <div className="update-payment">
            <div className="mb-5">
              <div className="alert-icon-wrapper">
                <IconContainer
                  Icon={AlertIcon}
                  iconColor="natural_900"
                  backgroundColor="transparent"
                  iconContainerClassname="p-0"
                  iconHeight={48}
                  iconWidth={48}
                />
              </div>
              <div className="close-icon-wrapper" onClick={() => setModal(initModal)}>
                <IconContainer
                  Icon={CloseIcon}
                  iconColor="natural_900"
                  iconClassName="cursor"
                  backgroundColor="transparent"
                  iconContainerClassname="p-0"
                />
              </div>
            </div>
            <div>
              <p className="text-center mb-3 font-18 fw-500 color-neutral-900">
                Are you sure you want to save the updates to this payment term?
              </p>
            </div>
            <div>
              <p className="text-center mb-6 font-14 model-description color-gray-secondary">
                Please note this will cause all previous and live quotes to be updated to have the same terms. To avoid
                this instead of saving the updates please create a new payment term
              </p>
            </div>
            <div>
              <div className="mb-3">
                <Button
                  className={`primary flex-1`}
                  label={loading ? 'Updating...' : 'Save'}
                  onClick={handleSubmit}
                  size="large"
                />
              </div>
              <div>
                <Button
                  className={`default flex-1 `}
                  label={'Cancel'}
                  size="large"
                  onClick={() => setModal(initModal)}
                />
              </div>
            </div>
          </div>
        </QuotesActionWrapper>
      </CSSTransition>
    </div>
  );
};

export const QuotesActionWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 24px;
  .update-payment {
    .alert-icon-wrapper {
      width: 100%;
    }
    .model-description {
      padding: 10px;
    }
    .close-icon-wrapper {
      position: absolute;
      top: 29px;
      right: 29px;
    }
  }
`;

export default UpdatePaymentTerms;
