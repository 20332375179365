import { t } from 'i18next';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Popover from '../../components/common/popover';
import PageHeader from '../../components/page-header';
import TasksFilter from '../../components/task-list/filter';
import AppliedFilters from '../../components/task-list/filter/applied-filters';
import TaskList from '../../components/task-list/task-list';
import { OrganisationContext } from '../../context/organisationContext';
import { statusOptions } from '../../helpers/optionData';
import useDebounce from '../../helpers/useDebounceHook';
import { useError } from '../../hooks/useError';
import { getTaskList } from '../../store/features/taskSlice';

const defaultStatuses = ['BLOCKED', 'TODO', 'STARTED'];

const defaultFilters = {
  due_date: null,
  priority: null,
  status: statusOptions.filter(item => defaultStatuses.includes(item.id)),
};

const Tasks = () => {
  const dispatch = useDispatch();
  const { showErrorToast } = useError();

  const { setSideModal } = useContext(OrganisationContext);
  const { user } = useSelector(state => state.user);
  const { user: internalUser, selectedAccount } = user || {};
  const { id: user_id, forename, surname, name } = selectedAccount || internalUser || {};
  const user_name = name ? name : forename || surname ? [forename, surname].join(' ') : '';

  const [sortBy, setSortBy] = useState([
    {
      id: 'due_date',
      name: t('DUE_DATE'),
      order_by: 'asc',
      order_by_text: { asc: 'Oldest first', desc: 'Newest first' },
    },
    {
      id: 'priority',
      name: t('PRIORITY'),
      order_by: 'desc',
      order_by_text: { asc: 'Lowest first', desc: 'Highest first' },
    },
  ]);
  const [searchText, setSearchText] = useState('');
  const [taskFilters, setTaskFilters] = useState({ ...defaultFilters, assignee: [{ id: user_id, name: user_name }] });
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const debouncedSearch = useDebounce(searchText, 500);

  const fetchTasksList = (page, merge, showLoading = true) => {
    const { due_date, priority, status, assignee } = taskFilters || {};
    const assignee_ids = (assignee || []).map(item => item?.id).join(',');
    const priority_ids = (priority || []).map(item => item?.id).join(',');
    const status_ids = (status || []).map(item => item?.id).join(',');
    const sort_by = sortBy.map(item => `${item.id},${item.order_by}`);

    return dispatch(
      getTaskList({
        merge,
        params: {
          page: page,
          size: 20,
          search: debouncedSearch ?? undefined,
          assignee_id: assignee_ids || undefined,
          status: status_ids || undefined,
          sort_by: sort_by,
          'due_date.max': due_date || undefined,
          priority: priority_ids || undefined,
        },
      }),
    ).catch(error => {
      showErrorToast({ error, default_message: t('ERROR_WHILE_FETCHING_TASKS') });
    });
  };

  const handleShowAddTask = () => {
    setSideModal({
      type: 'add-new-task',
      content: {
        fromTaskList: true,
        onSuccess: () => {
          fetchTasksList(0, false);
        },
      },
    });
  };

  const onResetFilters = () => {
    setTaskFilters({});
  };

  const BoardFilterWrapper = useCallback(
    ({ children }) => {
      const ref = useOnclickOutside(() => setShowFilterPopover(false));
      return (
        <Popover
          isOpen={showFilterPopover}
          offset={[0, 2]}
          content={
            <div ref={ref}>
              <TasksFilter
                setShowSortPopover={setShowFilterPopover}
                setBoardsFilters={setTaskFilters}
                boardsFilters={taskFilters}
                onClearFilters={onResetFilters}
                isFromTaskList={true}
              />
            </div>
          }>
          {children}
        </Popover>
      );
    },
    [showFilterPopover],
  );

  return (
    <TasksWrapper className="flex-column flex-1">
      <Routes>
        <Route
          index
          element={
            <Fragment>
              <PageHeader
                headerTitle={t('TASKS')}
                showAddNewBtn
                showSearch
                wrapperClassName="px-6 pb-4 pt-5"
                addButtonLabel={t('ADD_TASKS')}
                addBtnPermission="TASKS_MANAGE"
                searchText={searchText}
                onSearchChange={setSearchText}
                onAddClick={handleShowAddTask}
                BoardFilterWrapper={BoardFilterWrapper}
                showBoardsFilter
                showBoardFilterPopover={true}
                setBoardsFilters={setTaskFilters}
                boardsFilters={setTaskFilters}
                onBoardFilterClick={() => setShowFilterPopover(prev => !prev)}
              />
              <AppliedFilters
                sortBy={sortBy}
                canRemoveSort={false}
                boardsFilters={taskFilters}
                setBoardsFilters={setTaskFilters}
                onClearAllFilters={onResetFilters}
              />
              <TaskList debouncedSearch={debouncedSearch} fetchTasksList={fetchTasksList} filters={taskFilters} />
            </Fragment>
          }
        />
      </Routes>
    </TasksWrapper>
  );
};

const TasksWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export default Tasks;
