import classNames from 'classnames';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import Button from '../../components/common/button/button.js';
import DateSelectorElement from '../../components/common/date-selector/date-selector-element.js';
import InputElement from '../../components/common/input/index.js';
import SearchableDropdown from '../../components/common/searchable-dropdown/index.js';
import { OrganisationContext } from '../../context/organisationContext.js';
import { initModal } from '../../helpers/utils.js';
import { useError } from '../../hooks/useError.js';
import { getPropertyContacts } from '../../store/features/propertySlice.js';
import { createQuote, getPaymentTerms } from '../../store/features/quotesSlice.js';
import { addToast } from '../../store/features/toastSlice.js';

const CustomOption = ({ innerProps, data, isSelected, selectProps }) => {
  const { getOptionLabel } = selectProps;
  return (
    <div
      className={classNames(
        'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <span
        className={classNames(
          'flex-1 inter-400-text natural-900-text option-text ml-2',
          isSelected && 'inter-500-text',
        )}>
        {getOptionLabel(data)}
      </span>
      <div className="bg-pink radius-4 px-3 py-0_5 line-height-1 ">
        <label className="inter-400-text color-purple font-12 option-text line-height-20">
          {data.profession || 'Tenant'}
        </label>
      </div>
    </div>
  );
};

const PaymentCustomOption = ({ innerProps, data, isSelected }) => {
  return (
    <div
      className={classNames(
        'flex items-center w-full px-4 py-3 cursor border-bottom option-wrapper',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <span
        className={classNames(
          'flex-1 inter-400-text natural-900-text option-text ml-2',
          isSelected && 'inter-500-text',
        )}>
        {data.label}
      </span>
    </div>
  );
};

const AddQuote = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showErrorToast } = useError();

  const userId = user.user.id;
  const { setModal, modal } = useContext(OrganisationContext);
  const { engagement_id, property_id, onSuccess } = modal?.content || {};

  const [quoute, setQuote] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchedContact, setSearchedContact] = useState('');

  const { code, description, contact, expiration_date, payment_terms } = quoute;

  const checkQuoteErrors = () => {
    if (!contact?.id) {
      setError({
        contact: !contact?.id,
      });
      showErrorToast({ default_message: t('PLEASE_SELECT_RECIPIENT'), id: nanoid() });
      return true;
    }
    return false;
  };

  const onDone = async () => {
    if (loading) {
      return;
    }
    if (checkQuoteErrors()) {
      return;
    }
    setLoading(true);
    const request = {
      code: code,
      description: description,
      salesperson: { id: userId },
      expiry_date: expiration_date || moment().add(30, 'day').unix(),
      quote_type: 'COST',
      total_amount: 0,
      total_tax: 0,
      total_net: 0,
      status: 'DRAFT',
      payment_terms_template: payment_terms?.id
        ? {
            id: payment_terms?.id,
          }
        : null,
      recipients: [
        {
          contact: {
            id: quoute?.contact?.id,
          },
          requires_acceptance: true,
        },
      ],
    };
    dispatch(createQuote({ engagement_id: engagement_id, request }))
      .then(data => {
        setLoading(false);
        setModal(initModal);
        onSuccess && onSuccess(data);
        dispatch(
          addToast({
            error: false,
            title: t('QUOTE_ADDED'),
            text: t('SUCCESSFULLY_QUOTE_ADDED'),
            id: nanoid(),
          }),
        );
      })
      .catch(error => {
        showErrorToast({ error, default_message: t('ERROR_WHILE_ADDING_QUOTE'), id: nanoid() });
        setLoading(false);
      });
  };

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {}, payload = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map((option, index) => ({
        ...option,
        label: option.name,
        value: option.id,
        optionIndex: index,
      }));

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddQuoteWrapper className="flex-column pxy-10">
        <div className="flex justify-between pb-6 border-bottom">
          <p className="inter-700-text natural-900-text font-28">{t('ADD_AUOTE')}</p>
        </div>
        <div className="flex-column row-gap-6 mt-8">
          <div className="w-full flex-column">
            <InputElement
              name={t('QUOTE_NUMBER')}
              sub_name={t('OPTIONAL')}
              onChange={value => {
                setQuote({ ...quoute, code: value });
              }}
              value={code || ''}
              placeholder={t('EXAMPLE_EH_0001')}
            />
          </div>
          <div className="w-full flex-column">
            <InputElement
              name={t('DESCRIPTION')}
              sub_name={t('OPTIONAL')}
              onChange={value => {
                setQuote({ ...quoute, description: value });
              }}
              value={description || ''}
              placeholder={t('EXAMPLE_QUOTE_DESCRIPTION')}
              type="textarea"
            />
          </div>
          <div className="w-full flex-column">
            <SearchableDropdown
              isClearable
              inputValue={searchedContact}
              onInputChange={setSearchedContact}
              loadOptions={fetchChildOpt}
              placeholder={t('SELECT_RECIPENT')}
              name={t('RECIPIENT')}
              value={contact}
              onChange={option => {
                setQuote({ ...quoute, contact: option });
                setError({ ...error, contact: false });
              }}
              defaultAdditional={{
                page: 0,
                fetchFunction: getPropertyContacts,
                pageable: false,
                payload: {
                  property_id: property_id,
                },
              }}
              getOptionLabel={option => {
                return option.contact_type === 'COMPANY' ? option.company_name : option.name;
              }}
              customComponent={{ Option: CustomOption }}
              error={error.contact && !contact?.id}
            />
          </div>
          <div className="w-full flex-column">
            <DateSelectorElement
              name={t('EXPIRATION_DATE')}
              sub_name={t('OPTIONAL')}
              setSelectedDate={value => {
                setQuote({ ...quoute, expiration_date: value });
              }}
              selectedDate={expiration_date || ''}
              placeholder={t('EXAMPLE_DATE')}
              isCalendarIcon={false}
              minStartDate={moment().subtract(1, 'day').startOf('day').unix()}
            />
          </div>
          <div className="w-full flex-column">
            <SearchableDropdown
              inputValue={searchedContact}
              onInputChange={setSearchedContact}
              loadOptions={fetchChildOpt}
              placeholder={t('SELECT_PAYMENT_TERMS')}
              name={t('PAYMENT_TERMS')}
              value={payment_terms}
              onChange={option => {
                setQuote({ ...quoute, payment_terms: option });
              }}
              defaultAdditional={{
                page: 0,
                fetchFunction: getPaymentTerms,
                pageable: false,
              }}
              customComponent={{ Option: PaymentCustomOption }}
            />
          </div>
        </div>
        <div className="flex col-gap-6 justify-center mt-12 w-full">
          <Button
            className={classNames('primary-white flex-1', loading && 'disabled')}
            label={t('CANCEL')}
            disabled={loading}
            onClick={() => setModal(initModal)}
            size="large"
            borderRadius="100px"
          />
          <Button
            className={classNames('primary flex-1', loading && 'disabled')}
            label={t('ADD')}
            disabled={loading}
            onClick={onDone}
            size="large"
            borderRadius="100px"
          />
        </div>
      </AddQuoteWrapper>
    </CSSTransition>
  );
};

const AddQuoteWrapper = styled.div`
  width: 460px;
`;

export default AddQuote;
