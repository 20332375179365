import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Loader } from '../../assets/images/loading.svg';
import { getErrorDescription } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { useQuery } from '../../hooks/useQuery';
import { redirectOauth2 } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';

const Oauth2Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { showErrorToast } = useError();

  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');
  const queryError = query.get('error');
  const queryErrorDescription = query.get('error_description');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const onRedirectOauth2 = () => {
    dispatch(redirectOauth2({ request: { code, state } }))
      .then(data => {
        dispatch(addToast({ text: t('CONNECTED_SUCCESSFULLY'), id: nanoid() }));
        navigate(`/platforms/integrations/integration-details/${data.id}`, { replace: true });
      })
      .catch(error => {
        const errorText = getErrorDescription(error, t('ERROR_WHILE_CONNECTING'));
        setError(errorText);
        showErrorToast({ error, default_message: t('ERROR_WHILE_CONNECTING') });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (queryError || queryErrorDescription) {
      showErrorToast({ default_message: queryErrorDescription || queryError });
      setError(queryErrorDescription || queryError);
      setLoading(false);
      return;
    }
    onRedirectOauth2();
  }, [code, state, queryError, queryErrorDescription]);

  return (
    <Oauth2RedirectWrapper className="flex items-center justify-center">
      {loading ? (
        <Loader width={40} height={40} />
      ) : error ? (
        <label className="inter-500-text error-text font-20">{error}</label>
      ) : null}
    </Oauth2RedirectWrapper>
  );
};

const Oauth2RedirectWrapper = styled.div`
  height: 100dvh;
`;

export default Oauth2Redirect;
