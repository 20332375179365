import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as DropdownIcon } from '../../assets/icons/down-arrow.svg';
import { capitalize, formatText } from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { getEvidences, getEvidencesData } from '../../store/features/propertySlice';
import Button from '../common/button/button';
import IconContainer from '../common/icon-container';
import CustomTooltip from '../common/tooltip-new';

const ViewPdf = ({
  design_id,
  generatePdfData,
  onPdfGeneratedFinished = () => {},
  onPdfGenerationStart = () => {},
  onPdfGeneratedFailed = () => {},
}) => {
  const dispatch = useDispatch();
  const { showErrorToast } = useError();
  const generatingPdfTimeout = useRef(null);

  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPdfDropdown, setShowPdfDropdown] = useState(false);

  const pdfsInDescOrder = useMemo(() => {
    return pdfs.sort((a, b) => {
      const {
        evidence_date: { created_on: created_on_A },
      } = a;
      const {
        evidence_date: { created_on: created_on_B },
      } = b;
      return moment.unix(created_on_B).diff(moment.unix(created_on_A));
    });
  }, [pdfs]);

  const defaultFirstPdf = useMemo(() => {
    return pdfsInDescOrder[0];
  }, [pdfsInDescOrder]);

  const isMoreThanOnePdf = pdfsInDescOrder.length > 1;

  const fetchPdfsGenerate = (pdfGeneration = false) => {
    const showLoading = !pdfGeneration;
    setLoading(showLoading);
    dispatch(getEvidencesData({ id: generatePdfData?.id }))
      .then(data => {
        if (pdfGeneration && data) {
          if (data?.status === 'GENERATED') {
            onPdfGeneratedFinished();
            clearTimeout(generatingPdfTimeout.current);
            onViewPdf(data);
            fetchPdfs();
          } else if (data?.status === 'FAILED') {
            onPdfGeneratedFailed();
            clearTimeout(generatingPdfTimeout.current);
            fetchPdfs();
          }
        } else {
          clearTimeout(generatingPdfTimeout.current);
          fetchPdfs();
        }
      })
      .catch(() => {
        onPdfGeneratedFailed();
        clearTimeout(generatingPdfTimeout.current);
        fetchPdfs();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPdfs = (loading = false) => {
    setLoading(loading);
    dispatch(getEvidences({ params: { solar_design_id: design_id }, forFetchOnly: true }))
      .then(data => {
        const filteredPdfs = data.filter(pdf => pdf.status !== 'FAILED');
        setPdfs(filteredPdfs || []);
      })
      .catch(() => {
        setPdfs([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const pollPdfGeneration = () => {
    if (generatingPdfTimeout.current) {
      clearTimeout(generatingPdfTimeout.current);
    }
    fetchPdfsGenerate(true);

    generatingPdfTimeout.current = setTimeout(() => {
      pollPdfGeneration();
    }, 2000);
  };

  useEffect(() => {
    fetchPdfs(true);
    return () => {
      setPdfs([]);
    };
  }, [design_id]);

  useEffect(() => {
    if (generatePdfData?.id) {
      onPdfGenerationStart();
      pollPdfGeneration();
    }
  }, [generatePdfData?.id]);

  const onViewPdf = pdf => {
    const { media, status } = pdf || { media: { url: null } };
    const { url } = media || { url: null };

    if (status !== 'GENERATED') {
      showErrorToast({ default_message: `This PDF is in ${capitalize(formatText(status))} status` });
      return;
    }
    if (!url) {
      showErrorToast({ default_message: 'No PDF available to view for now' });
      return;
    }
    window.open(url, '_blank');
  };

  const renderPdfDropdown = () => {
    return pdfsInDescOrder.map((pdf, index) => {
      const {
        evidence_date: { created_on },
        name: file_name,
      } = pdf;
      return (
        <div
          key={created_on}
          className={classNames(
            'flex items-center justify-between px-4 py-3 col-gap-6 cursor pdf-dropdown-item',
            index !== 0 && 'border-top',
          )}
          onClick={() => onViewPdf(pdf)}>
          <label className="inter-400-text one-line">{file_name}</label>
          <label className="inter-400-text font-12 natural-500-text nowrap">
            {moment.unix(created_on).format('MMM DD, YYYY HH:mm')}
          </label>
        </div>
      );
    });
  };

  return (
    <ViewPdfWrapper className="flex items-center w-full">
      <Button
        className={classNames('primary', isMoreThanOnePdf && 'view-pdf-button')}
        label="View PDF"
        size="medium"
        loading={loading}
        disabled={pdfsInDescOrder?.length <= 0}
        onClick={() => onViewPdf(defaultFirstPdf)}
      />
      {isMoreThanOnePdf && (
        <CustomTooltip
          id="pdf-dropdown-tooltip"
          positionStrategy={'absolute'}
          wrapperClassName="pdf-dropdown-wrapper"
          place="bottom"
          disabled={pdfsInDescOrder?.length <= 0}
          clickable
          onBlurTooltip={() => setShowPdfDropdown(false)}
          hidden={!showPdfDropdown}
          isOpen={showPdfDropdown}
          content={renderPdfDropdown()}>
          <IconContainer
            onClick={() => setShowPdfDropdown(!showPdfDropdown)}
            Icon={DropdownIcon}
            iconColor="white"
            iconContainerClassname="bg-primary-500 cursor dropdown-container"></IconContainer>
        </CustomTooltip>
      )}
    </ViewPdfWrapper>
  );
};

const ViewPdfWrapper = styled.div`
  display: flex;
  gap: 2px;

  .view-pdf-button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .dropdown-container {
    padding: 8px 12px;
    border-radius: 12px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .pdf-dropdown-wrapper {
    .custom-tooltip {
      padding: 0;
      width: calc(100% + 32px) !important;
      border-radius: 6px;
      box-shadow: 0px 0px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1);

      .pdf-dropdown-item {
        &:hover {
          background-color: ${({ theme }) => theme.natural_50};
        }
      }
    }
  }
`;

export default ViewPdf;
