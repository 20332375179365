import { DrawingManagerF, OverlayView, OverlayViewF, Polygon, useGoogleMap } from '@react-google-maps/api';
import classNames from 'classnames';
import React, { Fragment, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ReactComponent as AddPanelIcon } from '../../assets/icons/add-panel.svg';
import { ReactComponent as AddRoofIcon } from '../../assets/icons/add-roof.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as EditPensilIcon } from '../../assets/icons/edit-pensil.svg';
import { ReactComponent as EnergyLeafIcon } from '../../assets/icons/energy-leaf.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as HandIcon } from '../../assets/icons/hand.svg';
import { ReactComponent as LayoutIcon } from '../../assets/icons/layout.svg';
import { ReactComponent as PanelRotateLandscapeIcon } from '../../assets/icons/panel-rotate-landscape.svg';
import { ReactComponent as PanelRotatePortraitIcon } from '../../assets/icons/panel-rotate-portrait.svg';
import { ReactComponent as PointerIcon } from '../../assets/icons/pointer.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/right-Icons.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg';
import RoofPanelIconImage from '../../assets/icons/roof-panel.svg';
import { ReactComponent as SegmentPanelAddIcon } from '../../assets/icons/segment-panel-add.svg';
import { ReactComponent as SolarPanelIcon } from '../../assets/icons/solar-panel.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as PlatformIcon } from '../../assets/images/sidebar/platforms.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import CustomTooltip from '../../components/common/tooltip-new';
import { OrganisationContext } from '../../context/organisationContext';
import {
  getFormattedNumberStyle,
  getPolygonCenter,
  getRoofDetailsFromManualOrImported,
  rotatePoint,
} from '../../helpers/utils';
import { useError } from '../../hooks/useError';
import { importRoofInformation } from '../../store/features/propertySlice';
import { addToast } from '../../store/features/toastSlice';

const PolygonWrapper = ({ polygonId, editable, draggable, paths, setPaths, options, onPolygonClick = () => {} }) => {
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPaths(nextPath, polygonId);
    }
  }, [setPaths]);

  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit),
      );
    },
    [onEdit],
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  const onClick = useCallback(() => {
    if (polygonRef.current) {
      onPolygonClick(polygonRef.current, polygonId);
    }
  }, [onEdit]);

  return (
    <Polygon
      key={polygonId}
      editable={editable}
      draggable={draggable}
      paths={paths}
      onMouseUp={onEdit}
      onDragEnd={onEdit}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
      onClick={onClick}
    />
  );
};

const calcBounds = (center, size, isLandScape) => {
  const top = google.maps.geometry.spherical.computeOffset(center, size.height, 0);
  const right = google.maps.geometry.spherical.computeOffset(center, size.width, 90);
  const left = google.maps.geometry.spherical.computeOffset(center, size.width, 270);

  const topRight = google.maps.geometry.spherical.computeOffset(top, size.width, 90);
  const bottomLeft = google.maps.geometry.spherical.computeOffset(left, size.height, 180);

  return new google.maps.LatLngBounds(
    new google.maps.LatLng(bottomLeft.lat(), bottomLeft.lng()),
    new google.maps.LatLng(topRight.lat(), topRight.lng()),
  );
};

const MarkerWrapper = ({
  polygonId,
  editable,
  draggable,
  paths,
  setPaths,
  options,
  onPolygonClick = () => {},
  bounds,
  angle,
  isPanelToBeDeleted,
  isPanelToDrag,
  onLoadPanel = () => {},
  onUnmountPanel = () => {},
}) => {
  const theme = useTheme();
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPaths(nextPath, polygonId);
    }
  }, [setPaths]);

  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit),
      );
      onLoadPanel(polygon);
    },
    [onEdit],
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
    onUnmountPanel();
  }, []);

  const onClick = useCallback(() => {
    if (polygonRef.current) {
      onPolygonClick(polygonRef.current, polygonId);
    }
  }, [onEdit]);

  return (
    <>
      <Polygon
        key={polygonId}
        editable={editable}
        draggable={draggable}
        paths={paths}
        onMouseUp={onEdit}
        onDragEnd={onEdit}
        onDrag={onEdit}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
        onClick={onClick}
      />

      <OverlayViewF
        key={`overlay-${polygonId}`}
        zIndex={1000}
        bounds={bounds}
        getPixelPositionOffset={(_width, _height) => {
          return { x: 0.5, y: 0.5 };
        }}
        mapPaneName={OverlayView.OVERLAY_LAYER}
        onLoad={overlay => {
          const containerDiv = overlay.container;
          containerDiv.style.transform = `rotate(${angle}deg)`;
        }}>
        <div
          className="h-full w-full"
          style={{
            border: isPanelToDrag ? `1px solid ${theme.primary_500}` : options?.visible ? '0.6px solid white' : 'none',
            backgroundImage: options?.visible ? `url(${RoofPanelIconImage})` : 'none',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.9,
            backgroundColor: isPanelToBeDeleted ? '#ab1c20' : options?.visible ? theme.natural_900 : 'transparent',
          }}></div>
      </OverlayViewF>
    </>
  );
};

const RoofEdit = ({
  segments,
  roofPanels,
  onSegmentBoundryUpdate,
  onSaveSegment,
  onCancelSegment,
  onDeleteSegment,
  onUpdatePanelPaths,
  propertyImages,
  segmentsToSave,
  onSegmentDetailsUpdate,
  panelsToDelete,
  onPanelSelectForDelete,
  annual_electric_kwh,
  onClearPanelSelection,
  onUpdatePanelCenter,
  roofInformation,
  property_id,
  sloarDetails,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const panelRefs = useRef({});
  const map = useGoogleMap();
  const mapProjection = map?.getProjection();

  const { showErrorToast } = useError();

  const { t } = useTranslation();
  const { setModal } = useContext(OrganisationContext);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [currentPanelBySegments, setCurrentPanelBySegment] = useState({});
  const [showPanels, setShowPanels] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedTab, setSelectedTab] = useState('DESIGN');
  const [editSegment, setEditSegment] = useState(null);
  const [selectedInstrument, setSelectedInstrument] = useState(null);
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [isAllPanelSelectedOfSegment, setIsAllPanelSelectedOfSegment] = useState(null);
  const [collapsedRoofs, setCollapsedRoofs] = useState({});
  const [newRoofPolygon, setNewRoofPolygon] = useState(null);
  const [savingRoof, setSavingRoof] = useState(false);

  const totalPanels = roofPanels?.length || 0;
  const isRoofAdd = selectedInstrument?.key === 'ADD_ROOF';

  const currentActivePanels = useMemo(() => {
    const panelsBySegments = roofPanels?.reduce((acc, panel) => {
      const { roof } = panel;
      if (acc[roof.id]) {
        acc[roof.id].push(panel);
      } else {
        acc[roof.id] = [panel];
      }
      return acc;
    }, {});
    return Object.keys(currentPanelBySegments).reduce((acc, segmentId) => {
      const panels = panelsBySegments[segmentId] || [];
      const currentPanels = panels.slice(0, currentPanelBySegments[segmentId]);
      return [...acc, ...currentPanels];
    }, []);
  }, [currentPanelBySegments, roofPanels]);

  const currentActivePanelIds = useMemo(() => {
    return currentActivePanels?.map(panel => panel.id);
  }, [currentActivePanels]);

  const currentActivePanelCount = currentActivePanels.length;

  const currentPanelEnergy = useMemo(() => {
    return currentActivePanels?.reduce((acc, panel) => acc + panel.yearly_energy, 0);
  }, [currentActivePanels, roofPanels]);

  const totalPanelEnergy = useMemo(() => {
    return roofPanels?.reduce((acc, panel) => acc + panel.yearly_energy, 0);
  }, [roofPanels]);

  const roofColors = useMemo(() => {
    return [theme.primary_500, theme.success_500, theme.orange_500, theme.error_500, theme.natural_500];
  }, []);

  const onInstrumentClick = instrument => {
    const { key, isDisabled } = instrument;

    if (isDisabled || !map) return;
    if (key === 'HAND') {
      map.setOptions({ gestureHandling: 'greedy', keyboardShortcuts: true });
    }
    if (key === 'POINTER') {
      map.setOptions({ gestureHandling: 'none', keyboardShortcuts: false, draggableCursor: null });
    } else {
      setSelectedPanels([]);
    }
    if (key === 'DELETE') {
      map.setOptions({ gestureHandling: 'none', keyboardShortcuts: false, draggableCursor: null });
    } else {
      onClearPanelSelection();
    }
    setSelectedInstrument(instrument);
  };

  const instrumentTools = useMemo(() => {
    return [
      {
        name: 'Hand',
        icon: HandIcon,
        key: 'HAND',
        onClick: onInstrumentClick,
      },
      {
        name: 'Pointer',
        icon: PointerIcon,
        key: 'POINTER',
        onClick: onInstrumentClick,
      },
      {
        name: 'Rotate portrait',
        icon: PanelRotatePortraitIcon,
        key: 'ROTATE_PORTRAIT',
        onClick: () => {},
        isDisabled: true,
      },
      {
        name: 'Rotate landscape',
        icon: PanelRotateLandscapeIcon,
        key: 'ROTATE_LANDSCAPE',
        onClick: () => {},
        isDisabled: true,
      },
      {
        name: 'Add roof',
        icon: AddRoofIcon,
        key: 'ADD_ROOF',
        onClick: onInstrumentClick,
        isDisabled: !!newRoofPolygon,
      },
      {
        name: 'Delete',
        icon: DeleteIcon,
        key: 'DELETE',
        onClick: onInstrumentClick,
      },
      {
        name: 'Layout',
        icon: LayoutIcon,
        key: 'LAYOUT',
        onClick: onInstrumentClick,
        isDisabled: true,
      },
    ];
  }, [map, newRoofPolygon]);

  const tabs = useMemo(() => {
    return [
      {
        name: 'Design',
        key: 'DESIGN',
      },
      {
        name: 'Quote',
        key: 'QUOTE',
      },
    ];
  }, []);

  const onDeleteRoofSegment = (roof_id, index, isSegment) => {
    setModal({
      type: 'delete-roof-segment-action',
      content: {
        roof_id: roof_id,
        isSegment: isSegment,
        property_id: property_id,
        title: isSegment ? `${t('SEGMENT_TITLE', { index: index + 1 })}?` : `${t('ROOF_TITLE', { index: index + 1 })}?`,
        description: isSegment ? t('ARE_YOU_SURE_DELETE_SEGMENT') : t('ARE_YOU_SURE_DELETE_ROOF'),
      },
    });
  };

  const onSegmentClick = segmentId => {
    const isSegmentAlreadySelected = selectedSegments.includes(segmentId);
    if (isSegmentAlreadySelected) {
      setSelectedSegments(selectedSegments.filter(seg => seg !== segmentId));
    } else {
      setSelectedSegments([...selectedSegments, segmentId]);
    }
  };

  const onSegmentAzimuthChange = (segmentId, azimuth, prevAzimuth) => {
    const segmentPanels = roofPanels.filter(panel => panel.roof.id === segmentId);
    const polygonCenter = getPolygonCenter(
      segmentPanels.map(({ center }) => ({ lat: center.lat, lng: center.lon })).flat(),
    );
    const origin = mapProjection.fromLatLngToPoint(polygonCenter);

    const updatedPanelCenters = segmentPanels.map(({ center, ...rest }) => {
      const point = mapProjection.fromLatLngToPoint({ lat: center.lat, lng: center.lon });
      const rotatedPoint = rotatePoint(point, origin, azimuth - prevAzimuth);
      const newCenter = mapProjection.fromPointToLatLng(rotatedPoint);
      return { ...rest, center: { lat: newCenter.lat(), lon: newCenter.lng() } };
    });

    onUpdatePanelCenter(updatedPanelCenters, [segmentId]);
    onSegmentDetailsUpdate(segmentId, 'azimuth', azimuth);
  };

  const onSegmentPanelSliderChange = (segmentId, value) => {
    setCurrentPanelBySegment({ ...currentPanelBySegments, [segmentId]: value });
  };

  const renderRoofSegment = roof => {
    const { segments: roofSegments } = roof;
    const roofSegmentIds = roofSegments?.map(segment => segment.id);
    const segmentsToRender = segments?.filter(segment => roofSegmentIds.includes(segment.id));

    return segmentsToRender?.map((segment, index) => {
      const { segment: segmentNumber, id, panels_count } = segment;
      const { id: editSegmentId, type } = editSegment || {};

      const roofColor = roofColors[segmentNumber % roofColors.length];
      const pitch = getRoofDetailsFromManualOrImported(segment, 'pitch', false, false, false) || 0;
      const azimuth = getRoofDetailsFromManualOrImported(segment, 'azimuth', false, false, false) || 0;
      const isSelected = selectedSegments.includes(id);
      const isSegmentToSave = segmentsToSave.includes(id);
      const isSegmentToEdit = editSegmentId === id;
      const isAllPanelSelected = isAllPanelSelectedOfSegment === id;
      const currentPanelsAdded = currentPanelBySegments[id] || 0;

      return (
        <div
          key={id}
          className={classNames('pxy-3 border radius-2 row-gap-3 flex-column', isSelected && 'selected-segment')}>
          <div className="pb-3 border-bottom flex items-center col-gap-2">
            <div className="dot" style={{ backgroundColor: roofColor }} />
            <label className="inter-500-text flex-1">Section {index + 1}</label>
            {isSegmentToSave ? (
              <div className="flex items-center col-gap-1">
                <span
                  className="flex justify-center items-center cross-icon border radius-100 cursor pxy-1"
                  onClick={e => {
                    e.stopPropagation();
                    onCancelSegment(id);
                  }}>
                  <CrossIcon className="natural-500-text" height={12} width={12} />
                </span>
                <span
                  className="flex justify-center items-center bg-primary-500 border radius-100 cursor pxy-1"
                  onClick={e => {
                    e.stopPropagation();
                    onSaveSegment(id);
                  }}>
                  <RightIcon color="#FFFFFF" height={12} width={12} />
                </span>
              </div>
            ) : (
              <div className="flex items-center col-gap-2">
                <IconContainer
                  Icon={SegmentPanelAddIcon}
                  backgroundColor={'natural_100'}
                  iconColor={'natural_500'}
                  iconContainerClassname="radius-50-percent cursor"
                />
                <IconContainer
                  Icon={AddPanelIcon}
                  backgroundColor={isAllPanelSelected ? 'primary_50' : 'natural_100'}
                  iconColor={isAllPanelSelected ? 'primary_500' : 'natural_500'}
                  iconContainerClassname="radius-50-percent cursor"
                  onClick={() => {
                    if (isAllPanelSelected) {
                      setIsAllPanelSelectedOfSegment(null);
                    } else {
                      setIsAllPanelSelectedOfSegment(id);
                      setSelectedPanels([]);
                    }
                  }}
                />
                <IconContainer
                  Icon={EyeIcon}
                  backgroundColor={isSelected ? 'primary_50' : 'natural_100'}
                  iconColor={isSelected ? 'primary_500' : 'natural_500'}
                  iconContainerClassname="radius-50-percent cursor"
                  onClick={() => onSegmentClick(id)}
                />
                <IconContainer
                  Icon={DeleteIcon}
                  backgroundColor="natural_100"
                  iconColor="natural_500"
                  iconContainerClassname="radius-50-percent cursor"
                  onClick={() => {
                    onDeleteRoofSegment(id, index, true);
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex items-center col-gap-2">
            <div className="flex-column row-gap-2 w-full">
              <div className="flex items-center justify-between flex-1 col-gap-4 segment-detail-grid">
                <div className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1">
                  <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                    0<span className="inter-500-text natural-400-text ml-1">obstacles</span>
                  </label>
                </div>
                {isSegmentToEdit && type === 'PITCH' ? (
                  <InputElement
                    type="number"
                    autoFocus
                    value={pitch}
                    variant="size_28"
                    inputMode="numeric"
                    className="input-pitch"
                    showNumberArrows={true}
                    onBlur={() => setEditSegment(null)}
                    onChange={value => onSegmentDetailsUpdate(id, 'pitch', value)}
                    onWheel={e => e.target.blur()}
                  />
                ) : (
                  <div
                    className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1"
                    onClick={e => {
                      e.stopPropagation();
                      setEditSegment({ id, type: 'PITCH' });
                    }}>
                    <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                      {getFormattedNumberStyle(pitch, 'decimal')}°
                      <span className="inter-500-text natural-400-text ml-1">pitch</span>
                    </label>
                  </div>
                )}
              </div>
              <div className="items-center justify-between w-full col-gap-4 segment-detail-grid">
                {isSegmentToEdit && type === 'AZIMUTH' ? (
                  <InputElement
                    type="number"
                    autoFocus
                    value={azimuth}
                    variant="size_28"
                    placeholder="0"
                    className="input-azimuth"
                    showNumberArrows={true}
                    onBlur={e => {
                      const value = parseFloat(e.target.value);
                      if (value > 360) {
                        onSegmentDetailsUpdate(id, 'azimuth', parseFloat(value % 360).toFixed(2));
                      }
                      if (value < 0) {
                        onSegmentDetailsUpdate(id, 'azimuth', parseFloat(360 + (value % 360)).toFixed(2));
                      }
                      setEditSegment(null);
                    }}
                    onChange={value => onSegmentAzimuthChange(id, value, azimuth)}
                    onWheel={e => e.target.blur()}
                  />
                ) : (
                  <div
                    className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1"
                    onClick={e => {
                      e.stopPropagation();
                      setEditSegment({ id, type: 'AZIMUTH' });
                    }}>
                    <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                      <span className="inter-500-text natural-400-text mr-1">Azimuth</span>
                      {getFormattedNumberStyle(azimuth, 'decimal')}°
                    </label>
                  </div>
                )}
                <div className="flex items-center flex-1 bg-natural-100 py-1 px-2 radius-1">
                  <label className="inter-500-text natural-900-text flex items-center flex-1 line-height-20">
                    {panels_count || 0}
                    <span className="inter-500-text natural-400-text ml-1">panels</span>
                  </label>
                </div>
              </div>
              <div className="flex col-gap-1 items-center mt-2">
                <label className="inter-500-text">Panels Count</label>
                <input
                  className="slider flex-1"
                  type="range"
                  min="0"
                  max={panels_count}
                  value={currentPanelsAdded}
                  onChange={({ target: { value } }) => onSegmentPanelSliderChange(id, value)}
                />
                <label className="inter-500-text">{panels_count}</label>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderCircularProgress = (header, label, value, lastValue, icon, color, showRightBorder = false) => {
    const progress = (value / lastValue) * 75;

    return (
      <div
        className={classNames(
          'flex-column items-center flex-1 justify-center row-gap-2',
          showRightBorder && 'border-right',
        )}>
        <label className="inter-600-text font-12">{header}</label>
        <CircularProgress color={theme[color]} progress={`${progress}%`}>
          <div className="flex-column items-center justify-center data">
            <IconContainer Icon={icon} iconColor={color} backgroundColor="transparent" iconWidth={24} iconHeight={24} />
          </div>
        </CircularProgress>
        <label className="inter-500-text font-10 flex-1">{label}</label>
      </div>
    );
  };

  const onPolygonEdit = (paths, polygonId) => {
    const updatedPath = paths.map(path => ({ lat: path.lat, lon: path.lng }));
    const polygonArea = google.maps.geometry.spherical.computeArea(paths?.map(path => new google.maps.LatLng(path)));
    onSegmentBoundryUpdate(polygonId, updatedPath, polygonArea);
  };

  const onPanelEdit = (paths, panelId, segment_id, center) => {
    try {
      const currentPanelData = roofPanels.find(panel => panel.id === panelId);
      const panelCenter = getPolygonCenter(paths);
      const isPointerSelected = selectedInstrument?.key === 'POINTER';
      const isAllPanelSelected = isAllPanelSelectedOfSegment === segment_id;
      if (isAllPanelSelected || isPointerSelected) {
        const distanceBetweenLatLon = google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(center.lat, center.lng),
          new google.maps.LatLng(panelCenter.lat, panelCenter.lng),
        );
        const headingBetweenLatLon = google.maps.geometry.spherical.computeHeading(
          new google.maps.LatLng(center.lat, center.lng),
          new google.maps.LatLng(panelCenter.lat, panelCenter.lng),
        );

        const otherPanels = isAllPanelSelected
          ? roofPanels.filter(panel => panel.roof.id === segment_id && panel.id !== panelId)
          : selectedPanels
              .filter(pId => pId !== panelId)
              .map(panelId => roofPanels.find(panel => panel.id === panelId));
        const updatedOtherPanelCenter = otherPanels.map(panel => {
          const newCenter = google.maps.geometry.spherical.computeOffset(
            new google.maps.LatLng(panel.center.lat, panel.center.lon),
            distanceBetweenLatLon,
            headingBetweenLatLon,
          );
          return { ...panel, center: { lat: newCenter.lat(), lon: newCenter.lng() } };
        });
        const updatedCurrentPanel = { ...currentPanelData, center: { lat: panelCenter.lat, lon: panelCenter.lng } };
        const newPanelsWithUpdatedCenter = [updatedCurrentPanel, ...updatedOtherPanelCenter];
        const segmentIds = newPanelsWithUpdatedCenter.map(panel => panel.roof.id);
        onUpdatePanelCenter(newPanelsWithUpdatedCenter, segmentIds);
      } else {
        onUpdatePanelPaths(panelId, { lat: panelCenter.lat, lon: panelCenter.lng }, segment_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPanelClick = (_polygon, polygonId) => {
    const isPanelDeleteSelected = selectedInstrument?.key === 'DELETE';
    const isPointerSelected = selectedInstrument?.key === 'POINTER';
    if (isPanelDeleteSelected) {
      onPanelSelectForDelete(polygonId);
    }
    if (isPointerSelected) {
      const isSelected = selectedPanels.includes(polygonId);
      if (isSelected) {
        setSelectedPanels(selectedPanels.filter(panel => panel !== polygonId));
      } else {
        setSelectedPanels([...selectedPanels, polygonId]);
      }
    }
  };

  const renderSegmentPolygons = () => {
    return segments?.map(segment => {
      const { segment: segmentNumber, id } = segment;
      const roofColor = roofColors[segmentNumber % roofColors.length];
      const boundary = getRoofDetailsFromManualOrImported(segment, 'boundary', false, false, false);
      const isSelected = selectedSegments.includes(id);

      return (
        <PolygonWrapper
          key={`polygon-${id}`}
          polygonId={id}
          paths={boundary?.slice(0, boundary.length - 1).map(bound => ({
            lat: parseFloat(bound?.lat),
            lng: bound?.lon ? parseFloat(bound?.lon) : parseFloat(bound?.lng),
          }))}
          setPaths={onPolygonEdit}
          options={{
            map: map,
            strokeWeight: 1,
            strokeColor: roofColor,
            fillColor: roofColor,
            fillOpacity: 0.3,
            strokeOpacity: 1,
            geodesic: false,
            zIndex: segmentNumber,
            editable: isSelected,
            draggable: isSelected,
            visible: isSelected,
          }}
        />
      );
    });
  };

  const renderPanelPolygons = () => {
    return roofPanels?.map(panel => {
      const { id, center, height: panelHeight, width: panelWidth, orientation, roof } = panel;
      const isVisible = showPanels && currentActivePanelIds.includes(id);
      const panelSegment = segments.find(segment => segment.id === roof.id);
      const isPanelToBeDeleted = panelsToDelete.includes(id);
      const isPanelSelected = selectedPanels.includes(id) && isVisible;
      const isLandScape = orientation === 'LANDSCAPE';
      const isPanelToDrag = isAllPanelSelectedOfSegment === roof.id && isVisible;

      let height = panelHeight / 2;
      let width = panelWidth / 2;

      if (isLandScape) {
        const previousHeight = height;

        height = width;
        width = previousHeight;
      }

      const angle = parseFloat(getRoofDetailsFromManualOrImported(panelSegment, 'azimuth', false, false, false) || 0);
      const pitch = parseFloat(getRoofDetailsFromManualOrImported(panelSegment, 'pitch', false, false, false) || 0);
      const pitchElevation = Math.sin(pitch / 360);

      height = height - pitchElevation;

      const centerPosition = {
        lat: center.lat,
        lng: center.lon,
      };

      const top = google.maps.geometry.spherical.computeOffset(centerPosition, height, angle + 0);
      const right = google.maps.geometry.spherical.computeOffset(centerPosition, width, angle + 90);
      const left = google.maps.geometry.spherical.computeOffset(centerPosition, width, angle + 270);

      const topRight = google.maps.geometry.spherical.computeOffset(top, width, angle + 90);
      const bottomRight = google.maps.geometry.spherical.computeOffset(right, height, angle + 180);
      const bottomLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 180);
      const topLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 0);

      return (
        <MarkerWrapper
          key={id}
          polygonId={id}
          paths={[topRight, bottomRight, bottomLeft, topLeft]}
          setPaths={(paths, polygonId) => {
            onPanelEdit(paths, polygonId, roof.id, centerPosition);
          }}
          bounds={calcBounds(centerPosition, {
            height: isLandScape ? width : height,
            width: isLandScape ? height : width,
          })}
          onPolygonClick={onPanelClick}
          angle={isLandScape ? angle + 90 : angle}
          isPanelToBeDeleted={isPanelToBeDeleted}
          isPanelToDrag={isPanelToDrag || isPanelSelected}
          onLoadPanel={polygon => {
            panelRefs.current[id] = polygon;
          }}
          onUnmountPanel={() => {
            panelRefs.current[id] = null;
          }}
          options={{
            map: map,
            strokeWeight: 1,
            fillColor: 'transparent',
            strokeColor: 'red',
            fillOpacity: 0,
            strokeOpacity: 0,
            geodesic: true,
            zIndex: 1000,
            draggable: isVisible,
            visible: isVisible,
            imageVisible: true,
          }}
        />
      );
    });
  };

  const renderGroundOverlay = () => {
    const propertyImage = propertyImages?.find(image => image.image_type === 'AERIAL');
    const { image, boundary } = propertyImage || {};

    if (image && boundary) {
      return (
        <OverlayViewF
          bounds={
            new google.maps.LatLngBounds(
              new google.maps.LatLng(boundary[2].lat, boundary[3].lon),
              new google.maps.LatLng(boundary[0].lat, boundary[1].lon),
            )
          }
          getPixelPositionOffset={(width, height) => {
            return { x: Math.sin(-width), y: Math.sin(-height) };
          }}
          mapPaneName={OverlayView.MAP_PANE}>
          <div>
            <img
              src={image?.url}
              alt="property"
              height={'100%'}
              width={'100%'}
              style={{ border: '4px solid white', visibility: showOverlay ? 'visible' : 'hidden' }}
            />
          </div>
        </OverlayViewF>
      );
    }

    return null;
  };

  const renderInstruments = () => {
    return instrumentTools.map(tool => {
      const { key, icon: Icon, name, onClick, isDisabled } = tool;
      const isSelected = selectedInstrument?.key === key;

      return (
        <CustomTooltip
          key={key}
          id={key}
          place="bottom"
          wrapperClassName="instrument-tooltip-wrapper"
          delayShow={200}
          content={<span className="inter-400-text font-12">{name}</span>}>
          <IconContainer
            key={key}
            Icon={Icon}
            iconColor={isDisabled ? 'natural_300' : isSelected ? 'primary_500' : 'natural_500'}
            backgroundColor="natural_100"
            iconWidth={18}
            iconHeight={18}
            onClick={() => onClick(tool)}
            iconContainerClassname={classNames('cursor px-1_5 py-1_5', isDisabled && 'opacity-50 pointer-events-none')}
          />
        </CustomTooltip>
      );
    });
  };

  const onCancelDrawRoof = () => {
    newRoofPolygon?.setMap(null);
    setNewRoofPolygon(null);
  };

  const onSaveDrawRoof = () => {
    const centerOfRoof = getPolygonCenter(
      newRoofPolygon
        .getPath()
        .getArray()
        .map(path => ({ lat: path.lat(), lng: path.lng() })),
    );
    const request = {
      center: { lat: centerOfRoof.lat, lon: centerOfRoof.lng },
    };
    setSavingRoof(true);
    dispatch(importRoofInformation({ property_id: property_id, request }))
      .then(() => {
        onCancelDrawRoof();
        dispatch(addToast({ error: false, text: t('ROOF_SAVED_SUCCESSFULLY') }));
      })
      .catch(error => showErrorToast({ error, default_message: t('ERROR_WHILE_SAVING_NEW_ROOF') }))
      .finally(() => setSavingRoof(false));
  };

  const onRedrawRoof = () => {
    onCancelDrawRoof();
    setSelectedInstrument(instrumentTools.find(tool => tool.key === 'ADD_ROOF'));
  };

  const onContinueToSystemOverview = () => {
    navigate(`system-overview/${sloarDetails[0]?.id}`);
  };

  return (
    <Fragment>
      <RightActionIconWrapper
        top={'8px'}
        right={'48px'}
        padding={'8px'}
        as={'button'}
        className="bg-white radius-2 cursor border-0 flex items-center justify-center pxy-2"
        onClick={onContinueToSystemOverview}>
        <label className="inter-500-text font-12 primary-500-text mr-1">Continue to system overview</label>
        <RightArrowIcon width={14} height={14} className="primary-500-text" />
      </RightActionIconWrapper>
      <RightActionIconWrapper
        top={'8px'}
        as={'button'}
        className="bg-white radius-2 cursor border-0 flex items-center justify-center"
        onClick={() => navigate(-1)}>
        <CustomTooltip
          id={'back'}
          place="left"
          wrapperClassName="tooltip-wrapper pxy-2"
          content={<span className="inter-400-text font-12">Back</span>}>
          <CrossIcon width={16} height={16} className="primary-500-text" />
        </CustomTooltip>
      </RightActionIconWrapper>
      <RightActionIconWrapper
        top={'48px'}
        as={'button'}
        className="bg-white radius-2 pxy-2 cursor border-0 flex items-center justify-center"
        onClick={e => setShowPanels(!showPanels)}>
        <CustomTooltip
          id={'toggle-panels'}
          place="left"
          wrapperClassName="tooltip-wrapper pxy-2"
          content={<span className="inter-400-text font-12">Toggle panels</span>}>
          <SolarPanelIcon
            width={16}
            height={16}
            className={classNames(showPanels ? 'primary-500-text' : 'natural-500-text')}
          />
        </CustomTooltip>
      </RightActionIconWrapper>
      <RightActionIconWrapper
        top={'88px'}
        as={'button'}
        className="bg-white radius-2 pxy-2 cursor border-0 flex items-center justify-center"
        onClick={e => setShowOverlay(!showOverlay)}>
        <CustomTooltip
          id={'toggle-overlay'}
          place="left"
          wrapperClassName="tooltip-wrapper pxy-2"
          content={<span className="inter-400-text font-12">Toggle overlay</span>}>
          <PlatformIcon
            width={16}
            height={16}
            className={classNames(showOverlay ? 'primary-500-text' : 'natural-500-text')}
          />
        </CustomTooltip>
      </RightActionIconWrapper>
      {newRoofPolygon && (
        <NewRoofWrapper className="flex items-center col-gap-3">
          <Button
            size="medium"
            borderRadius="6px"
            label="Cancel"
            afterIcon={<CrossIcon width={14} height={14} className="primary-500-text" />}
            className="primary-white px-3 specified-width"
            width="fit-content"
            onClick={onCancelDrawRoof}
            disabled={savingRoof}
          />
          <Button
            size="medium"
            borderRadius="6px"
            label="Save"
            afterIcon={<RightIcon className="primary-500-text" />}
            className="primary-white px-3 specified-width"
            width="fit-content"
            onClick={onSaveDrawRoof}
            loading={savingRoof}
          />
          <Button
            size="medium"
            borderRadius="6px"
            label="Re-draw"
            afterIcon={<EditPensilIcon width={14} height={14} className="primary-500-text" />}
            className="primary-white px-3 specified-width"
            width="fit-content"
            onClick={onRedrawRoof}
            disabled={savingRoof}
          />
        </NewRoofWrapper>
      )}
      <RoofEditWrapper className="absolute">
        {segments && roofPanels && map ? (
          <div className="flex-column data-wrapper bg-white radius-2">
            {renderSegmentPolygons()}
            {renderPanelPolygons()}
            {renderGroundOverlay()}
            <DrawingManagerF
              key={'drawing-manager'}
              drawingMode={isRoofAdd ? 'polygon' : null}
              options={{
                drawingControl: false,
                polygonOptions: {
                  fillColor: `#2196F3`,
                  strokeColor: `#2196F3`,
                  fillOpacity: 0.5,
                  strokeWeight: 2,
                  clickable: true,
                  editable: true,
                  draggable: true,
                  zIndex: 100,
                },
              }}
              onPolygonComplete={poly => {
                setNewRoofPolygon(poly);
                setSelectedInstrument(instrumentTools.find(tool => tool.key === 'HAND'));
              }}
            />
            <div className="pxy-2 flex items-center">
              {tabs.map(({ key, name }) => (
                <div
                  key={key}
                  onClick={() => setSelectedTab(key)}
                  className={classNames(
                    'pxy-2 flex-1 flex items-center justify-center cursor',
                    selectedTab === key ? 'border-bottom-primary' : 'border-bottom',
                  )}>
                  <label
                    className={classNames(
                      'inter-600-text',
                      selectedTab === key ? 'primary-500-text' : 'natural-700-text',
                    )}>
                    {name}
                  </label>
                </div>
              ))}
            </div>
            <Fragment>
              <div className="px-2 py-4 flex items-center radius-2 ">
                {renderCircularProgress(
                  'Panels',
                  `${currentActivePanelCount}/${totalPanels}`,
                  currentActivePanelCount || 0,
                  totalPanels || 0,
                  SolarPanelIcon,
                  'primary_500',
                  true,
                )}
                {renderCircularProgress(
                  'Annual Output',
                  `${getFormattedNumberStyle(currentPanelEnergy || 0, 'decimal', 0, 0)} kWh`,
                  currentPanelEnergy || 0,
                  totalPanelEnergy || 0,
                  EnergyLeafIcon,
                  currentPanelEnergy > annual_electric_kwh ? 'error_500' : 'success_500',
                )}
              </div>
              <div className="flex items-center justify-evenly border-top px-3 py-4">{renderInstruments()}</div>
              <div className="px-4 py-4 border-top flex-column row-gap-4 overflow-scroll custom-scrollbar thin-scrollbar">
                {roofInformation?.map((info, index) => (
                  <div key={info.id} className="flex-column row-gap-4">
                    <div className="flex items-center">
                      <label className="inter-600-text flex-1">ROOF {index + 1}</label>
                      <Button
                        icon={<AddIcon className="primary-500-text" />}
                        label="Add section"
                        width="120px"
                        className="secondary specified-width mr-4"
                      />
                      <IconContainer
                        Icon={DeleteIcon}
                        backgroundColor="primary_50"
                        iconColor="primary_500"
                        iconContainerClassname="radius-50-percent cursor mr-4"
                        onClick={() => {
                          onDeleteRoofSegment(info?.whole_roof?.id, index);
                        }}
                      />
                      <IconContainer
                        Icon={RightArrowIcon}
                        iconContainerClassname={classNames(
                          'radius-50-percent cursor',
                          collapsedRoofs[info?.whole_roof?.id] ? 'rotate-0' : 'rotate-90',
                        )}
                        onClick={() => {
                          setCollapsedRoofs({
                            ...collapsedRoofs,
                            [info?.whole_roof?.id]: !collapsedRoofs[info?.whole_roof?.id],
                          });
                        }}
                      />
                    </div>
                    {!collapsedRoofs[info?.whole_roof?.id] && renderRoofSegment(info)}
                  </div>
                ))}
              </div>
            </Fragment>
          </div>
        ) : null}
      </RoofEditWrapper>
    </Fragment>
  );
};

const RightActionIconWrapper = styled.div`
  position: absolute;
  right: ${({ right }) => right || '8px'};
  padding: ${({ padding }) => padding || '0px'};
  top: ${({ top }) => top || '8px'};

  .tooltip-wrapper {
    display: flex;
    .custom-tooltip {
      padding: 6px 12px;
      filter: drop-shadow(0px 3px 3px #0000001f);
    }
  }
`;

const SolarPanelWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const OverviewPanelWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 48px;
`;

const NewRoofWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 420px;
`;

const RoofEditWrapper = styled.div`
  left: 8px;
  top: 8px;
  overflow: hidden;
  height: calc(100% - 16px);
  overflow: hidden;

  .data-wrapper {
    min-width: 396px;
    max-width: 396px;
    height: fit-content;
    max-height: 100%;
    overflow: hidden;
  }

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.natural_300};
  }

  .segment-detail-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .selected-segment {
    border: 1px solid ${({ theme }) => theme.focus_border};
    box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.focus_border};
    .dot {
      background-color: ${({ theme }) => theme.primary_500};
    }
  }

  .panel-details {
    .input {
      height: 24px;
      padding: 4px;
      width: 60px;
      font-size: 12px;
      &:focus {
        box-shadow: none;
        border: 1px solid ${({ theme }) => theme.primary_500};
      }
    }
  }

  .input-pitch,
  .input-azimuth {
    .input-element {
      &:focus {
        box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.focus_border};
      }
    }
  }

  .instrument-tooltip-wrapper {
    .custom-tooltip {
      padding: 6px 12px;
      filter: drop-shadow(0px 3px 3px #0000001f);
    }
  }
`;

const CircularProgress = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-135deg);
  background: conic-gradient(rgb(232, 234, 237) 75%, transparent 0deg);

  &::before {
    content: '';
    position: absolute;
    inset: 0px;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: conic-gradient(${({ color }) => color} ${({ progress }) => progress}, transparent 0);
  }

  .data {
    border-radius: 50%;
    background: white;
    font-weight: bold;
    text-align: center;
    transform: rotate(135deg);
    width: 56px;
    height: 56px;
  }
`;

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;

  .canvas-stage {
    width: 100%;
    height: 100%;
  }
`;

export default RoofEdit;
